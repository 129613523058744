
export function GetAllLocalProducts (){
    return JSON.parse(window.localStorage.getItem('allProduct'))
}
export function GetAllLocalVariance (){
    return JSON.parse(window.localStorage.getItem('allVariance'))
}
export function LocalDateToTime(time) {
    let mySpitDate =time.split('T')
    let datum = Date.parse(mySpitDate[0] + ' '+mySpitDate[1]);
    datum =  datum/1000;
    return datum
    //database123456
}
export function RearrangeDate(date) {
    if (date) {
        let mySpitDate =date?.split('-')
        let day = ''
        let month = ''
        if (parseInt(mySpitDate[0]) < 10) {
            day = '0' +mySpitDate[2]
        }else{
            day = mySpitDate[2]
        }
        if (parseInt(mySpitDate[1]) < 10) {
            month =  mySpitDate[1]
        }else{
            month = mySpitDate[1]
        }

        return day+'-'+ month + '-'+ mySpitDate[0]
    }
}
export function RearrangeDateTwo(date) {
   if (date) {
    let mySpitDate =date?.split('/')
    let day = ''
    let month = ''
    if (mySpitDate[0] < 10) {
        day = '0' +mySpitDate[0]
    }else{
        day = mySpitDate[0]
    }

    if (mySpitDate[1] < 10) {
        month = '0' + mySpitDate[1]
    }else{
        month = mySpitDate[1]
    }

    return mySpitDate[2]+'-'+day+'-'+ month
   }
}
export function RearrangeDateThree(date) {
   //console.log(date)
   if (date) {
    let mySpitDate =date?.split('/')
    let day = ''
    let month = ''
    //console.log(mySpitDate)
    if (mySpitDate[1] < 10) {
        day = '0' +mySpitDate[1] 
    }else{
        day = mySpitDate[1] 
    }

    if (mySpitDate[0] < 10) {
        month = '0' + mySpitDate[0]
    }else{ 
        month =  mySpitDate[0]
    }  

    return day+'-'+ month + '-'+mySpitDate[2]
   }
}

export function LocalTimeStampToDate(timeStamp) {
    const dateInMillis = parseInt(timeStamp)
    const date = new Date(dateInMillis)
    let myDate = date.toLocaleDateString()
    let myTime = date.toLocaleTimeString()
    myDate = myDate.replaceAll('/', '-')
    return myDate + " " + myTime
}
export function LocalTimeStampToDateOnly(timeStamp) {
    const dateInMillis = timeStamp
    const date = new Date(dateInMillis)
    let myDate = date.toLocaleDateString()
   // myDate = myDate.replaceAll('/', '-')
   if (timeStamp) {
    let arrangeDate = myDate?.split('/')
    myDate = arrangeDate[1] + '-'+arrangeDate[0] + '-'+arrangeDate[2]
    return myDate
   }
   
} 

export function GetGenaratedTime(){
    const myDate = new Date();
    
        let year = myDate.getFullYear();
        let month = myDate.getMonth() + 1;
        let day = myDate.getDate()
        
        let hour = myDate.getHours();
        let minute = myDate.getMinutes()
        let seconds = myDate.getSeconds()
       // console.log(year, month, day)
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        if (hour < 10) {
            hour = '0' + hour
        }
        if (minute < 10) {
            minute = '0' + minute
        }
        if (seconds < 10) {
            seconds = '0' + seconds
        }
        let finalTime = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + seconds
        return finalTime
}
export function GetStartDate(start_date){
    var tempstartDate = new Date(start_date);
    var startDate = new Date(tempstartDate).setHours(tempstartDate.getHours()-6);
      startDate = new Date(startDate).getTime()
      return startDate
}
export function GetStartDateActual(start_date){
    var tempstartDate = new Date(start_date);
    var startDate = new Date(tempstartDate).setHours(tempstartDate.getHours());
      startDate = new Date(startDate).getTime()
      return startDate
}
export function GetEndDate(end_date){
    var tempendDate = new Date(end_date);
    var endDate = new Date(tempendDate).setHours(tempendDate.getHours()+18);
    endDate = new Date(endDate).getTime()
    return endDate
}
export function GetEndDateActual(end_date){
    var tempendDate = new Date(end_date);
    var endDate = new Date(tempendDate).setHours(tempendDate.getHours());
    endDate = new Date(endDate).getTime()
    return endDate
}
export function GetInputFormatedDate() {
    let mydate = new Date();
    let day = mydate.getDate()
    let month = mydate.getMonth() + 1
    if (day < 10) {
        day = '0' + day
    }
    if (month < 10) {
        month = '0' + month
    }
    let year = mydate.getFullYear()
    let okDate = year+'-'+ month+'-'+day
    // return year+'-'+ month+'-'+day
   return okDate
}
export function GetMyFormattedDate(date) {
    let mydate = new Date(date);
    let day = mydate.getDate()
    let month = mydate.getMonth() + 1
    if (day < 10) {
        day = '0' + day
    }
    if (month < 10) {
        month = '0' + month
    }
    let year = mydate.getFullYear()
    let okDate = day+'-'+ month+'-'+year
    // return year+'-'+ month+'-'+day
   return okDate
}

export function GetMonthname(month) {
    month = parseInt(month)
    let monthName = ''
    switch (month) {
      case 1:
        monthName = 'January';
        break;
      case 2:
        monthName = 'February';
        break;
      case 3:
        monthName = 'March';
        break;
      case 4:
        monthName = 'April';
        break;
      case 5:
        monthName = 'May';
        break;
      case 6:
        monthName = 'June';
        break;
      case 7:
        monthName = 'July';
        break;
      case 8:
        monthName = 'August';
        break;
      case 9:
        monthName = 'September';
        break;
      case 10:
        monthName = 'October';
        break;
      case 11:
        monthName = 'November';
        break;
      case 12:
        monthName = 'December';
        break;

      default:
        break;
    }
    return monthName
  }
