import React from 'react'
import PopUpInputCss from './Css/PopUpInput.module.css'

const PopUpInput = (props) => {
    const { popname, poplabel, popplaceholder, poptype, ondatachange, value, myref, imageLink } = props;
    const imgStyle = {
        height: "50px",
        width: 'auto',
    }
    return (
        <>
            <div className={PopUpInputCss.wrapper}>
                <div className={PopUpInputCss.inputcomm}>
                    <label>{poplabel}</label>
                    <input ref={myref} className={imageLink ? `${PopUpInputCss.withimage} ${PopUpInputCss.popinput}` : `${PopUpInputCss.popinput}`} name={popname} type={poptype} placeholder={popplaceholder} onChange={ondatachange} value={value}  />
                    {imageLink ? <div className={PopUpInputCss.imageShow}><img style={imgStyle} src={imageLink} /></div> : ""}

                </div>

            </div>
        </>
    )
}

export default PopUpInput