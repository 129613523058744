import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DeleteItemExpress, GetAllDataExpress, GetFilteredDataExpress, GetSingleDocExpress, UpdateItemExpress } from '../../api/ExpressFunctions'
import PurchaseQuantityCss from  './css/PurchaseQuantityEdit.module.css'
const PurchaseQuantityEdit = () => {
    const myParams = useParams()
    const [poData, setPoData] = useState()
    const [mySupplier, setMySupplier] = useState()
    const [myTabelItem, setTableItem] = useState([])
    const [myFinalData, setMyFinalData] = useState()
    const [alerMessage, setAlertMessage] = useState()
    const [deleteList, setDeleteList] = useState([])
    
    async function getPoData() {
        let mData = await GetSingleDocExpress('purchase', myParams.poid)
        
        let tableData = await GetFilteredDataExpress('purchase_items', 'purchase_id', myParams.poid)
        let supplierData = await GetAllDataExpress('Supplier')
        setMySupplier(supplierData)
      //  console.log(mData)
        let dataArr = []
        tableData.forEach(element => {
            element = {...element, oldQuantity:element.quantity}
            dataArr.push(element)
        });
        setPoData(mData[0])
        setTableItem(dataArr)
    }
    useEffect(() => {
        getPoData()
    }, [])

    function decreaseClick(e, index) {
        let tableArr = [...myTabelItem]
        tableArr[index].quantity = tableArr[index].quantity ? tableArr[index].quantity - 1 : 0
        setTableItem([...tableArr])
    }
    function increaseClick(e, index) {
        let tableArr = [...myTabelItem]
        tableArr[index].quantity = tableArr[index].quantity ? tableArr[index].quantity + 1 : 1
        setTableItem([...tableArr])
    }
    function onQuantityChange(e, index) {
        let name = e.target.name
        let value = e.target.value
        let tableArr = [...myTabelItem]
        tableArr[index][name] = parseInt(value)
        setTableItem([...tableArr])
    }
    function onPriceChange(e, index) {
        let name = e.target.name
        let value = e.target.value
        let tableArr = [...myTabelItem]
        tableArr[index][name] = value
        setTableItem([...tableArr])
    }
    function onRemove(e, index) {
        let tableArr = [...myTabelItem]
       let deleteData =  tableArr.splice(index, 1)
        setTableItem([...tableArr])
        setDeleteList([...deleteList, deleteData[0]])
        console.log(deleteData)
    }
    const [discountState, setDiscountState] = useState()
    function discountClick(e) {
        let name = e.target.getAttribute('name');
        let myValue = false
        if (!discountState?.[name]) {
            myValue = true
        }
        setPoData({ ...poData, fixed: 0, percent: 0 })
        setDiscountState({ ...discountState, discountfixed: false, discountpercent: false, [name]: myValue })
    }
    function discountChange(e) {
        let name = e.target.name
        let value = e.target.value
        setPoData({ ...poData, [name]: value })
    }
    function paymentMethodClick(e) {
        // let name = e.target.name
        let value = e.target.value
        setPoData({ ...poData, paymentMethod: value })
    }
    function payAmmountChange(e) {
        let name = e.target.name
        let value = e.target.value
        setPoData({ ...poData, [name]: value })
    }
    const marginTop = {
        marginTop: '60px'
    }

    const tableItem = myTabelItem?.map((item, index) => {
        return <MyTable key={item.id} sl={index + 1} itemName={item.en_sort_title}
            quantity={
                <div className={PurchaseQuantityCss.table_quantity}>
                    <p onClick={(e) => { decreaseClick(e, index) }}>-</p>
                    <input name='quantity' type='text' value={item.quantity ? item.quantity : 0} onChange={(e) => { onQuantityChange(e, index) }} />
                    <p onClick={(e) => { increaseClick(e, index) }}>+</p>
                    {/* <>{item.quantity}</> */}
                </div>
            }
            unit={item.selectedUnit}
            price={<>
                <input name='price' className={PurchaseQuantityCss.price} type='text' value={item.price ? item.price : 0} onChange={(e) => { onPriceChange(e, index) }} />
            </>}
            total={getTotal(item.quantity, item.price)}
            action={<button className={PurchaseQuantityCss.remove_item} onClick={(e) => { onRemove(e, index) }}>x</button>} />
    })

    function getTotal(quantity, price) {
        if (!quantity) {
            quantity = 0
        }
        if (!price) {
            price = 0
        }
        return quantity * price
    }

    function getAllTotal(tableArr) {
        let myTotal = 0

        tableArr?.forEach(element => {
            let quantity = element.quantity ? element.quantity : 0
            let price = element.price ? element.price : 0
            myTotal += quantity * price
        });
        // console.log(myTotal)
        return myTotal
    }
    function getDicountAmmount() {
        let discount = poData?.percent
        let total = getAllTotal(myTabelItem)
        let fixed = 0
        let percent = 0
        if (poData?.fixed) {
            fixed = poData?.fixed
        }
        if (poData?.percent) {
            percent = poData?.percent
        }

        if (discountState?.discountfixed) {
            discount = parseFloat(fixed)
        } else if (discountState?.discountpercent) {
            discount = (total * parseInt(percent)) / 100
        } else {
            discount = poData?.discountAmmount
        }
        return discount ? discount : 0
    }
    function getGrandTotal() {
        let discount = getDicountAmmount()
        let total = getAllTotal(myTabelItem)
        return total - discount
    }
    function dueAmmount() {
        let total = 0
        let payAmmount = 0
        if (poData?.payAmmount) {
            payAmmount = poData?.payAmmount
        }

        if (poData?.payAmmount) {
            total = getGrandTotal() - payAmmount
        } else {
            total = getGrandTotal()
        }
        let quantity = 0
        myTabelItem.forEach(element=>{
            quantity +=element.quantity ? element.quantity:0
        })
        //console.log(quantity) //discountAmmount: getDicountAmmount()
        setPoData({ ...poData, total: getAllTotal(myTabelItem), grandTotal: getGrandTotal(), dueAmmount: total, totalQuantity:quantity, discountAmmount: getDicountAmmount()})
    }

    useEffect(() => {
        dueAmmount()
    }, [myTabelItem])

    useEffect(() => {
        dueAmmount()
    }, [poData?.percent, poData?.fixed, poData?.payAmmount])

//    async function getDateTime() {
//         let mydate = new Date();
//         let day = mydate.getDate()
//         let month = mydate.getMonth() + 1
//         if (month < 10) {
//             month = '0' + month
//         }
//         let year = mydate.getFullYear()
//         let hour = mydate.getHours()
//         if (hour < 10) {
//             hour = '0' + hour
//         }
//         let minute = mydate.getMinutes()
//         if (minute < 10) {
//             minute = '0' + minute
//         }
//         // console.log(minute)
//         let seconds = mydate.getSeconds()
//         if (seconds < 10) {
//             seconds = '0' + seconds
//         }
//         let finalTime = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + seconds
//         // console.log(finalTime)
//         let userData = JSON.parse(window.localStorage.getItem('userData'))
//         //console.log(userData.name)

//         setMyFinalData({ ...myFinalData, poDate: finalTime, processedBy:userData.name, processorID:window.localStorage.getItem('user_id')})
//     }
//     useEffect(() => {
//         getDateTime()
//     }, [])

    function dateChange(e) {
        let value = e.target.value
        setPoData({ ...poData, poDate: value })
    }

    function onSupplierChange(e) {
        let value = e.target.value
        if (value) {
           // console.log(mySupplier)
            mySupplier.forEach(element => {
                if (value === element.companyaName) {
                    let supplierdata = {
                        name: element.companyaName,
                        supplierID: element.id,
                        phone: element.phone,
                        Area: element.Area,
                        District: element.District,
                        Divition: element.Divition,
                        address: element.address,
                        email: element.email
                    }
                    setPoData({ ...poData, supplierInfo: supplierdata })
                }
            });
        } else {
            setMyFinalData({ ...myFinalData, supplierInfo: value })
        }
    }
  async function onCompleteClick() {
        let tableDataok = false;
        if (myTabelItem.length < 1) {
            setAlertMessage('table item needed')
            // console.log()
        } else {
            for (let index = 0; index < myTabelItem.length; index++) {
                const element = myTabelItem[index];
                if (element.quantity > 0 && element.price > 0) {
                    tableDataok = true
                } else {
                    tableDataok = false
                    setAlertMessage('Price & Quantity Required in Item No : #' + (index + 1))
                    break;
                }
            }

            if (tableDataok) { 
                if (!poData.supplierInfo) {
                    setAlertMessage('Please Select Supplier to Continue')
                }else{
                    if (!poData.paymentMethod) {
                        setAlertMessage('Please Select Payment Methotd to Continue')
                    }else{
                        //console.log(myTabelItem)
                        delete poData['id']
                        let finalQuantity=0
                         await UpdateItemExpress('purchase', {...poData}, myParams.poid)
                        myTabelItem.forEach(async(element) => {
                            let itemid = element.purchaseID
                            delete element['id']
                            if (element.hasVariance) {
                                if (element.oldQuantity!==element.quantity) {
                                    finalQuantity = element.quantity
                                    delete element['oldQuantity']
                                    await UpdateItemExpress('purchase_items', {...element, varianceManaged:0}, itemid)
                                  //  console.log(element)
                                }else{
                                    delete element['oldQuantity']
                                    await UpdateItemExpress('purchase_items', {...element, varianceManaged:0}, itemid)
                                }  
                            }else{
                                delete element['oldQuantity']
                                await UpdateItemExpress('purchase_items', {...element, varianceManaged:0}, itemid)
                                //console.log(element)
                            }
                           
                        });
                         setAlertMessage('Data Updated')
                    }
                }
            }
        }
        if (deleteList.length>0) {
            deleteList.forEach(async(element) => {
                let itemid = element.purchaseID
             await DeleteItemExpress('purchase_items', myParams.poid, 'purchaseItem', itemid)
            });
        }else{
            console.log('no data for delete')
        }
    }
    function checkClick(params) {
         delete myTabelItem[0]['purchaseID'];
          console.log(myTabelItem[0]);
    }

    return (
        <>
            {alerMessage ? <AlertMessage message={alerMessage} setAlertMessage={setAlertMessage}/>:""}
            <section style={marginTop}>
                <div className={PurchaseQuantityCss.heading}>
                    <h2>Edit PO According to PR</h2>
                </div>
                <div className={PurchaseQuantityCss.purchase_wrapper}>
                    <div className={PurchaseQuantityCss.table_section}>
                        <table>
                           <tbody>
                           <tr>
                                <th>Sl</th>
                                <th>Item Name</th>
                                <th>Quantity</th>
                                <th>Unit</th>
                                <th>Price</th>
                                <th>Total</th>
                                <th>Action</th>
                            </tr>
                            {tableItem}
                           </tbody>
                        </table>
                    </div>



                    <div className={PurchaseQuantityCss.info_section}>
                        <div className={PurchaseQuantityCss.po_section}>
                            <p>PO No. </p>
                            <p># {poData?.PoNo}</p>
                        </div>
                        <div className={PurchaseQuantityCss.po_section}>
                            <p>PO Date : </p>
                            <input className={PurchaseQuantityCss.datepicker} name='poDate' type={'datetime-local'} defaultValue={poData?.poDate} onChange={dateChange} />
                            {/* <input type={'time'} value={'22:10:10'} /> */}
                        </div>
                        <hr />
                        <div className={PurchaseQuantityCss.supplier_select}>
                            <label>Select Supplier</label>
                            <select name='supplierName' onChange={onSupplierChange}>
                                <option value=''>--select one--</option>
                                {mySupplier?.map((item) => {
                                    return <option key={item.id} value={item.companyaName}>{item.companyaName}</option>
                                })}
                            </select>
                        </div>
                        <div className={PurchaseQuantityCss.supplier_discount}>
                            <p onClick={discountClick} name='discountfixed'> {discountState?.discountfixed ? <FontAwesomeIcon className={PurchaseQuantityCss.selected} icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCircle} />} Discount (Fixed)</p>
                            <p onClick={discountClick} name='discountpercent'> {discountState?.discountpercent ? <FontAwesomeIcon className={PurchaseQuantityCss.selected} icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCircle} />} Discount (%)</p>
                        </div>

                        {discountState?.discountfixed ? <div className={PurchaseQuantityCss.discount_ammount}>
                            <input name='fixed' type='text' onChange={discountChange} placeholder='discount ammount' />
                        </div> : ""}

                        {discountState?.discountpercent ? <div className={PurchaseQuantityCss.discount_ammount}>
                            <input name='percent' type='text' onChange={discountChange} placeholder='discount %' />
                        </div> : ""}

                        <div className={PurchaseQuantityCss.purchase_total}>
                            <div className={`${PurchaseQuantityCss.total}  ${PurchaseQuantityCss.common}`}>
                                <p className={PurchaseQuantityCss.constant}>Total : </p>
                                <p> {poData?.total} <span>Taka</span></p>
                            </div>
                            <div className={`${PurchaseQuantityCss.discount}  ${PurchaseQuantityCss.common}`}>
                                <p className={PurchaseQuantityCss.constant}>Discount (-)</p>
                                <p>  {poData?.discountAmmount} <span>Taka</span></p>
                            </div>
                            <div className={`${PurchaseQuantityCss.grand_total} ${PurchaseQuantityCss.common}`}>
                                <p className={PurchaseQuantityCss.constant}>Grand Total </p>
                                <p>{poData?.grandTotal} <span>Taka</span></p>
                            </div>
                        </div>
                        <div className={PurchaseQuantityCss.supplier_payment_method}>
                            <div className={PurchaseQuantityCss.cash}>
                                <input type="radio" id="cash" name="paymentType" value="cash" onChange={paymentMethodClick} checked={poData?.paymentMethod==='cash' ? true:false} />
                                <label htmlFor="cash">Cash</label>
                            </div>
                            <div className={PurchaseQuantityCss.check}>
                                <input type="radio" id="check" name="paymentType" value="check" onChange={paymentMethodClick} checked={poData?.paymentMethod==='check' ? true:false} />
                                <label htmlFor="check">Check</label>
                            </div>
                        </div>
                        <div className={PurchaseQuantityCss.paid_ammount}>
                            <label>Paid Ammount : </label>
                            <input name='payAmmount' type='text' value={poData?.payAmmount} onChange={payAmmountChange} />
                        </div>
                        <div className={PurchaseQuantityCss.paid_ammount}>
                            <div className={PurchaseQuantityCss.total}>
                                <p>Total : <span>{poData?.grandTotal}</span></p>
                            </div>
                            <div className={PurchaseQuantityCss.due}>
                                <p>Due : <span>{poData?.dueAmmount}</span></p>
                            </div>
                        </div>
                        <div className={PurchaseQuantityCss.paid_ammount}>
                            <label>Upload File : </label>
                            <input type='file' />
                        </div>
                        <div className={`${PurchaseQuantityCss.paid_ammount}  ${PurchaseQuantityCss.button_section}`}>
                            <button onClick={onCompleteClick}>Update PO</button>
                          
                        </div>

                    </div>
                </div>

            </section>
        </>

    )
}

export default PurchaseQuantityEdit
const MyTable = ({ sl, itemName, quantity, unit, price, total, action }) => {

    return <>
        <tr>
            <td>{sl}</td>
            <td>{itemName}</td>
            <td>{quantity}</td>
            <td>{unit}</td>
            <td>{price}</td>
            <td>{total}</td>
            <td>{action}</td>
        </tr>
    </>
}

const AlertMessage = ({message, setAlertMessage})=>{
    const myNavigation = useNavigate()
    function alertClick(e) {
       // console.log(e)
       if (message==='Data Updated') {
        setAlertMessage()
        myNavigation('/admin/purchase/pendinglist')
       }else{
        setAlertMessage()
       }
     
       
    }
    const zindex = {
        zIndex:20
      }
    return (
        <div style={zindex} className={PurchaseQuantityCss.AlertMessage}>
            <h2 >{message}</h2>
            <button onClick={alertClick}>OK</button>
        </div>
    )
}