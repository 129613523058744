import { faBars, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiLink, GetAllDataExpress, GetDataPassQuery, GetFilteredDataExpress } from '../../api/ExpressFunctions';
import { fetchAllUser, selectBackUserList, selectBackUserStatus } from '../../ReduxFiles/Slices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { GetPurchaseCompleate, GetSummeryList } from '../../api/PurchaseApi';
import { GetUserImage } from '../../api/BackendUserApi';
import Avatar from  '../../assets/dummyuser.png'
import { fetchAllSupplier, selectSupplierList, selectSupplierStatus } from '../../ReduxFiles/Slices/SupplierSlice';
import PurhcaseHistoryCss from './css/PurhcaseHistory.module.css'

const PurhcaseHistory = () => {
    const dispatch = useDispatch()
    const [tableData, setTableData] =useState([])
    const[spinnerShow, setSpinnerShow] = useState(false)
    const [summeryPop, setSummeryPop] = useState()
    const backUserStatus= useSelector(selectBackUserStatus)
    const backendUser = useSelector(selectBackUserList)
    const supplierStatus = useSelector(selectSupplierStatus)
    const [limitNumber, setLimitNumber] = useState(10)
    async function getAllData() {
        setSpinnerShow(true)
        let passdata = {
            limit:limitNumber,
            startId:tableData[0]?.id || 0, 
        }
        let allData = await GetPurchaseCompleate(passdata)
        setTableData(allData)
        setSpinnerShow(false)        
    }
    useEffect(()=>{
        getAllData()
        if (backUserStatus==='idle') {
            dispatch(fetchAllUser()) 
        }
        if (supplierStatus==='idle') {
            dispatch(fetchAllSupplier()) 
        }
 
    },[])

    function convertDate(time) {
        const dateInMillis = time 
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        // return myDate + " " + myTime
        return myDate + " " + myTime
    }
    const toltipPos = {
        top:'30px',
    }
    function showSummeryPop(e, item) {
        //console.log(item)
        setSummeryPop(item)
    }
    const CommonStyle = {
        color: 'white',
        borderRadius: '5px',
        textAlign: 'center',
        padding: '5px 0'
    }
    const statusSwitch =(value, item)=>{
        // console.log(value)
       let data = <></>
        switch (value) {
            case 'PO Received':
                data =  <p style={{...CommonStyle, backgroundColor:'#EE5A24'}}>{value}</p>
                break;
            case 'PO Partial Return':
                data = <p style={{...CommonStyle, backgroundColor:'#096960'}}>{value}</p>
                break;
            default:
               data =  <p style={{...CommonStyle, backgroundColor:'#006266'}}>{'PO Placed'}</p>
            break;
        }

        return data
     }

    const tableItem = tableData?.map((item, index)=>{
        return <MyTable key={item.id} po={'#'+item.id} date={convertDate(item.created_date)}
        total={item.grandTotal-item.returnAmount - (item.grandTotal*item.percent/100)}
        payAmount={item.paymentAmmount}salesHistoruImagesec
        dueAmount={item.grandTotal-item.paymentAmmount-item.returnAmount - (item.grandTotal*item.percent/100)} 
        processedby={<div className={PurhcaseHistoryCss.salesHistoruImagesec}>
        {backendUser?.filter((fitem)=>{
                // console.log(fitem)
                return fitem.id===item.processorID ? fitem:''
            })
            .map((uitem)=>{
                return <React.Fragment key={uitem.id+'user'}>
                <AvatarComp name={uitem.userImageName}/>
                <p>{uitem.userName}</p>
                </React.Fragment>
            })}
        
    </div>}
        status={statusSwitch(item.status)}
        action={
            <div className={PurhcaseHistoryCss.actionButton}>      
                <Link to={'/admin/purchase/purchasehistory/'+item.id+'/view'}><FontAwesomeIcon className='color-purple' icon={faEye}/></Link>
                <FontAwesomeIcon  style={{cursor:'pointer', color:'green'}} icon={faBars} onClick={(e)=>{showSummeryPop(e, item); document.body.style.overflow = 'hidden'}}/>     
        </div>
        }/>
    })

    function checkClick(params) {
        // tableData.map((item)=>{
        //     console.log(item)
        // })
    }
    
    const marginTop = {
        marginTop: '10px'
      }
    
  return (
    <>
     {spinnerShow ?  <Spinner text={'Loading...'}/>:""}
     {summeryPop ?  <SummeryPop popData={summeryPop} setPopData={setSummeryPop}/>:""}
    <section style={marginTop} className={PurhcaseHistoryCss.salesMain}>
                <div className={PurhcaseHistoryCss.innerSale}>
                    <h2 className={PurhcaseHistoryCss.saleHeading} onClick={checkClick}>Purchase History</h2>
                    <div className={PurhcaseHistoryCss.tableSection}>
                        <table>
                            <tbody>
                            <tr>
                                <th>PO</th>
                                <th>Date</th>
                                <th>Total Amount</th>
                                <th>Paid Amount</th>
                                <th>Due Amount</th>
                                <th>Processed By</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            {tableItem}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
    </>
  )
}

export default PurhcaseHistory

const SummeryPop = ({ popData, setPopData }) => { 
    const mySupplier = useSelector(selectSupplierList)
    const backendUser = useSelector(selectBackUserList)
    const [tableData, setTableData] = useState()

    async function getPoSummery() {
        let summeryData = await GetSummeryList({id:popData.id})
        let staticData = {
            supplier_id: popData.supplierID,
            status: "PO Placed",
            attemptBy: popData.processorID,
            created_date: popData.created_date,
        }
        summeryData.splice(0, 0, staticData)
        setTableData(summeryData)

    }
    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }

    useEffect(() => {
        getPoSummery()
    }, [])

    return <div className={PurhcaseHistoryCss.orderactivites_wrapper}>
        <div className={PurhcaseHistoryCss.orderactivites}>
            <h3> PO Activities</h3>
            <h4> PO Activity History Of # {popData.id}</h4>
            <table>
               <thead>
               <tr>
                    <th>Sl</th>
                    <th>Date</th>
                    <th>Supplier Name</th>
                    <th>Status</th>
                    <th>Attempt By</th>
                    <th>Attempt Time</th>
                </tr>
               </thead>
               <tbody>
               {tableData?.map((item, index) => {
                    return <tr key={index+'sum'}>
                        <td>{index + 1}</td>
                        <td>{popData?.poDate.replaceAll('T', ' ')}</td>
                        <td>{mySupplier.filter((fitem)=>{
                        return fitem.id === item.supplier_id ? fitem:''
                        })
                        .map((sitem)=>{
                            return sitem.companyaName
                        })}
                        </td>
                        <td>{item.status}</td>
                        <td>{backendUser.filter((fitem)=>{
                                return fitem.id === item.attemptBy ? fitem:''
                            })
                            .map((uitem)=>{ return uitem.userName})}
                        </td>
                        <td>{convertDate(item.created_date)}</td>
                    </tr>
                })}
               </tbody>
            </table>
            <button onClick={() => { setPopData(); document.body.style.overflow = 'auto' }}>Close</button>
        </div>
    </div>
}


const MyTable = ({po, date, total, payAmount, dueAmount,  processedby, status, action}) => {

    return <tr key={po}> 
        <td>{po}</td>
        <td>{date}</td>
        <td>{total}</td>
        <td>{payAmount}</td>
        <td>{dueAmount}</td>
        <td>{processedby}</td>
        <td>{status}</td>
        <td>{action}</td>    
      </tr>
  }

const Spinner = ({text}) => {
    const myIndex = {
      zIndex: '15' 
    }
    return (
       <>
         <div style={myIndex} className={PurhcaseHistoryCss.SpinnerWrrapper}>
        <div id="loader"></div>
        <p>{text}</p>
    </div>
       </>
    )
  }

  const AvatarComp = ({ name }) => {
    const [image, setImage] = useState()
    async function getLink() {
        if (name) {
            let imageInfo =  await GetUserImage({image_name:name})
            if (imageInfo.imageExist) {
                setImage(imageInfo.url)
            }else{
                setImage(Avatar)
            }
        }else{
            setImage(Avatar)
        }
    }
    useEffect(() => {
        getLink()
    }, [])
    return (
        <>
        {/*avatar this class not found  */}
            <img className='avatar' src={image} alt='avatar' />
        </>
    )
}
