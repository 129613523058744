import axios from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SendSMSFromRobi } from '../../../api/ExpressFunctions'
import CheckApiCss from './Css/CheckApi.module.css'
import { toast } from 'react-toastify'

const CheckApi = () => {
  const myNavigate = useNavigate()

  function onSubmit(e) {
    let number = ''
    e.preventDefault()

    try {
      SendSMSFromRobi('01611824143', 'hello message')
    } catch (error) {
      toast.error(error.message)
    }
  }

  function getApiRequest() {
    let number = ''
    for (let index = 0; index < 4; index++) {
      number += '' + Math.floor(Math.random() * 10)

    }

    let message = `Welcome to Anayase. %0aYour OTP is : ${number}`
    let myoredermessage = `Your Order Placed.%0aYour Order Number is : ${5 + 1}.%0a%0aThank You%0aAnayase%0a01810169101`
    let urlget = `https://api.mobireach.com.bd/SendTextMessage?Username=anayase&Password=Dhaka@0088&From=Anayase&To=8801810169033&Message=${myoredermessage}`
    axios.get(urlget, {})
      .then(function (response) {
        console.log(response); // this will print xml data structure
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        console.log(1)
      });
  }
  function onCheckClick() {
    console.log(1)

    //  addToList()
    // getApiRequest()
  }



  return (
    <div className={CheckApiCss.checkApi}>
      <form action='https://api.mobireach.com.bd/SendTextMessage' method="post">
        <input type="text" name="Username" value="anayase" />
        <input type="text" name="Password" value="Dhaka@0088" />
        <input type="text" name="From" value="8801819136397" />
        <input type="text" name="To" value="8801810169248" />
        <input type="text" name="Message" value="test message" />

      </form>
      <div className={CheckApiCss.checkApi}>
        <input type="submit" value="Submit" onClick={onSubmit} />
        <input type="submit" value="Check" onClick={onCheckClick} />

        <label for="date">Select a date:</label>
        <input type="date" id="date" name="date" pattern="\d{2}-\d{2}-\d{4}"></input>
      </div>
    </div>
  )
}

export default CheckApi