
import React, { useEffect, useState } from 'react'
import Table from '../Table'
import SettingInput from './SettingInput'
import ConfirmAlert from '../smallThings/ConfirmAlert'
import ToggleSwitch from '../smallThings/ToggleSwitch'
import { DeleteBannerData, GetBannerData, SaveBannerData, UpdateBannerData } from '../../api/SettingApi'
import { API_LINK } from '../../api/APILink'
import ManageBannersCss from './css/ManageBanners.module.css'

const ManageBanners = () => {

    const myData = new Date()

    const [currentState, setCurrentState] = useState({})
    const [errorText, setErrorText] = useState({})
    const [tableData, setTableData] = useState()
    const [conAlert, setConAlert] = useState(false)
    const [showUpdateButton, setShowUpdateButton] = useState(false)
    const [EditID, setEditID] = useState()

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name === "banner_image") {
            ShowImage(e.target.files[0], "fake" + name, name)

        } else {
            setCurrentState({ ...currentState, [name]: value })
        }

    }

    function ShowImage(image, identity, imgid) {
        //  console.log(imgArr)
        if (image) {
            let myurl = URL.createObjectURL(image)
            setCurrentState({ ...currentState, [identity]: myurl, [imgid]: image })
        }
    }

    async function ButtonClick() {
        let banner_image = currentState.banner_image
        let nametext = ValidateFunction("name", "Name ")
        let sectionErr = ValidateFunction("sectionNo", "Section Name ")
        let banner_imageErr = ValidateFunction("banner_image", "Banner Image ")
        let imageNoErr = ValidateFunction("imageNo", "Image No ")

        setErrorText({ ...errorText, nametext, sectionErr, banner_imageErr, imageNoErr })
        if (nametext || sectionErr || banner_imageErr, imageNoErr) {

        } else {
            let finalData = {
                name: currentState.name?.trim(),
                alter_text: currentState.alter_text?.trim(),
                banner_url: currentState.banner_url?.trim(),
                imageNo: currentState.imageNo,
                sectionNo: currentState.sectionNo,
                active: 0,
                created_date: myData.getTime(),
            }
            // console.log(finalData, banner_image);
            await SaveBannerData(finalData, banner_image)
        }

    }

    function ValidateFunction(field, message) {
        let text = ""
        // console.log(currentState.name)
        if (!currentState[field]) {
            text = message + "is Required"
        }
        return text;
    }

    function EditClick(id) {
        setEditID(id)
        getEditData(id)
        setShowUpdateButton(true)
    }
    function getEditData(id) {
        tableData.forEach(element => {
            if (element.id === id) {
                setCurrentState(element)
            }
        });
    }

    async function UpdateClick() {
        let banner_image = currentState.banner_image
        let nametext = ValidateFunction("name", "Name ")
        let sectionErr = ValidateFunction("sectionNo", "Section Name ")
        setErrorText({ ...errorText, nametext, sectionErr })

        if (nametext || sectionErr) {

        } else {
            let finalData = {
                name: currentState.name?.trim(),
                alter_text: currentState.alter_text?.trim(),
                banner_url: currentState.banner_url?.trim(),
                imageNo: currentState.imageNo,
                sectionNo: currentState.sectionNo,
                image_name: currentState.image_name,
            }
            await UpdateBannerData(currentState.id, { ...finalData }, banner_image)
        }
        alert('data updated')
    }
    function UpdateCancel() {
        setShowUpdateButton(false)
        setCurrentState({
            name: "",
            slider_url: "",
            alter_text: "",
            slider_type: ""
        })
    }

    function DeletClick(id) {
        getEditData(id)
        setConAlert(true)
    }

    async function ClickYes() {
        let newArr = []
        let deleteid = currentState.id
        await DeleteBannerData(deleteid, currentState.image_name)
        setConAlert(false)
        setCurrentState({})
        setShowUpdateButton(false)
        tableData.forEach(element => {
            if (element.id === deleteid) {
            } else {
                newArr.push(element)
            }
        });

        setTableData(newArr)
    }

    function ClickNo() {
        setConAlert(false)
        setCurrentState({
            name: "",
            url: "",
            alterText: "",
            slideOption: ""
        })
    }
    async function getAllData() {
        let myData = await GetBannerData()
        setTableData(myData)
        // console.log(myData)
    }
    useEffect(() => {
        getAllData()
    }, [])
    async function onItemSwitchClick(item) {
        let isActice = 0
        if (item.active === 0) {
            isActice = 1
        } else {
            isActice = 0
        }
        //console.log(image_name:item.image_name)
        await UpdateBannerData(item.id, { active: isActice, image_name: item.image_name })
        //    console.log(item.id)
        let tempArr = []
        tableData?.map((okItem) => {
            if (okItem.id === item.id) {
                okItem = {
                    ...okItem,
                    active: isActice
                }
            }
            tempArr.push(okItem)

        })
        setTableData(tempArr)

    }
    const TableItem = <>
        {tableData ? tableData.map((item, index) => {
            return <Table key={index} param1={index + 1}
                param2={
                    item.image_name ?
                        <img src={API_LINK + 'slider_images/' + item.image_name}
                        /> : <img src={item.fakeslideImage} />}
                param3={item.alter_text ? item.alter_text : '-'}
                param4={item.sectionNo ? item.sectionNo : "no data"}
                param5={item.imageNo}
                param6={item.banner_url}
                param7={<ToggleSwitch toggleOnOff={item.active} toggleClick={() => { onItemSwitchClick(item) }} />}
                param8={<div>
                    <input onClick={() => { EditClick(item.id) }} type="Submit" defaultValue="Edit" sid={item.id} />
                    <input className={ManageBannersCss.delete} onClick={() => { DeletClick(item.id) }} type="Submit" defaultValue="Delete" />
                </div>} />
        }) : ""}
    </>


    return (
        <>
            {conAlert ? <ConfirmAlert yesClick={ClickYes} noClick={ClickNo} /> : ""}

            <section className={ManageBannersCss.manage_slieds}>
                <h3 className={ManageBannersCss.itemlisth3}>Banner Image List</h3>
                <div className={ManageBannersCss.Sliderinput}>
                    <div className="col-6">
                        <SettingInput name="name" labeltext="Name" Itype="text" placeholder="banner name" changeme={onDataChange} myValue={currentState.name ? currentState.name : ''} warning={errorText ? errorText.nametext : ""} />
                        <SettingInput name="banner_url" labeltext="URL" Itype="text" placeholder="banner url here" changeme={onDataChange} myValue={currentState.banner_url ? currentState.banner_url : ''} />
                    </div>
                    <div className="col-6">
                        <SettingInput name="alter_text" labeltext="Alter Text" Itype="text" placeholder="image alter text here" changeme={onDataChange} myValue={currentState.alter_text ? currentState.alter_text : ''} />
                        <SettingInput name="banner_image" labeltext="Banner Image" Itype="file" changeme={onDataChange} warning={errorText ? errorText.banner_imageErr : ""} />
                    </div>

                    <div className={ManageBannersCss.fixed_image_slide}>
                        <img className={ManageBannersCss.SlideImageshow} src={currentState.fakebanner_image} />
                        {showUpdateButton && <img className={ManageBannersCss.SlideImageshowexits} src={`${API_LINK}/slider_images/${currentState.image_name}`} />}
                    </div>
                    <div className={ManageBannersCss.customClass}>
                        <div>
                            <label >Section Name</label>
                            <select onChange={onDataChange} name="sectionNo" defaultValue={currentState.sectionNo}>
                                <option value={""}>Select One</option>
                                <option value={1}  >Section One</option>
                                <option value={2}  >Section Two</option>
                                <option value={3}  >Section Three</option>
                                <option value={4}  >Section Four</option>
                                <option value={5}  >Section Five</option>
                            </select>
                            <span>{currentState.sectionNo}</span>
                            {errorText ? <p className={ManageBannersCss.WarningText}>{errorText.sectionErr}</p> : ""}
                        </div>
                        <div >
                            <label >Image No</label>
                            <select onChange={onDataChange} name="imageNo" defaultValue={currentState.imageNo}>
                                <option value={""}>Select One</option>
                                <option value={1}  >1</option>
                                <option value={2}  >2</option>
                                <option value={3}  >3</option>
                                <option value={4}  >4</option>
                                <option value={5}  >5</option>
                                <option value={6}  >6</option>
                                <option value={7}  >7</option>

                            </select>
                            <span>{currentState.imageNo}</span>
                            {errorText ? <p className={ManageBannersCss.WarningText}>{errorText.imageNoErr}</p> : ""}
                        </div>

                        <div className={ManageBannersCss.action_btn}>
                            {showUpdateButton ? <button className={`${ManageBannersCss.button} ${ManageBannersCss.webseting}`} onClick={UpdateClick}>Update Data</button> : <button className={`${ManageBannersCss.button} ${ManageBannersCss.webseting}`} onClick={ButtonClick}>Save</button>}

                            {showUpdateButton ? <button className={ManageBannersCss.buttonCancel} onClick={UpdateCancel}>Cancel Update</button> : ""}

                        </div>
                    </div>
                </div>

            </section>
            <table className={ManageBannersCss.tableSection}>
                <tbody>
                    <tr>
                        <th>Sl</th>
                        <th>Images</th>
                        <th>Alter Text</th>
                        <th>Section No</th>
                        <th>Image No</th>
                        <th>URL</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    {TableItem}
                </tbody>
            </table>

        </>
    )
}

export default ManageBanners