import React, { useEffect, useState } from 'react'
import {faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {LocalTimeStampToDate } from '../../MyFunctions/MyFunctions';
import { GetSearchedCustomer } from '../../../api/CustomerApi';
import { GetCustomerCredit } from '../../../api/CreditApi';
import CreditVoucherCustCss from './Css/CreditVoucherCustomer.module.css'
import { toast } from 'react-toastify';

const CreditVoucherCustomer = () => {
    const [CustomerList, setCustomerList] = useState()
    const [searchText, setSearchText] = useState('')
    const [userData, setUserData] = useState()
    const [isSelected, setIsSelected] = useState(false)

    const [myCheck, setMyCheck] = useState(true)
    const [tableData, setTableData] = useState([])
    const [allData, setAllData] = useState()
    const [errMessage, setErrMessage] = useState()
    const [paymentInfo, setPaymentInfo] = useState({
        paymentDate: '',
        paymentMethod: 1,
        paymentAmmount: 0,
        paymentRemark: '',
        bankID:'',
        sub_method:''
    })
    const [myBankList, setMyBankList] = useState() 
     const [runningVoucherNo, setRunningVoucherNo] = useState()

    async function getBankList() {
            let bankData = [] //await GetAllDataExpress('bank_list')
            setMyBankList(bankData)
        }
        useEffect(()=>{
            getBankList()
        },[])

   async function onSearchChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSearchText({ ...searchText, [name]: value })
        setIsSelected(false)

        if (value.length >=3) {
          let data =  await GetSearchedCustomer({search_text:value})
          setCustomerList(data)
        }
        
        if (value === '') {
            setUserData()
            setTableData()
        }
    }

    useEffect(() => {
        
    }, [])

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name === 'paymentAmmount') {
            value = e.target.value ? parseInt(e.target.value) : ''
            if (value > getPayableDue()) {
                value = getPayableDue()
                setPaymentInfo({ ...paymentInfo, [name]: value })
            } else if (value < 0) {
                setPaymentInfo({ ...paymentInfo, [name]: 0 })
            } else {
                setPaymentInfo({ ...paymentInfo, [name]: value })
            }
        } else {
            setPaymentInfo({ ...paymentInfo, [name]: value })
        }

        if (name ==='paymentMethod') {
            //console.log(parseInt(value))
            if (parseInt(value)===1 || parseInt(value)===4) {
                setPaymentInfo({ ...paymentInfo, [name]: parseInt(value),  bankID:'',
                sub_method:'' })
            }else{
                setPaymentInfo({ ...paymentInfo, [name]: parseInt(value) })
            } 
        }
    }

    function CheckAll() {
       // console.log(tableData)
        let newArr = []
        tableData.forEach(element => {
            element = {
                ...element,
                nowPay: true
            }
            newArr.push(element)
        });
        setTableData(newArr)
    }
    function unCheckAll() {
        let newArr = []
        tableData.forEach(element => {
            element = {
                ...element,
                nowPay: false
            }
            newArr.push(element)
        });
        setTableData(newArr)
    }
    function CheckData(e, id) {
        let newArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = {
                    ...element,
                    nowPay: true
                }
                newArr.push(element)
            } else {
                newArr.push(element)
            }
        });
        setTableData(newArr)
    }
    function unCheckData(e, id) {
        let newArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = {
                    ...element,
                    nowPay: false
                }
                newArr.push(element)
            } else {
                newArr.push(element)
            }
        });
        setTableData(newArr)

    }
    function checkAllData(params) {
        for (let index = 0; index < tableData?.length; index++) {
            const element = tableData[index];
            if (!element.nowPay) {
                setMyCheck(false)
                break;
            } else {
                setMyCheck(true)
            }
        }
    }
    useEffect(() => {
        checkAllData()
        getPayableDue()
        setPaymentInfo({ ...paymentInfo, paymentAmmount: 0, paymentDate:getDateTime()})
    }, [tableData])
    function getDateTime() {
        let mydate = new Date();
        let day = mydate.getDate()
        let month = mydate.getMonth() + 1
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        let year = mydate.getFullYear()
        let hour = mydate.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = mydate.getMinutes()
        if (minute < 10) {
            minute = '0' + minute
        }
        // console.log(minute)
        let seconds = mydate.getSeconds()
        if (seconds < 10) {
            seconds = '0' + seconds
        }
        let finalTime = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + seconds
        return finalTime
    }
    function getPayableDue() {
        let dueTotal = 0
        tableData?.map((item) => {
            if (item.nowPay) {
                dueTotal += item.due
            }
        })
        return dueTotal
    }
    async function onSearchClick() {
        if (userData) {
            let customerSales = await GetCustomerCredit(userData.id)
            organizeData(customerSales)
        } else {
            alert('Select user to continue')
        }
    }
   
    function organizeData(myTableData) {
        let tempArr = []
        myTableData.forEach(element => {
            let total = parseFloat(element.total)+parseFloat(element.deliveryCharge) 
            let discount = total*parseFloat(element.discountPercent)/100
            let due = total-discount - (parseFloat(element.pay_amount) || 0)
            element = {
                ...element,
                ftotal:total,
                discount,
                due,

            }
            if (due>0) {
                tempArr.push(element)
            }
            
        });
       
        setTableData(tempArr)
    }
   
     function getLeadgerBalance(marray) {
        let dueTotal = 0
        marray?.map((item) => {
            if (item.due>0) {
                dueTotal += item.due
            }
           
        })
        return dueTotal
    }
    
    function onSaveClick() {
        let payErr = ValidateFunction('paymentAmmount', 'Receive Ammount ')
        let remarkErr = ValidateFunction('paymentRemark', 'Remark  ')
        let dateErr = ValidateFunction('paymentDate', 'Receive Date ')
        let bankIDErr = ''
        let sub_methodErr = ''
        if (paymentInfo.paymentMethod ===2 || paymentInfo.paymentMethod===3) {
            bankIDErr = ValidateFunction('bankID', 'Bank Name ')
            sub_methodErr = ValidateFunction('sub_method', 'Sub Method ')
        }
        setErrMessage({ ...errMessage, payErr, remarkErr, dateErr, bankIDErr, sub_methodErr })
        if (!payErr && !remarkErr && !dateErr && !bankIDErr && !sub_methodErr) {
            let paymentArr = []
            tableData.forEach(element => {
                if (element.nowPay) {
                    paymentArr.push(element)
                }
            });
          
            if (paymentArr.length > 0) {
                if (paymentInfo.paymentMethod===1) {
                    cashPayment(paymentArr)
                } else if (paymentInfo.paymentMethod===2) {
                   // onlineDeposite(paymentArr)
                } else if (paymentInfo.paymentMethod===3) {
                   // onlineDeposite(paymentArr)
                }
            } else {
                toast.warn('No Invoice Found')
            }
            
        }
       
    }
    function ValidateFunction(field, message) {
        let text = ""
        if (!paymentInfo[field]) {
            text = message + " is Required"
        }
        return text;
    }
    async function cashPayment(paymentArr) {
        let processorData = window.localStorage.getItem('userData')
        processorData = JSON.parse(processorData)
        let userID = window.localStorage.getItem('user_id')
        if (paymentArr.length > 1) {
            if (paymentInfo.paymentAmmount === getPayableDue()) {
                let invoiceNo = ''
                paymentArr.forEach(async(item) => {
                    let dataID = item.id
                    let CreditData = {
                        identity: dataID,
                        receiveAmmount:item.dueAmmount,
                        particulars:'Sales',
                        transactionType:'cash',
                        receivedByID:userID,
                        remark:paymentInfo.paymentRemark,
                        receiveType : 'Collection',
                        created_date:new Date().getTime()
                    }
                    let dayBookData = {
                        created_date:new Date().getTime(),
                        paticulars:"Sales (Collection)",
                        identity:dataID,
                        received:item.dueAmmount,
                        payment:0,
                        processorID:userID,
                    }
                    let summeryData = {
                        ProssesedBy: userID,
                        sale_id:dataID,
                        created_date: new Date().getTime(),
                        activity: 'Invoice Ammount Full Paid',        
                    }
                 //    console.log(CreditData, dayBookData, summeryData)
                    // await SaveDataExpress('credit_voucher', {...CreditData})
                    // await SaveDataExpress('day_book', {...dayBookData})
                    // await SaveDataExpress('sales_summery', {...summeryData})
                 
                });
                
                
                alert('data saved')
              //  console.log(paymentData)
              
            } else {
                alert('you must pay full ammount')
            }
        } else {
            let fullPaid = true
            let status = ''
            let dataID = paymentArr[0].id
            // console.log(dataID)
            if (paymentInfo.paymentAmmount < getPayableDue()) {
                fullPaid =false
                status = 'Invoice Ammount Partial Paid'
            }else{
                fullPaid =true
                status = 'Invoice Ammount Paid'
            }
            let CreditData = {
                identity: dataID,
                receiveAmmount:paymentInfo.paymentAmmount,
                particulars:'Sales',
                transactionType:'cash',
                receivedByID:userID,
                remark:paymentInfo.paymentRemark,
                receiveType : 'Collection',
                created_date:new Date().getTime()
            }
            let dayBookData = {
                created_date:new Date().getTime(),
                paticulars:"Sales (Collection)",
                identity:dataID,
                received:paymentInfo.paymentAmmount,
                payment:0,
                processorID:userID,
            }
            let summeryData = {
                ProssesedBy: userID,
                sale_id:dataID,
                created_date: new Date().getTime(),
                activity: status,        
            }
         //    console.log(CreditData, dayBookData, summeryData)
            // await SaveDataExpress('credit_voucher', {...CreditData})
            // await SaveDataExpress('day_book', {...dayBookData})
            // await SaveDataExpress('sales_summery', {...summeryData})
            alert('Data Saved')

        }
    }
    async function onlineDeposite(paymentArr) {
        let processorData = window.localStorage.getItem('userData')
        processorData = JSON.parse(processorData)
        let userID = window.localStorage.getItem('user_id')
        if (paymentArr.length > 1) {
            if (paymentInfo.paymentAmmount === getPayableDue()) {
                let invoiceNo = ''
                paymentArr.forEach(async(item) => {
                    let dataID = item.id
                    //getDueAmmount(total, payment, discount, payAmmounthere)
                    let salesReceive = {
                        receiveTime: new Date().getTime(),
                        receiveAmmount:item.dueAmmount,
                        receiveType:methodReturn(paymentInfo.paymentMethod),
                        particulars:'Credit Voucher',
                        remark:paymentInfo.paymentRemark,
                        receivedBy:processorData.name,
                        receivedByID:userID,
                        voucherNo:runningVoucherNo
                    }
                    let summeryData = {
                        activity: 'Invoice Ammount Paid',
                        ProssesedBy: processorData.name,
                        deliveryDate:'',
                        ForwardTo:'',
                        deliveryDate:''
                    }
                //     // console.log(summeryData, salesReceive, dataID)
                //  await UpdateDataArray('sales', dataID, 'salesReceive', {...salesReceive})
                //  await UpdateData('sales', { fullPaid: true}, dataID)
                //  await SaveNestedExistingData('sales', dataID, 'ordersummery', summeryData)
                });
                // loop ends

                paymentArr.map((item)=>{
                    //console.log(item)
                    invoiceNo += item.invoiceno+','
                })

                let voucherData = {
                    invoiceNo:invoiceNo,
                    receiveAmmount:paymentInfo.paymentAmmount,
                    receiveType:methodReturn(paymentInfo.paymentMethod),
                    remark:paymentInfo.paymentRemark,
                    customerInfo:userData,
                    voucherNo:runningVoucherNo,
                    voucherType:2,
                    processorID:window.localStorage.getItem('user_id'),
                    processorName:processorData.name,
                    bankID:paymentInfo.bankID,
                    subMethod:paymentInfo.sub_method,
                    particulars:'Sales'
                }
                let bankData = {
                    inOut:1,
                    bankID: paymentInfo.bankID,
                    identity: paymentArr[0].invoiceno,
                    voucherNo: runningVoucherNo,
                    receiveAmmount:paymentInfo.paymentAmmount,
                    particulars:'Sales',
                    head:'Credit Voucher',
                    processorName:userData.name,
                    processorID:window.localStorage.getItem('user_id'),
                    paymentAmmount:0,
                    remark:paymentInfo.paymentRemark,
                    clientInfo:{name:userData.name, clientID:userData.userID, phone:userData.phone},
                    transactionType:methodReturn(paymentInfo.paymentMethod),
                    voucherType:2
                }
               // console.log(voucherData,cashData )
                
            //      await AutoIncrementNumberCustom('NumberCollection', 'NumberID', 'creditVoucherNo', 1 )
            //     await SaveData('Bank_Transaction', {...bankData})
            //     await SaveData('Vouchers', {...voucherData})  
            //     await AutoIncrementNumberCustom('BankList', paymentInfo.bankID, 'ammount', paymentInfo.paymentAmmount )              
            //     alert('data saved')
            //   //  console.log(paymentData)
              
            } else {
                alert('you must pay full ammount')
            }
        } else {
            let fullPaid = true
            let status = ''
            let dataID = paymentArr[0].id
            console.log(dataID)
            if (paymentInfo.paymentAmmount < getPayableDue()) {
                fullPaid =false
                status = 'Invoice Ammount Partial Paid'
            }else{
                fullPaid =true
                status = 'Invoice Ammount Paid'
            }

            let salesReceive = {
                receiveTime: new Date().getTime(),
                receiveAmmount:paymentInfo.paymentAmmount,
                receiveType:methodReturn(paymentInfo.paymentMethod),
                particulars:'Credit Voucher',
                remark:paymentInfo.paymentRemark,
                receivedBy:processorData.name,
                receivedByID:userID,
                voucherNo:runningVoucherNo
            }
            let summeryData = {
                activity: status,
                ProssesedBy: processorData.name,
                deliveryDate:'',
                ForwardTo:'',
                deliveryDate:''
            }
        //      console.log(salesReceive)
        //     await UpdateDataArray('sales', dataID, 'salesReceive', {...salesReceive})
        //    await UpdateData('sales', {fullPaid: fullPaid}, dataID)
        //    await SaveNestedExistingData('sales', dataID, 'PoSummery', summeryData)

        

            let voucherData = {
                invoiceNo:paymentArr[0].invoiceno,
                receiveAmmount:paymentInfo.paymentAmmount,
                receiveType:methodReturn(paymentInfo.paymentMethod),
                remark:paymentInfo.paymentRemark,
                customerInfo:userData,
                voucherNo:runningVoucherNo,
                voucherType:2,
                processorID:window.localStorage.getItem('user_id'),
                processorName:processorData.name,
                bankID:paymentInfo.bankID,
                subMethod:paymentInfo.sub_method,
                particulars:'Sales'
            }
            let bankData = {
                inOut:1,
                bankID: paymentInfo.bankID,
                identity: paymentArr[0].invoiceno,
                voucherNo: runningVoucherNo,
                receiveAmmount:paymentInfo.paymentAmmount,
                particulars:'Sales',
                head:'Credit Voucher',
                processorName:userData.name,
                processorID:window.localStorage.getItem('user_id'),
                paymentAmmount:0,
                remark:paymentInfo.paymentRemark,
                clientInfo:{name:userData.name, clientID:userData.userID, phone:userData.phone},
                transactionType:methodReturn(paymentInfo.paymentMethod),
                voucherType:2
            }
           
        //   // console.log(voucherData)
        //    await SaveData('Vouchers', {...voucherData})
        //    await SaveData('Bank_Transaction', {...bankData})
        //    await AutoIncrementNumberCustom('NumberCollection', 'NumberID', 'creditVoucherNo', 1 )
        //    await AutoIncrementNumberCustom('BankList', paymentInfo.bankID, 'ammount', paymentInfo.paymentAmmount )
        //    alert('data saved')
        }
    }
    function methodReturn(data) {
        let method = ''
        if (data===1) {
            method = 'Cash'
        } else if (data===2) {
            method = 'Online Deposite'
        }else if (data===3) {
            method = 'Transfered'
        }else{
            method ='Cheque'
        }
        return method
    }
 
    
     const errStyle = {
        display: 'block',
        textAlign: 'right',
        color: 'red',
    }
    return (
        <>
            <div className={`${CreditVoucherCustCss.common_class} ${CreditVoucherCustCss.complain_category}`} style={{ marginTop: '20px', width: '90%', marginLeft: '20px' }}>
                <div className={CreditVoucherCustCss.input_section}>
                    <div className={`${CreditVoucherCustCss.input_wrapper} ${CreditVoucherCustCss.dropdown_wrapper}`}>
                        <input onChange={onSearchChange} name='searchText' type={'text'} placeholder='Search By Costomer Name or Mobile Number' value={searchText?.searchText || ''} onFocus={(e) => { setIsSelected(false); e.target.select() }} autoComplete='off' />
                        <div className={`${CreditVoucherCustCss.dropdown_list} ${CreditVoucherCustCss.debit_dropdown}`}>
                            {CustomerList?.filter((fitem) => {
                                let msearchText = searchText.searchText?.toLowerCase()
                                let matchText = fitem.name.toLowerCase()
                                let matchTextnumber = fitem.phone.toLowerCase()
                                if (msearchText && matchText !== msearchText && !isSelected && matchText.includes(msearchText)) {
                                    return fitem
                                } else if (msearchText && matchText !== msearchText && !isSelected && matchTextnumber.includes(msearchText)) {
                                    return fitem
                                } else {
                                    return null
                                }
                            }).slice(0, 10)
                                .map((item, index) => {
                                    return <p key={index} onClick={() => { setSearchText({ searchText: item.name }); setUserData(item); setIsSelected(true) }}>{item.name} @ {item.phone}</p>
                                })}
                        </div>
                        {/* <p>ok</p> */}
                    </div>
                    {userData ? <div className={CreditVoucherCustCss.input_wrapper} style={{ backgroundColor: '#B1D7B4', flexDirection: 'column', justifyContent: 'left', alignItems: 'flex-start', padding: '10px' }}>
                        <p>Customer Name : {userData?.name}</p>
                        <p>Customer Phone : {userData?.phone}</p>
                        <p>Customer Email : {userData?.email}</p>
                    </div> : ''}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button style={{ float: 'none' }} onClick={onSearchClick}>Search Data</button>
                    </div>
                </div>
            </div>
           
            {tableData.length > 0 ?   <div className={CreditVoucherCustCss.tableSection} style={{width:'95%', margin:'auto'}}>
                <h3>Credit Voucher No : {runningVoucherNo}</h3>
            <table>
                <tbody>
                    <tr>
                        <th>Check
                            {myCheck ? <FontAwesomeIcon style={{ color: 'white', margin: '0 0 0 8px', padding: '0', fontSize: "18px", cursor: 'pointer' }} icon={faSquareCheck} onClick={(e) => { unCheckAll(e) }} /> : <FontAwesomeIcon style={{ color: 'white', margin: '0 0 0 8px', padding: '0', fontSize: "18px", cursor: 'pointer' }} icon={faSquare} onClick={(e) => { CheckAll(e) }} />}
                        </th>
                        <th>Date</th>
                        <th>Invoice No</th>
                        <th>Total Ammount</th>
                        <th>Paid Ammount</th>
                        <th>Return Ammount</th>
                        <th>Discount</th>
                        <th>Due Ammount</th>
                    </tr>

                    {tableData?.map((item, index) => {
                        return <tr key={index}>
                            <td>{item.nowPay ? <FontAwesomeIcon style={{ color: 'green', cursor: 'pointer', margin: '0 0 0 8px', padding: '0', }} icon={faSquareCheck} onClick={(e) => { unCheckData(e, item.id) }} /> : <FontAwesomeIcon style={{ color: '#b5b5b5', cursor: 'pointer', margin: '0 0 0 8px', padding: '0', }} icon={faSquare} onClick={(e) => { CheckData(e, item.id) }} />} </td>
                            <td>{item.mdate}</td>
                            <td>{item.id}</td>
                            <td>{item.ftotal}</td>
                            <td>{item.pay_amount}</td>
                            <td>{item.rtotal || 0}</td>
                            <td>{item.discount}</td>
                            <td>{item.due}</td>
                        </tr>
                    })}
                </tbody>
            </table>
            <div className={CreditVoucherCustCss.other_information}>
                            <div className={CreditVoucherCustCss.text_section}>
                                <p className={CreditVoucherCustCss.ledger_ballnce}>Customer Ledger Balance : {getLeadgerBalance(tableData)} Tk</p>
                                <div className={`${CreditVoucherCustCss.payment_info} ${CreditVoucherCustCss.limit_due}`}>
                                    <p>Payment Limit : 0</p>
                                    <p>Payable Due : {getPayableDue()} Tk</p>
                                </div>
                            </div>
                            <div className={CreditVoucherCustCss.payment_info}>
                            <div className={CreditVoucherCustCss.option_class}>
                                    <label>Receive Method</label>
                                    <select name='paymentMethod' onChange={onDataChange}>
                                        <option value={1}>Cash</option>
                                        <option value={2}>Online Deposite</option>
                                        <option value={3}>Transferred</option>
                                        <option value= {4} >Cheque</option>
                                    </select>
                                </div>
                                {paymentInfo?.paymentMethod === 2 || paymentInfo?.paymentMethod === 3 ? <>
                                    <div className={CreditVoucherCustCss.option_class} >
                                        <label>Receipt Sub Method : </label>
                                        <select name='sub_method' onChange={onDataChange}>
                                            <option value={''}>Select Type</option>
                                            <option value='BEFTN'>BEFTN</option>
                                            <option value='NPSB'>NPSB</option>
                                            <option value='RTGS'>RTGS</option>
                                        </select>
                                    </div>
                                    {errMessage ? <p className={CreditVoucherCustCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.sub_methodErr}</p> : ''}

                                    <div className={CreditVoucherCustCss.option_class}>
                                        <label>Select Bank Account : </label>
                                        <select name='bankID' onChange={onDataChange}>
                                            <option value={''}>Select Bank</option>
                                            {myBankList?.map((item, index) => {
                                                return <option key={index} value={item.myBankID}>{item.bankName} - ({item.acNumber})</option>
                                            })}
                                        </select>
                                    </div>
                                    {errMessage ? <p className={CreditVoucherCustCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.bankIDErr}</p> : ''}
                                </> : ''}
                                <div className={CreditVoucherCustCss.input_section}>
                                    <label>Receive Ammount</label>
                                    <input onChange={onDataChange} name='paymentAmmount' type='number' placeholder='payment ammount' value={paymentInfo?.paymentAmmount} />
                                </div>
                                <p style={errStyle}>{errMessage?.payErr}</p>
                                <div className={CreditVoucherCustCss.input_section}>
                                    <label>Receive Remarks</label>
                                    <input onChange={onDataChange} name='paymentRemark' type='text' placeholder='payment remark here' value={paymentInfo?.remark} />
                                </div>
                                <p style={errStyle}>{errMessage?.remarkErr}</p>
                                <div className={CreditVoucherCustCss.input_section}>
                                    <label>Date</label>
                                    <input onChange={onDataChange} name='paymentDate' type='datetime-local' value={paymentInfo?.paymentDate} />
                                </div>
                                <p style={errStyle}>{errMessage?.dateErr}</p>
                                <div className={CreditVoucherCustCss.input_section}>
                                    <label>Remaining </label>
                                    <input type='text' disabled value={paymentInfo?.paymentAmmount ? getPayableDue() - paymentInfo?.paymentAmmount : getPayableDue()} />
                                </div>
                                <div className={CreditVoucherCustCss.button_section}>
                                    <button onClick={onSaveClick}>Save</button>
                                </div>
                            </div>
                        </div>
            </div>:''}
        </>
    )
}

export default CreditVoucherCustomer