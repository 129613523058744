import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import OfferItems from "./OfferItems.js";
import OfferReport from './OfferReport.js';
import OfferReportPDF from './OfferReportPDF.js';
import offerMainCss from './css/OfferMain.module.css';
const OfferMain = () => {
    return (
        <>
            <div className={offerMainCss.menupart}>
                <ul className={offerMainCss.menuitems}>
                    <NavLink to={"/admin/sales_offers/offer_items"}>
                        <li >Offer items</li>
                    </NavLink>
                    <NavLink to={"/admin/sales_offers/offer_report"}>
                        <li >Offer Reports</li>
                    </NavLink>
                </ul>
            </div>
            <div>
                <Routes>
                    <Route path='/' element={<OfferItems />} />
                    <Route path='offer_items' element={<OfferItems />} />
                    <Route path='offer_report' element={<OfferReport />} />
                    <Route path='report_route' element={<OfferReportPDF />} />
                </Routes>
            </div>
        </>
    )
}

export default OfferMain
