import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AreaData, DistrictData } from "../../api/MyLocation";
import DummyImage from "../../assets/dummyuser.png";
import { AddSupplier } from '../../api/SupplierApi';
import { useDispatch } from 'react-redux';
import { setStatus } from '../../ReduxFiles/Slices/SupplierSlice';
import AddNewSupplierCss from './css/AddNewSupplier.module.css'


const AddNewSupplier = () => {
  const dispatch = useDispatch()
  const myNavigate = useNavigate()
  let DivitionArray = ["Dhaka", "Chittagong", "Khulna", "Mymensingh", "Sylhet", "Rangpur", "Rajshahi", "Barisal"]
  const [districtData, setDistrictData] = useState();
  const [areaData, setAreaData] = useState();
  const [errText, setErrText] = useState();
  const [supplyData, setSupplyData] = useState({});
  const[spinnerShow, setSpinnerShow] = useState(false)

  function onInputChange(e) {
    let name = e.target.name
    let value = e.target.value
    if (e.target.type === 'file') {
      setSupplyData({ ...supplyData, [name]: e.target.files[0], fakeUrl: genarateFakeUrl(e.target.files[0]) })
    } else {
      setSupplyData({ ...supplyData, [name]: value })
    }

  }
  function genarateFakeUrl(image) {
    let myurl = URL.createObjectURL(image)
    return myurl;
  }

  function onDivitionChange(e) {
    const DivitionValue = e.target.value;
    const DivitionName = e.target.name;
    setSupplyData({ ...supplyData, [DivitionName]: DivitionValue });
    let arr = []
    DistrictData?.forEach(element => {
      if (element.division === e.target.value) {
        arr.push(element.district)
      }
    });
    arr.sort()
    setDistrictData(arr)
  }

  function onDisChange(e) {
    const DistrictValue = e.target.value;
    const DistrictName = e.target.name;

    setSupplyData({ ...supplyData, [DistrictName]: DistrictValue })
    let arr = []
    AreaData?.forEach(element => {
      if (element.distrctName === e.target.value) {
        arr.push(element.AreaName)
      }

    })
    arr.sort()
    setAreaData(arr)
  }

  function OnAreaChange(e) {
    const AreaValue = e.target.value;
    const AreaName = e.target.name;
    setSupplyData({ ...supplyData, [AreaName]: AreaValue })
  }
  async function onSaveClick() {    
    let finalData = {
      Area: supplyData?.Area,
      District: supplyData?.District,
      Divition: supplyData?.Divition,
      address: supplyData?.address ? supplyData?.address:'',
      altphone: supplyData?.altphone ? supplyData?.altphone:'',
      bazar: supplyData?.bazar ? supplyData?.bazar:'',
      companyaName: supplyData?.companyaName,
      email: supplyData?.email ? supplyData?.email:'',
      nid: supplyData?.nid ? supplyData?.nid:'',
      ownerName: supplyData?.ownerName,
      phone: supplyData?.phone,
      tin: supplyData?.tin ? supplyData?.tin:'',
      status:1
    }

    let companyaNameerr = ValidateFunction('companyaName', 'Company Name ')
    let ownerNameerr = ValidateFunction('ownerName', 'Owner Name ')
    let phoneerr = ValidateFunction('phone', 'Phone Number ')
    let Divitionerr = ValidateFunction('Divition', 'Divition ')
    let Districterr = ValidateFunction('District', 'District ')
    let Areaerr = ValidateFunction('Area', 'Area ')
    setErrText({ companyaNameerr, ownerNameerr, phoneerr, Divitionerr, Districterr, Areaerr })
    if (!companyaNameerr && !ownerNameerr && !phoneerr && !Divitionerr && !Districterr && !Areaerr) {
      setSpinnerShow(true)
     await AddSupplier(finalData)
     dispatch(setStatus('idle'))
     setSpinnerShow(false)
     myNavigate('/admin/supplier/supplierlist')
    }else{
      console.log('must fill required data')
    }
  }
  function ValidateFunction(field, message) {
    let text = ""
    if (!supplyData[field]) {
      text = message + "is Required"
    }
    return text;
  }

  return (
    <>
      {spinnerShow ?  <Spinner text={'Saving...'}/>:""}
      <div className={AddNewSupplierCss.heading}>
        <h2>Add New Supplier</h2>
        <div className={AddNewSupplierCss.addsupplier}>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>Company Name *</label>
          <input name='companyaName' type='text' placeholder='Company Name' onChange={onInputChange} />
          {errText ? <p className={AddNewSupplierCss.WarningText}>{errText?.companyaNameerr}</p> : ""}
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>Owner Name *</label>
          <input name='ownerName' type='text' placeholder='Owner Name' onChange={onInputChange} />
          {errText ? <p className={AddNewSupplierCss.WarningText}>{errText?.ownerNameerr}</p> : ""}
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>Phone No *</label>
          <input name='phone' type='text' placeholder='Phone No' onChange={onInputChange} />
          {errText ? <p className={AddNewSupplierCss.WarningText}>{errText?.phoneerr}</p> : ""}
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>Alternative Phone</label>
          <input name='altphone' type='text' placeholder='Alternative Phone' onChange={onInputChange} />
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>Email</label>
          <input name='email' type='text' placeholder='Email' onChange={onInputChange} />
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>Division *</label>
          <PopUpOption popName={"Divition"} myArray={DivitionArray} myValue={supplyData?.Divition} onDataChange={onDivitionChange} />
          {errText ? <p className={AddNewSupplierCss.WarningText}>{errText?.Divitionerr}</p> : ""}
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>District *</label>
          <PopUpOption popName={"District"} myArray={districtData} myValue={supplyData?.District} onDataChange={onDisChange} />
          {errText ? <p className={AddNewSupplierCss.WarningText}>{errText?.Districterr}</p> : ""}
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>Sub-District & Thana *</label>
          <PopUpOption popName={"Area"} myArray={areaData} myValue={supplyData?.Area} onDataChange={OnAreaChange} />
          {errText ? <p className={AddNewSupplierCss.WarningText}>{errText?.Areaerr}</p> : ""}
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>Bazar</label>
          <input name='bazar' type='text' placeholder='Bazar' onChange={onInputChange} />
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>NID</label>
          <input name='nid' type='text' placeholder='NID' onChange={onInputChange} />
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>TIN</label>
          <input name='tin' type='text' placeholder='TIN' onChange={onInputChange} />
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>Address</label>
          <input name='address' type='text' placeholder='Address' onChange={onInputChange} />
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <label>Supplier Image</label>
          <input name='supplierImage' type='file' placeholder='Address' onChange={onInputChange} />
        </div>
        <div className={AddNewSupplierCss.supplierinput}>
          <img src={supplyData?.fakeUrl ? supplyData.fakeUrl : DummyImage} alt='supplier logo' />
        </div>
        <div className={`${AddNewSupplierCss.supplierinput} ${AddNewSupplierCss.buttonsection}`}>
          <button onClick={onSaveClick}>SAVE</button>
        </div>

      </div>
      </div>
      
    </>
  )
}

export default AddNewSupplier

const PopUpOption = ({ myArray, onDataChange, popName, myValue }) => {

  let OptionData = <>
    {myArray ? myArray.map((item, index) => {
      return <option key={index} onChange={onDataChange} value={item} selected={myValue === item ? true : false}>{item}</option>
    }) : ""}
  </>

  return (
    <>
      <select className={AddNewSupplierCss.popOption} name={popName} onChange={onDataChange} >
        <option value={""}>--Select Item--</option>
        {OptionData}
      </select>

    </>
  )
}

const Spinner = ({text}) => {
  return (
     <>
       <div className={AddNewSupplierCss.SpinnerWrrapper}>
      <div id="loader"></div>
      <p>{text}</p>
  </div>
     </>
  )
}