import { faBars, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DeliveryAssinedListCss from './css/DeliveryAssinedList.module.css'


const DeliveryAssinedList = () => {
    const [tableData, setTableData] = useState()

    const [activityView, setActivityView] = useState(false)
    const [activityTableData, setActivityTableData] = useState()
    //GetWhereAndOrderData(tableName, coloumnName, data, colName, ascDESC)
    // let myDate = await GetSingleData('sales', 'deliveryBoyAssigned', true)

    async function activityViewClick(e, item) {
        let staticData = {
            activity: "Customer Order Placed",
            createdDate: item.createdDate,
            ProssesedBy: '',
            ForwardTo: "",
            deliveryDate: "",
            note: ""
        }
        // let dataArr = [] GetNestedAllDatawithOrder
        // let myData = await GetNestedAllDatawithOrder('sales', item.id, 'ordersummery', '', 'createdDate', 'asc')
        // myData.splice(0, 0, staticData)
        // setActivityTableData(myData)
        setActivityView(true)

    }
    async function getAllData() {
        //  let myDate = await GetWhereAndOrderData('sales', 'deliveryBoyAssigned', true, 'createdDate', 'desc')
        //  setTableData(myDate)
        // console.log(myDate)
        // setNestedData(myNestedData)

    }

    useEffect(() => {
        getAllData()
    }, [])

    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    const statusSwitch = (value, item) => {
        // console.log(value)
        let data = <></>
        switch (value) {
            case 'Order Confirmed':
                data = <p className={'confirmed'}>{value}</p>
                break;
            case 'Delivery Complete':
                data = <p className={'complete'}>{value}</p>
                break;
            case 'Packaging Complete':
                data = <>
                    <p className={'packaging'}>{value}</p>

                </>
                break;
            case 'Order Partial Return':
                data = <p className={'preturn'}>{value}</p>
                break;
            case 'Order Full Return':
                data = <p className={'preturn'}>{value}</p>
                break;
            case 'Delivery Agent Assigned':
                data = <>
                    <p className={'deliveryboyassigned'}>{value}</p>
                </>
                break;

            default:
                break;
        }

        return data
    }

    const TableItem = tableData?.map((item) => {
        return <MyTable key={item.invoiceno} id={<Link to={`/admin/deliveryagent/deliveryassignmentlist/${item.id}`}>{'#' + item.invoiceno}</Link>} assigneddate={convertDate(item.deliveryAssignedTime.seconds)} orderdate={convertDate(item.createdDate.seconds)} agentname={item.deliveryBoyName} assignedby={item.assignedBy} status={statusSwitch(item.status)} action={
            <div className='actionButton'>
                <Link to={`/admin/deliveryagent/deliveryassignmentlist/${item.id}`}><FontAwesomeIcon icon={faInfoCircle} /></Link>
                <a onClick={(e) => { activityViewClick(e, item) }}><FontAwesomeIcon className='myBars' icon={faBars} /></a>
            </div>} />

    })
    const marginTop = {
        marginTop: '10px'
    }
    return (
        <>
            {activityView ? <OrderActivities setActivityView={setActivityView} activityTableData={activityTableData} /> : ""}
            <section style={marginTop} className={DeliveryAssinedListCss.salesMain}>
                <div className={DeliveryAssinedListCss.innerSale}>
                    <h2 className={DeliveryAssinedListCss.saleHeading}>Delivery Assignment list</h2>
                    <div className={DeliveryAssinedListCss.searchSection}>
                        <MyInput labelText="Start" type='date' inputName='start_date' placeholderText='dd/mm/yyyy' />
                        <MyInput labelText="End" type='date' inputName='end_date' placeholderText='dd/mm/yyyy' />
                        <OptionComp className={DeliveryAssinedListCss.drliverAssignListSearch} />
                        <div className={DeliveryAssinedListCss.btn_section}>
                            <ButtonInput mclass='searchClass' myValue={'Search'} />
                            <ButtonInput mclass='resetClass' myValue={'Reset'} />
                        </div>
                    </div>
                    <div className={DeliveryAssinedListCss.tableSection}>
                        <table>
                            <tr>
                                <th>Order ID</th>
                                <th>Assigned Date</th>
                                <th>Order Date</th>
                                <th>Agent Name</th>
                                <th>Assigned By</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            {TableItem}
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DeliveryAssinedList

const MyTable = ({ id, assigneddate, orderdate, agentname, assignedby, status, action, customerClick }) => {

    return (
        <>
            <tr>
                <td className={DeliveryAssinedListCss.invoiceClick}>{id}</td>
                <td>{assigneddate}</td>
                <td>{orderdate}</td>
                <td>{agentname}</td>
                <td>{assignedby}</td>
                <td className={DeliveryAssinedListCss.myStatus}>{status}</td>
                <td>{action}</td>
            </tr>
        </>
    )
}

const MyInput = ({ labelText, type, inputName, placeholderText, myValue }) => {
    const diplayBlock = {
        'display': 'block'
    }
    return (
        <>
            <div className={DeliveryAssinedListCss.inputAll}>
                <label style={diplayBlock}>{labelText}</label>
                <input type={type} name={inputName} placeholder={placeholderText} value={myValue} />
            </div>
        </>
    )
}
const ButtonInput = ({ myValue, mclass, onClickevent }) => {
    const diplayBlock = {
        'display': 'block'
    }
    return (
        <>
            <div>
                <button className={mclass} onClick={onClickevent}>{myValue}</button>
            </div>
        </>
    )
}

const OptionComp = ({ myArray, onDataChange, popName, myValue }) => {
    let OptionData = <>
        {myArray ? myArray.map((item) => {
            return <option onChange={onDataChange} value={item} selected={myValue === item ? true : false}>{item}</option>
        }) : ""}
    </>
    const diplayBlock = {
        'display': 'block'
    }
    return (
        <>
            <div className={`${DeliveryAssinedListCss.optionAll} ${DeliveryAssinedListCss.drliverAssignListSearch}`}>
                <label style={diplayBlock}>Select Customer</label>
                <select name={popName} onChange={onDataChange} >
                    {OptionData}
                </select>
            </div>

        </>
    )
}

const OrderActivities = ({ setActivityView, activityTableData }) => {


    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    return (
        <>
            <div className='orderactivites'>
                <h3> Order Activities</h3>

                <h4> Order Activity History Of #11</h4>
                <table>
                    <tr>
                        <th>Sl</th>
                        <th>Activity</th>
                        <th>Entry Time</th>
                        <th>Processed By</th>
                        <th>Forward To</th>
                        <th>Delivery Date</th>
                        <th>Notes</th>
                    </tr>
                    {activityTableData?.map((item, index) => {
                        return <tr>
                            <td>{index + 1}</td>
                            <td>{item.activity}</td>
                            <td>{convertDate(item.createdDate?.seconds)}</td>
                            <td>{item.ProssesedBy}</td>
                            <td>{item.ForwardTo}</td>
                            <td>{item.deliveryDate}</td>
                            <td>{item.note}</td>
                        </tr>
                    })}
                </table>
                <button onClick={() => { setActivityView(false) }}>Close</button>
            </div>
        </>
    )
}