import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEdit, faEye, faFilePen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUser, selectBackUserList, selectBackUserStatus, selectUserInfo } from '../../ReduxFiles/Slices/UserSlice';
import Avatar from  '../../assets/dummyuser.png'
import { GetUserImage } from '../../api/BackendUserApi';
import { GetFilterPurchaseVariance, GetPurchaseItems, GetPurchasePending, GetSummeryList, SavePurchaseSummery, UpdatePurchaseData } from '../../api/PurchaseApi';
import { fetchAllSupplier, selectSupplierList, selectSupplierStatus } from '../../ReduxFiles/Slices/SupplierSlice';
import { GetFilterProductVariance } from '../../api/ProductVarianceApi';
import { SaveProductStock, SaveProductVarianceStock} from '../../api/StockApi';
import { SaveDayBookData } from '../../api/DayBookApi';
import { GetDebitDataFilter } from '../../api/DebitApi';
import { selectProductList } from '../../ReduxFiles/Slices/ProductSlice';
import PendingPurchaseListCss from './css/PendingPurchaseList.module.css'
const PendingPurchaseList = () => {
    const supplierStatus = useSelector(selectSupplierStatus)
    const dispatch = useDispatch() 
    const backUserStatus= useSelector(selectBackUserStatus)
    const backendUser = useSelector(selectBackUserList)
    const [tableData, setTableData] = useState([])
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [popData, setPopData] = useState()
    const [summeryPop, setSummeryPop] = useState()
    async function getAllData() {
        setSpinnerShow(true)
         let allData = await GetPurchasePending()
         let myPayData = []
         let myPromise = allData.map(async function (item) {
            let payData = await GetDebitDataFilter(`identity = ${item.id}`)
            myPayData.push(...payData)
         })
         Promise.all(myPromise).then(()=>{
            let finalArr = []
            allData.forEach(element => {
                let dataMatch = false
                let total = 0
                for (let index = 0; index < myPayData?.length; index++) {
                    const pelement = myPayData[index];
                    if(pelement.identity === element.id){
                       total = pelement.paymentAmmount
                       dataMatch = true
                    }
                    
                }
                let percent = element.percent ? element.percent:0
                let discount = (element.grandTotal) * percent/100
                // console.log(element.grandTotal) 
                if (!dataMatch) {
                    finalArr.push({...element, discount:discount})
                }else{
                    finalArr.push({...element, payAmmount:total, discount:discount})
                }
            });
            setTableData(finalArr)
            setSpinnerShow(false)
         })
       
    }

    useEffect(() => {
        getAllData()
        if (backUserStatus==='idle') {
            dispatch(fetchAllUser()) 
        }
        if (supplierStatus==='idle') {
            dispatch(fetchAllSupplier()) 
        }
    }, [])

    function convertDate(time) {
        const dateInMillis = time 
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        // return myDate + " " + myTime
        return myDate + " " + myTime
    }
    const toltipPos = {
        top: '30px',
    }

    async function onDelcineClick(e, item) {
        let userData = window.localStorage.getItem('userData')
        userData = JSON.parse(userData)
        let summeryData = {
            supplierName: item.supplierInfo?.name,
            status: 'PO Declined',
            attemptBy: userData.name,
        }
        let tableArr = []
        tableData?.forEach(element => {
            if (element.id === item.id) {
            } else {
                tableArr.push(element)
            }

        });
        alert('need to work')
    }
    function onReceiveClick(e, item) {
        // console.log(item)
        setPopData(item)
    }

    function showSummeryPop(e, item) {
        //  console.log(item)
        setSummeryPop(item)
    }
    const CommonStyle = {
        color: 'white',
        borderRadius: '5px',
        textAlign: 'center',
        padding: '5px 0'
    }
    const statusSwitch = (value, item) => {

        let data = <></>
        switch (value) {
            case 'Variance Managed':
                data = <p style={{ ...CommonStyle, backgroundColor: '#1B1464' }}>{value}</p>
                break;
            case 'PO Received':
                data = <p style={{ ...CommonStyle, backgroundColor: '#EE5A24' }}>{value}</p>
                break;
            case 'PO Declined':
                data = <p style={{ ...CommonStyle, backgroundColor: '#EA2027' }}>{value}</p>
                break;
            case 'PO Partial Return':
                data = <p className={'preturn'}>{value}</p>
                break;
            case 'PO Full Return':
                data = <p className={'preturn'}>{value}</p>
                break;
            default:
                data = <p style={{ ...CommonStyle, backgroundColor: '#006266' }}>{'PO Placed'}</p>
                break;
        }

        return data
    }

    const tableItem = tableData?.map((item, index) => {
        return <MyTable key={item.id} po={'#' + item.id} date={convertDate(item.created_date)} total={(item.grandTotal - (item.grandTotal*item.percent/100)-item.payAmmount) + "Tk"} processedby={<div className={PendingPurchaseListCss.salesHistoruImagesec}>
            {backendUser?.filter((fitem)=>{
                return fitem.id===item.processorID ? fitem:''
            })
            .map((uitem)=>{
                return <React.Fragment key={uitem.id+'user'}>
                <AvatarComp name={uitem.userImageName}/>
                <p>{uitem.userName}</p>
                </React.Fragment>
            })}
            
        </div>}
            status={statusSwitch(item.status)}
            summery={<FontAwesomeIcon style={{ cursor: 'pointer', color: 'purple' }} icon={faBars} onClick={(e) => { showSummeryPop(e, item);document.body.style.overflow = 'hidden' }} />}
            action={
                <div className={PendingPurchaseListCss.actionButton}>
                    {item.varianceManaged ? <button onClick={(e) => { onReceiveClick(e, item) }}>receive PO</button> : <>
                    </>}
                    <Link to={'/admin/purchase/pendinglist/' + item.id + '/varianceedit'} className={PendingPurchaseListCss.tooltip_holder}>
                        <FontAwesomeIcon className={PendingPurchaseListCss.color_purple} icon={faFilePen} />
                        <span style={toltipPos} className={PendingPurchaseListCss.tooltip}>Manage Variance Quantity</span>
                    </Link>
                    <Link to={'/admin/purchase/pendinglist/' + item.id + '/quantityedit'}>
                        <p className={PendingPurchaseListCss.tooltip_holder}>
                            <FontAwesomeIcon icon={faEdit} />
                            <span style={toltipPos} className={PendingPurchaseListCss.tooltip}>Edit PO</span>
                        </p>
                    </Link>
                    {item.varianceManaged ? <Link to={'/admin/purchase/pendinglist/' + item.id + '/view'}><FontAwesomeIcon className={PendingPurchaseListCss.color_purple} icon={faEye} /></Link> : ''}
                    <a onClick={(e) => { onDelcineClick(e, item) }}><FontAwesomeIcon className={PendingPurchaseListCss.color_red} icon={faTrashCan} /></a>
                </div>
            } />
    })

    function checkClick(params) {
        // tableData.map((item) => {
        //     console.log(item)
        // })
    }

    const marginTop = {
        marginTop: '10px'
    }

    return (
        <>
            {spinnerShow ? <Spinner text={'Loading...'} /> : ""}
            {popData ? <ReceivePOpop popData={popData} setPopData={setPopData} /> : ""}
            {summeryPop ? <SummeryPop popData={summeryPop} setPopData={setSummeryPop} /> : ""}

            <section style={marginTop} className={PendingPurchaseListCss.salesMain}>
                <div className={PendingPurchaseListCss.innerSale}>
                    <h2 className={PendingPurchaseListCss.saleHeading} onClick={checkClick}>Pending Purchase</h2>
                    <div className={PendingPurchaseListCss.tableSection}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>PO</th>
                                    <th>Date</th>
                                    <th>Total Ammount</th>
                                    <th>Processed By</th>
                                    <th>Status</th>
                                    <th>Summery</th>
                                    <th>Action</th>
                                </tr>
                                {tableItem}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PendingPurchaseList

const SummeryPop = ({ popData, setPopData }) => { 
    const mySupplier = useSelector(selectSupplierList)
    const backendUser = useSelector(selectBackUserList)
    const [tableData, setTableData] = useState()
    // console.log(popData);

    async function getPoSummery() {
        let summeryData = await GetSummeryList({id:popData.id})

        let staticData = {
            supplier_id: popData.supplierID,
            status: "PO Placed",
            attemptBy: popData.processorID,
            created_date: popData.created_date,
        }
        summeryData.splice(0, 0, staticData)
        setTableData(summeryData)

    }
    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }

    useEffect(() => {
        getPoSummery()
    }, [])

    return <div className={PendingPurchaseListCss.orderactivites_wrapper}>
        <div className={PendingPurchaseListCss.orderactivites}>
            <h3> PO Activities</h3>
            <h4> PO Activity History Of # {popData.id}</h4>
            <table>
               <thead>
               <tr>
                    <th>Sl</th>
                    <th>Date</th>
                    <th>Supplier Name</th>
                    <th>Status</th>
                    <th>Attempt By</th>
                    <th>Attempt Time</th>
                </tr>
               </thead>
               <tbody>
               {tableData?.map((item, index) => {
                    return <tr key={index+'sum'}>
                        <td>{index + 1}</td>
                        <td>{popData?.poDate.replaceAll('T', ' ')}</td>
                        <td>{mySupplier.filter((fitem)=>{
                        return fitem.id === item.supplier_id ? fitem:''
                        })
                        .map((sitem)=>{
                            return sitem.companyaName
                        })}
                        </td>
                        <td>{item.status}</td>
                        <td>{backendUser.filter((fitem)=>{
                                return fitem.id === item.attemptBy ? fitem:''
                            })
                            .map((uitem)=>{ return uitem.userName})}
                        </td>
                        <td>{convertDate(item.created_date)}</td>
                    </tr>
                })}
               </tbody>
            </table>
            <button onClick={() => { setPopData(); document.body.style.overflow = 'auto' }}>Close</button>
        </div>
    </div>
}

const ReceivePOpop = ({ popData, setPopData }) => {
    const userInfo = useSelector(selectUserInfo)
    const [tableData, setTableData] = useState()
    const [varData, setVarData] = useState()
    const myNavigate = useNavigate()
    async function onConfirm() {
        tableData.forEach(async (element) => {
            await SaveProductStock({ product_id: element.product_id, stock_quantity: element.quantity })
            if (element.hasVriance) {
                element.variance.map(async (vitem) => {
                    let vrID = vitem.varinace_id
                    await SaveProductVarianceStock({ variance_id: vrID, stock_quantity: vitem.quantity })
                })
            }
        });
          await UpdatePurchaseData(popData.id, {pending:0, receivedPO: 1, status: 'PO Received' })

          let dayBookData = {
            created_date:new Date().getTime(),
            paticulars:"Purchase (PO Received)",
            identity:popData.id,
            received:0,
            payment:popData.payAmmount ? popData.payAmmount:0,
            processorID:userInfo.id,
        }
       await SaveDayBookData(dayBookData)
        let summeryData = {
            order_date:popData.poDate,
            supplier_id:popData.supplierID,
            status:'PO Received',
            attemptBy:userInfo.id,
            purchase_id:popData.id,
            created_date:new Date().getTime()
          }
        await SavePurchaseSummery(summeryData)
    setPopData()
   myNavigate('/admin/purchase/purchasehistory')
        
    }
    function onCloseClick(params) {
        setPopData()
    }
    const mAllProducts = useSelector(selectProductList)
    async function getTableData() {
        let tableData = await GetPurchaseItems(`purchase_id = ${popData.id}`)
        let AllVarData = []
        let tempArr = []
      let myPromises = tableData.map(async function(item) {
            if (item.hasVriance) {
                let varianceData = await GetFilterProductVariance(`product_id = ${item.product_id}`)
                let purchasedVar = await GetFilterPurchaseVariance(`product_id = ${item.product_id} AND purchase_item_id = ${item.id}`)
                item = {
                    ...item,
                    variance:purchasedVar
                }
                AllVarData.push(...varianceData)
                tempArr.push(item)
                
            }else{
                item = {
                    ...item,
                    variance:[]
                }
                tempArr.push(item)
            } 
        })
        Promise.all(myPromises).then(()=>{            
            let myArr = []
            tempArr.forEach(element => {
                for (let index = 0; index < mAllProducts?.length; index++) {
                    const pelement = mAllProducts[index];
                    if (element.product_id === pelement.id) {
                        element = {
                            ...element,
                            name: pelement.en_sort_title
                        }
                        myArr.push(element)
                        break
                    }
                }
            });
            setTableData(myArr) 
            setVarData(AllVarData)           
        })
       
        // console.log(tableData)
    }
    useEffect(() => {
        getTableData()
    }, [])
    
    const myColor = {
        color: 'yellow',
        fontSize: '30px'

    }
    return <>
        <div className={PendingPurchaseListCss.my_popup}>
            <h2>Receive Purchase Item(s)- <strong style={myColor}> #{popData.id}</strong></h2>
            <table>
                <tbody>
                    <tr>
                        <th>Sl</th>
                        <th>Item Name</th>
                        <th>Total Quantity</th>
                        <th>Details</th>
                    </tr>
                    {tableData?.map((item, index) => {
                       return  <tr key={index+'main'}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.hasVriance ?
                               item.variance.map((vitem) => {
                                   return <React.Fragment key={vitem.id + 'vr'}>
                                       {varData.filter((fitem) => {
                                        return fitem.id===vitem.varinace_id ? fitem:''
                                       })
                                        .map((mvar) => {
                                           return <p key={mvar.id+'mvar'}>{mvar.Variance_name}</p>
                                        })}
                            </React.Fragment> 
                        }) 
                        :'No Variance'}</td>
                    </tr>
                    })
                        
                    }

                </tbody>
            </table>
            <div className={PendingPurchaseListCss.button_section}>
                <button onClick={onConfirm}>Receive</button>
                <button onClick={onCloseClick}>Close</button>
            </div>
        </div>
    </>
}

const MyTable = ({ po, date, total, processedby, status, summery, action }) => {

    return <tr key={po}>
            <td>{po}</td>
            <td>{date}</td>
            <td>{total}</td>
            <td>{processedby}</td>
            <td>{status}</td>
            <td>{summery}</td>
            <td>{action}</td>
        </tr>
}

const Spinner = ({ text }) => {
    const myIndex = {
        zIndex: '15'
    }
    return (
        <>
            <div style={myIndex} className={PendingPurchaseListCss.SpinnerWrrapper}>
                <div id="loader"></div>
                <p>{text}</p>
            </div>
        </>
    )
}


const AvatarComp = ({ name }) => {
    const [image, setImage] = useState()
    async function getLink() {
        if (name) {
            let imageInfo =  await GetUserImage({image_name:name})
            if (imageInfo.imageExist) {
                setImage(imageInfo.url)
            }else{
                setImage(Avatar)
            }
        }else{
            setImage(Avatar)
        }
    }
    useEffect(() => {
        getLink()
    }, [])
    return (
        <>
        {/* avatar this class not found */}
            <img className='avatar' src={image} alt='avatar' />
        </>
    )
}