import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import ManageUser from './ManageUser'
import AddnewUser from './AddnewUser'
import TrashListUser from './TrashListUser'
import ManageUserMainCss from './css/ManageUserMain.module.css'

const ManageUserMain = () => {
    const myParams = useParams()

    return (
        <>
            <div className={ManageUserMainCss.menupart}>
                <ul className={ManageUserMainCss.menuitems}>
                    <NavLink to={"/admin/manage_user/userlist"}>
                        <li >Manage User</li>
                    </NavLink>
                    <NavLink to={"/admin/manage_user/newuser"}>
                        <li >Add New User</li>
                    </NavLink>
                    <NavLink to={"/admin/manage_user/trashlist"}>
                        <li >Trash List</li>
                    </NavLink>
                </ul>
            </div>
            <div>
            {myParams.muser === undefined ? <ManageUser/>: ""}
                {myParams.muser === "userlist" ? <ManageUser/>: ""}

                {myParams.muser === "newuser" ? <AddnewUser/>: ""}
                {myParams.muser === "trashlist" ? <TrashListUser/>: ""}
            </div>


        </>
    )
}

export default ManageUserMain;