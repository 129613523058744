import React, { useEffect, useState } from 'react'
import './imagename.css'

const GetImageName = () => {
    const [state, setState] = useState()
    const [finalState, setFinalState] = useState()

    function onInputChange(e) {
        setState(e.target.files)
    }

    function onButtonClick() {
        console.log(1)
        console.log(state)
        let myArr = []
        for (let index = 0; index < state.length; index++) {
            const element = state[index];
           // console.log(element.name)
            let data = {
                name:element.name,
                copied:false
            }
            myArr.push(data)
        }
        window.localStorage.setItem('image_name', JSON.stringify(myArr))
        setFinalState(myArr)
    }

    useEffect(()=>{
        let preData = window.localStorage.getItem('image_name')
        if (preData !== undefined || preData !== 'undefined') {
           preData = JSON.parse(preData)
           setFinalState(preData)
        }

    },[])
    
    function onItemClick(item) {
        let tempArr = []
        for (let index = 0; index < finalState.length; index++) {
            let element = finalState[index];
            if (element.name===item.name) {
                element = {
                    ...element,
                    copied:true
                }
            }
            tempArr.push(element)
            
        }
        CopyText(item.name)
        window.localStorage.setItem('image_name', JSON.stringify(tempArr))
        setFinalState(tempArr)
    }

    function CopyText(text) {
        
        navigator.clipboard.writeText(text).then(function() {
          //console.log('Async: Copying to clipboard was successful!');
          //alert("Coppied");
        }, function(err) {
            alert("Not Coppied", err);
        });
      }

  return (
    <div>
        <h2>Upload Image & Get Name</h2>
        <input name='imagename' type='file' multiple onChange={onInputChange} accept="image/png, image/gif, image/jpeg"/>
        <button onClick={onButtonClick}>Click Me</button>

        <div>
            {finalState?.map((item, index)=>{
                return <p key={index} className={item.copied ? 'display_none':'name-click'} onClick={()=>{onItemClick(item)}}>{index+1} . {item.name}</p>
            })}
        </div>
    </div>
  )
}

export default GetImageName