import React, { useEffect, useState } from 'react'
import { GetDataPassQuery } from '../../../api/ExpressFunctions'
import { GetEndDate, GetStartDate, LocalDateToTime, LocalTimeStampToDate, GetInputFormatedDate} from '../../MyFunctions/MyFunctions'
import { GetDayBookFilterData } from '../../../api/DayBookApi'
import DayBookCss from './Css/DayBook.module.css'

const DayBook = () => {
    const [tableData, setTableData] = useState()
    const [filterData, setFilterData] = useState()
    const [showSpinner, setShowSpinner] = useState(false)
    
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setFilterData({...filterData, [name]:value})
    }
    async  function getDataFormDatabase() {
        setShowSpinner(true)
        let myStartDate = new Date(GetStartDate(GetInputFormatedDate())).getTime()
        let myEndDate = new Date(GetEndDate(GetInputFormatedDate())).getTime()
        let dayData = await GetDayBookFilterData(`created_date > ${myStartDate} && created_date < ${myEndDate}`)
        setTableData(dayData)
        setShowSpinner(false)
    }
 
    async function onSearchClick() {
        setShowSpinner(true)
        let myStartDate = new Date(GetStartDate(filterData.startDate)).getTime()
        let myEndDate = new Date(GetEndDate(filterData.endDate)).getTime()
        let dayData = await GetDayBookFilterData(`created_date > ${myStartDate} && created_date < ${myEndDate}`)
        setTableData(dayData)
        setShowSpinner(false)
    }
    useEffect(() => {
        setFilterData({...filterData, startDate:GetInputFormatedDate(), endDate:GetInputFormatedDate()})
        getDataFormDatabase()

    },[])
  return (
      <>
       {showSpinner ? <Spinner text={'loading...'}/>:''}
          <section className={DayBookCss.salesMain}>
              <div className={DayBookCss.innerSale}>
                  <h2 className={DayBookCss.saleHeading}>Day Book</h2>
                  <div className={DayBookCss.search_section}>
                      <div className={DayBookCss.input_section}>
                          <label>Start Date</label>
                          <input name='startDate' type={'date'} value={filterData?.startDate?filterData?.startDate:''} onChange={onDataChange}/>
                      </div>
                      <div className={DayBookCss.input_section}>
                          <label>End Date</label>
                          <input name='endDate' type={'date'} value={filterData?.endDate ? filterData?.endDate:''} onChange={onDataChange}/>
                      </div>
                     
                      <div className={DayBookCss.input_section}>
                          <button onClick={onSearchClick}>search</button>
                          <button>reset</button>
                      </div>
                      
                  </div>
                  <div className={DayBookCss.tableSection}>
                      <table>
                          <tbody>
                              <tr>
                                  <th>Sl</th>
                                  <th>Date</th>
                                  <th>Particulars</th>
                                  <th>Receipt</th>
                                  <th>Payment</th>
                                  <th>Process By</th>
                                  <th>Action</th>
                              </tr>
                              {tableData?.map((item, index)=>{
                                return <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{LocalTimeStampToDate(item.created_date)}</td>
                                    <td>{item.paticulars} - #{item.identity}</td>
                                    <td>{item.received}</td>
                                    <td>{item.payment}</td>
                                    <td>{item.processorID}</td>
                                    <td>action</td>
                                </tr>
                              })}
                          </tbody>
                      </table>
                  </div>
              </div>
          </section>
      </>
  )
}

export default DayBook

const Spinner = ({text}) => {
    const myIndex = {
      zIndex: '15' 
    }
    return (
       <>
         <div style={myIndex} className={DayBookCss.SpinnerWrrapper}>
        <div id="loader"></div>
        <p>{text}</p>
    </div>
       </>
    )
  }