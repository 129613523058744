import { faBars, faEye, faTrashArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { GetFilteredDataExpress, SaveDataExpress, UpdateItemExpress } from '../../api/ExpressFunctions'
import { LocalTimeStampToDate } from '../MyFunctions/MyFunctions'
import ExpenseDeclineCss from './css/ExpenseDecline.module.css'
const ExpenseDecline = () => {
    const [filterData, setFilterData] = useState()
    const [tableData, setTableData] = useState()

    async  function getDataFormDatabase() {   
        let myData = await GetFilteredDataExpress('expense', 'declined', 1)
       setTableData(myData)
    }

    function onDataChange(params) {
        
    }
    function onSearchClick(params) {
        
    }
   async function onRestoreClick(index) {
       // console.log(index)
       let myitem = {}
       let myArr = []
        tableData?.map((item, mindex)=>{
            if (index===mindex) {
                myitem = {...item}
            }else{
                myArr.push(item)
            }
        })
        setTableData(myArr)
       // console.log(myitem)

        let userID = window.localStorage.getItem('user_id')
       
         await UpdateItemExpress('expense', {declined:0, pending:1}, myitem.id)

         let ExpenseSummeryDetals ={
            expensID:myitem.id,
            status:'Expense Restored',
            attemptByID:userID,
            note:'',
            created_date:new Date().getTime(),
           }
            await SaveDataExpress('expense_summery', {...ExpenseSummeryDetals})
    }
    const [activityID, setActivityID] = useState()

    function onActivityClick(item, mexNo) {
        setActivityID({id:item.id, exno:mexNo})
    }


    useEffect(() => {
        getDataFormDatabase()
    },[])
    const tableIconStyle = {
        margin: '0',
        padding: '0',
        margin: '0 3px 0 3px',
        fontSize: '18px',
        cursor: 'pointer'
      }
  return (
    <>
    {activityID ? <OrderActivities activityID={activityID} setActivityID={setActivityID}/>:''}
    <section className={ExpenseDeclineCss.salesMain}>
        <div className={ExpenseDeclineCss.innerSale}>
            <h2 className={ExpenseDeclineCss.saleHeading}>Expense History</h2>
            <div className={ExpenseDeclineCss.search_section}>
                      <div className={ExpenseDeclineCss.input_section}>
                          <label>Start Date</label>
                          <input name='startDate' type={'date'} value={filterData?.startDate? filterData?.startDate:''} onChange={onDataChange}/>
                      </div>
                      <div className={ExpenseDeclineCss.input_section}>
                          <label>End Date</label>
                          <input name='endDate' type={'date'} value={filterData?.endDate ? filterData?.endDate:''} onChange={onDataChange}/>
                      </div>
                     
                      <div className={ExpenseDeclineCss.input_section}>
                          <button onClick={onSearchClick}>search</button>
                          <button>reset</button>
                      </div>
                      
                  </div>
            <div className={ExpenseDeclineCss.tableSection}>
                <table>
                    <tbody>
                        <tr>
                            <th>Sl</th>
                            <th>Date</th>
                            <th>Ex. No</th>
                            <th>Amount</th>
                            <th>Paid Amount</th>
                            <th>Due Amount</th>
                            <th>Payment Type</th>
                            <th>Process By</th>
                            <th>Action</th>
                        </tr>
                        {tableData?.map((item, index)=>{
                          return <tr key={index}>
                              <td>{index+1}</td>
                              <td>{LocalTimeStampToDate(item.created_date)}</td>
                              <td>{item.id}</td>
                              <td>{item.ammount}</td>
                              <td>{item.paymentAmmount}</td>
                              <td>{item.ammount-item.paymentAmmount}</td>
                              <td>{item.paymentType}</td>
                              <td>{item.processorID}</td>
                              <td>
                                <span style={{display:'flex'}}>
                                {/* <FontAwesomeIcon style={{...tableIconStyle, color:'green'}} icon={faEdit}/> */}
                                <FontAwesomeIcon onClick={()=>{onRestoreClick(index)}} style={{...tableIconStyle, color:'green'}} icon={faTrashArrowUp}/>
                                    <NavLink to={"/admin/expenses/declined_expense/expense_view_decline?exno="+item.expenseNo}>
                                        <FontAwesomeIcon style={{...tableIconStyle, color:'#A91079'}} icon={faEye}/>
                                    </NavLink>
                                     <FontAwesomeIcon onClick={()=>{onActivityClick(item, item.expenseNo)}} style={{...tableIconStyle, color:'blue'}} icon={faBars}/>
                                </span>
                            </td>
                          </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</>
  )
}

export default ExpenseDecline

const OrderActivities = ({ activityID, setActivityID }) => {
    const [activityTableData, setActivityTableData] = useState()
     async  function getActivityData() {
        let myData = await GetFilteredDataExpress('expense_summery', 'expensID', activityID.id)
        // console.log(myData, activityID)
        let sortArr = myData.sort((a, b) => (a.createdDate?.seconds > b.createdDate?.seconds) ? 1 : -1)
        setActivityTableData(sortArr)
    }
    useEffect(()=>{
        getActivityData()
    },[])
    return (
        <>
            <div className={ExpenseDeclineCss.orderactivites}>
                <h3> Expense Activities</h3>

                <h4> Expense No : #{activityID.exno}</h4>
                <table>
                    <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Activity</th>
                        <th>Entry Time</th>
                        <th>Processed By</th>
                        <th>Notes</th>
                    </tr>
                    {activityTableData?.map((item, index) => {
                        return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.status}</td>
                            <td>{LocalTimeStampToDate(item.created_date)}</td>
                            <td>{item.attemptByID}</td>
                            <td>{item.note}</td>
                        </tr>
                    })}
                    </thead>
                </table>
                <button onClick={() => { setActivityID() }}>Close</button>
            </div>
        </>
    )
}