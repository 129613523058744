import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import { GetAllLocalProducts } from '../MyFunctions/MyFunctions'
import { GetPurchaseItems, GetsinglePurchase } from '../../api/PurchaseApi'
import { GetDebitDataFilter } from '../../api/DebitApi'
import { selectSupplierList } from '../../ReduxFiles/Slices/SupplierSlice'
import { useSelector } from 'react-redux'
import ViewOnlyCss from './css/ViewOnly.module.css'

const ViewOnly = () => {
    const myParams = useParams()
    const [tableData, setTableData] = useState()
    const [orderData, setOrderData] = useState()
    const supplierData = useSelector(selectSupplierList)
    
   async function onPrintInvoice() {
        let finalData = {...orderData, purchaseItems:tableData}
        window.localStorage.setItem('PoInvoiceData', JSON.stringify(finalData))
    }

    function convertDate(time) {
        const dateInMillis = time 
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getAllData() {
        let mAllProducts = GetAllLocalProducts()
        let myData = await GetsinglePurchase(myParams.poid)
        let myNestedData = await GetPurchaseItems(`purchase_id = ${myParams.poid}`)
        let myPayData = await GetDebitDataFilter(`identity = ${myParams.poid}`)
        //product_id
        let myArr = []
        myNestedData.forEach(element => {
            for (let index = 0; index < mAllProducts.length; index++) {
                const pelement = mAllProducts[index];
                if (element.product_id===pelement.id) {
                    element = {
                        ...element,
                        name:pelement.en_sort_title
                    }
                    myArr.push(element)
                    break
                }
            }
        });
        let total = 0
        let finalData = {
            ...myData[0]
        }
        for (let index = 0; index < myPayData?.length; index++) {
            const pelement = myPayData[index];
            if (pelement.identity === parseInt(myParams.poid)) {
                total += pelement.paymentAmmount
            }

        }
        setOrderData({...finalData, payAmmount:total})
        setTableData(myArr)
    }

    useEffect(() => {
        getAllData()
    }, [myParams.orderid])
    
    function finalQuantity(quantity, returnQuantity) {
        let mquantity = 0
        if (returnQuantity) {
            mquantity = quantity-returnQuantity
        }else{
            mquantity = quantity
        }
        return mquantity
    }
    const TableItem = tableData?.map((item, index) => {
        return <MyTable key={item.productID} item={item.name}
            price={item?.price} qty={finalQuantity(item.quantity, item.returnQuantity)} tammount={finalQuantity(item.quantity, item.returnQuantity)*item.price} />

    })

    function getItemTotal(mtabelData){
        let total = 0
        mtabelData?.map((item)=>{
            total += item.quantity * item.price
        })

        return total
    }
    function getPaymentTotal(payArr){
        let total = 0
        payArr?.map((pitem)=>{
            total += pitem.paymentAmmount
        })

        return total
    }
    
    return (
        <>
            <div>
                <section className={ViewOnlyCss.salesMain}>
                    <div className={ViewOnlyCss.innerSale}>
                        <div className={ViewOnlyCss.gobackSec}>
                            <h2 className={ViewOnlyCss.saleHeading}>Purchase History Details</h2>
                            <NavLink to={'/admin/purchase/purchasehistory'}><button>Go Back</button></NavLink>
                        </div>
                        <div className={ViewOnlyCss.orderdetaislInner}>
                            <h3>PO No. #{orderData?.id}</h3>
                            <div>
                                <div className={ViewOnlyCss.customerDetails}>
                                <p className={ViewOnlyCss.highlighter}>Supplier Details</p>
                                    {supplierData?.filter((fitem)=>{
                                        return fitem.id === orderData?.supplierID ? fitem : ''
                                    }).map((item)=>{
                                        return <span>-
                                        <p><strong>Company Name</strong> : {item?.companyaName}</p>
                                        <p><strong>Phone</strong> : {item?.phone}</p>
                                        <p><strong>Division</strong> :{item?.Divition}</p>
                                        <p><strong>District</strong> : {item?.District}</p>
                                        <p><strong>area</strong> : {item?.Area}</p>
                                    </span>
                                    })}
                                </div>
                                <div className={ViewOnlyCss.orderDetails}>
                                    <p className={ViewOnlyCss.highlighter}>PO Details</p>
                                    <p>PO Date : {convertDate(orderData?.created_date)} </p>
                                    <p>Payment Method : {orderData?.paymentMethod}</p>
                                </div>
                            </div>
                            <div className={`${ViewOnlyCss.tableSection} ${ViewOnlyCss.detailstableSection}`}>
                                <table>
                                   <tbody>
                                   <tr>
                                        <th>Item</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total Ammount</th>
                                    </tr>
                                    {TableItem}
                                   </tbody>
                                </table>
                                <div className={ViewOnlyCss.tableDetails}>
                                    <p>Sub total : {getItemTotal(tableData)} tk</p>
                                    <p>Discount ({orderData?.percent?.toFixed(2)}%): {Math.round( (getItemTotal(tableData))*orderData?.percent/100)} tk {}</p>
                                    <p>Paid : {orderData?.payAmmount ? parseInt(orderData?.payAmmount) + getPaymentTotal(orderData?.Payment): "0"} tk</p>
                                    <p>Due Ammount : {Math.round(getItemTotal(tableData) -(orderData?.payAmmount ? orderData?.payAmmount:0)-(orderData?.percent ? (getItemTotal(tableData)*orderData?.percent)/100:0)) -getPaymentTotal(orderData?.Payment)} tk</p>
                                    
                                </div>
                                
                                <div className={ViewOnlyCss.detailsWrapper}>
                                    <div className={`${ViewOnlyCss.detailInput} ${ViewOnlyCss.salesDetail}`}>
                                        <div className={ViewOnlyCss.ButtonGroup}>
                                        <Link to={`/admin/purchase/${myParams.mpurchase}/${myParams.poid}/poinvoice`} target='_blank' rel="noopener noreferrer"><button onClick={onPrintInvoice}>Print Invoice</button></Link>
                                            {/* <button onClick={onPrintInvoice}>Print Invoice</button>  */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default ViewOnly

const MyTable = ({ item, editVariance, price, qty, tammount}) => {

    return (
        <>
            <tr>
                <td className={ViewOnlyCss.foreditVariance}>{item} {editVariance}</td>
                <td >{price}</td>
                <td>{qty}</td>
                <td>{tammount}</td>
            </tr>
        </>
    )
}