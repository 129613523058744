import React, { useEffect, useRef, useState } from 'react'
import EditItemCss from './css/edititem.module.css'
import { UpdateProductList } from '../../../../api/ProductListApi'
import { toast } from 'react-toastify'

const ItemPriceEdit = ({ item, setItem, setstate }) => {
    const myRef = useRef()
    // console.log(itemId)
    function onDataChance(e) {
        let name = e.target.name
        let value = e.target.value.trim()
        setItem({ ...item, [name]: value })
    }

   async function SaveItem(e) {
        e.preventDefault()
        const form = e.target
        const Selling_price = form.Selling_price.value
        const tax = form.tax.value
        const Promo_price = form.Promo_price.value
        const Start_date = form.Start_date.value
        const End_date = form.End_date.value
        let data = {
            Selling_price, tax, Promo_price, Start_date, End_date
        }
        try {
            await UpdateProductList(item.id, data)
            toast.success('Information Updated')
        } catch (error) {
           toast.error(error.message) 
        }
    }
    return (
        <>
        <div className={EditItemCss.extra_wrapper}>
            <h4>Product Price</h4>
        </div>
        <section className={EditItemCss.main}>
            <form onSubmit={SaveItem}>
            <div className={EditItemCss.input_wrapper}>
                <div className={EditItemCss.common}>
                    <label>Selling Price*</label>
                    <input name="Selling_price" type='text' placeholder='Selling Price' required defaultValue={item.Selling_price}/>
                </div>
                <div className={EditItemCss.common}>
                    <label>TAX</label>
                    <input name="tax" type='text' placeholder='Tax' defaultValue={item.tax}/>
                </div>
                <div className={EditItemCss.common}>
                    <label>Promo Price</label>
                    <input name="Promo_price" type='text' placeholder='Promo Price' defaultValue={item.Promo_price}/>
                </div>
                <div className={EditItemCss.common}>
                    <label>Start date</label>
                    <input name="Start_date" type='date' defaultValue={item.Start_date}/>
                </div>
                <div className={EditItemCss.common}>
                    <label>End date</label>
                    <input name="End_date" type='date'  defaultValue={item.End_date}/>
                </div>
            </div>
            <div className={EditItemCss.btn_group}>
                <button>Update Price</button>
            </div>
            </form>
        </section>
    </>
    )
}

export default ItemPriceEdit