import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import { fetchAllVariance, selectAllVariance, selectVarianceStatus } from '../../ReduxFiles/Slices/VarianceSlice'
import { SaveSummeryData } from '../../api/OrderInformationApi'
import { GetDataToReturn, GetSingleOrderItems, SaveSalesReturnData, UpdateOrderData } from '../../api/SalesApi'
import { InecreaseProductStock, InecreaseVarianceStock } from '../../api/StockApi'
import SalesReturnCss from './salesCss/SalesReturn.module.css'

const SalesReturn = () => {
    const userInfo = useSelector(selectUserInfo)
    const myNavigate = useNavigate()
    const [mainPo, setMainPo] = useState()
    const [searchData, setSearchData] = useState()
    const [alertMessage, setAlertMessage] = useState(false)
    const [tableData, setTableData] = useState()
    const [returnQuantity, setReturnQuantity] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSearchData({ ...searchData, [name]: value })
    }
    function returnQuantityChange(e, preQuantity,) {
        let name = e.target.name
        let value = e.target.value
        if (value > preQuantity) {
            value = preQuantity
            setReturnQuantity({ ...returnQuantity, [name]: parseInt(value) })
        } else {
            setReturnQuantity({ ...returnQuantity, [name]: parseInt(value) })
        }

    }
    function onSearchClick() {
        if (searchData?.search) {
            searchInvoice(searchData?.search)
        } else {
            setAlertMessage('Please Enert Invoice Number to Return')
        }
    }
    function onEnterPress(e) {
        if (e.key === 'Enter') {
            if (searchData?.search) {
                // myfoucsRef.current.focus()
                searchInvoice(searchData?.search)
            } else {
                alert('Please Enert Invoice Number to Return')

            }
        }

    }
    async function searchInvoice(value) {
        // let query = `SELECT * FROM sales WHERE id=${value} AND (packaging = 1 OR deliveryBoyAssigned = 1 OR deliveryComplete = 1)`
        let returnInvoice = await GetDataToReturn({invoice:value})
        //  console.log(returnInvoice)
        if (returnInvoice.length > 0) {
            if (returnInvoice[0].fullReturn === 1) {
                setAlertMessage('Your Invoice is Fully Returned, Please Check Return History')
            } else {
                setMainPo(returnInvoice[0])
                getNestedData(returnInvoice[0])
            }
        } else {
            setAlertMessage('Your Invoice Number not Found')
        }
        // setReturnQuantity()
    }
    async function getNestedData(mainData) {
        let allProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        let allItem = await GetSingleOrderItems({id:mainData.id} )
        let myArr = []
        allItem?.forEach(ielement => {
            for (let index = 0; index < allProducts.length; index++) {
                const element = allProducts[index];
                if (ielement.product_id === element.id) {
                      ielement = { 
                        ...ielement,
                        name: element.en_sort_title
                    }
                    myArr.push(ielement)
                    break
                }
            }
        });
        setTableData(myArr)
    }

    async function onSubmitClick() {
        let finalArr = []
        let returnArr = []
        tableData?.forEach((item, index) => {
            let myreturnQuantity = (returnQuantity?.['returnQuantity' + index] ? returnQuantity?.['returnQuantity' + index] : 0)
            if (myreturnQuantity > 0) {
                let myreturnItem = { ...item, rQuantity: myreturnQuantity }
                delete myreturnItem.mQuantity
                returnArr.push(myreturnItem)
            }

            item = {
                ...item,
                mQuantity: item.mQuantity - myreturnQuantity
            }
            finalArr.push(item)
        })
        let mainDataID = mainPo.id
        await UpdateOrderData(mainDataID, { partialReturn: 1, status: 'Order Partial Return' })
        // await UpdateItemExpress('sales', { partialReturn: 1, status: 'Order Partial Return' }, mainDataID)
         updateReturnArray(returnArr, mainDataID)
         updateSockAmmount(returnArr)
    }
    async function updateReturnArray(returnArr, mainID) {

        returnArr?.map(async function (item) {
            let returnSingleData = {
                sales_id: mainID,
                product_id: item.product_id,
                hasVriance: item.hasVriance ? item.hasVriance : 0,
                variance_id: item.variance_id,
                price: item.price,
                sale_item_id: item.sales_id,
                quantity: item.rQuantity,
                created_date: new Date().getTime()
            }
            //  console.log(returnSingleData)
          //  await SaveDataExpress('sales_return_items', { ...returnSingleData })
          await SaveSalesReturnData(returnSingleData)
        })

        let summeryData = {
            sale_id: mainID,
            ProssesedBy: userInfo.id,
            activity: 'Order Partial Return',
            created_date: new Date().getTime(),
        }
        await SaveSummeryData(summeryData)
      //  await SaveDataExpress('sales_summery', { ...summeryData })
    }
    function updateSockAmmount(myArray) {
        myArray?.map(async (item) => {
            if (item.hasVriance) {
                await InecreaseVarianceStock({variance_id:item.variance_id, stock_quantity:item.quantity})
               // await IncreaseValue('product_variance_stock', 'stock_quantity', item.rQuantity, 'variance_id', item.variance_id)
            }
            else {
                await InecreaseProductStock({product_id:item.product_id, stock_quantity:item.quantity})
               // await IncreaseValue('product_stock', 'stock_quantity', item.rQuantity, 'product_id', item.product_id)
            }
        })
        myNavigate('/admin/sales/sales_return_history')
    }

    async function onWholePoCancel() {
        let mainID = mainPo.id
        tableData.map(async function (item) {
            let returnSingleData = {
                sales_id: mainID,
                product_id: item.product_id,
                hasVriance: item.hasVriance ? item.hasVriance : 0,
                variance_id: item.variance_id,
                price: item.price,
                sale_item_id: item.sales_id,
                quantity: item.quantity,
                created_date: new Date().getTime()
            }
            await SaveSalesReturnData(returnSingleData)
           // await SaveDataExpress('sales_return_items', { ...returnSingleData })
            if (item.hasVriance) {
                await InecreaseVarianceStock({variance_id:item.variance_id, stock_quantity:item.quantity})
              //  await IncreaseValue('product_variance_stock', 'stock_quantity', item.quantity, 'variance_id', item.variance_id)
            }
            else {
                await InecreaseProductStock({product_id:item.product_id, stock_quantity:item.quantity})
               // await IncreaseValue('product_stock', 'stock_quantity', item.quantity, 'product_id', item.product_id)
            }
        })
        let summeryData = {
            sale_id: mainID,
            ProssesedBy:  userInfo.id,
            activity: 'Order Full Return',
            created_date: new Date().getTime(),
        }
        await SaveSummeryData(summeryData)
        await UpdateOrderData(mainID, { fullReturn: 1, status: 'Order Full Return', packaging: 0, deliveryComplete: 0 })
       // await SaveDataExpress('sales_summery', { ...summeryData })
      //  await UpdateItemExpress('sales', { fullReturn: 1, status: 'Order Full Return', packaging: 0, deliveryComplete: 0 }, mainID)
        myNavigate('/admin/sales/sales_return_history')
    }
    function checkClick(params) {

    }
    function getReturnAmmount() {
        let total = 0
        tableData?.forEach((item, index) => {
            total += item.price * (returnQuantity?.['returnQuantity' + index] ? returnQuantity?.['returnQuantity' + index] : 0)
        })

        return total
    }
    const myAllVariance = useSelector(selectAllVariance)
    const varinaceStatus = useSelector(selectVarianceStatus)
    const dispatch = useDispatch()
    useEffect(() => {
        // console.log(varinaceStatus);
         if (varinaceStatus === 'idle') {
            dispatch(fetchAllVariance())
         }
    }, [])


    return (
        <>
            {alertMessage ? <AlertMessage message={alertMessage} setAlertMessage={setAlertMessage} /> : ''}
            {/* {spinerShow ? <Spinner text={"Loading..."} /> : ""} */}
            <div className={SalesReturnCss.search} style={{ marginTop: '30px' }}>
                <input name='search' className={SalesReturnCss.searchbox} type="search" placeholder="Search Invoice ID Here..." autoFocus={true} onKeyDown={onEnterPress}
                    onChange={onDataChange} value={searchData?.search}  autoComplete='false'/>
                <input className={SalesReturnCss.buttonsubmit} type="submit" value='search ' onClick={onSearchClick} />

                {/* <input ref={myfoucsRef} className="searchbox dummy" type="search" placeholder="dummy input" /> */}
            </div>

            <section className={SalesReturnCss.SalesReturnSection}>
                <div className={SalesReturnCss.return_item}>
                    <div className={SalesReturnCss.detailstableSection}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Item Name</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Return Quantity</th>
                                    <th>Total</th>
                                    <th>Return Ammount </th>
                                </tr>
                                {tableData?.map((item, index) => {
                                    return <tr key={index}>
                                        <td>{item.name}
                                            {item.hasVriance ? <> - {
                                                myAllVariance?.filter((fitem) => {
                                                    return fitem.id === item.variance_id ? fitem : ''
                                                })
                                                    .map((finalItem) => {

                                                        return <span key={item.variance_id}>{finalItem.Variance_name}</span>
                                                        //item.variance_id
                                                    })
                                            }
                                            </> : ''}
                                        </td>
                                        <td>{item.price}</td>
                                        <td>{item.quantity}</td>
                                        <td>
                                            <input style={{ maxWidth: '50px' }} name={'returnQuantity' + index} type='number' placeholder='enter quantity' onChange={(e) => { returnQuantityChange(e, item.quantity, item.price) }}
                                                value={returnQuantity?.['returnQuantity' + index] ? returnQuantity?.['returnQuantity' + index] : '0'}
                                                onFocus={(e) => { e.target.select() }} />
                                        </td>

                                        <td>{item.quantity * item.price}</td>
                                        <td>{(returnQuantity?.['returnQuantity' + index] ? returnQuantity?.['returnQuantity' + index] : 0) * item.price}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div className={SalesReturnCss.return_input}>
                            <div className={SalesReturnCss.common}>
                                <label>Return Product Ammount*</label>
                                <input type='text' placeholder='returnig ammount' value={getReturnAmmount()} disabled />
                            </div>
                        </div>


                    </div>
                    <div className={SalesReturnCss.info_section}>
                        <p className={SalesReturnCss.highlight} onClick={checkClick}>Invoice Details</p>
                        <div className={SalesReturnCss.common}>
                            <p>Invocie No : </p>
                            <p>#{mainPo?.id}</p>
                        </div>
                        <div className={SalesReturnCss.common}>
                            <p>Invoice Ammount : </p>
                            <p>৳ {mainPo?.grandTotal} </p>
                        </div>
                        <div className={SalesReturnCss.common}>
                            <p>Now Returning : </p>
                            <p>{getReturnAmmount()}</p>
                        </div>
                        <div className={SalesReturnCss.common}>
                            <p>Discount : </p>
                            <p>{mainPo?.discountAmmount}</p>
                        </div>
                    </div>

                </div>
                <div className={SalesReturnCss.button_section}>
                    {mainPo ? <>
                        <button onClick={onWholePoCancel}>Return Whole Order</button>
                    <button onClick={onSubmitClick}>Submit</button>
                    </>:<button>Select an order to return</button>}
                    
                </div>
            </section>

        </>
    )
}

export default SalesReturn

const AlertMessage = ({ message, setAlertMessage }) => {

    function alertClick(e) {
        // console.log(e)
        setAlertMessage()

    }
    const zindex = {
        zIndex: 20
    }
    return (
        <div style={zindex} className={SalesReturnCss.AlertMessage}>
            <h2 >{message}</h2>
            <button onClick={alertClick} autoFocus={true}>OK</button>
        </div>
    )
}