import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import { fetchInitCustomer, fetchSearchedCustomer, selectCustomerStatus, selectInitCustomer } from '../../../ReduxFiles/Slices/CustomerSlice';
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import CustomerLedgerPDF from '../SalesReportPDFnew/CustomerLedgerPDF';
import CustomerLedgerCss from './css/CustomerLedger.module.css'

const CustomerLedger = () => {


 return (
    <>
        <Routes>
            <Route path='/' element={<MainComp/>}/>
            <Route path='/view_customer_ledger' element={<CustomerLedgerPDF/>}/>
        </Routes>
    </>
  )
}

export default CustomerLedger

const MainComp = () => {
    const dispatch = useDispatch()
    const CustomerList = useSelector(selectInitCustomer)
    const customerStatus = useSelector(selectCustomerStatus)
    const [userData, setUserData] = useState()

    function onCustomerChange(data) {
        setUserData(data)
    }

    const onInputDataChange = (inputValue, { action }) => {
        if (action === 'input-change') {
            if (inputValue.length >= 3) {
                dispatch(fetchSearchedCustomer(inputValue))
            }
        }
    };

      useEffect(() => {
        if (customerStatus === 'idle') {
           dispatch(fetchInitCustomer())
        }
    }, [customerStatus])
  
    function onCustomerClick() {
   // console.log(userData)
    if (userData) {
        window.open('/admin/report/customer_ledger/view_customer_ledger?phone='+userData.phone, '_blank')
    }else{
        alert('Select user to continue')
    }
}
// function onAllLedgerClick() {
//     window.open('/admin/report/customer_ledger/view_customer_ledger?', '_blank')
// }

 return (
    <>
    <section style={{ marginTop: '10px' }} className={CustomerLedgerCss.salesMain}>
        <div className={CustomerLedgerCss.innerSale}>
            <h2 className={CustomerLedgerCss.saleHeading}>Customer Ledger</h2>
        </div>
        <div className={`${CustomerLedgerCss.common_class} ${CustomerLedgerCss.complain_category}`} style={{ margin: '0 20px' }}>
            <div className={CustomerLedgerCss.input_section2}>
            <Select options={CustomerList} getOptionLabel={(option)=> `${option.name} - ${option.phone}`} getOptionValue={(option)=>option.id} onChange={onCustomerChange} onInputChange={onInputDataChange}/>
                {userData ? <div className={CustomerLedgerCss.input_wrapper} style={{backgroundColor:'bisque', flexDirection:'column', justifyContent:'left', alignItems:'flex-start', padding:'10px'}}>
                    <p>Customer Name : {userData?.name}</p> 
                    <p>Customer Phone : {userData?.phone}</p>
                    <p>Customer Email : {userData?.email}</p>
                </div>:''}
                <div className={CustomerLedgerCss.customer_btn}>
                <button   onClick={onCustomerClick}>See Customer Ledger</button>
                {/* <button  style={{float:'none', background:'green'}} onClick={onAllLedgerClick}>See All Ledger</button> */}
                </div>
            </div>
        </div>
    </section>
</>
  )
}