import React, { useEffect, useState } from 'react'
import { GetAllDataExpress, SendMessage, SendSMSFromRobi } from '../../api/ExpressFunctions'
import { fetchInitCustomer, fetchSearchedCustomer, selectCustomerStatus, selectInitCustomer } from '../../ReduxFiles/Slices/CustomerSlice'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { InformationMessage } from '../../api/SalesApi'
import crmCss from './crm.module.css'
const CrmMain = () => {
  // const [allCustomer, setAllCustomer] = useState()
  const [addedNumber, setAddedNumber] = useState([])
  const [inputedNumber, setInputedNumber] = useState({})
  const [messageText, setMessageText] = useState()
  const [characterCount, setCharacterCount] = useState(0)
  const [messageCount, setMessageCount] = useState(0)
  const dispatch = useDispatch()
  const customerStatus = useSelector(selectCustomerStatus)
  const allCustomer = useSelector(selectInitCustomer)



useEffect(() => {
      if (customerStatus === 'idle') {
          dispatch(fetchInitCustomer(10))
      }
  }, [])

  async function getAllCustomer() {
  //  console.log(1)
    let mAllCustomer = [] //await GetAllDataExpress('user')
    // setAllCustomer(mAllCustomer)
    // console.log(mAllCustomer)
  }
  function onAddClick(item) {
    setAddedNumber([item,...addedNumber])
  }
  function onRemoveClick(item, mindex) {
    let tempArr = []
   // console.log(mindex)
    addedNumber.forEach((element, index) => {
      if (mindex!==index) {
        tempArr.push(element)
      }
    });
    setAddedNumber(tempArr)
  }
  function addToList() {
    if (inputedNumber) {
      setAddedNumber([inputedNumber,...addedNumber])
      setInputedNumber()
    }else{
      alert('Write a number to add')
    }
   
  }
  function onSendClick() {
    // console.log(messageText) %0a 
    if (messageText) {
      let stringArr = messageText.split('\n')
      let finalText = ''
      stringArr.forEach(element => {
        finalText += element + '%0a'
      });
      // console.log(finalText)
      if (addedNumber.length > 0) {
        // await SendSMSFromRobi(element.phone, finalText)
        addedNumber.forEach(async (element) => {
          try {
            await InformationMessage({ phone: `88${element.phone}`, message: finalText })
          } catch (error) {

          }
        });
        setAddedNumber([])
        setMessageText()
      }else{
        alert('Please add at list one number to send SMS')
      }
    } else {
      alert('You cannot send blank SMS')
    }

  }
  function wordCount() {
    //per sms 160 character
    let count = 0
    for (let index = 0; index < messageText?.length; index++) {
      // const element = array[index];
      count++
    }
   if (count>0) {
    let smsNumber = Math.floor(count/161)
    setMessageCount(smsNumber+1)
   }else{
    setMessageCount(0)
   }
    setCharacterCount(count)
   
  }
  useEffect(()=>{
    wordCount()
  },[messageText])

  useEffect(() => {
    getAllCustomer()
   
  }, [])

  function onCustomerChange(data) {
   // console.log(data.phone);
     setInputedNumber(data)
    // setSearchDate({ ...searchDate, customer_id: data.id })
   }
   const onInputDataChange = (inputValue, { action }) => {
       if (action === 'input-change') {
          if (inputValue.length >=3) {
               dispatch(fetchSearchedCustomer(inputValue))
          }

          setInputedNumber({phone:inputValue})
       }
     };
  return (
    <div className={crmCss.crm_section}>
      {/* <h2>CRM is Coming Soon</h2> */}
      <div className={crmCss.left_side}>
        <div className={crmCss.add_number}>
          {/* <input type='text' placeholder='8801' onChange={(e)=>{setInputedNumber(e.target.value)}} value={inputedNumber ? inputedNumber:''}/> */}
          <Select options={allCustomer} getOptionLabel={(option)=> `${option.name} - ${option.phone}`} getOptionValue={(option)=>option.id} onChange={onCustomerChange} onInputChange={onInputDataChange}/>
          <button onClick={addToList}>Add to List</button>
        </div>
        <h3>Contact List</h3>
        <div>
          <div className={crmCss.tableSection}>
            <table>
              <thead>
              <tr>
                <th>SL</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Remove</th>
              </tr>
              </thead>
              <tbody>
                {addedNumber?.map((item, index) => {
                  return <tr key={index+10000}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td><button onClick={()=>{onRemoveClick(item, index)}}>X</button></td>
                  </tr>
                })}

              </tbody>

            </table>
          </div>
        </div>
      </div>
      <div className={crmCss.right_side}>
        <div className={crmCss.messagebox}>
          <textarea rows="8" cols="45" placeholder='write your message here' onChange={(e)=>{setMessageText(e.target.value)}} value={messageText ? messageText:''}></textarea>
          <div className={crmCss.action_wrapper}>
            <div className={crmCss.count_section}>
            <p>Character</p>
            <p>{characterCount}</p>
            <p>Message</p>
            <p>{messageCount}</p>
            </div>
              <button onClick={onSendClick}>send</button>
          </div>
        </div>
        {/* <h3>Customer List</h3>
        <div className='contact_list'>
          <div className='tableSection'>
            <table>
              <thead>
              <tr>
                <th>SL</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Add</th>
              </tr>
              </thead>
              <tbody>
                {allCustomer?.map((item, index) => {
                  return <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td><button onClick={()=>{onAddClick(item)}}>add</button></td>
                  </tr>
                })}

              </tbody>

            </table>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default CrmMain