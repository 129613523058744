import { faBars, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../smallThings/Spinner'
import SalesSearchComp from './SalesSearchComp'
import { GetSingleCustomer, GetSingleCustomerOrderAddress } from '../../api/CustomerApi'
import { GetReturnSalesData, UpdateOrderData } from '../../api/SalesApi'
import { selectBackUserList, selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import { useSelector } from 'react-redux'
import {SaveSummeryData } from '../../api/OrderInformationApi'
import { API_LINK } from '../../api/APILink'
import FemaleUser from '../../assets/dummyuserfemal.png'
import SalesReturnHisCss from './salesCss/SalesReturnHistory.module.css'
import ActivityViewModel from './models/ActivityViewModel'
const SalesReturnHistory = () => {

    const backendUser = useSelector(selectBackUserList)
    const [tableData, setTableData] = useState()
    const [userData, setUserData] = useState()
    const [showUser, setShowUser] = useState(false)
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [activityView, setActivityView] = useState(false)

    async function customerClick(userid, address_id) {
        setSpinnerShow(true)
        let userData = await GetSingleCustomer(userid)
        let myData = address_id ? await GetSingleCustomerOrderAddress(address_id) : []
        let address = myData.length > 0 ? myData[0].Address + ", " + myData[0].Area + ", " + myData[0].District + ", " + myData[0].Divition : ''
        setUserData({ ...userData[0], address: address })
        setSpinnerShow(false)
        setShowUser(true)
    }

    function oncloseClick() {
        document.body.style.overflow = 'auto'
        setShowUser(false)

    }
    function convertDate(time) {
        const date = new Date(time)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }

    async function tableDataFilter() {
        setSpinnerShow(true)
        let returnData = await GetReturnSalesData({ limit: 10 })
        let tempArr = []
        returnData.forEach(element => {
            element = {
                ...element,
                discount: element.total_orprice * element.discountPercent / 100
            }
            tempArr.push(element)
        });
        setTableData(tempArr)
        setSpinnerShow(false)
    }

    useEffect(() => {
        tableDataFilter()
    }, [])
    

    async function activityViewClick(e, item) {
        setActivityView(item)
        document.body.style.overflow = 'hidden'

    }

    const statusSwitch = (value, item) => {
        let data = <></>
        switch (value) {
            case 'Order Partial Return':
                data = <div className={SalesReturnHisCss.myStatus}>
                    <p className={'preturn'}>{value}
                    </p>
                </div>
                break;
            case 'Order Full Return':
                data = <p>{value}</p>
                break;
            default:
                data = <p>{value}</p>
                break;
        }

        return data
    }
    const [chargeItem, setChargeItem] = useState()
    function AddReturnChargeClick(item) {
        setChargeItem(item)
    }

    const TableItem = tableData?.map((item) => {
        return <MyTable key={item.id} id={<Link to={`/admin/sales/sales_return_history/${item.id}`}>{'#' + item.id}</Link>} date={convertDate(item.created_date)} cname={item.name} cphone={item.aphone || item.phone}
            caddress={item.address}
            tammount={item.rprice - item.discount}
            returnCharge={item.return_charge}
            returnCost={<div className={SalesReturnHisCss.myStatus}>
                <p className={SalesReturnHisCss.fullreturn}>{'Order Return'}</p>
                <button onClick={() => { AddReturnChargeClick(item) }}>Add Return Charge</button>
            </div>}
            status={statusSwitch(item.status, item)}
            confirmedby={<div className={SalesReturnHisCss.salesHistoruImagesec}>
                {backendUser?.filter((fitem) => {
                    return item.CDby === fitem.id ? fitem : ''
                })
                    .map((item) => {
                        return <>
                            <img src={API_LINK + 'userimages/' + item.userImageName} alt={item.userImageName} />
                            {item.userName}
                        </>
                    })}

            </div>}
            action={
                <div className={SalesReturnHisCss.actionButton}>
                    <Link to={`/admin/sales/sales_return_history/${item.id}`}><FontAwesomeIcon icon={faCircleInfo} /></Link>
                    <p onClick={(e) => { activityViewClick(e, item) }}><FontAwesomeIcon className={SalesReturnHisCss.myBars} icon={faBars} /></p>
                </div>} customerClick={() => { customerClick(item.userID, item.user_address_id); document.body.style.overflow = 'hidden' }} />
    })
    return (
        <>
            {activityView ? <ActivityViewModel popUp={activityView} setPopUp={setActivityView} /> : ""}
            {chargeItem ? <ReturnChargeAdd chargeItem={chargeItem} setChargeItem={setChargeItem} /> : ''}
            {showUser ? <UserInfo userData={userData} oncloseClick={oncloseClick} /> : ""}
            <section className={SalesReturnHisCss.salesMain}>
                {spinnerShow ? <Spinner /> : ""}
                <div className={SalesReturnHisCss.innerSale}>
                    <h2 className={SalesReturnHisCss.saleHeading}>Sales Return History</h2>
                    <SalesSearchComp passedFrom={'rhistory'} setFilteredData={setTableData} />
                    <div className={SalesReturnHisCss.tableSection}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>id</th>
                                    <th>Date</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Customer Address</th>
                                    <th>Total Ammount</th>
                                    <th>Return Cost</th>
                                    <th>Status</th>
                                    <th>Confirmed By</th>
                                    <th>Action</th>
                                </tr>
                                {TableItem}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SalesReturnHistory


const UserInfo = ({ userData, oncloseClick }) => {
    return (
        <div className={SalesReturnHisCss.UerInfo_wrapper}>
            <div className={SalesReturnHisCss.UerInfo}>
                <div className={SalesReturnHisCss.profile}>
                    {userData.userImageName ? <img src={API_LINK + 'userimages/' + userData.userImageName} alt={userData.userImageName} /> : <img src={FemaleUser} alt='dummy place holder'/>}
                </div>
                <h3>Customer Information :</h3>
                <div>
                    <p><strong>Name : </strong>{userData.name}</p>
                    <p><strong>Phone : </strong>{userData.phone}</p>
                    <p><strong>Email : </strong>{userData.email}</p>
                    <p><strong>Address : </strong>{userData.address}</p>
                </div>
                <button onClick={oncloseClick}>Close</button>
            </div>
        </div>
    )
}

const MyTable = ({ id, date, cname, cphone, caddress, tammount, returnCost, status, confirmedby, action, customerClick, returnCharge }) => {
    const ScrollHidden = () => {
        document.body.style.overflow = 'hidden'
    }
    return (

        <tr key={id + 'tab'} className={SalesReturnHisCss.salesReturnHisTable_tr}>
            <td className={SalesReturnHisCss.invoiceClick}>{id}</td>
            <td>{date}</td>
            <td className={SalesReturnHisCss.customerClick} onClick={customerClick}>{cname}</td>
            <td>{cphone}</td>
            <td>{caddress}</td>
            <td>{tammount} <br /> RC :  {returnCharge}</td>
            <td onClick={ScrollHidden}>{returnCost}</td>
            <td >{status}</td>
            <td>{confirmedby}</td>
            <td>{action}</td>
        </tr>

    )
}


const ReturnChargeAdd = ({ chargeItem, setChargeItem }) => {
    const userInfo = useSelector(selectUserInfo)
    const [return_charge, setReturnCharge] = useState(chargeItem?.return_charge)
    async function onUpdateClick() {
        let nestedData = {
            activity: 'Return Charge Added',
            ProssesedBy: userInfo.id,
            sale_id: chargeItem.id,
            created_date: new Date().getTime()
        }


        await UpdateOrderData(chargeItem.id, { return_charge: return_charge })
        await SaveSummeryData(nestedData)
        setChargeItem()
    }



    return (
        <div className={SalesReturnHisCss.deliverydone_wrapper}>
            <div className={SalesReturnHisCss.deliverydone}>
                <h3>Add Return Charge</h3>
                <div className={SalesReturnHisCss.common}>
                    <input type='number' placeholder='0' name='return_charge' onChange={(e) => { setReturnCharge(e.target.value) }} value={return_charge} />
                    <div className={SalesReturnHisCss.deliverydone_btns}>
                        <button onClick={onUpdateClick}>Update</button>
                        <button onClick={() => { setChargeItem(); document.body.style.overflow = 'auto' }}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}