import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetEndDate, GetStartDate, RearrangeDate } from '../../MyFunctions/MyFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import { SalesProductWiseReportDynamic, SalesReportData } from '../../../api/ReportApi/SalesReportApi'
import SalesReportViewProductWiseNewCss from './css/SalesReportViewProductWiseNew.module.css'

const SalesReportViewProductWiseNew = () => {
    const [invoiceState, setInvoiceState] = useState([])
    let [searchParams] = useSearchParams();

    let minusAmount = 0
    let month =  new Date(searchParams.get('start_date')).getMonth()
    let year =  new Date(searchParams.get('start_date')).getFullYear()
    if (month === 1 && year === 2024) {
      minusAmount = 842
    }

    async function getData() {

        let type = searchParams.get('type')
        let queryText = ''
        if (type === 'sales.packaging') {
            queryText = 'packaging = 1'
        } else if (type === 'delivery') {
            queryText = '(sales.deliveryComplete = 1 OR sales.fullReturn = 1 OR sales.partialReturn = 1) '
        } else if (type === 'confirm') {
            queryText = 'sales.confirmed = 1 AND sales.packaging = 0'
        } else if (type === 'pending') {
            queryText = 'sales.pending = 1'
        }
        let start_date =  GetStartDate(searchParams.get('start_date'))
        let end_date =GetEndDate(searchParams.get('end_date'))            
        sendRequestForData(start_date, end_date, queryText)
    } 
    const [showSpinner, setShowSpinner] = useState() 

    async function sendRequestForData(start_date, end_date, queryText) {
        setShowSpinner(true)
        let data = await SalesProductWiseReportDynamic({starttime:start_date, endtime:end_date, query:queryText})
        let all_id = ''
        let totalQty = 0
        let totalMRP = 0
        let totalTP = 0
        data?.forEach(element => {
            all_id += element.sales_id + ', '
            totalQty += parseInt(element.total_qty)
            totalMRP += parseInt(element.mrp)*parseInt(element.total_qty)
            totalTP += parseInt(element.tp)*parseInt(element.total_qty)
        });

        all_id = all_id.slice(0, -2)
        setInvoiceState(data, 1)
        GetSalesData(start_date, end_date, totalQty, totalMRP, totalTP)
        // console.log(totalMRP,totalTP);
        
      }

    async function GetSalesData(start_date, end_date, totalQty, totalMRP, totalTP) {
        setShowSpinner(true)
        let data = await SalesReportData({ starttime: start_date, endtime: end_date })
        let tempArr = []
        data.forEach(item => {
        
            let receiveAmount = item.bank+item.cash
            let returnPrice = item.fullReturn ? 0 : item.rprice
            let discount = item.fullReturn ? 0 : (item.discountPercent * item.total_orprice / 100) + (item.total_orprice - item.total_price)
            let tba = item.fullReturn ? 0 : item.total_orprice - ((item.discountPercent * item.total_orprice / 100) + (item.total_orprice - item.total_price)) + item.deliveryCharge - returnPrice
            let netSale = tba - (item.codSSLCharge + item.deliveryCost + item.return_charge)
            let due = (tba - receiveAmount)
            item = {
              ...item, discount, tba, netSale, due,
              total_orprice: item.fullReturn ? 0 : item.total_orprice - returnPrice,
              receiveAmount: receiveAmount || 0,
              deliveryCharge: item.fullReturn ? 0 : item.deliveryCharge
            }
            tempArr.push(item)
          });
        SetAllTotal(tempArr, totalQty, totalMRP, totalTP)
    }
      const [AllTotal, setAllTotal] = useState({})

      function SetAllTotal(filteredData, totalQty, totalMRP, totalTP) {
        let grandTotal = 0;
    let discount = 0;
    let totalBill = 0;
    let codSSLCharge = 0;
    let deliveryCharge = 0;
    let deliveryCost = 0;
    let return_charge = 0;
    let netSale = 0;
    let collectedAmmount = 0;
    let due = 0;
    let bankTotal = 0;
    let cashTotal = 0;
    let bankcost = 0
    let cashcost = 0
    filteredData?.forEach(element => {
        bankcost += element.bank > 0 ? (element.deliveryCost+element.return_charge+element.codSSLCharge):0
        cashcost += element.cash > 0  && element.bank < 1 ? (element.deliveryCost+element.return_charge+element.codSSLCharge):0
        grandTotal += element.fullReturn ? 0 : element.total_orprice
        discount += element.discount
        totalBill += element.tba 
        codSSLCharge += element.codSSLCharge
        deliveryCharge += element.deliveryCharge
        deliveryCost += element.deliveryCost
        return_charge += element.return_charge
        netSale += element.netSale
        collectedAmmount += element.fullReturn ? 0 : element.receiveAmount
        due += element.due
        bankTotal += element.bank
        cashTotal += element.cash
      });
      bankTotal = bankTotal-bankcost-return_charge
      cashTotal = cashTotal-cashcost-minusAmount
          setAllTotal({
              grandTotal, discount, totalBill, codSSLCharge, deliveryCharge, deliveryCost,
 return_charge, netSale, collectedAmmount, due, bankTotal, cashTotal, totalQty, totalTP, totalMRP })
      }

    
    function onInvoiceNoClick(e, invoiceNo) {
        window.open(`/admin/report/sales_report_productwise/sales_report_productwise_view?individual_report=${invoiceNo}`, "_blank");

    }

    useEffect(() => {
        getData()
    }, [])
    
    function salesIDsplit(sales_id) {
        let finalText = ''
        if (sales_id) {
            let ids = sales_id.split(',')
            ids = ids.sort()
            let tempArr = []
            ids.forEach(element => {
                if (!tempArr.includes(element)) {
                    tempArr.push(element)
                }
            });
            // let unique = [...new Set(ids)];
            tempArr.forEach(element => {
                    finalText += `${element}, `
            });
            
        }
        return finalText
    }

    // console.log(invoiceState[33]?.sales_id);
    // console.log(1);
    function getArray(sales_id) {
        if (sales_id) {
            let ids = sales_id.split(',')
            ids = ids.sort()
            console.log(ids);
        }
    }

    useEffect(()=>{
        getArray(invoiceState[33]?.sales_id)
    },[invoiceState])

    return (
        <>
            <HeaderFooterWrapper content={
                <> <h3 >Product Wise Sales Report f</h3> 
                    <div className={SalesReportViewProductWiseNewCss.details}>
                        <div className={SalesReportViewProductWiseNewCss.client}>
                            <div className={SalesReportViewProductWiseNewCss.info}>
                                <span className={SalesReportViewProductWiseNewCss.moreinfo} >Stages : </span> Customer Order <strong>{searchParams.get('type')}</strong>
                            </div>
                        </div>
                        <div className={SalesReportViewProductWiseNewCss.invoice}>
                            <div className={SalesReportViewProductWiseNewCss.info}>
                                {<span className={SalesReportViewProductWiseNewCss.moreinfo}>Date : {RearrangeDate(searchParams.get('start_date'))}  To {RearrangeDate(searchParams.get('end_date'))}</span>}
                            </div>
                        </div>
                    </div>
                    <div className={`${SalesReportViewProductWiseNewCss.table} ${SalesReportViewProductWiseNewCss.sales_table} ${SalesReportViewProductWiseNewCss.fixed_table}`}>
                        <table>
                            <thead >
                                <tr className={SalesReportViewProductWiseNewCss.fixed_table_header}>
                                    <th >Sl</th>
                                    <th >Invoice No.</th>
                                    <th >Product Title</th>
                                    <th >T.P</th>
                                    <th >M.R.P</th>
                                    <th >Quantity</th>
                                    <th >T.P Amount</th>
                                    <th >M.R.P Amount</th>
                                </tr>
                            </thead>
                            <tbody className={SalesReportViewProductWiseNewCss.product_wise}>
                                {invoiceState?.map((item, index) => {
                                    return <>
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td style={{width:'140px'}}>{salesIDsplit(item.sales_id)}</td>

                                            <td style={{ textAlign: 'left!important' }}>{item.en_sort_title}</td>
                                            <td>{item.tp}</td>
                                            <td >{item.mrp}</td>
                                            <td >{item.total_qty}</td>
                                            <td>{item.tp*parseInt(item.total_qty)}</td>
                                            <td>{item.mrp*parseInt(item.total_qty)}</td>
                                            {/* <td>{getDiscountTotal(item)}</td> */}
                                        </tr>
                                    </> 
                                })}

                                <tr className={`${SalesReportViewProductWiseNewCss.final_row} ${SalesReportViewProductWiseNewCss.last_row_product}`}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'5'}>Total :</td>
                                    <td >{AllTotal.totalQty}</td>
                                    <td >{AllTotal.totalTP?.toFixed(2)}</td>
                                    <td >{AllTotal.totalMRP?.toFixed(2)}</td>
                                    {/* <td >{getAlltotal(invoiceState, 'discount')}</td> */}
                                </tr>
                                {/* bottom part */}
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>Discount :
                                        {/* ({othersInfo?.dicountInoviceNo}) : */}
                                    </td>
                                    <td >{AllTotal?.discount?.toFixed(2)}</td>
                                </tr>
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>After Discount :</td>
                                    <td >{((AllTotal?.totalMRP-AllTotal?.discount)?.toFixed(2))}</td>
                                </tr>
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>Bank Received :</td>
                                    <td >{AllTotal?.bankTotal?.toFixed(2)}</td>
                                </tr>
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>Delivery Charge Collect :</td>
                                    <td >{AllTotal?.deliveryCharge?.toFixed(2)}</td>
                                </tr>
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>Delivery Charge Expense :</td>
                                    <td >{(AllTotal?.deliveryCost + AllTotal?.return_charge)?.toFixed(2)}</td>
                                </tr>
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>Delivery Charge Difference :</td>
                                    <td >{(AllTotal?.deliveryCharge - AllTotal?.deliveryCost - AllTotal?.return_charge)?.toFixed(2)}</td>
                                </tr>
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>COD Charge :</td>
                                    <td >{AllTotal?.codSSLCharge?.toFixed(2)}</td>
                                </tr>
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>Cash in Hand :</td>
                                    <td >{(AllTotal?.cashTotal)?.toFixed(2)}</td>
                                </tr>
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>Total TP Sale :</td>
                                    <td >{AllTotal.totalTP?.toFixed(2)}</td>
                                </tr>
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>Total Amount Collection :	</td>
                                    <td >{(AllTotal.bankTotal + AllTotal.cashTotal)?.toFixed(2)}</td>
                                </tr>
                                <tr className={SalesReportViewProductWiseNewCss.final_row}>
                                    <td className={SalesReportViewProductWiseNewCss.no_border_right} colSpan={'7'}>Invoice Discount :</td>
                                    <td >{((AllTotal.bankTotal+AllTotal.cashTotal)-(AllTotal.grandTotal - AllTotal.grandTotal * 20 / 100)).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            } />
        </>
    )
}

export default SalesReportViewProductWiseNew