import React, { useEffect, useState } from 'react'
import { GetDivisionList } from '../../../api/Address/DivisionApi';
import { GetDistrictList } from '../../../api/Address/DistrictApi';
import { GetAreaList, SaveAreaData } from '../../../api/Address/AreaApi';
import {toast} from 'react-toastify'
import AddAreaCss from './css/AddArea.module.css'
const AddArea = () => {

    const [DivitionArray, setDivitionArray] = useState([]);
    const [allDistrict, setAllDistrict] = useState([]);
    const [allArea, setAllArea] = useState([]);
    const [districtData, setDistrictData] = useState();
    const [areaData, setAreaData] = useState()
    const [data, setdata] = useState(
        {
            Divition: '',
            District: '',
            Area: ''
        });
    function onDivisionChange(e) {
        const DivitionValue = e.target.value;
        const DivitionName = e.target.name;
        setdata({ ...data, [DivitionName]: DivitionValue, District: '', Area: '' });
        let arr = []
        allDistrict.forEach(element => {
            if (element.division_id === parseInt(e.target.value)) {
                arr.push(element)
            }
        });
        setDistrictData(arr)
    }

    function onDisctrictChange(e) {
        const DistrictValue = e.target.value;
        const DistrictName = e.target.name;
        setdata({ ...data, [DistrictName]: DistrictValue, Area: '' })
        let arr = []
        allArea?.forEach(element => {
            if (element.district_id === parseInt(e.target.value)) {
                arr.push(element)
            }

        })
        setAreaData(arr)
    }
    function OnAreaChange(e) {
        const AreaValue = e.target.value;
        const AreaName = e.target.name;
        setdata({ ...data, [AreaName]: AreaValue })
    }



  async  function onAddData() {
        if (data.District && data.Area) {
            let finalData = {
                district_id:data.District,
                area_name:data.Area
            }
            let matchFound = false
            areaData.forEach(element => {
                if (element.area_name.toLowerCase() ===data.Area.toLowerCase() ) {
                    matchFound = true
                }
            });
            if (matchFound) {
                toast.warn(`${data.Area} already exist`)
            }else{
                await SaveAreaData(finalData)
                setdata({
                    Divition: '',
                    District: '',
                    Area: '' 
                })
                toast.success('Area Added Successfully')
            }            
        }else{
            toast.error('Please Select District & Fill Area Data')
        }
    }
    
    async function getPrimaryAddress() {
        let division = await GetDivisionList()
        let sortArrDiv = division.sort((a, b) => (a.division_name > b.division_name) ? 1 : -1)
        setDivitionArray(sortArrDiv)
        let district = await GetDistrictList()
        let sortArrDis = district.sort((a, b) => (a.district_name > b.district_name) ? 1 : -1)
        setAllDistrict(sortArrDis)
        let area = await GetAreaList()
        let sortArrArea = area.sort((a, b) => (a.area_name > b.area_name) ? 1 : -1)
        setAllArea(sortArrArea) 
      }
      
      useEffect(()=>{
        getPrimaryAddress()
      },[])
   function onItemClick(e, item) {
    // console.log(item);
    setdata({ ...data, Area: item.area_name })
   }

    return (
        <>
            <div className={AddAreaCss.add_new_area} >
                <div className={AddAreaCss.input_group}>
                    <label>Division</label>
                    <select className={AddAreaCss.popOption} name={'Divition'} onChange={onDivisionChange} defaultValue={''}>
                        <option value={""} selected={data.Divition===''?true:''}>--Select Division--</option>
                        {DivitionArray?.map((item)=>{
                           return <option key={item.division_id} value={item.division_id}>{item.division_name}</option>
                        })}
                    </select>
                </div>
                <div className={AddAreaCss.input_group}>
                    <label>District</label>
                    <select className={AddAreaCss.popOption} name={'District'} onChange={onDisctrictChange} disabled={data.Divition ? false:true} >
                        <option value={""}>--Select District--</option>
                        {districtData?.map((item)=>{
                           return <option key={item.district_id} value={item.district_id}>{item.district_name}</option>
                        })}
                    </select>
                </div>

                <div style={{position: 'relative'}} className={AddAreaCss.input_group}>
                    <label>Area</label>
                    <input type='text' name='Area' placeholder='add new area' disabled={data.District ? false:true} value={data.Area} onChange={OnAreaChange}/>
                    <div className={`${AddAreaCss.search_item_section} ${AddAreaCss.dropdown_list} ${AddAreaCss.area_search}`}>
                {areaData?.filter((fitem) => {
                    let msearchText = data.Area?.toLowerCase()
                    let matchText =  fitem.area_name?.toLowerCase()

                    if (msearchText && matchText !== msearchText && matchText?.includes(msearchText)) {
                        return fitem
                    } else {
                        return null
                    }
                    // return conditionMatch &&  matchText.includes(msearchText) || matchTextnumber.includes(msearchText)
                }).slice(0, 10)
                    .map((item, index) => {
                        return <p key={index} onClick={(e)=>{onItemClick(e, item)}} >{item.area_name}</p>
                    })}
            </div>
                </div>
                <div className={AddAreaCss.input_group} onClick={onAddData}>
                    <button className={AddAreaCss.save_button}><strong >Save New Address</strong></button>
                </div>

            </div>

        </>
    )
}

export default AddArea

