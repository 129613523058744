import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetAllDataExpress, GetDataPassQuery, GetDoubleQueryExpress, GetFilteredDataExpress } from '../../../api/ExpressFunctions'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import Headerimage from '../../../assets/Headerimage.jpg'
import { GetSearchedCustomer } from '../../../api/CustomerApi'
import { GetCustomerLadgerData } from '../../../api/ReportApi/SalesReportApi'
import CustomerLedgerPDFCss from './css/CustomerLedgerPDF.module.css'

const CustomerLedgerPDF = ({heading}) => {
    const [invoiceState, setInvoiceState] = useState()
    const [customerInfo, setCustomerInfo] = useState()
    let [searchParams] = useSearchParams();
   
    async function getData() { 
        let phone = searchParams.get('phone') 
        if (phone) {
            let customer = await GetSearchedCustomer({search_text:phone})
             let myInvoiceData = await GetCustomerLadgerData({customer_id:customer[0].id})
            setCustomerInfo(customer[0])
   
            let tempArr = []
            myInvoiceData.forEach(item => {
                let melement = {
                    created_date:item.created_date,
                    id:item.id,
                    status:item.declined ? 'Declined Order': item.status,
                    saleAmount:item.declined ? 0: parseInt(item.total_orprice)+parseInt(item.deliveryCharge),
                    returnAmount:item.declined ? 0: item.rprice,
                    discount: item.declined ? 0: (item.total_orprice-item.total_price)+(item.total_orprice*item.discountPercent/100),
                    received: item.declined ? 0: item.receiveAmount || 0,
                    payment:item.declined ? 0: item.paymentAmmount || 0,
                    balance: item.declined ? 0: (item.total_orprice+item.paymentAmmount+item.deliveryCharge)-(item.receiveAmount+discount(item)+item.rprice),
                    note:item.approval_note
                }
                tempArr.push(melement)  
                });


             setInvoiceState(tempArr)
        }
       
    }
   
    useEffect(()=>{
       getData()
    },[])

    function getTotalAmmount(arrayData, identifire) {
        let total = 0
        arrayData?.forEach(element => {
            total += element[identifire] ? parseInt(element[identifire]):0 
        });
        return total
    }
    function PrintPaper() {
        window.print()
    }
   function discount(item) {
    return (item.total_orprice-item.total_price)+(item.total_orprice*item.discountPercent/100)
   }
  return (
    <>
            <div className={CustomerLedgerPDFCss.invoicemain}>
                <button className={CustomerLedgerPDFCss.printBtn} onClick={PrintPaper}>Print</button>

                <section className={`${CustomerLedgerPDFCss.invoiceSection} ${CustomerLedgerPDFCss.supplier_invoice}`}>
                    <div className={CustomerLedgerPDFCss.container} id="printInvoice" >
                        <div className={CustomerLedgerPDFCss.topSection}>
                            <img src={Headerimage} alt="" />
                        </div>
                        <div className={CustomerLedgerPDFCss.main}>
                <h3>{heading}</h3>
                <div className={CustomerLedgerPDFCss.details}>

                    <div className={CustomerLedgerPDFCss.client}>
                        <div className={CustomerLedgerPDFCss.info}>
                            <span className={CustomerLedgerPDFCss.moreinfo}>Customar Name : </span>{customerInfo?.name}
                        </div>
                        <div className={CustomerLedgerPDFCss.info}>
                            <span className={CustomerLedgerPDFCss.moreinfo}>Phone No : </span>{customerInfo?.phone}
                        </div>

                      
                    </div>
                    <div className={CustomerLedgerPDFCss.invoice}>
                        <div className={CustomerLedgerPDFCss.info}>
                            <span className={CustomerLedgerPDFCss.moreinfo}>Date : </span> All Time
                        </div>
                        <div className={CustomerLedgerPDFCss.info}>
                            <span className={CustomerLedgerPDFCss.moreinfo}>Sold By : </span>Anayase.com
                        </div>
                    </div>
                </div>
                <div className={`${CustomerLedgerPDFCss.table} ${CustomerLedgerPDFCss.fixed_table}`}>
                    <table>
                        <thead>
                            <tr className={CustomerLedgerPDFCss.fixed_table_header}>
                                <th >Sl No.</th>
                                <th style={{paddingLeft:'10px'}}>Date</th>
                                <th >Invoice No</th>
                                <th >Status</th>
                                <th >Sales Ammount</th>
                                <th >Return Ammount</th>
                                <th >Discount</th>
                                <th >Received Ammount</th>
                                <th >Payment</th>
                                <th >Balance</th>
                                <th >Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceState?.map((item, index)=>{
                                return <tr key={index}>
                                    <td style={{textAlign:'center'}}>{index+1}</td>
                                    <td style={{textAlign:'left'}}>{LocalTimeStampToDate(item.created_date)}</td>
                                    <td style={{textAlign:'center'}}><strong>#{item.id}</strong></td>
                                    <td style={{textAlign:'left'}}>{item.status}</td>
                                    <td style={{textAlign:'right'}}>{item.saleAmount}</td>
                                    {/* total_orprice total_price */}
                                    <td style={{textAlign:'right'}}>{item.returnAmount}</td>
                                    <td style={{textAlign:'right'}}>{item.discount.toFixed(2)}</td>
                                    <td style={{textAlign:'right'}}>{parseInt(item.received) || 0}</td>
                                    <td style={{textAlign:'right'}}>{item.payment}</td>
                                    <td style={{textAlign:'right'}}>{item.balance.toFixed(2)}</td>
                                    <td style={{textAlign:'left'}}>{item.note || 'no note'}</td>
                                </tr>
                            })}
                            <tr >
                                <td colSpan={'4'}>Total :</td>
                                <td>{getTotalAmmount(invoiceState, 'saleAmount')}</td>
                                <td>{getTotalAmmount(invoiceState, 'returnAmount')}</td>
                                <td>{getTotalAmmount(invoiceState, 'discount').toFixed(2)}</td>
                                <td>{getTotalAmmount(invoiceState, 'received')}</td>
                                <td>{getTotalAmmount(invoiceState, 'payment')}</td>
                                <td>{getTotalAmmount(invoiceState, 'balance').toFixed(2)}</td>
                                <td></td>
                            </tr>
                            <tr >
                                <td colSpan={'9'}><strong>Total Due :</strong></td>
                                <td>
                                    <strong>{getTotalAmmount(invoiceState, 'saleAmount') +getTotalAmmount(invoiceState, 'payment') -(getTotalAmmount(invoiceState, 'discount')+getTotalAmmount(invoiceState, 'received')+getTotalAmmount(invoiceState, 'returnAmount'))}</strong>
                                </td>
                                <td></td>
                                
                            </tr>
                        </tbody>
                       
                    </table>
                </div>
               
                <div className={CustomerLedgerPDFCss.seal_signiture} style={{marginTop:'70px'}}>
                    <div className={`${CustomerLedgerPDFCss.singniture} ${CustomerLedgerPDFCss.singniture1}`}>

                        <span>Customer Signature</span>
                    </div>
                    <div className={`${CustomerLedgerPDFCss.singniture} ${CustomerLedgerPDFCss.singniture2}`}>
                        <span>Authority Signature</span>
                    </div>
                </div>
                <div className={`${CustomerLedgerPDFCss.seal_signiture} ${CustomerLedgerPDFCss.seal_signiture1}`}>
                    <span className={CustomerLedgerPDFCss.thankyou_msg}>Thank you for being with Anayase.com</span>
                </div>
    
                </div>
                        
                    </div>
                </section>
            </div>            
    </>
  )
}

export default CustomerLedgerPDF
