import React, { useEffect, useState } from 'react'
import { GetAllDataExpress, GetFilteredDataExpress } from '../../api/ExpressFunctions'
import Headerimage from '../../assets/Headerimage.jpg'
import { GenarateText } from '../connector/InWord'
import POInvoiceReturnCss from './css/POInvoiceReturn.module.css'

const POInvoiceReturn = () => {
    const [invoiceState, setInvoiceState] = useState()
    function PrintPaper() {
       window.print()
       //console.log(invoiceState)
    }
    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        myDate = myDate.replaceAll('/', '-')
        return myDate
    }
    function convertDateTime(time) {
        const dateInMillis = time 
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myTime
    }
    const [supplierData, setSupplierData] = useState()
    const [allVariance, setAllVariance] = useState()
    const [mVarData, setMvarData] = useState()
    async function getData() {
        let mallVariance = JSON.parse(window.localStorage.getItem('allVariance'))
        setAllVariance(mallVariance)

        let data =  JSON.parse(window.localStorage.getItem('PoInvoiceData'))
       

        let mySupplier = await GetAllDataExpress('supplier')
        let varData = []
        let myPromises =   data.purchaseItems.map(async function (item) {
                let mitems =  await GetFilteredDataExpress('purchase_return_variance', 'purchase_item_id', item.id)
                varData.push(...mitems)
            })
        // let getVarItems = await GetFilteredDataExpress()
        
        Promise.all(myPromises).then(()=>{
            setInvoiceState(data)
            setSupplierData(mySupplier)
            setMvarData(varData)
        })
    }
    useEffect(()=>{
        getData()
    },[])
  //  varitem.variance_id
    //sl, description, quantity, uniteprice, total
    const tableItem = invoiceState?.purchaseItems?.map((item, index)=>{
        return <InvoiceTable sl={index+1} description={item.name}

        varInfo={item.hasVriance ? <>{mVarData?.map((varitem)=>{return <p>
            {allVariance?.filter((fitem)=>{
                return fitem.id === varitem.variance_id ? fitem:''
            })
            .map((vitem)=>{
                return vitem.Variance_name
            })}
            { ` - (return : ${varitem.quantity ? varitem.quantity:varitem.quantity})`}
        </p>})}</>:<p>(return : {item.returnQuantity ? item.returnQuantity:item.quantity})</p>}
        
        quantity={<>{item.quantity}</>}
        uniteprice={item.price}
        total={item.quantity ? item.quantity*parseInt(item.price)+' TK':''}/>
    })
    function getItemTotal(mtabelData){
        let total = 0
        mtabelData?.map((item)=>{
            total += item.quantity * item.price
        })

        return total
    }
  return (
    <>
        <div className={POInvoiceReturnCss.invoicemain} id='pdf_body'>
        <button className={POInvoiceReturnCss.printBtn} onClick={PrintPaper}>Print</button>

        <section className={POInvoiceReturnCss.invoiceSection}>

        <div className={POInvoiceReturnCss.container} id="printInvoice">

            <div className={POInvoiceReturnCss.topSection}>
                <img src={Headerimage} alt=""/>
            </div>

            <div className={POInvoiceReturnCss.main}>
                <h3 style={{backgroundColor:'red', color:'white'}}>PO Return Invoice {`${invoiceState?.fullReturn ? "(Full Return)":''}`}</h3>
                <div className={`${POInvoiceReturnCss.info} ${POInvoiceReturnCss.po_info}`}>
                 <span className={POInvoiceReturnCss.moreinfo}>Invoice Number : </span> #{invoiceState?.id}
                </div>
                          <div className={POInvoiceReturnCss.details}>

                              <div className={POInvoiceReturnCss.client}>
                                  {supplierData?.filter((fitem) => {
                                      return fitem.id === invoiceState?.supplierID ? fitem : ''
                                  }).map((item) => {
                                      return <span>
                                          <div className={POInvoiceReturnCss.info}>
                                              <span className={POInvoiceReturnCss.moreinfo}>Customar Name : </span>{item?.companyaName}
                                          </div>
                                          <div className={POInvoiceReturnCss.info}>
                                              <span className={POInvoiceReturnCss.moreinfo}>Phone No : </span>{item?.phone}
                                          </div>
                                          <div className={POInvoiceReturnCss.info}>
                                              <span className={POInvoiceReturnCss.moreinfo}>Address : </span> {item?.address + ", " + item?.Area + ", " + item?.District + ", " + item?.Divition + ","}
                                          </div>
                                      </span>
                                  })}
                              </div>
                    <div className={POInvoiceReturnCss.invoice}>
                        <div className={POInvoiceReturnCss.info}>
                            <span className={POInvoiceReturnCss.moreinfo}>Date : </span>{convertDate(invoiceState?.created_date)}
                        </div>
                        <div className={POInvoiceReturnCss.info}>
                            <span className={POInvoiceReturnCss.moreinfo}>Time : </span> {convertDateTime(invoiceState?.created_date)}
                        </div>
                        <div className={POInvoiceReturnCss.info}>
                            <span className={POInvoiceReturnCss.moreinfo}>Purchased By : </span>Anayase.com
                        </div>
                    </div>
                </div>
                <div className={POInvoiceReturnCss.table}>
                    <table>
                        <thead>
                            <tr>
                                <th className={POInvoiceReturnCss.no}>SI No.</th>
                                <th style={{paddingLeft:'10px'}}>Item Name</th>
                                <th className={POInvoiceReturnCss.unit}>Return Quantity</th>
                                <th className={POInvoiceReturnCss.unit_price}>Unit Price</th>
                                <th className={POInvoiceReturnCss.total}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                           {/* here table item goes */}
                           {tableItem}
                        </tbody>
                        <tbody className={POInvoiceReturnCss.tfoot}>
                            <tr>
                                <td colSpan="3"></td>
                                <td>Sub Total</td>
                                <td>{getItemTotal(invoiceState?.purchaseItems)} TK</td>
                            </tr>
                            <tr>
                                <td colSpan="3"></td>
                                <td style={{width:'120px'}}>Discount (- {invoiceState?.percent ? invoiceState?.percent.toFixed(2) +'%' :''})</td>
                                <td>{invoiceState?.percent ? Math.round((getItemTotal(invoiceState?.purchaseItems) *invoiceState?.percent/100)):0} TK </td> 

                            </tr>
                            {/* <tr>
                                <td colSpan="3"></td>
                                <td>Grand Total</td>
                                <td>{invoiceState?.grandTotal} TK</td>
                            </tr> */}
                            <tr>
                                <td colSpan="5">
                                    <span></span>
                                </td>
                            </tr>
                            
                            <tr className={POInvoiceReturnCss.gran_total}>
                                <td colSpan="3"></td>
                                <td className={POInvoiceReturnCss.gran_total_text}>Grand Total</td>
                                <td>{Math.round(getItemTotal(invoiceState?.purchaseItems)-(invoiceState?.percent ? (getItemTotal(invoiceState?.purchaseItems) *invoiceState?.percent/100):0))} TK</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
                <div className={POInvoiceReturnCss.notice1} style={{marginTop:'-70px'}}>
                    <div className={POInvoiceReturnCss.notice1_1}>
                        <p>
                            <span className={POInvoiceReturnCss.inword}>In Word : </span>
                            {GenarateText(getItemTotal(invoiceState?.purchaseItems)-(getItemTotal(invoiceState?.purchaseItems) *invoiceState?.percent/100))}
                        </p>
                    </div>
                </div>
                {/* <div className={POInvoiceReturnCss.notices}>
                    <div>
                        <b className={POInvoiceReturnCss.notetext}>NOTE : </b>
                    </div>
                    <div className={POInvoiceReturnCss.notice}>{invoiceState?.special_instruction}</div>

                </div>
                <img src={QRcode} alt=""/> */}
                <div className={POInvoiceReturnCss.seal_signiture} style={{marginTop:'120px'}}>
                    <div className={`${POInvoiceReturnCss.singniture} ${POInvoiceReturnCss.singniture1}`}>

                        <span>Customer Signature</span>
                    </div>
                    <div className={`${POInvoiceReturnCss.singniture} ${POInvoiceReturnCss.singniture2}`}>
                        <span>Authority Signature</span>
                    </div>
                </div>
                <div className={`${POInvoiceReturnCss.seal_signiture} ${POInvoiceReturnCss.seal_signiture1}`}>
                    <span className={POInvoiceReturnCss.thankyou_msg}>Thank you for being with Anayase.com.</span>
                </div>
            </div>
        </div>

    </section>

        </div>
    
    </>
  )
}

export default POInvoiceReturn

const InvoiceTable = ({sl, description, quantity, uniteprice, total, varInfo}) => {

    return (
        <>
            <tr>
                <td className={POInvoiceReturnCss.no}>{sl}</td>
                <td className={POInvoiceReturnCss.desc}><strong>{description}</strong> {varInfo}</td>
                <td className={POInvoiceReturnCss.unit}>{quantity}</td>
                <td className={POInvoiceReturnCss.unit_price}>{uniteprice}</td>
                <td className={POInvoiceReturnCss.total}>{total}</td>
            </tr>
        </>
    )
}