import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetAllDataExpress, GetDataPassQuery, SaveDataExpress } from '../../api/ExpressFunctions'
import { GetInputFormatedDate } from '../MyFunctions/MyFunctions'
import AddNewExpenseCss from './css/AddNewExpense.module.css'
const AddNewExpense = () => {
    const myNavigate= useNavigate()
    const [tableData, setTableData] = useState([])
    const [headData, setHeadData] = useState()
    const [errMessage, setErrMessage] = useState()
    const [expenseData, setExpenseData] = useState()
    function onDataChange(e) {
       
        let name = e.target.name
        let value = e.target.value
        if (name === 'price' || name==='paymentAmmount') {
            value = parseFloat(value)
        }

        if (name==='expenseHeadID') {
           // console.log(value)
            headData?.map((item)=>{
                if (item.id===parseInt(value)) {
                 setExpenseData({ ...expenseData, [name]: value, selectedGroup:item.selectedGroup})
                }
            })
        }else{
            setExpenseData({ ...expenseData, [name]: value })
        }
        

    }
    async function onAddClick() {
        let particularErr = ValidateFunction('particular', 'Particular ')
        let priceErr = ValidateFunction('price', 'Price ')
        let noteErr = ValidateFunction('note', 'Note ')
        let expenseHeadIDErr = ValidateFunction('expenseHeadID', 'Particular ')
        setErrMessage({ particularErr, priceErr, noteErr, expenseHeadIDErr })
        if (!particularErr && !priceErr && !noteErr && !expenseHeadIDErr) {
            let finalData = { ...expenseData }
            //console.log(finalData)
            // let expenseID = await SaveData('Expense', finalData)
            for (let index = 0; index < headData.length; index++) {
                const element = headData[index];
               
                if (element.id === parseInt(finalData.expenseHeadID)) {
                  //  console.log(element)
                    finalData = { ...finalData, headName: element.headName, expenseID: '' }
                }

            }
            setTableData([...tableData, finalData])
        }

    }
    function ValidateFunction(field, message) {
        let text = ""
        if (!expenseData?.[field]) {
            text = message + " is Required"
        }
        return text;
    }

    function onRemoveClick(index) {
        let newArr = []
        tableData?.filter((fitem, myIndex) => {
            return myIndex !== index ? newArr.push(fitem) : ''
        })
        setTableData(newArr)

    }
    function getTableTotal(field) {

        let total = 0
        tableData?.map((item) => {
            total += item[field] ? item[field]:0
        })
        return total
    }
    let [paymentInfo, setPaymentInfo] = useState({
        paymentAmmount:0,
        paymentDate:GetInputFormatedDate(),
        paymentType:'cash',
        expenseNote:''
    })
    function onPaymentDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name==='paymentAmmount') {
            value = parseFloat(value)
            if (value>getTableTotal()) {
                value=getTableTotal()
            }
        }
        setPaymentInfo({...paymentInfo, [name]:value})
    }
 async   function onSaveCLick() {
        let userID = window.localStorage.getItem('user_id')
        // let paymentErr = ValidateFunctionpayment('paymentAmmount', 'Paid Ammount ')
        let dateErr = ValidateFunctionpayment('paymentDate', 'Payment Date ')
        let paymentTypeErr = ValidateFunctionpayment('paymentType', 'Payment Type ')
        let expenseNoteErr = ValidateFunctionpayment('expenseNote', 'Expense Note  ')
        setErrMessage({...errMessage, dateErr, paymentTypeErr, expenseNoteErr})
        if (tableData.length>0) {
                let gorupArr = []
                let headArr = []
                tableData.map(async(item)=>{
                    if (!gorupArr.includes(item.selectedGroup)) {
                        gorupArr.push(item.selectedGroup)
                    }
                    if (!headArr.includes(item.expenseHeadID)) {
                        headArr.push(item.expenseHeadID)
                    }
                })

            if (!dateErr && !paymentTypeErr && !expenseNoteErr) {
                let expenseData = {
                    ammount: getTableTotal('price'),
                    paymentAmmount:getTableTotal('paymentAmmount'),
                    paymentType:paymentInfo.paymentType,
                    paymentDate:paymentInfo.paymentDate,
                    expenseNote:paymentInfo.expenseNote,
                    processorID:userID,
                    pending:1,
                    confirmed:0,
                    declined:0,
                    created_date:new Date().getTime(),            
                }

                if (getTableTotal('paymentAmmount')>0) {
                    let debitData = {
                        identity: runningExpenseNo,
                        particulars:'Expense',
                        processorID:userID,
                        paymentAmmount:parseFloat(getTableTotal('paymentAmmount')),
                        remark:paymentInfo.expenseNote,
                        paymentType :'cash',
                        created_date:new Date().getTime(),
                    }
                    await SaveDataExpress('debit_voucher', {...debitData})
                }
                
               // console.log(expenseData, tableData)
                let dataID = await SaveDataExpress('expense', {...expenseData})

               let ExpenseSummeryDetals ={
                expensID:runningExpenseNo,
                status:'Expense Created',
                attemptByID:userID,
                note:paymentInfo.expenseNote,
                created_date:new Date().getTime(),
               }
                await SaveDataExpress('expense_summery', {...ExpenseSummeryDetals})
                let dayBookData = {
                    created_date:new Date().getTime(),
                    paticulars:"Expense",
                    identity:dataID,
                    received:0,
                    payment:getTableTotal('paymentAmmount'),
                    processorID:window.localStorage.getItem('user_id'),
                }
                await SaveDataExpress('day_book', {...dayBookData})
            let itemPromises = tableData.map(async function (item) {
            let itemData = {
                    expenseID:dataID,
                    particular:item.particular,
                    note:item.note,
                    expenseHeadID:item.expenseHeadID,
                    selectedGroup:item.selectedGroup,
                    price:item.price,
                    paymentAmmount:item.paymentAmmount,
                    created_date:new Date().getTime(),
                }
                await SaveDataExpress('expense_items', {...itemData})
            })

            Promise.all(itemPromises).then(()=>{
                myNavigate('/admin/expenses/pending_expense')
            })
             //  await SaveNestedExistingData('Expense', dataID, 'ExpenseSummery', ExpenseSummeryDetals)
             //  await AutoIncrementNumberCustom('NumberCollection', 'NumberID', 'ExNo', 1)

             //  myNavigate('/admin/expenses/pending_expense')
            // alert('Data Saved')
            }
        }else{
            alert('expense data needed')
        }
       
    }
    function ValidateFunctionpayment(field, message) {
        let text = ""
        if (!paymentInfo?.[field]) {
            text = message + " is Required"
        }
        return text;
    }
    const [runningExpenseNo, setRunningExpenseNo] = useState()
    async function getDataFromDatabase() {
        let mheadData = []// await GetAllDataExpress('expense_head')
        let expenseCount = `SELECT COUNT(*) FROM expense`
        let myCount =[] // await GetDataPassQuery(expenseCount) 
        setRunningExpenseNo(myCount[0]?.['COUNT(*)']+1)
       // console.log(myCount)
       setHeadData(mheadData)
    }
    useEffect(() => {
        getDataFromDatabase()
    }, [])
    return (
        <section className={`${AddNewExpenseCss.salesMain} ${AddNewExpenseCss.expense}`}>
            <div className={AddNewExpenseCss.innerSale}>
                <h2 className={AddNewExpenseCss.saleHeading}>Add New Expense</h2>
                <h3 style={{margin:'10px 0', padding:'0', color:'#A91079'}}>Expense Number :  {runningExpenseNo}</h3>
                <div className={`${AddNewExpenseCss.input_section} ${AddNewExpenseCss.add_expense}`}>
                    <div className={`${AddNewExpenseCss.input_common} ${AddNewExpenseCss.expenceLanel}`}>
                        <label>Particulars</label>
                        <input name='particular' type={'text'} onChange={onDataChange} />
                        {errMessage ? <p className={AddNewExpenseCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.particularErr}</p> : ''}
                    </div>
                    <div className={`${AddNewExpenseCss.input_common} ${AddNewExpenseCss.expenceLanel}`}>
                        <label>Price</label>
                        <input name='price' type={'number'} onChange={onDataChange} />
                        {errMessage ? <p className={AddNewExpenseCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.priceErr}</p> : ''}
                    </div>
                    <div className={`${AddNewExpenseCss.input_common} ${AddNewExpenseCss.expenceLanel}`}>
                        <label>Paid Amount</label>
                        <input name='paymentAmmount' type={'number'} onChange={onDataChange} />
                        {errMessage ? <p className={AddNewExpenseCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.paaymentAmmountErr}</p> : ''}
                    </div>
                    <div className={`${AddNewExpenseCss.input_common} ${AddNewExpenseCss.expenceLanel}`}>
                        <label>Note</label>
                        <input name='note' type={'text'} onChange={onDataChange} />
                        {errMessage ? <p className={AddNewExpenseCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.noteErr}</p> : ''}
                    </div>
                    <div className={`${AddNewExpenseCss.input_common} ${AddNewExpenseCss.expenceLanel}`}>
                        <label>Head</label>
                        <select name='expenseHeadID' onChange={onDataChange}>
                            <option value={''}>Select Head</option>
                            {headData?.filter((fitem) => {
                                return fitem.status ? fitem : ''
                            })
                                .map((item, index) => {
                                    return <option key={index} value={item.id}>{item.headName}</option>
                                })}

                        </select>
                        {errMessage ? <p className={AddNewExpenseCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.expenseHeadIDErr}</p> : ''}
                    </div>

                    <div className={AddNewExpenseCss.input_common}>
                        <button onClick={onAddClick}>Add New</button>
                    </div>
                </div>
                <div className={AddNewExpenseCss.tableSection} >
                    <table style={{ marginTop: '0' }}>
                        <tbody>
                            <tr>
                                <th>Sl</th>
                                <th>Particulars</th>

                                <th>Note</th>
                                <th>Expense Head</th>
                                <th>Price</th>
                                <th>Paid Amount</th>
                                {/* <th>Status</th> */}
                                <th>Action</th>
                            </tr>
                            {tableData?.map((item, index) => {
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.particular}</td>
                                    <td>{item.note}</td>
                                    <td>{item.headName}</td>
                                    <td style={{ textAlign: 'right' }}>{item.price}</td>
                                    <td style={{ textAlign: 'right' }}>{item.paymentAmmount}</td>
                                    <td><FontAwesomeIcon onClick={() => { onRemoveClick(index) }} icon={faTrashCan} className={AddNewExpenseCss.color_red} /></td>
                                </tr>
                            })}
                            <tr>

                                <td colSpan={4} style={{ textAlign: 'right', fontWeight: 'bold', color: 'black', fontSize:'1rem' }}>Grand Total</td>
                                <td style={{ textAlign: 'right', fontWeight: 'bold', color: 'black', fontSize:'1rem' }}>{getTableTotal('price')}</td>
                                <td  style={{ textAlign: 'right', fontWeight: 'bold', color: 'black', fontSize:'1rem' }}>{getTableTotal('paymentAmmount')}</td>
                                <td ></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={AddNewExpenseCss.save_expense}>
                    <div className={AddNewExpenseCss.input_common}>
                        <label>Payment Date</label>
                        <input name='paymentDate' type={'date'} onChange={onPaymentDataChange} value={paymentInfo?.paymentDate}/>
                    </div>
                    {errMessage ? <p className={AddNewExpenseCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.dateErr}</p> : ''}
                    {/* <div className={AddNewExpenseCss.input_common}>
                        <label>Piad b Ammount</label>
                        <input name='paymentAmmount' type={'number'} onChange={onPaymentDataChange}  value={paymentInfo?.paymentAmmount}/>
                    </div>
                    {errMessage ? <p className={AddNewExpenseCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.paymentErr}</p> : ''} */}
                    <div className={AddNewExpenseCss.input_common}>
                        <label>Payment Type</label>
                        <select name='paymentType' onChange={onPaymentDataChange} value={paymentInfo?.paymentType}>
                            <option value={'cash'}>Cash</option>
                            <option value={'cheque'}>Cheque</option>
                        </select>
                    </div>
                    {errMessage ? <p className={AddNewExpenseCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.paymentTypeErr}</p> : ''}
                    <div className={AddNewExpenseCss.input_common}>
                        <label>Expense Note</label>
                        <input name='expenseNote' type={'text'} onChange={onPaymentDataChange} value={paymentInfo?.expenseNote}/>
                    </div>
                    {errMessage ? <p className={AddNewExpenseCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.expenseNoteErr}</p> : ''}
                    <div className={AddNewExpenseCss.input_common}>
                        <button onClick={onSaveCLick}>Save Data</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddNewExpense
