import { useState } from 'react'
import { SaveGalleryData } from '../../../../../api/ProductListApi'
import GalleryCss from './uploadgallery.module.css'
import { toast } from 'react-toastify'

const UploadGalleryImage = ({popUp, setPopUp}) => {
  const [shwoItemImage, setShowItemImage] = useState([])

   async function onImageUpload(e) {
        e.preventDefault()
        const gallery_images = e.target.gallery_images.files
       try {
        await SaveGalleryData(gallery_images, popUp.id)
        toast.success('Gallery Image Uploaded')
        setPopUp()
       } catch (error) {
        toast.error(error.message)
       }
    }

    function onImageChange(e) {
      const images = e.target.files
      ImageShow(images)
    }

    function ImageShow(imgArr) {
      let arr = []
      if (imgArr) {
        for (let index = 0; index < imgArr.length; index++) {
          const element = imgArr[index]
          let myurl = URL.createObjectURL(element)
          arr.push(myurl)
      }
      setShowItemImage(arr)
      }else{
        setShowItemImage([])
      }
      

  }

  return (
    <div className={GalleryCss.wrapper}>
        <div className={GalleryCss.holder}>
            <h2>Upload Gallery Images Here</h2>
            <div className={GalleryCss.content}>
                  <form onSubmit={onImageUpload}>
                     <div className={GalleryCss.input_holder}>
                     <label>Gallery Images :</label>
                      <input name='gallery_images' type='file' accept='image/webp' multiple={true} onChange={onImageChange} required/>
                     </div>
                      <div className={GalleryCss.image_holder}>
                        {shwoItemImage.map((item, index)=>{
                         return <img src={item} key={index} alt='fake url'/>
                        })}
                      </div>
                      <div className={GalleryCss.btn_group}>
                          <button type='submit'>Upload</button>
                          <button type='button' onClick={()=>setPopUp()}>Close</button>
                      </div>
                  </form>
            </div>
            
        </div>
    </div>
  )
}

export default UploadGalleryImage