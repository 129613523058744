import React, { useEffect, useState } from 'react'
import { GetAllDataExpress, GetFilteredDataExpress } from '../../../api/ExpressFunctions'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import TransictionOutCss from './Css/TransictionOUT.module.css'
const TransictionOUT = () => {
    const [filterData, setFilterData] = useState()
    const [tableData, setTableData] = useState()
    const [bankList, setBankList] = useState()

    async  function getDataFormDatabase() {   
        let myData = await GetFilteredDataExpress('bank_transaction', 'debitCredit', 2)
        let mBankList = await GetAllDataExpress('bank_list')
        setBankList(mBankList)
        // let myData = []//await GetFilteredDataExpress('Bank_Transaction', 'inOut', '==', 2, 'transictionID')
        
        //console.log(myData)
        let sortArr = myData.sort((a, b) => (a.created_date > b.created_date) ? -1 : 1)
        setTableData(sortArr)
    }

    function onDataChange(params) {
        
    }
    function onSearchClick(params) {
        
    }

    useEffect(() => {
        getDataFormDatabase()
    },[])

    function getTotalAmmount(myArray, fieldName) {
        let total = 0
        myArray?.map((item)=>{
            total +=item[fieldName] ? item[fieldName]:0
        })
        return total
    }
  return (
    <>
    <section className={TransictionOutCss.salesMain}>
        <div className={TransictionOutCss.innerSale}>
            <h2 className={TransictionOutCss.saleHeading}>Transaction Out</h2>
            <div className={TransictionOutCss.search_section}>
                      <div className={TransictionOutCss.input_section}>
                          <label>Start Date</label>
                          <input name='startDate' type={'date'} value={filterData?.startDate? filterData?.startDate:''} onChange={onDataChange}/>
                      </div>
                      <div className={TransictionOutCss.input_section}>
                          <label>End Date</label>
                          <input name='endDate' type={'date'} value={filterData?.endDate ? filterData?.endDate:''} onChange={onDataChange}/>
                      </div>
                     
                      <div className={TransictionOutCss.input_section}>
                          <button onClick={onSearchClick}>search</button>
                          <button>reset</button>
                      </div>
                      
                  </div>
            <div className={TransictionOutCss.tableSection}>
                <table>
                    <tbody>
                        <tr>
                            <th>Sl</th>
                            <th>Date</th>
                            <th>Bank Name</th>
                            <th>Particular</th>
                            <th>Payment</th>
                            <th>Process By</th>
                            <th>Action</th>
                        </tr>
                        {tableData?.map((item, index)=>{
                          return <tr key={index}>
                              <td>{index+1}</td>
                              <td>{LocalTimeStampToDate(item.createdDate?.seconds)}</td>
                              <td>{bankList.filter((fitem)=>{
                                return fitem.id===item.bankID ? fitem:''
                              })
                              .map((mitem)=>{
                                return <span key={mitem.id}>{mitem.bankName}</span>
                              })} fdfd</td>
                              <td>{item.particulars} (#{item.identity})</td>
                              <td>{item.paymentAmmount}</td>
                              <td>{item.processorName}</td>
                              <td>action</td>
                          </tr>
                        })}
                        <tr>
                            <td colSpan={4} style={{textAlign:'right'}}>Total : </td>
                            <td>{getTotalAmmount(tableData, 'paymentAmmount')}</td>
                            <td></td>  
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</>
  )
}

export default TransictionOUT