import React from 'react'
import {useParams} from 'react-router-dom'
import PaymentVoucher from './PaymentVoucher'
import Headerimage from '../../../assets/Headerimage.jpg'
import VoucherCommonCss from './css/VoucherCommon.module.css'

const VoucherCommon = () => {
    const myParams = useParams()
function PrintPaper() {
        window.print()
    }
  return (
    <>
    <div className={VoucherCommonCss.invoicemain}>
                <button className={VoucherCommonCss.printBtn} onClick={PrintPaper}>Print</button>

                <section className={`${VoucherCommonCss.invoiceSection} ${VoucherCommonCss.supplier_invoice}`}>
                    <div className={VoucherCommonCss.container} id="printInvoice">
                        <div className={VoucherCommonCss.topSection}>
                            <img src={Headerimage} alt="" />
                        </div>
                        {myParams.function === 'payment-history' && myParams.action==='print-voucher' ? <PaymentVoucher heading={'Payment Voucher'}/>:''}
                    </div>
                </section>
            </div>
    
    </>
  )
}

export default VoucherCommon