import React from 'react'
import { NavLink, Route, Routes, useParams } from 'react-router-dom'
import PendingSales from './PendingSales'
import SaleHistory from './SaleHistory'
import DeclinedOrders from './DeclinedOrders'
import CompleteOrder from './CompleteOrder'
import SalesReturn from './SalesReturn'
import SalesReturnHistory from './SalesReturnHistory'
import OrderDetailsView from './OrderDetailsView'
import SalesDetailView from './SalesDetailView'
import CompleteDetailsView from './CompleteDetailsView'
import DeclineDetailView from './DeclineDetailView'
import ReturnDetailsView from './ReturnDetailsView'
import Invoice from './Invoice'
import InvoiceReturn from './InvoiceReturn'
import SalesEdit from './SalesEdit'
import SalesMainCss from './salesCss/SalesMain.module.css'


const SalesMain = () => {
    const myParams = useParams()
    return (
        <>
            <div className={SalesMainCss.menupart}>
                <ul className={SalesMainCss.menuitems}>
                    <NavLink to={"/admin/sales/pending_sales"}>
                        <li >Pending Sales</li>
                    </NavLink>
                    <NavLink to={"/admin/sales/sale_history"}>
                        <li >Sale History</li>
                    </NavLink>
                    {/* <NavLink to={"/admin/sales/prickup_ready"}>
                        <li >Pickup Ready Orders</li>
                    </NavLink> */}
                    <NavLink to={"/admin/sales/complete_orders"}>
                        <li >Complete Orders</li>
                    </NavLink>
                    <NavLink to={"/admin/sales/decline_orders"}>
                        <li >Decline Orders</li>
                    </NavLink>
                    <NavLink to={"/admin/sales/sales_return"}>
                        <li>Sales Return</li>
                    </NavLink>
                    <NavLink to={"/admin/sales/sales_return_history"}>
                        <li>Sales Return History</li>
                    </NavLink>
                </ul>
            </div>
            <div>
                <Routes>
                    {/* main routes here */}
                    <Route path='/' element={<PendingSales />} />
                    <Route path='/pending_sales' element={<PendingSales />} />
                    <Route path='/sale_history' element={<SaleHistory />} />
                    <Route path='/complete_orders' element={<CompleteOrder />} />
                    <Route path='/decline_orders' element={<DeclinedOrders />} />
                    <Route path='/sales_return' element={<SalesReturn />} />
                    <Route path='/sales_return_history' element={<SalesReturnHistory />} />

                    {/* other routes here */}
                    <Route path='details/:orderid' element={<OrderDetailsView />} />
                    <Route path='salesdetails/:orderid' element={<SalesDetailView />} />
                    <Route path='complete_orders/:orderid' element={<CompleteDetailsView />} />
                    <Route path='declinedetails/:orderid' element={<DeclineDetailView />} />
                    <Route path='sales_return_History/:orderid' element={<ReturnDetailsView />} />
                    <Route path='salesdetails/:orderid/invoice' element={<Invoice />} />
                    <Route path='salesdetails/:orderid/invoice_return' element={<InvoiceReturn />} />
                    <Route path='sale_history/sales_edit/:orderid' element={<SalesEdit />} />
                </Routes>

            </div>
        </>
    )
}

export default SalesMain
