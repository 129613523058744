import Axios from "axios";
import { API_LINK } from "../APILink";

const MY_API_LINK = `${API_LINK}area/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function GetAreaList() {
    try {
        let result = await Axios.get(`${MY_API_LINK}all`)
        return result.data       
    } catch (error) {
        return 0
    }    
}

export async function GetAreabyFilter(filter_text) {
    try {
        let result = await Axios.get(`${MY_API_LINK}filter/${filter_text}`)
        return result.data      
    } catch (error) {
        return 0
    }    
}
export async function SaveAreaData(data) {
    try {
        let result = await Axios.post(`${MY_API_LINK}add`, data, configdata)
        return result.data      
    } catch (error) {
        return 0
    }    
}
