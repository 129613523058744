import ItemCategoryCss from './css/Item_category.module.css'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfoAlert from '../../AllContent/smallThings/InfoAlert'
import { fetchCategory, selectCategoryList } from '../../ReduxFiles/Slices/CategorySlice'
import { fetchAtributesName, selectMAUStatus } from '../../ReduxFiles/Slices/MAU_Slice'
import { API_LINK } from '../../api/APILink'
import { DeleteCategory, GetCategoryMetadata, SaveCategoryMetadata, UpdateCategoryMetadata } from '../../api/CategoryApi'
import ConfirmAlert from '../smallThings/ConfirmAlert'
import AddNewCategory from './AddnewCategoryPop/AddNewCategory'
import UpdateCategoryPop from './AddnewCategoryPop/UpdateCategoryPop'
import Select from 'react-select'

const Item_catagory = () => {
    const allCategory = useSelector(selectCategoryList)
    const mauStatus = useSelector(selectMAUStatus)
    const dispatch = useDispatch()
    const [myOverlay, setMyOverlay] = useState(false);
    const [catEditData, setCatEditData] = useState()

    function addnewPop() {
        document.body.style.overflow = 'hidden'
        setMyOverlay(true)
    }
    function EditClick(item) {
        setCatEditData(item)
    }


    useEffect(() => {
        if (mauStatus.attributesNameStatus === 'idle') {
            dispatch(fetchAtributesName())
        }
    }, [])

    const [myInfoAlert, setMyInfoAlert] = useState({
        alertVisibilty: false,
        alertText: ""
    })
  


    function searchClick() {
        let data = "hello bangladesh how are you"
        console.log(data.replaceAll(" ", "_"))
    }
    const [conAlert, setConAlert] = useState()
    const [deleteItem, setDeleteItem] = useState()
    function DeletClick(item) {
        setConAlert(true)
        setDeleteItem(item)

    }
    async function Clickyes() {
         document.body.style.overflow = 'auto'
        await DeleteCategory(deleteItem.id, { catIcon: deleteItem.catIcon, catImage: deleteItem.catImage, catBanner: deleteItem.catBanner })
        dispatch(fetchCategory())
        setConAlert(false)
    }
    function ClickNo() {
        document.body.style.overflow = 'auto'
        setConAlert(false)
        setDeleteItem()
    }

    const [seoData, setSeoData] = useState()
    function seoClick(item) {
        setSeoData(item)
    }

    function infoOkClick() {
        setMyInfoAlert(
            {
                alertVisibilty: false,
                alertText: ""
            }
        )
    }

    return (
        <>
        {myOverlay && <AddNewCategory setPopup={setMyOverlay}/>}
        {catEditData && <UpdateCategoryPop popUp={catEditData} setPopUp={setCatEditData}/>}
            
            {seoData ? <SeoComp seoData={seoData} setSeoData={setSeoData} /> : ''}
            {conAlert ? <ConfirmAlert yesClick={Clickyes} noClick={ClickNo} /> : ''}
            {myInfoAlert.alertVisibilty ? <InfoAlert okClick={infoOkClick} message={myInfoAlert.alertText} /> : ""}
            <div className={`${ItemCategoryCss.addCatagory} ${ItemCategoryCss.admincomm}`}>
                <h3 className={ItemCategoryCss.itemlisth3}>Catagories</h3>
                <div className={ItemCategoryCss.ltemlistsearch}>
                    <button className={ItemCategoryCss.add_new_button} onClick={addnewPop}>Add New Category</button>

                    <div className={ItemCategoryCss.searh_select}>
                        <Select
                            options={allCategory}
                            getOptionLabel={(option) => `${option.catName}`}
                            getOptionValue={(option) => `${option.id}`}
                            isClearable
                        />
                    </div>
                </div>
                <div className={ItemCategoryCss.itemCategoryTable}>
                    <table>
                        <tbody>
                            <tr>
                                <th>Sl</th>
                                <th>Catagory Name</th>
                                <th>Catagory Description</th>
                                <th>Catagory Slug</th>
                                <th>Parent Catagory</th>
                                <th>Code</th>
                                <th>Action</th>
                            </tr>
                            {allCategory.map((item, index)=>{
                                return <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.catName}</td>
                                    <td>{item.catDescriptioon}</td>
                                    <td>{item.catSlug}</td>
                                    <td>{item.selected_cat}</td>
                                    <td>{item.code}</td>
                                    <td>
                                        <div className={ItemCategoryCss.btn_group}>
                                            <input onClick={() => { EditClick(item) }} type="Submit" defaultValue="Edit" sid={item.id} />
                                            <input className={ItemCategoryCss.seo} onClick={() => { seoClick(item); document.body.style.overflow = 'hidden' }} type="Submit" defaultValue="SEO" sid={item.id} />
                                            <input className={ItemCategoryCss.delete} onClick={() => { DeletClick(item); document.body.style.overflow = 'hidden' }} type="Submit" defaultValue="Delete" sid={item.id} />
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Item_catagory

const SeoComp = ({ seoData, setSeoData }) => {
    const [metaData, setMetadata] = useState({})
    function onInputChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name === 'og_image_raw') {
            setMetadata({ ...metaData, og_image_raw: e.target.files[0], fakeImage: genarateFakeUrl(e.target.files[0]) })
        } else {
            setMetadata({ ...metaData, [name]: value })
        }

    }
    function genarateFakeUrl(image) {
        let myurl = URL.createObjectURL(image)
        return myurl;
    }
    async function onSaveClick() {
        let finalData = {
            ...metaData,
            cat_id: seoData.id
        }
        await SaveCategoryMetadata(finalData, metaData.og_image_raw)
        setSeoData()
        document.body.style.overflow = 'auto'
    }

    function onCancelClick() {
        document.body.style.overflow = 'auto'
        setSeoData()
    }
    async function onUpdateClick() {
        let finalData = {
            ...metaData,
            cat_id: seoData.id
        }
        delete finalData.og_image_raw
        delete finalData.fakeImage
        await UpdateCategoryMetadata(finalData, metaData.og_image_raw, metaData.id)
        setSeoData()
        document.body.style.overflow = 'auto'
    }

    async function getSeoData() {
        let data = await GetCategoryMetadata(seoData.id)
        setMetadata(data?.[0] || {})
    }
    useEffect(() => {
        getSeoData()
    }, [])

    return <div className={ItemCategoryCss.add_seodata_wrapper}>
        <div className={ItemCategoryCss.add_seodata}>
            <h2 className={ItemCategoryCss.category_name}>{seoData.catName}</h2>
            <div className={ItemCategoryCss.input_section}>

                <div className={ItemCategoryCss.inputs}>
                    <label>Meta Keywords</label>
                    <textarea type='text' name='meta_keywords' rows="4" cols="50" maxLength="200" onChange={onInputChange} value={metaData.meta_keywords} />
                </div>

                <div className={ItemCategoryCss.inputs}>
                    <label>Meta Description</label>
                    <textarea type='text' name='meta_description' rows="4" cols="50" maxLength="200" onChange={onInputChange} value={metaData.meta_description} />
                </div>

                <div className={ItemCategoryCss.inputs}>
                    <label>OG Image</label>
                    <input name='og_image_raw' type='file' accept="image/jpeg, image/jpg" multiple={false} onChange={onInputChange} />
                    <img src={metaData.fakeImage} alt='fake data' />
                    {metaData.og_image ? <img src={`${API_LINK}ogimages/${metaData.og_image}`} alt='uploaded data' /> : ''}
                </div>
            </div>

            <div className={ItemCategoryCss.buttons}>
                {metaData.id ? <button onClick={onUpdateClick}>Update</button> : <button onClick={onSaveClick}>Save</button>}
                <button onClick={onCancelClick}>Cancel</button>
            </div>
        </div>
    </div>
}
