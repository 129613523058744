import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../../smallThings/Spinner'
import SalesSearchComp from '../SalesSearchComp'
import { GetInitOfferItem } from '../../../api/OrderInformationApi'
import { GetSingleCustomer } from '../../../api/CustomerApi'
import { API_LINK } from '../../../api/APILink'
import OfferItemsCss from './css/OfferItems.module.css'


const Pending_Sales = () => {
    const [tableData, setTableData] = useState()
    const [userData, setUserData] = useState()
    const [showUser, setShowUser] = useState(false)
    const [spinnerShow, setSpinnerShow] = useState(false)

    async function customerClick(userid) {
        document.body.style.overflow = 'hidden'
        setSpinnerShow(true)
        let userData = await GetSingleCustomer(userid)
        setUserData({ ...userData[0], address: '' })
        setSpinnerShow(false)
        setShowUser(true)
    }

    function oncloseClick() {
        document.body.style.overflow = 'auto'

        setShowUser(false)
    }
    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }

    const marginTop = {
        marginTop: '10px'
    }
    async function getDataFormDatabase() {
        let myData = await GetInitOfferItem({ limit: 20 })
        setTableData(myData)
        // console.table(myData);
    }
    useEffect(() => {
        getDataFormDatabase()
    }, [])

    const TableItem = tableData?.map((item) => {
        // id, salesid, date, cname, cphone, promoPrice, originalPrice, startDate, endDate, view,
        return <MyTable key={item.id} id={<Link to={`/admin/sales/details/${item.sales_id}`}>{'#' + item.id}</Link>}
            salesid={<Link to={`/admin/sales/details/${item.sales_id}`}>{'#' + item.sales_id}</Link>}
            date={convertDate(item.created_date / 1000)}
            cname={item.name}
            cphone={item.phone}
            promoPrice={item.promoPrice}
            originalPrice={item.original_price}
            startDate={item.Start_date}
            endDate={item.End_date}
            view={
                <div className={OfferItemsCss.actionButton}>
                    <Link to={`/admin/sales/details/${item.sales_id}`}><FontAwesomeIcon icon={faCircleInfo} /></Link>
                    {/* <Link to={'/'+item.itemid}><FontAwesomeIcon className='myBars' icon={faBars}/></Link> */}
                </div>} customerClick={(e) => { customerClick(item.userID) }} />
    })

    return (
        <>
            {showUser ? <UserInfo name={userData?.name} phone={userData?.phone} email={userData?.email} address={userData?.address} image={userData.userImageName} oncloseClick={oncloseClick} /> : ""}
            <section style={marginTop} className={OfferItemsCss.salesMain}>
                {spinnerShow ? <Spinner /> : ""}

                <div className={OfferItemsCss.innerSale}>
                    <h2 className={OfferItemsCss.saleHeading}>Offer Sales Items</h2>
                    <SalesSearchComp myPassedQuery='SELECT * FROM sales_offer_items WHERE  canceled = 0' setFilteredData={setTableData} limitNumber={10} />
                    <div className={OfferItemsCss.tableSection}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>ID</th>
                                    <th>Sales ID</th>
                                    <th>Date</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Promo Price</th>
                                    <th>Original Price</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>View</th>
                                </tr>
                                {TableItem}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pending_Sales


const UserInfo = ({ name, phone, email, address, image, oncloseClick }) => {

    return (
        <div className={OfferItemsCss.UerInfo_wrapper}>
            <div className={OfferItemsCss.UerInfo}>
                <div className={OfferItemsCss.profile}>
                    {image ? <img src={API_LINK + 'userimages/' + image} alt={image}/> : <img src='https://www.mothermary.rw/images/img/lady.jpg' alt='lady placeholder' />}
                </div>
                <h3>Customer Information :</h3>
                <div>
                    <p><strong>Name : </strong>{name}</p>
                    <p><strong>Phone : </strong>{phone}</p>
                    <p><strong>Email : </strong>{email}</p>
                    <p><strong>Address : </strong>{address}</p>
                </div>
                <div className={OfferItemsCss.close_btns}>
                    <button onClick={oncloseClick}>Close</button>
                </div>
            </div>
        </div>
    )
}

const MyTable = ({ id, salesid, date, cname, cphone, promoPrice, originalPrice, startDate, endDate, view, customerClick }) => {

    return (
        <tr key={id}>
            <td className={OfferItemsCss.invoiceClick}>{id}</td>
            <td className={OfferItemsCss.invoiceClick}>{salesid}</td>
            <td>{date}</td>
            <td className={OfferItemsCss.customerClick} onClick={customerClick}>{cname}</td>
            <td>{cphone}</td>
            <td>{promoPrice}</td>
            <td>{originalPrice}</td>
            <td>{startDate}</td>
            <td>{endDate}</td>
            <td>{view}</td>
        </tr>
    )
}
