import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { GetDetailsOrderItems, GetSingleOrder, UpdateOrderData } from '../../api/SalesApi'
import { GetSingleCustomer, GetSingleCustomerOrderAddress } from '../../api/CustomerApi'
import { SaveSummeryData } from '../../api/OrderInformationApi'
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import DecDetView from './salesCss/DeclineDetailView.module.css'

const DeclineDetailView = () => {
    const userIfno = useSelector(selectUserInfo)
    const myParams = useParams()
    const [tableData, setTableData] = useState()
    const [orderData, setOrderData] = useState()
    const [conShow, setConShow] = useState(false)
    const [showPop, setShowPop] = useState(false)


   
   async function onRestoreClick() {
    setConShow(true)
    //console.log('clicked')
    
 }
    async function clickYes() {
        let nestedData = {
            activity: 'Declined Order Restore',
            sale_id: myParams.orderid,
            ProssesedBy:userIfno.id,
            created_date: new Date().getTime()
        }
        await SaveSummeryData(nestedData)
        await UpdateOrderData(myParams.orderid, {declined:0, pending:1})
        setConShow(false)
    }

    function clickNo() {
       setConShow(false)
    }
    
    function convertDate(time) {
        const dateInMillis = time 
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getAllData() {
        let myData = await GetSingleOrder(myParams.orderid)
        let myCustomer = await GetSingleCustomer(myData[0].userID)
        let userAddress = await GetSingleCustomerOrderAddress(myData[0].user_address_id)
        let getSSLdata = []
        let finalData = {
            ...myData[0],
            order_created: myData[0].created_date,
            ...userAddress[0],
            ...myCustomer[0],
            ...getSSLdata[0],
            deliveryCharge:myData[0].deliveryCharge,
            id:myData[0].id
        }
setOrderData(finalData)
        let morderItems = await GetDetailsOrderItems({id:myParams.orderid} )
        organizeData(morderItems)
    }


    function organizeData(myData) {
        let allProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        let finalArr = []
        let uniqueElements = myData.slice()
        for (let index = 0; index < myData.length; index++) {
            const element = myData[index];
            // console.log(element)
            let tempArr = []
            for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                const melement = uniqueElements[mindex];
                if (melement.product_id === element.product_id) {
                    tempArr.push(melement)
                }
            }

            if (tempArr.length > 0) {
                tempArr.forEach(element => {
                    uniqueElements.forEach((melement, mindex) => {
                        if (melement.id === element.id) {
                            uniqueElements.splice(mindex, 1)
                        }
                    });
                });

                finalArr.push(tempArr)
            }

        }
        let okArr = []
        finalArr.forEach(element => {
            // console.log(element)
            for (let index = 0; index < allProducts.length; index++) {
                const pelement = allProducts[index];
                if (pelement.id === element[0].product_id) {
                    element[0].name = pelement.en_sort_title
                    //   element = [...element, element[0].name]
                    okArr.push(element)
                    break
                }
            }
        });
        // console.log(finalArr)
        setTableData(okArr)

    }

    const [grandTotal, setGrandTotal]= useState(0)
    function getGrandTotal(data){
        let total = 0
        data?.forEach(element => {
            total += (element.price*element.quantity)
        });
        setGrandTotal(total)
    }
        
    useEffect(() => {
        getAllData()
    }, [myParams.orderid, showPop])
   
    function getQTotal(item){
        let total = 0
        item?.forEach(element => {
            total +=element.quantity
        });

        return total;
    }
    function totalAmmount(item){
      let quantity =   getQTotal(item)
        let total = 0
        total +=item[0].price
        return total*quantity;
    }

    const TableItem = tableData?.map((item, index) => {
        return <>
        <MyTable key={index} item={item[0].name} 
        price={item[0]?.price} qty={getQTotal(item)} tammount={totalAmmount(item)}/>
        </>
    })

  return (
    <>      
            {conShow ? <ConfirmAlert alertText='Do you want to Restore this order?'yesClick={clickYes} noClick = {clickNo}/>:""}
            
            <div className={DecDetView.border}>
            <section className={DecDetView.salesMain}>
                <div className={DecDetView.innerSale}>
                    <div className={DecDetView.gobackSec}>
                    <h2 className={DecDetView.saleHeading}>Declined Order Details</h2>
                    <NavLink to={'/admin/sales/decline_orders'}><button>Go Back</button></NavLink>
                    </div>
                    {/* {showPop ? <ShowVarianceData data={editVarData} setShowPop={setShowPop}/>:""} */}

                    <div className={DecDetView.orderdetaislInner}>
                        <h3>Invoice #{orderData?.id}</h3>
                        <div className={DecDetView.customerSection}>
                            <div className={DecDetView.customerDetails}>
                                <p>Customer Details</p>
                                <p><strong>Customer Name</strong> : {orderData?.name}</p>
                                <p><strong>Phone</strong> : {orderData?.Phone}</p>
                                <p><strong>Division</strong> :{orderData?.Divition}</p>
                                <p><strong>District</strong> : {orderData?.District}</p>
                                <p><strong>area</strong> : {orderData?.Area}</p>
                                <div className={DecDetView.addressSec}>
                                <p><strong>Address</strong> : {orderData?.Address}</p>
                                </div>
                            </div>
                            <div className={DecDetView.orderDetails}>
                                <p>Order Details</p>
                                <p>Order Date : {convertDate(orderData?.order_created)} </p>
                                <p>Payment Type : {orderData?.paymentType}</p>
                            </div>
                        </div>
                        <div className={`${DecDetView.tableSection} ${DecDetView.detailstableSection}`}>
                        <table>
                            <tr>
                                <th>Item</th>
                                <th>Price</th>
                                {/* <th>Variations</th> */}
                                <th>Quantity</th>
                                <th>Total Ammount</th>
                            </tr>
                            {TableItem}
                        </table>
                        <div className={DecDetView.tableDetails}>
                            <p>Payment Type : {orderData?.paymentType}</p>
                            <p>Discount : {orderData?.discount ? orderData?.discount:"0"} tk</p>
                            <p>Delivery Charge : {orderData?.deliveryCharge} tk</p>
                            <p>Total Ammount : {grandTotal+orderData?.deliveryCharge} tk</p>
                            <p>Collected : {orderData?.collected ? orderData?.collected:"0"} tk</p>
                            <p>Return : {orderData?.returnAmmount ? orderData?.returnAmmount:"0"} tk</p>
                        </div>

                        <div className={DecDetView.detailsWrapper}>
                        <div className={DecDetView.detailInput}>
                            <div className={DecDetView.ButtonGroup}>
                            <ButtonInput myValue={"Restore"} mclass={'approvedetail'}
                            onClickevent={onRestoreClick}/>
                            </div>
                        </div>
                        </div>

                    </div>
                    </div>
                </div>
            </section>
               
            </div>
        </>
  )
}

export default DeclineDetailView

const ConfirmAlert = (props) => {
    const { alertText, yesClick, noClick, delID } = props

    return (
        <>
            <div className={DecDetView.conAlert}>
                <h2 className={DecDetView.caption}>{alertText}</h2>
                <div className={DecDetView.buttonsec}>
                    <input sid={delID} onClick={yesClick} className={DecDetView.yes} type={"submit"} value={"Yes"} />
                    <input onClick={noClick} className={DecDetView.no} type={"submit"} value={"No"} />
                </div>

            </div>
        </>
    )
}


const ButtonInput = ({ myValue, mclass, onClickevent }) => {
    return (
        <>
            <div>
                <button className={DecDetView.mclass} onClick={onClickevent}>{myValue}</button>
            </div>
        </>
    )
}

const MyTable = ({ item,editVariance, price, qty, tammount}) => {

    return (
        <>
            <tr>
                <td className={DecDetView.foreditVariance}>{item} {editVariance}</td>
                <td >{price}</td>
                {/* <td>{variations}</td> */}
                <td>{qty}</td>
                <td>{tammount}</td>
            </tr>
        </>
    )
}