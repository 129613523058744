import { text } from '@fortawesome/fontawesome-svg-core'
import { faClipboardCheck, faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link} from 'react-router-dom'
import { GetDatafromDatabase, GetDatafromDatabasewithOrder } from '../connector/DatabaseCheck'
import ComplainListCss from './css/ComplainList.module.css'


const ComplainList = () => {
    const [complainData, setComplainData] = useState()
    const [settingData, setSettingData] = useState()


    async function getDataFromDataBase() {
        let mySettingData = [] // await GetDatafromDatabase('ComplainSetting', 'comID')
        let myData = [] // await GetDatafromDatabasewithOrder('userComplain', 'createdDate', 'asc')
        setComplainData(myData)
        setSettingData(mySettingData)
    }

    useEffect(()=>{
        getDataFromDataBase()
    },[])

    function getInfo(data, valueHolder) {
        let myText = ''
        settingData?.map((element)=>{
            //console.log(element)
            if (element.comID===data) {
                myText = element[valueHolder]
            }
        })
        return myText
       // console.log('working')
    }
    //complainCatID complainTypeID complainPriorityID
    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    function onEditClick(params) {
        
    }
    function onSolveClick(params) {
        
    }
    function onDeleteClick(params) {
        
    }

    const myStyle = {
        color: 'white',
	    padding: '4px',
	    borderRadius: '5px',
        fontSize:'14px',
        width:'max-content'
    }
    const TableItem = complainData?.map((item, index)=>{
        return <MyTable key={item.id} sl={index+1} title={item.subject.slice(0,20)+'...'} complainby={item.userName} comaplain={item.complainMessage.slice(0,30)+'...'}
        attachment = {item.complainImages?.map((image, mindex)=>{ return <a key={image.name} href={image.url} target='blank' style={{textDecoration:'none', color:'#1e3799'}}>Attachment {mindex+1} <br/></a> })}  
        info ={
        <span className={ComplainListCss.info_class}>
        <p ><span>Type :</span> <span className={ComplainListCss.common} style={{backgroundColor:'#079992'}} >{getInfo(item.complainTypeID, 'complainType')}</span></p>
        <p ><span>Category :</span> <span className={ComplainListCss.common} style={{backgroundColor:'#e58e26'}} >{getInfo(item.complainCatID, 'complainCatName')}</span></p>
        <p ><span>Priority :</span> <span className={ComplainListCss.common} style={{backgroundColor:'#b71540'}}>{item.priority}</span></p>
        </span> }
        date={convertDate(item.createdDate?.seconds)}

        status={
        <>
        {item.status === 'Created' ? <p style={{...myStyle, backgroundColor:'#e58e26'}}>Created</p>:''}
        {item.status === 'Closed' ? <p style={{...myStyle, backgroundColor:'#3867d6'}}>Closed</p>:''}
        {item.status === 'Running' ? <p style={{...myStyle, backgroundColor:'#eb3b5a'}}>Running	</p>:''}
        </>}

        solved={item.solved ? <p style={{...myStyle, backgroundColor:'green'}}>solved</p>:<p style={{...myStyle, backgroundColor:'#e58e26'}}>Not Solved Yet</p>}
        manage={<>
       <Link to={'/admin/manage_complains/complainlist/edit/'+item.id}>
       <FontAwesomeIcon style={{color:'#82589F', cursor:'pointer'}} icon={faEdit} onClick={onEditClick}/>
       </Link>
       <Link to={'/admin/manage_complains/complainlist/function/'+item.id}>
        <FontAwesomeIcon style={{color:'#20bf6b', cursor:'pointer'}} icon={faClipboardCheck} onClick={onSolveClick}/>
        </Link>
        <FontAwesomeIcon  style={{color:'#b71540', cursor:'pointer'}} icon={faTrashCan} onClick={onDeleteClick} />
        </>}
        />
    })


    return (
        <>
            <section style={{ marginTop: '10px' }} className={`${ComplainListCss.salesMain} ${ComplainListCss.manageuserMain}`}>
                <div className={ComplainListCss.innerSale}>
                    <h2 className={ComplainListCss.saleHeading}>Manage Complains</h2>
                    <div className={ComplainListCss.tableSection}>
                        <table>
                          <tbody>
                          <tr>
                                <th>SL</th>
                                <th>Complain Title</th>
                                <th>Complain By</th>
                                <th>Complain </th>
                                <th>Attachments</th>
                                <th>Complain Type/Category/Priority</th>
                                <th>Complain Created</th>
                                <th>Complain Status</th>
                                <th>Complain Solved</th>
                                <th>Manage</th>
                            </tr>
                            {TableItem}
                          </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ComplainList
const MyTable = ({ sl, title, complainby, comaplain,attachment, info, date, status, solved, manage }) => {

    return <>
      <tr>
        <td>{sl}</td>
        <td>{title}</td>
        <td>{complainby}</td>
        <td>{comaplain}</td>
        <td>{attachment}</td>
        <td>{info}</td>
        <td>{date}</td>
        <td>{status}</td>
        <td>{solved}</td>
        <td>{manage}</td>
      </tr>
    </>
  }