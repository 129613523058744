
//my-pages

import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}my-pages/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function GetPageData() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}/all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetPageDataFilter(filter_text) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}/filter/${filter_text}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function SavePageData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}/add`, data,  configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdatePageData(id, data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}/${id}`, data,  configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DeletePageData(id) {
    let result = await MyAxios.delete(`${MY_API_LINK}delete/${id}`)
    return result        
}
export async function GetPageContent() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}page-content/get`)
        return result.data        
    } catch (error) {
        return []
    }    
}
export async function GetPageContentSingle(id) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}page-content/get/single/${id}`)
        return result.data[0]        
    } catch (error) {
        return {}
    }    
}
export async function UpdatePageContent(id, data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}page-content/update/${id}`, data, configdata)
        return result.data  
    } catch (error) {
        return {}
    }    
}

