import React from 'react'
import { NavLink, Route, Routes } from 'react-router-dom'
import offerMainCss from './css/bogomain.module.css'
import GroupList from './GroupList'
import AssignProducts from './AssignProducts'

const BogoMain = () => {
  return (
    <div>
        <div className={offerMainCss.menupart}>
                <ul className={offerMainCss.menuitems}>
                    <NavLink to={"/admin/bogo_offers/group_list"}>
                        <li >Group list</li>
                    </NavLink>
                    <NavLink to={"/admin/bogo_offers/assign_products"}>
                        <li >Assign Products</li>
                    </NavLink>
                </ul>
            </div>
            <Routes>
                    <Route path='/' element={<GroupList />} />
                    <Route path='group_list' element={<GroupList />} />
                    <Route path='assign_products' element={<AssignProducts />} />
                  
                </Routes>
    </div>
  )
}

export default BogoMain