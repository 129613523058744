import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import { GetDetailsOrderItems, GetSingleOrder } from '../../api/SalesApi'
import { GetSingleCustomer, GetSingleCustomerOrderAddress } from '../../api/CustomerApi'
import ComDetViewCss from './salesCss/CompleteDetailsView.module.css'

const CompleteDetailsView = () => {
    const myParams = useParams()
    const [tableData, setTableData] = useState()
    const [orderData, setOrderData] = useState()
    const [conShow, setConShow] = useState(false)
    const [condetailsEditShow, setCondetailsEditShow] = useState(false)

  
    function onPrintInvoice() {
        let okdata = {}
        okdata = {...okdata, tableData, ...orderData}
       localStorage.setItem('invoiceData', JSON.stringify(okdata))
    }
    function PrintChallan() {
        
    }
    
    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getAllData() {
        let myData = await GetSingleOrder(myParams.orderid)
        let myCustomer = await GetSingleCustomer(myData[0].userID)
        let userAddress = await GetSingleCustomerOrderAddress(myData[0].user_address_id)
        let getSSLdata = []
        let finalData = {
            ...myData[0],
            order_created: myData[0].created_date,
            ...userAddress[0],
            ...myCustomer[0],
            ...getSSLdata[0],
            deliveryCharge:myData[0].deliveryCharge,
            id:myData[0].id
        }
setOrderData(finalData)
        let morderItems = await GetDetailsOrderItems({id:myParams.orderid} )
        organizeData(morderItems)
    }

    function organizeData(myData) {
        let finalArr = []
        let uniqueElements = myData.slice()
        for (let index = 0; index < myData.length; index++) {
            const element = myData[index];
            let tempArr = []
            for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                const melement = uniqueElements[mindex];
                if (melement.product_id === element.product_id) {
                    tempArr.push(melement)
                }
            }

            if (tempArr.length > 0) {
                tempArr.forEach(element => {
                    uniqueElements.forEach((melement, mindex) => {
                        if (melement.id === element.id) {
                            uniqueElements.splice(mindex, 1)
                        }
                    });
                });
                finalArr.push(tempArr)           
            }
        }
        setTableData(finalArr)
    }



    useEffect(() => {
        getAllData()
    }, [myParams.orderid, condetailsEditShow])


    function getQTotal(item) {
        let total = 0
        item?.forEach(element => {
            total += element.quantity
        });

        return total;
    }
    function totalAmmount(item) {
        let quantity = getQTotal(item)
        let total = 0
        total += item[0].price
        return total * quantity;
    }
     
      async function clickYes() {
        //   let userData = JSON.parse(window.localStorage.getItem('userData'))
        //  await UpdateData('sales', {declined:true, approved:false, declineTime:serverTimestamp(), declineBy:userData.name}, myParams.orderid)
        //   let nestedData = {
        //       activity:'Order Declined',
        //       ProssesedBy:userData.name,
        //       ForwardTo:"",
        //       deliveryDate:"",
        //       note:''
        //   }
        //  await SaveNestedExistingData('sales', myParams.orderid, 'ordersummery', nestedData)
          setConShow(false)
      }
     
      function clickNo() {
         setConShow(false)
      }
      function getVarianceList(item) {
        let variations = ''
            item.forEach(element => {
                variations += element.Variance_name ?  element.Variance_name+', ' : ''
            });
            return variations
      }
      function getAgeList(item) {
        let age = ''
            item.forEach(element => {
                age += element.attr_value_name ?  element.attr_value_name+', ': ''
            });
            return age
      }

    const TableItem = tableData?.map((item, index) => {
        return <MyTable key={index} item={item[0].mainName ? item[0]?.mainName : item[0].name}
            variance={getVarianceList(item)}
            age={getAgeList(item)}
            price={item[0]?.price} qty={getQTotal(item)} tammount={totalAmmount(item)} />
    })

    function getAllTotal() {
        let total = 0
        tableData?.forEach(item => {
            item.forEach(element => {
                total += element.quantity*element.price
            });
        });
        return total
    }
    return (
        <>
             {conShow ? <ConfirmAlert alertText='Do you want to decline this order?'yesClick={clickYes} noClick = {clickNo}/>:""}
             
            
            <div>
                <section className={ComDetViewCss.salesMain}>
                    <div className={ComDetViewCss.innerSale}>
                        <div className={ComDetViewCss.gobackSec}>
                            <h2 className={ComDetViewCss.saleHeading}>Complete Order Details</h2>
                            <NavLink to={'/admin/sales/sale_history'}><button>Go Back</button></NavLink>
                        </div>
                        <div className={ComDetViewCss.orderdetaislInner}>
                            <h3>Invoice #{orderData?.id}</h3>
                            <div className={ComDetViewCss.customerSection}>
                                <div className={ComDetViewCss.customerDetails}>
                                    <p className={ComDetViewCss.highlighter}>Customer Details</p>
                                    <p><strong>Customer Name</strong> : {orderData?.name}</p>
                                    <p><strong>Phone</strong> : {orderData?.Phone}</p>
                                    <p><strong>Division</strong> :{orderData?.Divition}</p>
                                    <p><strong>District</strong> : {orderData?.District}</p>
                                    <p><strong>area</strong> : {orderData?.Area}</p>
                                    <div className={ComDetViewCss.addressSec}>
                                        <p><strong>Address</strong> : {orderData?.Address}</p>
                                        {/* <FontAwesomeIcon onClick={EditAddressClick} icon={faEdit} /> */}
                                    </div>
                                </div>
                                <div className={ComDetViewCss.orderDetails}>
                                    <p className={ComDetViewCss.highlighter}>Order Details</p>
                                    <p>Order Date : {convertDate(orderData?.order_created)} </p>
                                    <p>Payment Type : {orderData?.paymentType}</p>
                                </div>
                            </div>
                            <div className={ComDetViewCss.customerSection}>
                                { condetailsEditShow ? <div className={ComDetViewCss.confimationEdit}><ConfirmationDetailsEdit setCondetailsEditShow={setCondetailsEditShow} alldata={orderData}/></div>:""}
                                <div className={ComDetViewCss.customerDetails}>
                                    <p className={ComDetViewCss.highlighter}>Confirmation Details</p>
                                    <p><strong>Note : </strong> : {orderData?.approval_note}</p>
                                    <p><strong>Delivery Priority : </strong> :{orderData?.priority}</p>
                                    <p><strong>Delivery Date : </strong> : {orderData?.delivery_date}</p>
                                    <p><strong>Special Instruction's : </strong> : {orderData?.special_instruction}</p>
                                    {/* <button onClick={conEditClick} className={ComDetViewCss.condetailsEdit}><FontAwesomeIcon icon={faEdit} /> Edit</button> */}
                                </div>
                            </div>
                            <div className={`${ComDetViewCss.detailstableSection} ${ComDetViewCss.tableSection}`}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Item</th>
                                        <th>Variance</th>
                                        <th>Age</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total Ammount</th>
                                    </tr>
                                    {TableItem}
                                    </tbody>
                                </table>
                                <div className={ComDetViewCss.tableDetails}>
                                <p>Total Ammount : {getAllTotal()} tk</p>
                                    <p>Discount : {Math.round(orderData?.discountPercent ? (orderData?.grandTotal *orderData?.discountPercent)/100 : "0")} tk</p>
                                    <p>Delivery Charge : {orderData?.deliveryCharge} tk</p>
                                    <p>Grand Total : {Math.round((getAllTotal() + orderData?.deliveryCharge)- (orderData?.discountPercent ? (getAllTotal() *orderData?.discountPercent)/100 : "0"))} tk</p>
                                </div>
                                <div className={ComDetViewCss.detailsWrapper}>
                                    <div className={`${ComDetViewCss.detailInput} ${ComDetViewCss.salesDetail}`}>
                                        <div className={ComDetViewCss.ButtonGroup}>
                                            <Link to={`/admin/sales/salesdetails/${orderData?.itemid}/invoice`} target='_blank' rel="noopener noreferrer">
                                                <button onClick={onPrintInvoice}>Print Invoice</button>
                                            </Link>
                                            <button onClick={PrintChallan}>Print Challan Copy</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default CompleteDetailsView

const ConfirmationDetailsEdit = ({alldata, setCondetailsEditShow, myValue }) => {
        const [condetalData, setConDetailData] = useState({
            priority:alldata.priority,
            delivery_date:alldata.delivery_date,
            special_instruction:alldata.special_instruction,
        })
        function CloseClick() {
            setCondetailsEditShow(false)
        }
        function onDataChange(e){
            let name = e.target.name
            let value = e.target.value
            setConDetailData({...condetalData, [name]:value})
            
        }

        async function onUpdateClick(){
           // await UpdateData('sales', condetalData, alldata.orderid)
            // let nestedData = {
            //     activity: 'Confirmation Details Updated',
            //     ProssesedBy: 'admin',
            //     ForwardTo: "",
            //     deliveryDate: "",
            //     note: ""
            // }
           // await SaveNestedExistingData('sales', alldata.orderid, 'ordersummery', nestedData)
            setCondetailsEditShow(false)
        }
    return (
        <>
            <h3>Confirmation Details Update</h3>
            <div className={ComDetViewCss.common}>
                <label>Order Priority</label>
                <select name='priority' onChange={onDataChange}>
                    <option>Select Priority</option>
                    <option>High</option>
                    <option>Medium</option>
                    <option>Low</option>
                </select>
            </div>
            <div className={ComDetViewCss.common}>
                <label>Delivery Date</label>
                <input onChange={onDataChange} name='delivery_date' type='date' value={condetalData?.delivery_date} />
            </div>
            <div className={ComDetViewCss.common}>
                <label>Special Instruction</label>
                <input onChange={onDataChange} name='special_instruction' type='text' value={condetalData?.special_instruction} />
            </div>
            <button onClick={CloseClick}>Close</button>
            <button onClick={onUpdateClick}>Update</button>
        </>
    )
}

const ConfirmAlert = (props) => {
    const { alertText, yesClick, noClick, delID } = props

    return (
        <>
            <div className={ComDetViewCss.conAlert}>
                <h2 className={ComDetViewCss.caption}>{alertText}</h2>
                <div className={ComDetViewCss.buttonsec}>
                    <input sid={delID} onClick={yesClick} className={ComDetViewCss.yes} type={"submit"} value={"Yes"} />
                    <input onClick={noClick} className={ComDetViewCss.no} type={"submit"} value={"No"} />
                </div>

            </div>
        </>
    )
}


const MyTable = ({ item, variance, age, price, qty, tammount }) => {

    return (
        <>
            <tr>
                <td className={ComDetViewCss.foreditVariance}>{item}</td>
                <td >{variance}</td>
                <td >{age}</td>
                <td >{price}</td>
                <td>{qty}</td>
                <td>{tammount}</td>
            </tr>
        </>
    )
}