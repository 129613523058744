import React, { useEffect, useState } from 'react'
import { GetDivisionList } from '../../../api/Address/DivisionApi'
import viewDivisionCss from './css/ViewDivision.module.css'
const ViewDivision = () => {
    const [divisionArray, setDivitionArray] = useState()
    async function getPrimaryAddress() {
        let division = await GetDivisionList()
        let sortArrDiv = division.sort((a, b) => (a.division_name > b.division_name) ? 1 : -1)
        setDivitionArray(sortArrDiv)
      }
      
      useEffect(()=>{
        getPrimaryAddress()
      },[])
  return (
    <div className={viewDivisionCss.tableSection} style={{width:'50%', margin:'auto'}}>
        <table>
            <thead>
                <tr>
                    <th>sl</th>
                    <th>Division Name</th>
                </tr>
            </thead>
            <tbody>
                {divisionArray?.map((item, index)=>{
                    return <tr key={item.division_id}>
                        <td>{index+1}</td>
                        <td>{item.division_name}</td>
                    </tr>
                })}
                
            </tbody>
        </table>
    </div>
  )
}

export default ViewDivision