import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetAllBrands } from '../../api/BrandListApi'


const initialState = {
  brandList:[],
  status:'idle',
}
export const BrandListSlice = createSlice({
  name: 'brandlist',
  initialState,
  reducers: {
    setBrandList: (state, action) => {
      state.brandList = action.payload
    },
    setBrandStatus: (state, action) => {
        state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchBrands.pending, (state, action) => {
      state.status = 'pending'
    })
    .addCase(fetchBrands.fulfilled, (state, action) => {
      state.status = 'success'
      let mData = action.payload
        state.brandList = mData
    })
    .addCase(fetchBrands.rejected, (state, action) => {
      state.status = action.payload
    })
},
  
})

export const fetchBrands = createAsyncThunk('brandlist/fetchBrands', async ()=>{
    try {
      const resposne = await GetAllBrands()
      return resposne
    } catch (error) {
      return error.message
    }
})


export const selectBrandList = state =>state.brandlist.brandList
export const selectBrandStatus = state =>state.brandlist.status
export const { setBrandList, setBrandStatus} = BrandListSlice.actions

export default BrandListSlice.reducer
