import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import SalesProductWiseReport from './SalesReport/SalesProductWiseReport'
// import SalesProductWiseReportAccount from './SalesReport/SalesProductWiseReportAccount'
import SalesReport from './SalesReport/SalesReport'
import SalesReturnReport from './SalesReport/SalesReturnReport'
import SalesReportNew from './SalesReportNew/SalesReportNew'
import PurchaseReport from './PurchaseReport/PurchaseReport'
import SupplierLedger from './PurchaseReport/SupplierLedger'
import StockSummeryPS from './PurchaseReport/StockSummeryPS'
import StockCapital from './PurchaseReport/StockCapital'
import StockMaintainReport from './PurchaseReport/StockMaintainReport'
import ExpenseReport from './ExpenseReport/ExpenseReport'
import ExpenseReportHeadWise from './ExpenseReport/ExpenseReportHeadWise'
import SaleCollectionStatement from './BankBalance/SaleCollectionStatement'
import CashinHandReceive from './BankBalance/CashinHandReceive'
import BankPaymentReceive from './BankBalance/BankPaymentReceive'
import AtaGlanceReport from './AccountsPart/AtaGlanceReport'
import AccountAraGlanceReport from './AccountsPart/AccountAraGlanceReport'
import TrialBalance from './AccountsPart/TrialBalance'
import SalesProductWiseReportNew from './SalesReportNew/SalesProductWiseReportNew'
import SalesReturnReportNew from './SalesReportNew/SalesReturnReportNew'
import CustomerLedger from './SalesReportNew/CustomerLedger'
import ReportMainCss from './ReportMain.module.css'

const ReportMain = () => {
  return (
    <>
        <Routes>
            <Route path='/' element={<DashBoard/>} /> {/*done */}
            
            <Route path='/sales_report_new/*' element={<SalesReportNew/>} /> {/*done */}
            <Route path='/sales_productwise/*' element={<SalesProductWiseReportNew/>} /> {/*done */}
            <Route path='/sales_report_return/*' element={<SalesReturnReportNew/>} /> {/*done */}
            <Route path='/customer_ledger/*' element={<CustomerLedger/>} /> {/*done */}

            <Route path='/purchase_report/*' element={<PurchaseReport/>} /> {/*done */}
            <Route path='/supplier_ledger/*' element={<SupplierLedger/>} /> {/*done */}
            <Route path='/stock_summery_purchase_sales/*' element={<StockSummeryPS/>} /> {/*done */}
            <Route path='/stock_capital/*' element={<StockCapital/>} /> {/*done */}
            <Route path='/stock_maintain_report/*' element={<StockMaintainReport/>} /> {/*done */}

            <Route path='/expense_report/*' element={<ExpenseReport/>} /> {/*done */}
            <Route path='/expense_report_head/*' element={<ExpenseReportHeadWise/>} />  {/*done */}
            <Route path='/collection_statement/*' element={<SaleCollectionStatement/>} /> {/*done */}
            <Route path='/cashinhand_receive/*' element={<CashinHandReceive/>} /> {/*done */}
            <Route path='/bank_payment_receive/*' element={<BankPaymentReceive/>} /> {/*done */}
            <Route path='/ata_glance/*' element={<AtaGlanceReport/>} /> {/*done */}
            <Route path='/account_ata_glance/*' element={<AccountAraGlanceReport/>} /> {/*done */}
            <Route path='/trial_balance/*' element={<TrialBalance/>} />
            <Route path='*' element={<NoRoute/>}/>
        </Routes>
    
    </>
  )
}

export default ReportMain

const DashBoard =()=>{
    return (
        <div className={ReportMainCss.myreport} style={{marginTop:'10px'}}>
        <div className={ReportMainCss.common_part}>
            <h2>Sales Report's</h2>
            <div className={`${ReportMainCss.all_reports} ${ReportMainCss.sales_part}`}>
               
                <div style={{background:'#219F94'}}>
                    <Link to={'/admin/report/sales_report_new'}>Sales Report</Link>
                </div>
                <div style={{background:'#11698E'}}>
                <Link to={'/admin/report/sales_productwise'}>Sales Report Product Wise</Link>
                </div>
                 <div style={{background:'#400D51'}}>
                    <Link to={'/admin/report/sales_report_return'}>Sales Return Report</Link>
                </div>
                <div style={{background:'black'}}>
                    <Link to={'/admin/report/customer_ledger'}>Customer Ledger</Link>
                </div>
            </div>
        </div>
        <div className={ReportMainCss.common_part}>
            <h2>Purchase Report's</h2>
            <div className={`${ReportMainCss.all_reports} ${ReportMainCss.purchase_part}`}>
                <div style={{background:'#06283D'}}>
                    <Link to={'/admin/report/purchase_report'}>Purchase Report</Link>
                </div>
                {/* <div>
                    <Link to={'/admin/report/pw_report'}>Purchase Return Report</Link>
                </div> */}
                <div style={{background:'#219F94'}}>
                    <Link to={'/admin/report/supplier_ledger'}>Supplier Ledger</Link>
                </div>
                <div style={{background:'#11698E'}}>
                    <Link to={'/admin/report/stock_summery_purchase_sales'}>Stock Summery (Purchase & Sales)</Link>
                </div>
                <div style={{background:'#400D51'}}>
                    <Link to={'/admin/report/stock_capital'}>Stock Capital Report</Link>
                </div>
                <div style={{background:'Black'}}>
                    <Link to={'/admin/report/stock_maintain_report'}>Stock Maintain Report</Link>
                </div>
                <div className={ReportMainCss.no_color}> 
                </div> 
                <div className={ReportMainCss.no_color}> 
                </div> 
            </div>
        </div>
        <div className={ReportMainCss.common_part}>
            <h2>Expence Report's</h2>
            <div className={`${ReportMainCss.all_reports} ${ReportMainCss.expence_part}`}>
                <div style={{background:'#06283D'}}>
                    <Link to={'/admin/report/expense_report'}>Expence Report</Link>
                </div>
                <div style={{background:'#219F94'}}>
                    <Link to={'/admin/report/expense_report_head'}>Head Wise Expence Report</Link>
                </div> 
                <div className={ReportMainCss.no_color}> 
                </div> 
                <div className={ReportMainCss.no_color}> 
                </div> 
                
            </div>
        </div>
        <div className={ReportMainCss.common_part}>
            <h2>Bank Balance</h2>
            <div className={`${ReportMainCss.all_reports} ${ReportMainCss.bank_part}`}>
                <div style={{background:'#06283D'}}>
                    <Link to={'/admin/report/collection_statement'}>Sale Collection Statement</Link>
                </div>
                <div style={{background:'#219F94'}}>
                    <Link to={'/admin/report/cashinhand_receive'}>Cash in Hand Received Statement</Link>
                </div>
                <div style={{background:'#11698E'}}>
                    {/* Link */}
                    <a style={{color:'white'}} onClick={()=>{alert('Working')}}>Online Payment Received (SSL)</a>
                </div>
                <div style={{background:'#400D51'}}>
                    <Link to={'/admin/report/bank_payment_receive'}>Bank Payment & Received</Link>
                </div>
            </div>
        </div>
        <div className={ReportMainCss.common_part}>
            <h2>Accounts Report</h2>
            <div className={`${ReportMainCss.all_reports} ${ReportMainCss.account_part}`}>
                <div style={{background:'#06283D'}}>
                    <Link to={'/admin/report/ata_glance'}>At-A-Glance Report</Link>
                </div>
                <div style={{background:'#219F94'}}>
                    <Link to={'/admin/report/account_ata_glance'}>Account At-A-Glance Report</Link>
                </div>
                <div style={{background:'#11698E'}}>
                    <Link to={'/admin/report/trial_balance'}>Trial Balance Report</Link>
                </div>
                <div style={{background:'#400D51'}}>
                    <Link to={'/admin/report/pw-report'}>Trading Account Report</Link>
                </div>
                <div style={{background:'black'}}>
                    <Link to={'/admin/report/pw-report'}>Balance Sheet Report</Link>
                </div>
                <div style={{background:'#351F39'}}>
                    <Link to={'/admin/report/pw-report'}>Profit & Loss Summery</Link>
                </div>
                <div style={{background:'#8CBA51'}}>
                    <Link to={'/admin/report/pw-report'}>Profit & Loss Account Report</Link>
                </div>
                <div className={ReportMainCss.no_color}> 
                </div>  
            </div>
        </div>
        
    </div>
    )
}

const CommingSoon =()=>{
    return (
        <h1>Comming Soon</h1>
    )
}
const NoRoute =()=>{
    return (
        <h1>No Route Found</h1>
    )
}