import React, { useEffect, useState } from 'react'
import addCss from './addressview.module.css'
import { GetCustomerAddressList } from '../../../api/CustomerApi'

async function getAddressData(id, setData) {
    let data = await GetCustomerAddressList(`userID=${id}`)
    setData(data)
  }
const AddressView = ({popUp, setPopUp}) => {
    const [tableData, setTableData] = useState([])
    useEffect(()=>{
        getAddressData(popUp.id, setTableData)
    },[])
  return (
    <div className={addCss.wrapper}>

    <div className={addCss.holder}>
        <h3>{popUp.name} - {popUp.phone}</h3>
    <div className={addCss.tableSection}>
                  <table>
                      <thead>
                          <tr>
                              <th>SL</th>
                              <th>Address</th>
                              <th>Phone</th>
                              <th>Divistion</th>
                              <th>District</th>
                              <th>Area</th>
                              <th>Status</th>
                          </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index)=>{
                            return <tr key={index}>
                                <td>{index+1}</td>
                                <td>{item.Address}</td>
                                <td>{item.Phone}</td>
                                <td>{item.Divition}</td>
                                <td>{item.District}</td>
                                <td>{item.Area}</td>
                                <td>{item.active ? 'Active' : ''}</td>
                            </tr>
                        })}
                        {tableData.length < 1 && <tr><td colSpan={7}>No Address found</td></tr>}
                      </tbody>
                  </table>
        </div>

      <div className={addCss.close_btn}>
        <button onClick={()=>{setPopUp(); document.body.style.overflow = 'auto'}}>Close</button>
      </div>

    </div>
  </div>
  )
}

export default AddressView