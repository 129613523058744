import React, { useEffect, useState } from 'react'
import { GetAllDataExpress, GetDoubleQueryExpress } from '../../api/ExpressFunctions'
import Headerimage from '../../assets/Headerimage.jpg'
import { GenarateText } from '../connector/InWord'
import POInvoiceCss from './css/POInvoice.module.css'

const POInvoice = () => {

    const [invoiceState, setInvoiceState] = useState()
    const [supplierData, setSupplierData] = useState()
    const [varState, setVarState] = useState()

    function PrintPaper() {
        window.print()
    }
    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        myDate = myDate.replaceAll('/', '-')
        return myDate
    }
    function convertDateTime(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myTime
    }
    async function getData() {
        let mySupplier = await GetAllDataExpress('supplier')
        let data = JSON.parse(localStorage.getItem('PoInvoiceData'))
        let allVariance = JSON.parse(localStorage.getItem('allVariance'))
        let myItems = data.purchaseItems
        let varArr = []
        let varPromises = myItems.map(async function (pitem) {
            // console.log(pitem) 
            let variance = await GetDoubleQueryExpress('purchase_variance', 'purchase_item_id', 'product_id', pitem.id, pitem.product_id)
            varArr.push(...variance)
        })
        Promise.all(varPromises).then(() => {
            // console.log(varArr) 
            setInvoiceState(data)
            let finalArr = []
            varArr.forEach(element => {
                //console.log(element)
                for (let index = 0; index < allVariance.length; index++) {
                    const velement = allVariance[index];
                    if (element.varinace_id === velement.id) {
                        element = {
                            ...element,
                            vname: velement.Variance_name
                        }
                        finalArr.push(element)
                        break
                    }

                }
            });
            let sortArr = finalArr.sort((a, b) => (parseInt(a.vname) > parseInt(b.vname)) ? 1 : -1)
            setVarState(sortArr)
            setSupplierData(mySupplier)
        })

    }
    useEffect(() => {
        getData()
    }, [])
    function finalQuantity(quantity, returnQuantity) {
        let mquantity = 0
        if (returnQuantity) {
            mquantity = quantity - returnQuantity
        } else {
            mquantity = quantity
        }
        return mquantity
    }
    //sl, description, quantity, uniteprice, total
    const tableItem = invoiceState?.purchaseItems?.map((item, index) => {
        return <InvoiceTable sl={index + 1} description={item.name}
            varInfo={item.hasVriance ? <>{varState?.filter((fitem) => {
                return fitem.product_id === item.product_id ? fitem : ''
            })
                .map((varitem) => {
                    return <p>
                        {varitem.vname} - {varitem.quantity}
                    </p>
                })}</> : ''}

            quantity={finalQuantity(item.quantity)} uniteprice={item.price} total={item.quantity * item.price} />
    })
    function getItemTotal(mtabelData) {
        let total = 0
        mtabelData?.map((item) => {
            total += finalQuantity(item.quantity, item.returnQuantity) * item.price
        })

        return total
    }
    function getPaymentTotal(payArr) {
        let total = 0
        payArr?.map((pitem) => {
            total += pitem.paymentAmmount ? pitem.paymentAmmount : 0
        })

        return total
    }

    return (
        <>
            <div className={POInvoiceCss.invoicemain} id='pdf_body'>
                <button className={POInvoiceCss.printBtn} onClick={PrintPaper}>Print</button>

                <section className={POInvoiceCss.invoiceSection}>
                    <div className={POInvoiceCss.container} id="printInvoice">
                      
                        <div className={POInvoiceCss.topSection}>
                            <img src={Headerimage} alt="" />
                        </div>

                        <div className={POInvoiceCss.main}>
                            <h3>PO Invoice</h3>
                            <div className={`${POInvoiceCss.info} ${POInvoiceCss.po_info}`}>
                                <span className={POInvoiceCss.moreinfo}>Invoice Number : </span> #{invoiceState?.id}
                            </div>
                            <div className={POInvoiceCss.details}>

                                <div className={POInvoiceCss.client}>
                                    {supplierData?.filter((fitem) => {
                                        return fitem.id === invoiceState?.supplierID ? fitem : ''
                                    }).map((item) => {
                                        return <span>
                                            <div className={POInvoiceCss.info}>
                                                <span className={POInvoiceCss.moreinfo}>Customar Name : </span>{item?.companyaName}
                                            </div>
                                            <div className={POInvoiceCss.info}>
                                                <span className={POInvoiceCss.moreinfo}>Phone No : </span>{item?.phone}
                                            </div>
                                            <div className={POInvoiceCss.info}>
                                                <span className={POInvoiceCss.moreinfo}>Address : </span> {item?.address + ", " + item?.Area + ", " + item?.District + ", " + item?.Divition + ","}
                                            </div>
                                        </span>
                                    })}
                                </div>
                                <div className={POInvoiceCss.invoice}>
                                    <div className={POInvoiceCss.info}>
                                        <span className={POInvoiceCss.moreinfo}>Date : </span>{convertDate(invoiceState?.created_date)}
                                    </div>
                                    <div className={POInvoiceCss.info}>
                                        <span className={POInvoiceCss.moreinfo}>Time : </span> {convertDateTime(invoiceState?.created_date)}
                                    </div>
                                    <div className={POInvoiceCss.info}>
                                        <span className={POInvoiceCss.moreinfo}>Purchased By : </span>Anayase.com
                                    </div>
                                </div>
                            </div>
                            <div className={POInvoiceCss.table}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className={POInvoiceCss.no}>SI No.</th>
                                            <th style={{ paddingLeft: '10px' }}>Item Name</th>
                                            <th className={POInvoiceCss.unit}>Quantity</th>
                                            <th className={POInvoiceCss.unit_price}>Unit Price</th>
                                            <th className={POInvoiceCss.total}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* here table item goes */}
                                        {tableItem}
                                    </tbody>
                                    <tbody className={POInvoiceCss.tfoot}>
                                        <tr>
                                            <td colspan="3"></td>
                                            <td>Sub Total</td>
                                            <td>{getItemTotal(invoiceState?.purchaseItems)} TK</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3"></td>
                                            <td style={{ width: '140px' }}>Discount (- {invoiceState?.percent ? invoiceState?.percent.toFixed(2) + '%' : ''})</td>
                                            <td>{invoiceState?.percent ? <>{Math.round(getItemTotal(invoiceState?.purchaseItems) * invoiceState?.percent / 100)}</> : '0'} TK</td>
                                        </tr>
                                        {/* <tr>
                                <td colspan="3"></td>
                                <td>Grand Total</td>
                                <td>{invoiceState?.grandTotal} TK</td>
                            </tr> */}
                                        <tr>
                                            <td colspan="5">
                                                <span></span>
                                            </td>
                                        </tr>
                                        <tr className={POInvoiceCss.gran_total}>
                                            <td colspan="3"></td>
                                            <td className={POInvoiceCss.gran_total_text}>Grand Total</td>
                                            <td>{getItemTotal(invoiceState?.purchaseItems) - Math.round(invoiceState?.percent ? (getItemTotal(invoiceState?.purchaseItems) * invoiceState?.percent / 100) : 0)} TK</td>
                                        </tr>
                                        <tr className={POInvoiceCss.pre_Paid}>
                                            <td colspan=" 3"></td>
                                            <td className={POInvoiceCss.pre_Paid_text}> Paid (-)</td>
                                            <td>{parseInt(invoiceState?.payAmmount ? invoiceState?.payAmmount : 0) + getPaymentTotal(invoiceState?.Payment)} TK</td>
                                        </tr>
                                        <tr className={POInvoiceCss.amount}>
                                            <td colspan="3"></td>
                                            <td className={POInvoiceCss.amount_text}> Due Ammount</td>
                                            <td>{getItemTotal(invoiceState?.purchaseItems) - (invoiceState?.payAmmount ? invoiceState?.payAmmount : 0) - getPaymentTotal(invoiceState?.Payment) - Math.round((getItemTotal(invoiceState?.purchaseItems) * invoiceState?.percent / 100))} TK</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={POInvoiceCss.notice1} style={{ marginTop: '-108px' }}>
                                <div className={POInvoiceCss.notice1_1}>
                                    <p>
                                        <span className={POInvoiceCss.inword}>In Word : </span>
                                        {GenarateText(getItemTotal(invoiceState?.purchaseItems) - (invoiceState?.payAmmount ? invoiceState?.payAmmount : 0) - getPaymentTotal(invoiceState?.Payment) - Math.round((getItemTotal(invoiceState?.purchaseItems) * invoiceState?.percent / 100)))}
                                    </p>
                                </div>
                            </div>
                            {/* <div className={POInvoiceCss.notices}>
                    <div>
                        <b className={POInvoiceCss.notetext}>NOTE : </b>
                    </div>
                    <div className={POInvoiceCss.notice}>{invoiceState?.special_instruction}</div>

                </div>
                <img src={QRcode} alt=""/> */}
                            <div className={POInvoiceCss.seal_signiture} style={{ marginTop: '140px' }}>
                                <div className={`${POInvoiceCss.singniture} ${POInvoiceCss.singniture1}`}>

                                    <span>Customer Signature</span>
                                </div>
                                <div className={`${POInvoiceCss.singniture} ${POInvoiceCss.singniture2}`}>
                                    <span>Authority Signature</span>
                                </div>
                            </div>
                            <div className={`${POInvoiceCss.seal_signiture} ${POInvoiceCss.seal_signiture1}`}>
                                <span className={POInvoiceCss.thankyou_msg}>Thank you for being with Anayase.com.</span>
                            </div>
                        </div>
                    </div>

                </section>

            </div>

        </>
    )
}

export default POInvoice

const InvoiceTable = ({ sl, description, quantity, uniteprice, total, varInfo }) => {

    return (
        <>
            <tr>
                <td className={POInvoiceCss.no}>{sl}</td>
                <td className={POInvoiceCss.desc}><strong>{description}</strong> {varInfo}</td>
                <td className={POInvoiceCss.unit}>{quantity}</td>
                <td className={POInvoiceCss.unit_price}>{uniteprice}</td>
                <td className={POInvoiceCss.total}>{total}</td>
            </tr>
        </>
    )
}