import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import ComplainFunctions from './Actions/ComplainFunctions'
import ComplainEdit from './Actions/ComplainEdit'
import AddComplain from './AddComplain'
import ComplainList from './ComplainList'
import ComplainSettingMain from './ComplainSettingMain'
import ManageComplainMainCss from './css/ManageComplainMain.module.css'


const ManageComplainMain = () => {
  const myParams = useParams()

  return (
    <>
      <div className={ManageComplainMainCss.menupart}>
        <ul className={ManageComplainMainCss.menuitems}>
          <NavLink to={"/admin/manage_complains/complainlist"}>
            <li >Manage Complains</li>
          </NavLink>
          <NavLink to={"/admin/manage_complains/complain_setting"}>
            <li >Complain Setting</li>
          </NavLink>
          <NavLink to={"/admin/manage_complains/add_complain"}>
            <li >Add Complain</li>
          </NavLink>
        </ul>
      </div>
      <div>
        {myParams.mcomplain === undefined ? <ComplainList /> : ""}
        {myParams.mcomplain === "complainlist" && !myParams.actions ? <ComplainList /> : ""}

        {myParams.mcomplain === "complain_setting" ? <ComplainSettingMain activity={1} /> : ""}
        {myParams.mcomplain === "add_complain" ? <AddComplain /> : ""}
        {myParams.actions === "edit" ? <ComplainEdit /> : ""}
        {myParams.actions === "function" ? <ComplainFunctions /> : ""}

      </div>


    </>
  )
}

export default ManageComplainMain

