import { faEdit} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetAllLocalProducts } from '../MyFunctions/MyFunctions'
import { selectSupplierList } from '../../ReduxFiles/Slices/SupplierSlice'
import { useSelector } from 'react-redux'
import { GetPurchaseItems, GetsinglePurchase, SavePurchaseSummery, SavePurchaseVariance, UpdatePurchaseData, UpdatePurchaseItemData } from '../../api/PurchaseApi'
import { GetDebitDataFilter } from '../../api/DebitApi'
import { GetFilterProductVariance } from '../../api/ProductVarianceApi'
import { selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import PurchaseConfirmCSs from './css/PurchaseConfirm.module.css'
import { toast} from "react-toastify";
const PurchaseConfirm = () => {
    const userInfo = useSelector(selectUserInfo)
    const supplierData = useSelector(selectSupplierList)
    const myParams = useParams()
    const [tableData, setTableData] = useState()
    const [orderData, setOrderData] = useState()
    const [editVarData, setEditVarData] = useState()
    const [showPop, setShowPop] = useState(false)
    const myNavigation = useNavigate()
    
   async function OnConfirmData() {
        let allVarianceManaged = false
        for (let index = 0; index < tableData.length; index++) {
            let element = tableData[index];
            if (element.hasVriance) {
                if (element.varianceManaged) {
                    allVarianceManaged = true
                } else {
                    allVarianceManaged = false
                    toast.warn(`please manage all variance, ${element.name}`)
                    break
                }
            } else {
                allVarianceManaged = true
            }

        }
        if (allVarianceManaged) {         
          let summeryData = {
            order_date:orderData.poDate,
            supplier_id:orderData.supplierID,
            status:'Variance Managed',
            attemptBy:userInfo.id,
            purchase_id:orderData.id,
            created_date:new Date().getTime()
          }
        await UpdatePurchaseData(orderData.id, {varianceManaged:1})
        await SavePurchaseSummery(summeryData)
        myNavigation('/admin/purchase/pendinglist')
        }
    }
    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getAllData() {
        let mAllProducts = GetAllLocalProducts()
        let myData = await GetsinglePurchase(myParams.poid)
        let myNestedData = await GetPurchaseItems(`purchase_id = ${myParams.poid}`)
        let myPayData = await GetDebitDataFilter(`identity = ${myParams.poid}`)
        //product_id
        let myArr = []
        myNestedData.forEach(element => {
            for (let index = 0; index < mAllProducts.length; index++) {
                const pelement = mAllProducts[index];
                if (element.product_id===pelement.id) {
                    element = {
                        ...element,
                        name:pelement.en_sort_title
                    }
                    myArr.push(element)
                    break
                }
            }
        });
        let total = 0
        let finalData = {
            ...myData[0]
        }
        for (let index = 0; index < myPayData?.length; index++) {
            const pelement = myPayData[index];
            if (pelement.identity === parseInt(myParams.poid)) {
                total = pelement.paymentAmmount
            }

        }
        setOrderData({...finalData, payAmmount:total})
        setTableData(myArr)
    }

    function EditeVariance(e, items) {
        setEditVarData(items)
        setShowPop(true)
    }
    function getDiscountAmount(amount, percent) {
        let mpercent = percent ? percent :0
       return amount*mpercent/100
    }
    useEffect(() => {
        getAllData()
    }, [myParams.orderid, showPop])

    const TableItem = tableData?.map((item, index) => {
        return <MyTable item={item.name} editVariance={item?.hasVriance ?
                <p className={PurchaseConfirmCSs.editVariance}>
                    <FontAwesomeIcon onClick={(e) => { EditeVariance(e, item) }} icon={faEdit} />
                    {item.varianceManaged ? <span className={PurchaseConfirmCSs.success}>variance managed</span>:""}
                    <span className={PurchaseConfirmCSs.tooltip}>Manage Variance Quantity</span>
                </p> : ""}
                price={item?.price} qty={item.quantity} tammount={parseInt(item.quantity)*parseInt(item.price)} />
    })
    function getItemTotal(mtabelData){
        let total = 0
        mtabelData?.map((item)=>{
            total += item.quantity * item.price
        })

        return total;
    }

    return (
        <>
            <div>
                <section className={PurchaseConfirmCSs.salesMain}> 
                    <div className={PurchaseConfirmCSs.innerSale}>
                        <div className={PurchaseConfirmCSs.gobackSec}>
                            <h2 className={PurchaseConfirmCSs.saleHeading}>PO Variance Manage</h2>
                            <NavLink to={'/admin/purchase/pendinglist'}><button>Go Back</button></NavLink>
                        </div>
                        {showPop ? <ShowVarianceData data={editVarData} setShowPop={setShowPop} tableData={tableData} setTableData={setTableData}/> : ""}

                        <div className={PurchaseConfirmCSs.orderdetaislInner}>
                            <h3>PO No. #{orderData?.id}</h3>
                            <div className={PurchaseConfirmCSs.customerSection}>
                                <div className={PurchaseConfirmCSs.customerDetails}>
                                    <p className={PurchaseConfirmCSs.highlighter}>Supplier Details</p>
                                    {supplierData?.filter((fitem)=>{
                                        return fitem.id === orderData?.supplierID ? fitem : ''
                                    }).map((item)=>{
                                        return <span>
                                        <p><strong>Company Name</strong> : {item?.companyaName}</p>
                                        <p><strong>Phone</strong> : {item?.phone}</p>
                                        <p><strong>Division</strong> :{item?.Divition}</p>
                                        <p><strong>District</strong> : {item?.District}</p>
                                        <p><strong>area</strong> : {item?.Area}</p>
                                    </span>
                                    })}                            
                                </div>
                                <div className={PurchaseConfirmCSs.orderDetails}>
                                    <p className={PurchaseConfirmCSs.highlighter}>PO Details</p>
                                    <p>PO Date : {convertDate(orderData?.created_date)} </p>
                                    <p>Payment Method : {orderData?.paymentMethod}</p>
                                </div>
                            </div>
                            <div className={`${PurchaseConfirmCSs.tableSection} ${PurchaseConfirmCSs.detailstableSection}`}>
                                <table key={'abcd'}>
                                    <tbody>
                                    <tr>
                                        <th>Item</th>
                                        <th>Price</th>
                                        {/* <th>Variations</th> */}
                                        <th>Quantity</th>
                                        <th>Total Ammount</th>
                                    </tr>
                                    {TableItem}
                                    </tbody>
                                </table>
                                <div className={PurchaseConfirmCSs.tableDetails}>
                                    <p>Total Ammount : {getItemTotal(tableData)} tk</p>
                                    <p>Paid : {orderData?.payAmmount ? orderData?.payAmmount : "0"} tk</p>
                                    <p>Discount : {getDiscountAmount(getItemTotal(tableData), orderData?.percent)} tk</p>
                                    <p>Due Ammount : {getItemTotal(tableData) - (orderData?.payAmmount + getDiscountAmount(getItemTotal(tableData), orderData?.percent))} tk</p>
                                </div>
                                
                                <div className={PurchaseConfirmCSs.detailsWrapper}>
                                    <div className={`${PurchaseConfirmCSs.detailInput} ${PurchaseConfirmCSs.salesDetail}`}>
                                        <div className={PurchaseConfirmCSs.ButtonGroup}>
                                            <button onClick={OnConfirmData}>Variance Managed</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default PurchaseConfirm

const ShowVarianceData = ({ data, setShowPop, tableData, setTableData}) => {
    const [varState, setVarState] = useState()
    const [varQuantity, setVarQuantity] = useState(0)

    function onVarQuantityChange(e) {
        let varData = [...varState]
        let name = e.target.name
        let value = e.target.value
        let findIndex = varData.findIndex(obj => obj.id === parseInt(name))
        varData[findIndex].newQuantity = parseInt(value)
        CountQuantity(varData)
    }
    function CountQuantity(arrayData) {
        let counter = 0
        arrayData.forEach(element => {
            let elementValue = 0
            if (element.newQuantity) {
                elementValue = element.newQuantity
            }else{
                elementValue = 0
            }
            counter +=parseInt(elementValue)
        });
        setVarQuantity(counter) 
    }

   async function onUpdateClick() {
         if (varQuantity>data.quantity) {
           alert('variance quantity is greater then purchase quantity')
        }else if(varQuantity<data.quantity){
            alert('variance quantity is less then purchase quantity')
        }else{
           let varData = []
            varState.forEach(element => {
                let newQuantity = element.newQuantity ? element.newQuantity :0
                let stockAmmount = element.stockAmmount ? element.stockAmmount :0

                if (parseInt(newQuantity)>0) {
                    element = {...element, stockAmmount:(parseInt(newQuantity)+parseInt(stockAmmount))}
                    varData.push(element)

                }
            });
            let myUpdateArr = []
            tableData.forEach(element => {
                if (element.id===data.id) {
                    element = {...element, varianceManaged:1}
                    myUpdateArr.push(element)
                }else{
                    myUpdateArr.push(element)
                }
            });
            setTableData(myUpdateArr)
            await UpdatePurchaseItemData( data.id, {varianceManaged:1})
            varData.forEach(async element => {
                let mfinalData ={
                    purchase_item_id:data.id,
                    product_id:data.product_id,
                    varinace_id:element.id,
                    quantity:element.newQuantity,
                    price:data.price,
                    created_date:new Date().getTime(),
                }
             await SavePurchaseVariance(mfinalData)
            });
        setShowPop(false)
        }
    }
    function CloseMainpPop() {
       setShowPop(false)
    }
    async function getVarianceList() {
        let nestedData = await GetFilterProductVariance(`product_id = ${data.product_id}`)
        setVarState(nestedData)
    }
    useEffect(()=>{
        getVarianceList()
    },[])

    function checkClick(params) {

    }

    return (
        <>
            <div className={`${PurchaseConfirmCSs.editVariantData} ${PurchaseConfirmCSs.purchase_variance}`}>
                <h3 className={PurchaseConfirmCSs.varTitle}>Product : {data?.name}<br/> <span>(Order Quantity : {data?.quantity})</span> <span>[Variance Quantity : {varQuantity ? varQuantity:0}]</span></h3>
                <div className={PurchaseConfirmCSs.detailstableSection}>
                    <table>
                        <tbody>
                        <tr >
                            <th>Variant</th>
                            <th>Variaance Info</th>
                            <th>Quantity</th>
                        </tr>

                        {varState?.map((item, index) => {
                            return <tr key={index + 10}>
                                    <td>{item.Variance_name}</td>
                                    <td>{item.selectedVarUnit}</td>
                                    <td>{<input className={PurchaseConfirmCSs.tableinput} name={item.id} type='number' onChange={onVarQuantityChange} value={item.newQuantity ?item.newQuantity:0} />}</td>
                                </tr>
                        })}

                        </tbody>
                    </table>
                </div>
                <div className={PurchaseConfirmCSs.buttonClass}>
                    <button onClick={onUpdateClick}>Update</button>
                    <button onClick={CloseMainpPop}>close</button>
                    <button onClick={checkClick}>Check</button>
                </div>
            </div>
        </>
    )
}

const MyTable = ({ item, editVariance, price, qty, tammount }) => {

    return (
            <tr key={item}>
                <td className={PurchaseConfirmCSs.foreditVariance}>{item} {editVariance}</td>
                <td >{price}</td>
                {/* <td>{variations}</td> */}
                <td>{qty}</td>
                <td>{tammount}</td>
            </tr>
    )
}