import React, { useEffect, useState } from 'react'
import { GetDistrictList } from '../../../api/Address/DistrictApi';
import { GetDivisionList } from '../../../api/Address/DivisionApi';
import ViewDistrictCss from './css/ViewDistrict.module.css'

const ViewDistrict = () => {
    const [divisionArray, setDivitionArray] = useState()
    const [allDistrict, setAllDistrict] = useState([]);
    const [formatedData, setFormatedData] = useState([])
    async function getPrimaryAddress() {
        let division = await GetDivisionList()
        let sortArrDiv = division.sort((a, b) => (a.division_name > b.division_name) ? 1 : -1)
        setDivitionArray(sortArrDiv)
        let district = await GetDistrictList()
        let sortArrDis = district.sort((a, b) => (a.district_name > b.district_name) ? 1 : -1)
        setAllDistrict(sortArrDis)
        let tempArr=[]
        sortArrDiv.forEach(element => {
            let tempDis = []
            sortArrDis.forEach(delement => {
                if (element.division_id===delement.division_id) {
                    tempDis.push({district_name:delement.district_name, district_id:delement.district_id})
                }
            });
            let finalDatab ={
                division_id:element.division_id,
                division_name:element.division_name,
                districtArr:tempDis
            }
            tempArr.push(finalDatab)
        });
        
        //console.log(tempArr);
         setFormatedData(tempArr)
    }
    function onDivisionChange(e) {
        let tempArr=[]
        let value = parseInt(e.target.value)

        if (value) {
            divisionArray.forEach(element => {
                if (element.division_id===value) {
                    let tempDis = []
                    allDistrict.forEach(delement => {
                        if (element.division_id === delement.division_id) {
                            tempDis.push({ district_name: delement.district_name, district_id: delement.district_id })
                        }
                    });
                    let finalDatab = {
                        division_id: element.division_id,
                        division_name: element.division_name,
                        districtArr: tempDis
                    }
                    tempArr.push(finalDatab)
                }
            });
            
        }else{
            divisionArray.forEach(element => {
                let tempDis = []
                allDistrict.forEach(delement => {
                    if (element.division_id===delement.division_id) {
                        tempDis.push({district_name:delement.district_name, district_id:delement.district_id})
                    }
                });
                let finalDatab ={
                    division_id:element.division_id,
                    division_name:element.division_name,
                    districtArr:tempDis
                }
                tempArr.push(finalDatab)
            });
        }
        //console.log(tempArr);
         setFormatedData(tempArr)
    }

    useEffect(() => {
        getPrimaryAddress()
    }, [])
    return (
        <div className={ViewDistrictCss.view_district}>
            <select className={ViewDistrictCss.popOption} name={'Divition'} onChange={onDivisionChange} defaultValue={''}>
                <option value={""}>--Select Division--</option>
                {divisionArray?.map((item) => {
                    return <option key={item.division_id} value={item.division_id}>{item.division_name}</option>
                })}
            </select>
            <table>
                <thead>
                    <tr>
                        <th>sl</th>
                        <th>Division Name</th>
                        <th>District Name</th>
                    </tr>
                </thead>
                <tbody>
                    {formatedData?.map((item, index) => {
                        return <tr key={item.division_id+'d'}>
                            <td>{index + 1}</td>
                            <td>{item.division_name}</td>
                            <td style={{display:'flex', flexWrap:'wrap'}}>
                            {item.districtArr?.map((ditem)=>{
                                return <p className={ViewDistrictCss.district_name} key={ditem.district_id}>{ditem.district_name}</p>
                            })}</td>
                        </tr>
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default ViewDistrict