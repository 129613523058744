import React from 'react'
import SettingTextAreaCss from './css/SettingTextArea.module.css'

const SettingTextArea = (props) => {
    const { name, labeltext, placeholder, changeme, myValue } = props;
    return (
        <>
            <div className={SettingTextAreaCss.mt_2}>
                <div className={SettingTextAreaCss.mySetting}>
                    <div className={SettingTextAreaCss.innercom}>
                        <label className={SettingTextAreaCss.additemlabel}>{labeltext}</label>
                        <textarea name={name} placeholder={placeholder} onChange={changeme} value={myValue} cols="30" rows="5"></textarea>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingTextArea