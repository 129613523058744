import React, { useEffect, useState } from 'react'
import activityCss from './activity.module.css'
import { GetSummeryData } from '../../../api/OrderInformationApi'
import { ServerTimeToFormateTime } from '../../../api/GlobalFunctions'

async function getData(id, setData, item) {
    let staticData = {
        activity: "Customer Order Placed",
        created_date: item.created_date,
        ProssesedBy: '',
        ForwardTo: "",
        deliveryDate: "",
        note: ""
    }
    let myData = await GetSummeryData({id})
    myData.splice(0, 0, staticData)
    setData(myData)
}

const ActivityViewModel = ({popUp, setPopUp}) => {
    const [tableData, setTableData] = useState([])
    useEffect(()=>{
        getData(popUp.id, setTableData, popUp)
    },[popUp.id])

  return (
    <div className={activityCss.orderactivites_wrapper}>
    <div className={activityCss.holder}>
            <h3> Order Activities</h3>
        <div className={activityCss.orderactivites}>
        <h4> Order Activity History Of # {popUp.id}</h4>
        <table>
            <tbody>
                <tr>
                    <th>Sl</th>
                    <th>Activity</th>
                    <th>Entry Time</th>
                    <th>Processed By</th>
                    <th>Forward To</th>
                    <th>Delivery Date</th>
                    <th>Notes</th>
                </tr>
                {/* backendUser  item.ProssesedBy*/}
                {tableData.map((item, index) => {
                    return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.activity}</td>
                        <td>{ServerTimeToFormateTime(item.created_date)}</td>
                        <td>{item.userName}</td>
                        <td>{item.ForwardTo}</td>
                        <td>{item.deliveryDate}</td>
                        <td>{item.note}</td>
                    </tr>
                })}
            </tbody>
        </table>
        <div className={activityCss.btn_group}>
        <button  onClick={() => { setPopUp(); document.body.style.overflow = 'auto' }}>Close</button>
        </div>
        </div>
    </div>
</div>
  )
}

export default ActivityViewModel