import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}settings/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

const configfile = {
    headers:{
        'Content-Type': 'multipart/form-data'
    }
}
// site information
export async function GetSiteInformation() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}site-info`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function SaveSiteInformation(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}save-site-info`, data,  configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateSiteInformation(id, data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}update-site-info/${id}`, data,  configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
// Slider starts
export async function SaveSlideData(data, imagedata) {
    try {
        let image_name = ''
        if (imagedata) {
            const formData = new FormData();
            formData.append("myfile", imagedata);
            let resultImage = await MyAxios.post(`${MY_API_LINK}upload-slide-image`, formData, configfile);
            image_name = resultImage.data;
          }
        let result = await MyAxios.post(`${MY_API_LINK}save-slides`, {...data, image_name}, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetSlideData() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}get-slides`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateSlideData(id, data, imagedata) {
    
    try {
        let image_name = data.image_name
        if (imagedata) {
            const formData = new FormData();
            formData.append("myfile", imagedata);
            let resultImage = await MyAxios.post(`${MY_API_LINK}upload-slide-image`, formData, configfile);
            image_name = resultImage.data;
            await MyAxios.post(`${MY_API_LINK}delete-slide-image`, {image_name:data.image_name}, configdata);
          }
        let result = await MyAxios.put(`${MY_API_LINK}update-slides/${id}`, {...data, image_name}, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DeleteSliderData(id, image_name) {
    try {
        let result = await MyAxios.delete(`${API_LINK}slider-data/${id}`)
        if (image_name) {
            await MyAxios.post(`${MY_API_LINK}delete-slide-image`, {image_name}, configdata);
        }
        // console.log(id, image_name);
        return result  
    } catch (error) {
        console.log(error.message);
        return []
    }    
}
export async function UpdateSlideSLNO(id, data) {
    
    try {
        let result = await MyAxios.put(`${MY_API_LINK}update-slides/${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

//banner data api

export async function SaveBannerData(data, imagedata) {
    try {
        let image_name = ''
        if (imagedata) {
            const formData = new FormData();
            formData.append("myfile", imagedata);
            let resultImage = await MyAxios.post(`${MY_API_LINK}upload-slide-image`, formData, configfile);
            image_name = resultImage.data;
          }
        let result = await MyAxios.post(`${MY_API_LINK}save-banners`, {...data, image_name}, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetBannerData() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}get-banners`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function UpdateBannerData(id, data, imagedata) {
    
    try {
        let image_name = data.image_name
        if (imagedata) {
            const formData = new FormData();
            formData.append("myfile", imagedata);
            let resultImage = await MyAxios.post(`${MY_API_LINK}upload-slide-image`, formData, configfile);
            image_name = resultImage.data;
            await MyAxios.post(`${MY_API_LINK}delete-slide-image`, {image_name:data.image_name}, configdata);
          }
        let result = await MyAxios.put(`${MY_API_LINK}update-banners/${id}`, {...data, image_name}, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DeleteBannerData(id, image_name) {
    try {
        let result = await MyAxios.delete(`${MY_API_LINK}delete-banners/${id}`)
        await MyAxios.post(`${MY_API_LINK}delete-slide-image`, {image_name:image_name}, configdata);
        return result.data        
    } catch (error) {
        return 0
    }    
}

// popup offer-banner 
export async function SaveOfferBanner(data, imagedata) {
    try {
        let banner_image = ''
        if (imagedata) {
            const formData = new FormData();
            formData.append("myfile", imagedata);
            let resultImage = await MyAxios.post(`${MY_API_LINK}upload-offer-banner`, formData, configfile);
            banner_image = resultImage.data;
          }
        let result = await MyAxios.post(`${MY_API_LINK}save-offer-banner`, {...data, banner_image}, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateOfferBanner(id, data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}update-offer-pop/${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetOfferPopBanners() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}get-all-offer-pop`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DeleteOfferPop(id) {
    try {
        let result = await MyAxios.delete(`${MY_API_LINK}delete-offer-pop/${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}