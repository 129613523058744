import HeaderImage from '../../assets/Headerimage.jpg'
import HeadetFooterCss from './HeadetFooter.module.css'

export const HeaderFooterWrapper = ({content}) => {
    function PrintPaper() {
        window.print()
    }
    return (
        <div className={HeadetFooterCss.invoicemain}>
            <button className={HeadetFooterCss.printBtn} onClick={PrintPaper}>Print</button>
            <section className={`${HeadetFooterCss.invoiceSection} ${HeadetFooterCss.supplier_invoice}`}>
                <div className={HeadetFooterCss.container} id="printInvoice" >
                    <div className={HeadetFooterCss.topSection}>
                        <img src={HeaderImage} alt="" />
                    </div>
                    <div className={HeadetFooterCss.main}>
                        {content}
                    </div>
                </div>
            </section>
        </div>
    )
}