import SaleHisCss from './salesCss/SaleHistory.module.css'
import { faBagShopping, faBars, faCheck, faCircleInfo, faEdit, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchAllUser, selectBackUserList, selectBackUserStatus } from '../../ReduxFiles/Slices/UserSlice'
import { API_LINK } from '../../api/APILink'
import { ServerTimeToFormateTime } from '../../api/GlobalFunctions'
import { GetHistorySalesData } from '../../api/SalesApi'
import LoadingShow from '../smallThings/LoadingShow'
import SalesSearchComp from './SalesSearchComp'
import ActivityViewModel from './models/ActivityViewModel'
import CollectOption from './models/CollectOption'
import CustomerInformationModel from './models/CustomerInformationModel'
import DeliveryBoyAssign from './models/DeliveryBoyAssign'
import PackagingPop from './models/PackagingPop'


const SaleHistory = ({ mtableData }) => {
    const dispatch = useDispatch()
    const selectUserStatus = useSelector(selectBackUserStatus)
    const backendUser = useSelector(selectBackUserList)
    const [tableData, setTableData] = useState([])
    const [showUser, setShowUser] = useState(false)
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [showPackagingBox, setshowPackagingBox] = useState(false)
    const [packagingData, setPackagingData] = useState()
    const [deliveryBoyData, setDeliveryBoyData] = useState()
    const [activityView, setActivityView] = useState()

    async function customerClick(item) {
        setShowUser(item)
        document.body.style.overflow = 'hidden'
    }

    async function activityViewClick(e, item) {
        setActivityView(item)
        document.body.style.overflow = 'hidden'
    }

    const [deliveryDoneInfo, setDeliveryDoneInfo] = useState()
    function deliveryAgentClick(e, item) {
        setDeliveryDoneInfo(item)
        document.body.style.overflow = 'hidden'
    }



    function deliveryBoyAssign(e, item) {
        setDeliveryBoyData(item)
        document.body.style.overflow = 'hidden'
    }

    function PackagingClick(e, item) {
        setshowPackagingBox(true)
        setPackagingData(item)
    }

    async function getTableData() {
        try {
            setSpinnerShow(true)
            let historyData = await GetHistorySalesData()
            let sortArr = historyData?.sort((a, b) => (a.id > b.id) ? -1 : 1)
            setTableData(sortArr)
            setSpinnerShow(false)
        } catch (error) {
            setSpinnerShow(false)
        }
    }
    useEffect(() => {
        getTableData()
        if (selectUserStatus === 'idle') {
            dispatch(fetchAllUser())
        }
    }, [mtableData, deliveryDoneInfo])

    const statusSwitch = (value, item) => {
        let data = <></>
        switch (value) {
            case 'Order Confirmed':
                data = <div className={SaleHisCss.myStatus}>
                    <p className={SaleHisCss.confirmed}>{value}</p>
                </div>
                break;
            case 'Delivery Complete':
                data = <p className={SaleHisCss.complete}>{value}</p>
                break;
            case 'Packaging Complete':
                data = <div className={SaleHisCss.myStatus}>
                    <p className={SaleHisCss.packaging}>{value}</p>
                    <FontAwesomeIcon onClick={(e) => { deliveryBoyAssign(e, item) }} icon={faRightFromBracket} />
                </div>
                break;
            case 'Order Partial Return':
                data = <div className={SaleHisCss.myStatus}>
                    <p className={SaleHisCss.preturn}>{value}</p>
                    <FontAwesomeIcon onClick={(e) => { deliveryBoyAssign(e, item) }} icon={faRightFromBracket} />
                </div>
                break;
            case 'Delivery Agent Assigned':
                data = <div className={SaleHisCss.myStatus}>
                    <p className={SaleHisCss.deliveryboyassigned}>DA Assigned</p>
                    <FontAwesomeIcon onClick={(e) => { deliveryAgentClick(e, item) }} icon={faCheck} />
                </div>
                break;
            case 'Delivery Complete':
                data = <p className={SaleHisCss.deliveryHandover}>{value}</p>
                break;
            default:
                data = <>{value}</>
                break;
        }

        return data
    }

    // const TableItem = tableData?.filter((fitem) => {
    //     return !fitem.fullReturn ? fitem : ''
    // })
    //     .map((item) => {
    //         return <MyTable key={item.id}
    //             id={<Link to={`/admin/sales/salesdetails/${item.id}`}>{'#' + item.id}</Link>}
    //             date={DateFormate(item.created_date)}
    //             cname={item.name}
    //             cphone={item.aphone ? item.aphone : item.phone}
    //             caddress={item.address || 'pick up point'}
    //             tammount={((parseInt(item.total_price) + item.deliveryCharge) - (item.total_orprice * item.discountPercent / 100) - item.rprice).toFixed(2)}
    //             status={statusSwitch(item.status, item)}
    //             confirmedby={<div className={SaleHisCss.salesHistoruImagesec}>
    //                 {backendUser?.filter((fitem) => {
    //                     return item.CDby === fitem.id ? fitem : ''
    //                 })
    //                     .map((item) => {
    //                         return <React.Fragment key={item.id + 'user'}>
    //                             <img src={API_LINK + 'userimages/' + item.userImageName} alt={item.userImageName} />
    //                             {item.userName}
    //                         </React.Fragment>
    //                     })}

    //             </div>}
    //             action={
    //                 <div className={SaleHisCss.actionButton}>
    //                     {item.packaging ? "" : <a><FontAwesomeIcon onClick={(e) => { PackagingClick(e, item); document.body.style.overflow = 'hidden' }} icon={faBagShopping} /></a>}
    //                     <Link to={`/admin/sales/salesdetails/${item.id}`}><FontAwesomeIcon icon={faCircleInfo} /></Link>
    //                     <a onClick={(e) => { activityViewClick(e, item); document.body.style.overflow = 'hidden' }}><FontAwesomeIcon className={SaleHisCss.myBars} icon={faBars} /></a>
    //                     {item.packaging ? "" : <Link to={`/admin/sales/sale_history/sales_edit/${item.id}`}><FontAwesomeIcon className={SaleHisCss.myBars} icon={faEdit} /></Link>}
    //                 </div>} customerClick={() => { customerClick(item) }} />
    //     })

    return (
        <section className={SaleHisCss.salesMain}>

                {activityView ? <ActivityViewModel popUp={activityView} setPopUp={setActivityView} /> : ""}

                {showUser && <CustomerInformationModel popUp={showUser} setPopUp={setShowUser} />}

                {showPackagingBox ? <PackagingPop myPackagingData={packagingData} setshowPackagingBox={setshowPackagingBox} tableData={tableData} /> : ""}

                {deliveryBoyData ? <DeliveryBoyAssign deliveryBoyData={deliveryBoyData} setDeliveryBoyData={setDeliveryBoyData} tableData={tableData} /> : ""}
                {deliveryDoneInfo ? <CollectOption deliveryDoneInfo={deliveryDoneInfo} setDeliveryDoneInfo={setDeliveryDoneInfo} tableData={tableData} setTableData={setTableData} /> : ""}

                <div className={SaleHisCss.innerSale}>
                    <h2 className={SaleHisCss.saleHeading}>Sales History</h2>
                    <LoadingShow ShowLodign={spinnerShow} />
                    <SalesSearchComp passedFrom={'history'} setFilteredData={setTableData} />
                    <div className={SaleHisCss.tableSection}>
                        <table>
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>id</th>
                                    <th>Date</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Customer Address</th>
                                    <th>Total Ammount</th>
                                    <th>Status</th>
                                    <th>Confirmed By</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.filter((fitem) => !fitem.fullReturn && fitem)
                                    .map((item, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td><Link to={`/admin/sales/salesdetails/${item.id}`}>{'#' + item.id}</Link></td>
                                            <td>{ServerTimeToFormateTime(item.created_date)}</td>
                                            <td><span className={SaleHisCss.customerClick} onClick={() => customerClick(item)}>{item.name}</span></td>
                                            <td>{item.aphone ? item.aphone : item.phone}</td>
                                            <td>{item.address || 'pick up point'}</td>
                                            <td>{((parseInt(item.total_price) + item.deliveryCharge) - (item.total_orprice * item.discountPercent / 100) - item.rprice).toFixed(2)}</td>
                                            <td>{statusSwitch(item.status, item)}</td>
                                            <td>
                                            <span className={SaleHisCss.user_wrapper}>
                                            <img src={API_LINK + 'userimages/' + item.userImageName} alt={item.userImageName} />
                                                {item.userName}
                                            </span>
                                                </td>
                                            <td>
                                                <div className={SaleHisCss.actionButton}>
                                                    {item.packaging ? "" : <a><FontAwesomeIcon onClick={(e) => { PackagingClick(e, item); document.body.style.overflow = 'hidden' }} icon={faBagShopping} /></a>}
                                                    <Link to={`/admin/sales/salesdetails/${item.id}`}><FontAwesomeIcon icon={faCircleInfo} /></Link>
                                                    <a onClick={(e) => { activityViewClick(e, item); document.body.style.overflow = 'hidden' }}><FontAwesomeIcon className={SaleHisCss.myBars} icon={faBars} /></a>
                                                    {item.packaging ? "" : <Link to={`/admin/sales/sale_history/sales_edit/${item.id}`}><FontAwesomeIcon className={SaleHisCss.myBars} icon={faEdit} /></Link>}
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
    )
}

export default SaleHistory

const MyTable = ({ id, date, cname, cphone, caddress, tammount, status, confirmedby, action, customerClick }) => {
    return (
        <tr key={id} className={SaleHisCss.salesHistory_tr}>
            <td className={SaleHisCss.invoiceClick}>{id}</td>
            <td>{date}</td>
            <td className={SaleHisCss.customerClick} onClick={customerClick}>{cname}</td>
            <td>{cphone}</td>
            <td>{caddress}</td>
            <td>{tammount}</td>
            <td >{status}</td>
            <td>{confirmedby}</td>
            <td>{action}</td>
        </tr>

    )
}

