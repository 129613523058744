import React, { useState } from 'react'

const CSVReader = () => {
    const [csvFile, setCsvFile] = useState()
    const [csvData, setCsvData] = useState()
function handleFileUpload(e) {
    const file = e.target.files[0];
    setCsvFile(file)
   
}

function onReadClick() {
    if (csvFile && csvFile.type=== 'text/csv') {
        const reader = new FileReader();
    reader.onload = () => {
      const mcsvData = reader.result;
      const lines = mcsvData.split('\n');
      const data = [];
    //   console.log(lines);
      lines.forEach((line) => {
        const dataArray = line.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).map((item) => item.replace(/^"(.*)"$/, '$1'));
        const [status, time, from, to, sms, smsid, camid] = dataArray;

        let tempData = {
            status, time, from, to, sms, smsid, camid
        }
        data.push(tempData);
      });
      data.pop()
      data.shift()
      setCsvData(data)
   //  console.table(data);
    };

    reader.readAsText(csvFile);
    }else{
        alert('please upload a csv file')
        setCsvData([])
    }
    
}

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
      <button onClick={onReadClick}>Read File</button>
      <div>
        <table>
            <thead>
                <tr>
                    <th>SL</th>
                    <th>Status</th>
                    <th>Event Time</th>
                    <th>From No</th>
                    <th>To No</th>
                    <th>Sms</th>
                    <th>SMSID</th>
                    <th>Campaign ID</th>
                </tr>
            </thead>
            <tbody>
                {csvData?.map((item, index)=>{
                    return <tr key={item.smsid+index}>
                    <td>{index+1}</td>
                    <td>{item.status}</td>
                    <td>{item.time}</td>
                    <td>{item.from}</td>
                    <td>{item.to}</td>
                    <td>{item.sms}</td>
                    <td>{item.smsid}</td>
                    <td>{item.camid}</td>
                </tr>
                })}
                
            </tbody>
        </table>
      </div>
    </div>
  )
}

export default CSVReader

/*
const data = 'Success,24/06/23 01:55,8801810180099,8801726904044,"Dear Sir/Madam, We have received your order. Your Order Number is : 1467. We will let you know when the order is confirmed. Thank You Anayase 01810169101",a8fcd7ef,1094113';

// Split the data by comma (,) and remove any surrounding quotes (") if present
const dataArray = data.split(',').map((item) => item.replace(/^"(.*)"$/, '$1'));

// Extract the individual values from the dataArray
const [status, date, sender, receiver, message, code, id] = dataArray;

// Create a JSON object using object assignment
const jsonObject = {
  status,
  date,
  sender,
  receiver,
  message,
  code,
  id,
};

console.log(jsonObject);


*/