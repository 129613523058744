import React from 'react'
import Item_menu from './Item_menu'
import ListItem from './ListItem'
import { Route, Routes } from 'react-router-dom'
import AddNewItemEdit from './listItemComponents/EditItem/AddNewItemEdit'
import AddNewItem from './AddNewItem'
import Item_catagory from './Item_catagory'
import BrandList from './BrandList'
import ManageTag from './ManageTag'
import Atributes from './Atributes'
import Units from './Units'


const ItemManagement = () => {

    return (
        <>           
            <Item_menu />
            <Routes>
                <Route path='/' element={<ListItem/>} />
                <Route path='/itemlist' element={<ListItem />} />
                <Route path='itemlist/:data' element={<AddNewItemEdit />} />
                <Route path='additem' element={<AddNewItem />} />
                <Route path='itemcat' element={<Item_catagory />} />
                <Route path='brands' element={<BrandList />} />
                <Route path='managetag' element={<ManageTag />} />
                <Route path='atributes' element={<Atributes />} />
                <Route path='unit' element={<Units />} />
            </Routes>

        </>
    )
}

export default ItemManagement
