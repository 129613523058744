import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import StockDetails from './StockDetails'
import StockRequest from './StockRequest'
import StockInfoMainCss from './css/StockInfoMain.module.css'



const StockInfoMain = () => {
    const myParams = useParams()
    
    return (
        <> 
            <div className={StockInfoMainCss.menupart}>
                <ul className={StockInfoMainCss.menuitems}>
                <NavLink to={"/admin/stockinformation/details"}>
                        <li >Stock Details</li>
                    </NavLink>

                    <NavLink to={"/admin/stockinformation/request"}>
                        <li >Stock Request</li>
                    </NavLink>
                </ul>
            </div>
            <div>

                {myParams.mstock === undefined ? <StockDetails /> : ""}
                {myParams.mstock === 'details' ? <StockDetails data={"details"}/> : ""}
                {myParams.mstock === 'request' ? <StockRequest /> : ""}
            </div>
        </>
    )
}

export default StockInfoMain


const ComingSoon =({data})=>{
    return (
        <h1>Coming Soon {data}</h1>
    )
}