import packagingCss from './packaging.module.css'
import { useSelector } from 'react-redux'
import LoadingShow from '../../smallThings/LoadingShow'
import React, { useEffect, useState } from 'react'
import { selectUserInfo } from '../../../ReduxFiles/Slices/UserSlice'
import { GetSingleProductVarianceName, SaveSummeryData, SaveTrackingData } from '../../../api/OrderInformationApi'
import { DecreaseProductStock, DecreaseVarianceStock, GetFilterProductStock } from '../../../api/StockApi'
import { UpdateOrderData } from '../../../api/SalesApi'
const PackagingPop = ({ myPackagingData, setshowPackagingBox, tableData }) => {
    const userIfno = useSelector(selectUserInfo)
    const [orders, setOrders] = useState([])
    const [showLoading, setShowLoading] = useState(false)
    async function getOrders() {
        let myOrders = await GetSingleProductVarianceName({ id: myPackagingData.id })
        organizeData(myOrders)
    }

    function organizeData(myData) {
        let finalArr = []
        let uniqueElements = myData.slice()
        for (let index = 0; index < myData.length; index++) {
            const element = myData[index];
            let tempArr = []
            for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                const melement = uniqueElements[mindex];
                if (melement.product_id === element.product_id) {
                    tempArr.push(melement)
                }
            }

            if (tempArr.length > 0) {
                tempArr.forEach(element => {
                    uniqueElements.forEach((melement, mindex) => {
                        if (melement.id === element.id) {
                            uniqueElements.splice(mindex, 1)
                        }
                    });
                });
                finalArr.push(tempArr)
            }
        }
        setOrders(finalArr)
    }

    async function onUpdateClick() {
        setShowLoading(true)
        let newFormat = []
        orders.forEach(element => {
            element.forEach(melement => {
                newFormat.push(melement)
            });
        });
        let stockResult = await checkStockQuantity(newFormat)
        if (!stockResult.isOK) {
            alert(stockResult.text)
        } else {
            newFormat.forEach(async (element) => {
                await DecreaseProductStock({ product_id: element.product_id, stock_quantity: element.quantity })
                if (element.hasVriance) {
                    await DecreaseVarianceStock({ variance_id: element.variance_id, stock_quantity: element.quantity })
                }
            });
            let nestedData = {
                activity: 'Proceed To packaging',
                sale_id: myPackagingData.id,
                ProssesedBy: userIfno.id,
                ForwardTo: "",
                deliveryDate: "",
                note: "",
                created_date: new Date().getTime()
            }
            await SaveSummeryData(nestedData)
            let trackData = {
                sales_id: myPackagingData.id,
                created_date: new Date().getTime(),
                note: 'Your Order Packaging is Complete.',
            }
            await SaveTrackingData(trackData)
            await UpdateOrderData(myPackagingData.id, { status: "Packaging Complete", confirmed: 0, packaging: 1 })
            let findIndex = tableData.findIndex(obj => parseInt(obj.id) === parseInt(myPackagingData.id))
            tableData[findIndex] = {
                ...tableData[findIndex],
                status: "Packaging Complete", confirmed: 0, packaging: 1
            }
            setshowPackagingBox(false)
            document.body.style.overflow = 'auto'
            setShowLoading(false)
        }

    }

    async function checkStockQuantity(passedData) {
        let text = '';
        let isOK = true;
        let myPromises = passedData.map(async (item) => {
            let result = await GetFilterProductStock(`product_id = ${item.product_id}`)
            if (result.length > 0) {
                result.forEach(element => {
                    if (element.stock_quantity < item.quantity) {
                        text += `${item.name} is stock out \n`
                        isOK = false
                    }
                })
            } else {
                text += `${item.name} (${item.product_id}) is stock out \n`
                isOK = false
            }
        })
        return Promise.all(myPromises).then(() => {
            let data = {
                text,
                isOK,
            }
            return data
        })
    }


    function oncloseClick() {
        document.body.style.overflow = 'auto'
        setshowPackagingBox(false)
    }

    useEffect(() => {
        getOrders()
    }, [myPackagingData])

    function getQTotal(item) {
        let total = 0
        item?.forEach(element => {
            total += element.quantity
        });

        return total;
    }
    const myhrStyle = {
        margin: "3px 0"
    }
    const VarianceName = ({ item }) => {
        let data = item?.map((mitem, index) => {
            return <span key={index + 10}>
                <p  >{mitem.vrName ? `${mitem.vrName} : ${mitem.quantity}` : ''}  { }</p>
                <hr style={myhrStyle} ></hr>
            </span>

        })
        return <>{data}</>
    }
    
    return (
        <div className={packagingCss.wrapper}>
            <LoadingShow ShowLodign={showLoading} message={'Processing your data...'}/>
            <div className={packagingCss.holder}>
                <h3>Proceed To Packaging</h3>
               <div className={packagingCss.info_holder}>
                <div className={packagingCss.info_section}>
                    <p>Order No : # {myPackagingData?.id}</p>
                    <p>Customer Name : {myPackagingData?.name}</p>
                    <p>Phone : {myPackagingData?.aphone || myPackagingData.phone}</p>
                </div>
               <div className={packagingCss.table_holder}>
               <table>
                    <thead>
                        <tr>
                            <th>sl</th>
                            <th>Item</th>
                            <th>Variations</th>
                            <th>Quantity</th>
                        </tr>
                        {orders?.map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item[0].mainName ? item[0]?.mainName : item[0].name}</td>
                                <td>{<VarianceName item={item} />}</td>
                                <td>{getQTotal(item)}</td>
                            </tr>
                        })}

                    </thead>
                </table>
               </div>
                <div className={packagingCss.buttonsection}>
                    <button onClick={oncloseClick}>Close</button>
                    <button onClick={onUpdateClick}>Update</button>
                </div>
               </div>
            </div>
        </div>
    )
}


export default PackagingPop