import React from 'react'
import profileCss from './tabview.module.css'
import DummyImage from '../../../../assets/dummyuser.png'
import { API_LINK } from '../../../../api/APILink'
const ProfileTab = ({popUp, setPopUp}) => {

    function ClosePop() {
        setPopUp()
        document.body.style.overflow = 'auto'
    }
  return (
    <div className={profileCss.profile_holder}>
        <div className={profileCss.profile}>
          <img name='userImage' src={popUp.userImageName ? API_LINK + 'userimages/' + popUp.userImageName : DummyImage} alt='user placeholder here' />
        </div>
        <div className={profileCss.info}>
            <div className={profileCss.info_inner}>
                <p>Name</p>
                <p>: {popUp.name}</p>
            </div>
            <div className={profileCss.info_inner}>
                <p>Phone</p>
                <p>: {popUp.phone}</p>
            </div>
            <div className={profileCss.info_inner}>
                <p>Email</p>
                <p>: {popUp.email || '-'}</p>
            </div>
            <div className={profileCss.info_inner}>
                <p>About</p>
                <p>: {popUp.userbio || '-'}</p>
            </div>
        </div>
        <div className={profileCss.close_btn}>
          <button onClick={ClosePop}>Close</button>
        </div>
      </div>
  )
}

export default ProfileTab