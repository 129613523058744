import React, { useEffect, useState } from 'react'
import { ApiLink } from '../../api/ExpressFunctions';
import { useNavigate } from 'react-router-dom';
import { GetFilterProductVariance } from '../../api/ProductVarianceApi';
import ProductListingCss from './Css/ProductListing.module.css'

const ProductListing = () => {
    const [allProducts, setAllProducts] = useState(JSON.parse(window.localStorage.getItem('allProduct')))
    const [selectedItem, setSelectedItem] = useState()
    let localList = window.localStorage.getItem('listedItem')
    const [ListedItem, setListeItem] = useState(localList ? JSON.parse(localList):[]) // 
    const [searchText, setSearchText] = useState('')

    function onSearchChange(e) {
        let value = e.target.value
        setSearchText(value)
    }

    function onItemClick(item) {
        let itemFound = false
        // console.log(ListedItem);
        ListedItem.forEach(lelement => {
            if (lelement.id === item.id) {
                itemFound = true
            }
        });

        if (!itemFound) {
            allProducts.forEach(async element => {
                if (element.id === item.id) {
                    let variance = []
                    if (element.hasVariance) {
                     variance = await GetFilterProductVariance(`product_id = ${element.id}`)
                    }
                    setSelectedItem({ product: element, variance: variance })
                }
            });
        } else {
            alert('Product Already Exist in this list')
        }
    }
    function onItemRemove(item) {
        let tempArr = []
        ListedItem?.forEach(element => {
            if (element.id!==item.id) {
                tempArr.push(element)
            }
        });
        setListeItem(tempArr)
        window.localStorage.setItem('listedItem', JSON.stringify(tempArr))
    }
    function createPDFClick() {
        window.open('product-listing/view')
    }

    return (
        <div className={ProductListingCss.main_section}>
            {selectedItem ? <ItemPopup product={selectedItem} setSelectedItem={setSelectedItem} setListeItem={setListeItem} ListedItem={ListedItem} /> : ''}
            <div className={ProductListingCss.product_part}>
                <input type='text' placeholder='search product' className={ProductListingCss.search_input} onChange={onSearchChange} value={searchText}/>
               <div>
                 <table>
                    <thead>
                        <tr>
                            <th>sl</th>
                            <th>name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allProducts?.filter((fitem)=>{
                            let filterItem = fitem.en_sort_title?.toLowerCase()
                            let msearchText = searchText?.toLowerCase()
                            return filterItem.includes(msearchText) ? fitem:''
                        })                       
                        .map((item, index) => {
                            return <tr key={item.id+'p'} className={ProductListingCss.item_select} onClick={()=>{(onItemClick(item))}}>
                                <td>{index+1}</td>
                                <td>{item.en_sort_title}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
               </div>
            </div>
            <div className={ProductListingCss.selected_section}>
                <div>
                    <button onClick={createPDFClick}>Create PDF</button>
                    <table>
                        <thead>
                            <tr>
                                <th>sl</th>
                                <th>image</th>
                                <th>name</th>
                                <th>Special Price</th>
                                <th>Ready Stock Quantity</th>
                                <th>Delivery Lead Time</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ListedItem?.map((item, index) => {
                                return <tr key={item.id + 'l'}>
                                    <td>{index + 1}</td>
                                    <td><img src={ApiLink+'productimages/'+ item.imageName}/></td>
                                    <td>{item.name}</td>
                                    <td>{item.dicountPrice?.toFixed(2)}</td>
                                    <td>{item.stock}</td>
                                    <td>{item.lead_date}</td>
                                    <td><button onClick={()=>{onItemRemove(item)}}>X</button></td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ProductListing


const ItemPopup = ({ product, setSelectedItem, setListeItem, ListedItem }) => {
    const [myProduct, setMyProduct] = useState(product.product)
    let sortArr = product?.variance?.sort((a, b) => (parseInt(a.Variance_name) > parseInt(b.Variance_name)) ? 1 : -1)
    // console.log(sortArr, product?.variance);
    const [myVariance, setMyVariance] = useState(sortArr || [])
    const [SelectedVariance, setSelectedVariance] = useState(myVariance || [])
    const [others_info, setOthers_info] = useState()
    const [errMessage, setErrmessage] = useState()
    //  console.log(myVariance);

    function onInputChange(e) {
        let name = e.target.name
        let value = e.target.value
        setOthers_info({ ...others_info, [name]: value })
    }

    function onVariaceSelect(e) {
        let value = e.target.value
        let tempArr = [...SelectedVariance]
        let itemFound = false
        if (value) {
            tempArr.forEach(element => {
                if (element.Variance_name === value) {
                    itemFound = true
                    alert('variance already selected')
                }
            });
            if (!itemFound) {
                let tempItem = {}
                myVariance.forEach(element => {
                    if (element.Variance_name === value) {
                        tempItem = element
                    }
                })

                tempArr.push(tempItem)
                setSelectedVariance(tempArr)
            }
        }
    }
    function onAddtoList() {

        let varianceErr = ''
        if (myProduct.hasVariance) {
            varianceErr = SelectedVariance.length > 0 ? '' : 'You Must Select A Variance'
        }
        let discountErr = ValidateFunction(others_info, 'discount', 'Discount ')
        // let stockErr = ValidateFunction(others_info, 'stock', 'Ready Stock ')
        // let leadErr = ValidateFunction(others_info, 'lead_date', 'Delivery Lead Time ')
        setErrmessage({ discountErr, varianceErr,  }) //leadErr, stockErr
        if (!discountErr && !varianceErr) { //&& !leadErr && !stockErr
            let dicountPrice = 0
            let tempDiscount = myProduct.Selling_price- myProduct.Selling_price*parseFloat(others_info.discount)/100
            // console.log(tempDiscount);
            let tempDicount2 = tempDiscount - tempDiscount*parseFloat(others_info.discount)/100
            dicountPrice = tempDicount2 || 0
            let finalData = {
                id: myProduct.id,
                name: myProduct.en_sort_title,
                imageName: myProduct.itemImageName,
                variance: SelectedVariance,
                price:myProduct.Selling_price,
                dicountPrice,
                ...others_info,
            }
            let tempList = ListedItem
            tempList.push(finalData)
            setListeItem(tempList)
            window.localStorage.setItem('listedItem', JSON.stringify(tempList))
            setSelectedItem()
            setMyProduct()
            setMyVariance()
            // console.log(1);
        }
        // console.log(discountErr, stockErr, leadErr, varianceErr);


    }

    function ValidateFunction(dataset, field, message) {
        let text = ""
        if (!dataset?.[field]) {
            text = message + "is Required"
        }
        return text;
    }

    function onRemoveClick(item) {
        let tempArr = []
        SelectedVariance.forEach(element => {
            if (element !== item) {
                tempArr.push(element)
            }
        });
        setSelectedVariance(tempArr)
    }

    function onCancelClick() {
        setSelectedItem()
    }

    return (
        <div className={ProductListingCss.pop_up}>
            <h2>Select Product Options</h2>
            <h3>{myProduct?.en_sort_title}</h3>
            {myProduct.hasVariance ?
                // <Select2 datalist={myVariance} identity={'Variance_name'} placeHolder={'select Variance'} showValue={'Variance_name'} onChange={onVariaceSelect}/>
                <>
                    <div className={ProductListingCss.input_holder}>
                        <label>Select Variance</label>
                        <select onChange={onVariaceSelect}>
                            <option value={''}>Select Variance</option>
                            {myVariance?.map((item) => {
                                return <option key={item.id} value={item.Variance_name}>{item.Variance_name}</option>
                            })}
                        </select>
                    </div>

                    {errMessage?.varianceErr ? <p className={ProductListingCss.err_message}>{errMessage?.varianceErr}</p> : ''}
                </>
                : ''
            }
            <div className={ProductListingCss.varinave_items}>
                {SelectedVariance?.map((item) => {
                    return <div key={item.id} className={ProductListingCss.varinave_item}>
                        <p >{item.Variance_name} <span onClick={() => { onRemoveClick(item) }}>X</span></p>
                    </div>
                })}
            </div>

            <div className={ProductListingCss.input_holder}>
                <label>Discount Percent</label>
                <input type='number' placeholder='discount percent' name='discount' onChange={onInputChange} />
            </div>
            {errMessage?.discountErr ? <p className={ProductListingCss.err_message}>{errMessage?.discountErr}</p> : ''}
            {/* <div className={ProductListingCss.input_holder}>
                <label>Ready Stock Quantity</label>
                <input type='number' placeholder='Ready Stock Quantity' name='stock' onChange={onInputChange} />
            </div> */}
            {/* {errMessage?.stockErr ? <p className={ProductListingCss.err_message}>{errMessage?.stockErr}</p> : ''} */}
            {/* <div className={ProductListingCss.input_holder}>
                <label>Delivery Lead Time</label>
                <input type='date' name='lead_date' onChange={onInputChange} required />
            </div> */}
            {/* {errMessage?.leadErr ? <p className={ProductListingCss.err_message}>{errMessage?.leadErr}</p> : ''} */}

            <div className={ProductListingCss.button_section_others}>
                <button onClick={onCancelClick}>cancel</button>
                <button onClick={onAddtoList}>Add to List</button>
            </div>
        </div>
    )
}