import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import { GetAllDataExpress, GetFilteredDataExpress, GetSingleDocExpress } from '../../api/ExpressFunctions';
import { GetAllLocalProducts } from '../MyFunctions/MyFunctions';
import ViewOnlyReturnCss from './css/ViewOnlyReturn.module.css';
const ViewOnlyReturn = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const myParams = useParams()
    const [tableData, setTableData] = useState()
    const [orderData, setOrderData] = useState()
    const myNavigation = useNavigate()
    
   async function onPrintInvoice() {
        delete orderData.returnItems
        

        let finalData = {...orderData, purchaseItems:tableData}
        console.log(finalData)
         window.localStorage.setItem('PoInvoiceData', JSON.stringify(finalData))
       //'/admin/purchase/purchasehistory/'+item.id+'/view'
      // myNavigation(`/admin/purchase/${myParams.mpurchase}/${myParams.poid}/poinvoice`)
      // console.log(myParams) 
    }

    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    function convertDateCustom(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    const [supplierData, setSupplierData] = useState()
    async function getAllData() {
        let mAllProducts = GetAllLocalProducts()
       let myData = await GetSingleDocExpress('purchase', myParams.poid) 
       // console.log(myData)
       let mySupplier = await GetAllDataExpress('supplier')
       setSupplierData(mySupplier)
       let myNestedData = await GetFilteredDataExpress('purchase_items', 'purchase_id', myParams.poid)
       console.log(myNestedData)
       let returnItems = []

       let total = 0
       let finalData = {
           ...myData[0]
       }
       let myPayData = await GetFilteredDataExpress('debit_voucher', 'identity', myParams.poid)

       for (let index = 0; index < myPayData?.length; index++) {
           const pelement = myPayData[index];
           if (pelement.identity === parseInt(myParams.poid)) {
               total = pelement.paymentAmmount
           }

       }

       setOrderData({...finalData, payAmmount:total})


       let rPromises = [];
       if (myData[0].fullReturn===1 || myData[0].partialReturn===1) {
          rPromises =  myNestedData?.map(async (item)=>{
           let mitems = await GetFilteredDataExpress('purchase_return_items', 'purchase_item_id', item.id)
           returnItems.push(...mitems)
       }) 
       }
     
       //product_id
       let myArr = []
       Promise.all(rPromises).then(()=>{
           let tempArr = []
           myNestedData.forEach(element => {
               for (let index = 0; index < mAllProducts.length; index++) {
                   const pelement = mAllProducts[index];
                   if (element.product_id===pelement.id) {
                       element = {
                           ...element,
                           name:pelement.en_sort_title
                       }
                       tempArr.push(element)
                       break
                   }
               }
           });
           tempArr?.forEach(element => {
               let match = false
               for (let index = 0; index < returnItems?.length; index++) {
                   const relement = returnItems[index];
                   if (relement.product_id===element.product_id) {
                       element = {
                           ...element,
                           quantity:relement.quantity
                       }
                       myArr.push(element)
                       match  = true
                       break
                   }
                   
               }
               if (!match) {
                   myArr.push(element)
               }
           });
           setTableData(myArr)
       })
   }

    useEffect(() => {
        getAllData()
       // console.log(searchParams.get('item_index'))
    }, [myParams.orderid])
    
    function getItemTotal(mtabelData){
        let total = 0
        mtabelData?.map((item)=>{
            total += item.quantity * item.price
        })

        return total
    }
    const TableItem = tableData?.map((item, index) => {
        return <>
            <MyTable key={index} item={item?.name}
            price={item.price } qty={item.quantity}
            tammount={<>{!orderData?.fullReturn ? parseInt(item.price)*parseInt(item.quantity):"full return"}</>} />
        </>
    })
    return (
        <>
            <div>
                <section className={ViewOnlyReturnCss.salesMain}>
                    <div className={ViewOnlyReturnCss.innerSale}>
                        <div className={ViewOnlyReturnCss.gobackSec}>
                            <h2 className={ViewOnlyReturnCss.saleHeading}>PO Return History Details</h2>
                            {orderData?.fullReturn ? <h2 className={`${ViewOnlyReturnCss.saleHeading} color-red`}>PO Full Return</h2>:""}
                            <NavLink to={'/admin/purchase/purchasereturnhistory'}><button>Go Back</button></NavLink>
                        </div>
                        <div className={ViewOnlyReturnCss.orderdetaislInner}>
                            <h3>PO No. #{orderData?.id}</h3>
                            <div className={ViewOnlyReturnCss.customerSection}>
                                <div>
                                <p className={ViewOnlyReturnCss.highlighter}>Supplier Details</p>
                                    {supplierData?.filter((fitem)=>{
                                        return fitem.id === orderData?.supplierID ? fitem : ''
                                    }).map((item)=>{
                                        return <span>-
                                        <p><strong>Company Name</strong> : {item?.companyaName}</p>
                                        <p><strong>Phone</strong> : {item?.phone}</p>
                                        <p><strong>Division</strong> :{item?.Divition}</p>
                                        <p><strong>District</strong> : {item?.District}</p>
                                        <p><strong>area</strong> : {item?.Area}</p>
                                    </span>
                                    })}
                                </div>
                                <div>
                                    <p className={ViewOnlyReturnCss.highlighter}>PO Details</p>
                                    <p>PO Date : {convertDate(orderData?.created_date)} </p>
                                    <p>Payment Method : {orderData?.paymentMethod}</p>
                                </div>
                            </div>
                            <div className={`${ViewOnlyReturnCss.tableSection} ${ViewOnlyReturnCss.detailstableSection}`}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Item</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total Ammount</th>
                                    </tr>
                                    {TableItem}
                                    </tbody>
                                </table>

                                <div className={ViewOnlyReturnCss.tableDetails}>
                                 {<p>Return Ammount : {getItemTotal(tableData)} tk</p>}

                                {orderData?.percent ? <p>Discount ({orderData?.percent}%) :   
                                    {' ' +Math.round(getItemTotal(tableData)*orderData?.percent/100)
                                    } tk
                                </p>:<></>}

                                    <p>Total Ammount :  {Math.round(getItemTotal(tableData)- (orderData?.percent ? (getItemTotal(tableData)*orderData?.percent/100):0))} tk</p>
                                    {/* <p>Paid : {orderData?.payAmmount ? orderData?.payAmmount : "0"} tk</p> */}
                                    {/* <p>Due Ammount : {orderData?.dueAmmount} tk</p> */}
                                </div>
                                
                                <div className={ViewOnlyReturnCss.detailsWrapper}>
                                    <div className={`${ViewOnlyReturnCss.detailInput} ${ViewOnlyReturnCss.salesDetail}`}> 
                                        <div className={ViewOnlyReturnCss.ButtonGroup}>
                                        {/* <button onClick={onPrintInvoice}>Print Invoice</button> */}
                                        <Link to={`/admin/purchase/${myParams.mpurchase}/${myParams.poid}/returnpoinvoice`} target='_blank' rel="noopener noreferrer"><button onClick={onPrintInvoice}>Print Invoice</button></Link>
                                            {/* <button onClick={onPrintInvoice}>Print Invoice</button>  */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default ViewOnlyReturn

const MyTable = ({ item, editVariance, price, qty, tammount}) => {

    return (
        <>
            <tr>
                <td className={ViewOnlyReturnCss.foreditVariance}>{item} {editVariance}</td>
                <td >{price}</td>
                <td>{qty}</td>
                <td>{tammount}</td>
            </tr>
        </>
    )
}