import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import AddnewAgent from './AddnewAgent'
import DeliveryAgentList from './DeliveryAgentList'
import DeliveryAssinedList from './DeliveryAssinedList'
import DeliveryAgentMainCss from './css/DeliveryAgentMain.module.css'
const DeliveryAgentMain = () => {
    const myParams = useParams()
    return (
        <>
            <div className={DeliveryAgentMainCss.menupart}>
                <ul className={DeliveryAgentMainCss.menuitems}>
                    <NavLink to={"/admin/deliveryagent/agentlist"}>
                        <li >Delivery Agent List</li>
                    </NavLink>
                    <NavLink to={"/admin/deliveryagent/deliveryassignmentlist"}>
                        <li >Delivery Assignment List</li>
                    </NavLink>

                    <NavLink to={"/admin/deliveryagent/addnewagent"}>
                        <li >Add New Delivery Agent</li>
                    </NavLink>

                </ul>
            </div>
            <div>
                {myParams.agentdata === undefined ? <DeliveryAgentList /> : ""}
                {myParams.agentdata === 'agentlist' ? <DeliveryAgentList /> : ""}
                {myParams.agentdata === "addnewagent" ? <AddnewAgent /> : ""}
                {myParams.agentdata === "deliveryassignmentlist" ? <DeliveryAssinedList /> : ""}
                {/* {myParams.salesData === "sale_history" ? <SaleHistory mtableData={tableData}/> : ""}
        {myParams.salesData === "decline_orders" ? <DeclinedOrders mtableData={tableData}/> : ""} */}

                {/* {myParams.msetting === "slides" ? <ManageSlides /> : ""} */}

            </div>
        </>
    )
}

export default DeliveryAgentMain

const ComingSoon = () => {
    return (
        <>
            <h1>Coming Soon</h1>
        </>
    )
}