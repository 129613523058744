
import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import ManageBanners from './ManageBanners'
import ManageSlides from './ManageSlides'
import WebsiteSetting from './WebsiteSetting'
import SettingMainCss from './css/SettingMain.module.css'
import OfferPopup from './OfferPopup'

const SettingMain = () => {
    const myParams = useParams()
    //console.log(myParams.msetting)
    return (
        <>
            <div className={SettingMainCss.menupart}>
                <ul className={SettingMainCss.menuitems}>
                    <NavLink to={"/admin/setting/websetting"}>
                        <li >Website Settings</li>
                    </NavLink>
                    <NavLink to={"/admin/setting/slides"}>
                        <li >Manage Slieds</li>
                    </NavLink>
                    <NavLink to={"/admin/setting/banners"}>
                        <li >Manage Banners</li>
                    </NavLink>
                    <NavLink to={"/admin/setting/offer-popup"}>
                        <li >Offer Popup</li>
                    </NavLink>
                    <NavLink to={"/admin/setting/changepass"}>
                        <li >Change Password</li>
                    </NavLink>
                </ul>
            </div>
            <div>
                {myParams.msetting === undefined ? <WebsiteSetting /> : ""}
                {myParams.msetting === "websetting" ? <WebsiteSetting /> : ""}

                {myParams.msetting === "slides" ? <ManageSlides /> : ""}
                {myParams.msetting === "banners" ? <ManageBanners /> : ""}
                {myParams.msetting === "offer-popup" ? <OfferPopup /> : ""}

            </div>


        </>
    )
}

export default SettingMain