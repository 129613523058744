import { NavLink, useParams } from 'react-router-dom'
import AddNewBank from './AddNewBank'
import BankList from './BankList'
import ManageCheque from './ManageCheque'
import BankMainCss from './css/BankMain.module.css'

const BankMain = () => {
    const myParams = useParams()
    return (
        <> 
            <div className={BankMainCss.menupart}>
                <ul className={BankMainCss.menuitems}>
                    <NavLink to={"/admin/banks/bank_list"}>
                        <li >Bank List</li>
                    </NavLink>
                    <NavLink to={"/admin/banks/addbanks"}>
                        <li >Add New Bank</li>
                    </NavLink>
                    <NavLink to={"/admin/banks/manage_cheque"}>
                        <li >Manage Cheque</li>
                    </NavLink>
                </ul>
            </div>
            
            <div>
                {myParams.mybanks === undefined ? <BankList /> : ""}
                {myParams.mybanks === 'bank_list' ? <BankList data={"Banks"}/> : ""}
                {myParams.mybanks === 'addbanks' ? <AddNewBank data={"add banks"}/> : ""}
                {myParams.mybanks === 'manage_cheque' ? <ManageCheque data={"manage cheque"}/> : ""}
            </div>
        </>
    )
}

export default BankMain
