import LogintoDashboardCss from './css/LogintoDashboard.module.css'

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LoginBG from '../../assets/loginbgok.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons'
import CryptoJS from "crypto-js";
import { ENCRYPTION_KEY } from '../../api/APILink'
import { LoginUser } from '../../api/BackendUserApi'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { selectIsLogedIn, setIsLogedIn, setUserInfo } from '../../ReduxFiles/Slices/UserSlice'

const LogintoDashboard = () => {
    const isLogedIn = useSelector(selectIsLogedIn)
    const myDispatch = useDispatch()
    const myNavigation = useNavigate()
    const [alertMessage, setAlertMessage] = useState()
    const [logingText, setLoginText] = useState({
        username: "",
        password: ""
    })
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setLoginText({ ...logingText, [name]: value })
    }

    function onEnterPress(e) {
        if (e.key === "Enter") {
            LoginClick()
        }
    }
    async function LoginClick(e) {
        e.preventDefault()
        let username = logingText.username
        let password = logingText.password 
        if (!username || !password) {
            toast.warn('username & password required')
            //alert('user name & password required')
        } else {
               const userData =  await LoginUser({username, password})
               if (userData.isMatch) {
                const toastId = toast.loading('Checking User! please Wait...')
                const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userData.userData[0]), ENCRYPTION_KEY).toString();
                 myDispatch(setUserInfo(userData.userData[0]))
                 myDispatch(setIsLogedIn(true))
                window.localStorage.setItem('userData', encryptedData)
                myNavigation('/')
                toast.update(toastId, { render: "User Logedin! Welcome to anayase.", type: "success", isLoading: false, autoClose:3000, closeOnClick:true})
               }else{
                toast.warn(userData.message)
               }
        }
    }
    useEffect(() => {
        if (!isLogedIn) {
            myNavigation('/login')
        }
    }, [isLogedIn])

    return (
        <>
            {alertMessage ? <AlertMessage message={alertMessage} setAlertMessage={setAlertMessage} /> : ""}
            <section className={LogintoDashboardCss.login}>

                {/* <CheckLogin /> */}
                <div className={LogintoDashboardCss.container}>
                    <img src={LoginBG} />

                    <form className={LogintoDashboardCss.main} onSubmit={LoginClick}>
                        <h3>Welcome to Anayase</h3>
                        <span>Login to Continue</span>
                        <div className={LogintoDashboardCss.wrapper}>
                            <div className={LogintoDashboardCss.input_container}>
                                <input name='username' type="text" placeholder="Enter Username" onChange={onDataChange} value={logingText.username} required/>
                                <div className={LogintoDashboardCss.icon_holder}>
                                    <FontAwesomeIcon icon={faUser}/>
                                </div>

                            </div>
                            <div className={LogintoDashboardCss.input_container} onKeyUp={(e) => { onEnterPress(e) }}>
                                <input name='password' type="password" placeholder="Enter Password" onChange={onDataChange} value={logingText.password} required/>  
                                <div className={LogintoDashboardCss.icon_holder}>
                                    <FontAwesomeIcon icon={faKey}/>
                                </div>
                            </div>
                            <button >Login</button>

                        </div>
                    </form>
                </div>
            </section>

        </>
    )
}

export default LogintoDashboard

const AlertMessage = ({ message, setAlertMessage }) => {

    function alertClick(e) {
        // console.log(e)
        setAlertMessage()

    }
    const zindex = {
        zIndex: 1000
    }
    return (
        <div style={zindex} className='AlertMessage'>
            <h2 >{message}</h2>
            <button onClick={alertClick}>OK</button>
        </div>
    )
}