import deliveryCss from './deliveryboy.module.css'
import { useSelector } from "react-redux"
import { selectUserInfo } from "../../../ReduxFiles/Slices/UserSlice"
import { useEffect, useState } from "react"
import { UpdateOrderData } from "../../../api/SalesApi"
import { GetDeliveryBoyList, SaveSummeryData } from "../../../api/OrderInformationApi"
import { toast } from 'react-toastify'

const DeliveryBoyAssign = ({ deliveryBoyData, setDeliveryBoyData, tableData }) => {
    const userIfno = useSelector(selectUserInfo)
    const [deliveryBoy, setDeliveryBoy] = useState()
    const [getDeliveryBoyData, setGetDeliveryBoyData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setGetDeliveryBoyData({ ...getDeliveryBoyData, [name]: value })
    }
    function onOptionChange(e) {
        let name = e.target.name
        let value = e.target.value
        deliveryBoy?.forEach(element => {
            if (element.userName === value) {
                setGetDeliveryBoyData({ ...getDeliveryBoyData, [name]: value, deliveryBoyID: element.id })
            }
        });
    }
    async function onSubmitClick(e) {
        e.preventDefault()
        if (getDeliveryBoyData?.deliveryBoyName) {
            let finalUpdateData = {
                status: "Delivery Agent Assigned",
                deliveryBoyAssigned: 1,
                deliveryBoyID: getDeliveryBoyData.deliveryBoyID,
                deliveryBoyNote: getDeliveryBoyData.note,
            }
            await UpdateOrderData(deliveryBoyData.id, finalUpdateData)
            let findIndex = tableData.findIndex(obj => obj.id === parseInt(deliveryBoyData.id))
            tableData[findIndex] = {
                ...tableData[findIndex],
                ...finalUpdateData
            }
            let nestedData = {
                activity: 'Delivery Boy Asigned',
                ProssesedBy: userIfno.id,
                sale_id: deliveryBoyData.id,
                created_date: new Date().getTime()

            }
            await SaveSummeryData(nestedData)
            setDeliveryBoyData()
            document.body.style.overflow = 'auto'
        } else {
           toast.warn('You must Select Delivery Boy')
        }
    }

    async function getDeliveryBoy() {
        let myData = await GetDeliveryBoyList()
        setDeliveryBoy(myData)
    }

    function onCancelClick() {
        setDeliveryBoyData()
        document.body.style.overflow = 'auto'
    }
    useEffect(() => {
        getDeliveryBoy()
    }, [])

    return (
        <div className={deliveryCss.wrapper}>
            <div className={deliveryCss.holder}>
                <h2>Assign Delivery Boy</h2>
                <div className={deliveryCss.inner_holder}>
                    <h3> Your Invoice No : # {deliveryBoyData.id}</h3>
                    <form className={deliveryCss.inputSection}  onSubmit={onSubmitClick}>
                        <div className={deliveryCss.common}>
                            <label>Note </label>
                            <input name='note' type={'text'} placeholder="note" onChange={onDataChange} />
                        </div>
                        <div className={deliveryCss.common}>
                            <label>Delivery Boy </label>
                            <select name='deliveryBoyName' onChange={onOptionChange} required>
                                <option value={''}>Select Delivery Boy</option>
                                {deliveryBoy?.map((item) => {
                                    return <option key={item.id}>{item.userName}</option>
                                })}
                            </select>
                        </div>
                        <div className={deliveryCss.buttonsection}>
                        <button type='button' onClick={onCancelClick}>Cancel</button>
                        <button type='submit'>Submit</button>
                    </div>
                    </form>
                    
                </div>
            </div>
        </div>
    )
}

export default DeliveryBoyAssign