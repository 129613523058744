import React, { useEffect, useState } from 'react'
import { ApiLink } from '../../api/ExpressFunctions'
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import * as htmlToImage from 'html-to-image';
import { GetProductImageConverted, GetProductVarianceConverted } from '../../api/ProductListApi';
import CatelogPDFViewCss from './Css/CatelogPDFView.module.css'
const CatelogPDFView = () => {
    let localList = window.localStorage.getItem('catlistedItem')
    const [productList, setProductList] = useState(localList ? JSON.parse(localList) : [])

    return (
        <>
            <PDFViewer width="70%" height="1100px">
                <MyDocument />
            </PDFViewer>

            <div>
                {productList.map((item, index)=>{
                    return <React.Fragment key={index+'check'}>
                        {/* <img  style={{height:'200px'}} src='https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg?w=2000'/> */}
                        {/* <ImageComp name={item.imageName}/> */}
                    </React.Fragment>
                })}
                
            </div>
        </>
    )
}

export default CatelogPDFView

const PDFview = () => {
    let localList = window.localStorage.getItem('listedItem')
    const [productList, setProductList] = useState(localList ? JSON.parse(localList) : [])
    // console.log(productList?.variance);
    function onExportPDF() {
         window.print();
        // handleConvertToJPEG()
    }
    const htmlRef = React.useRef(null);
    const [imageDataUrl, setImageDataUrl] = React.useState('');
  
    const handleConvertToJPEG = () => {
      const htmlElement = htmlRef.current;
        //console.log(htmlElement);
    //   htmlToImage.toJpeg(htmlElement)
    //     .then(function (dataUrl) {
    //       setImageDataUrl(dataUrl);
    //     })
    //     .catch(function (error) {
    //       console.error('Error converting HTML to JPEG:', error);
    //     });
    htmlToImage.toJpeg(htmlElement)
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'image.jpg';
        link.click();
      })
      .catch(function (error) {
        console.error('Error converting HTML to JPEG:', error);
      });
    };
    
    useEffect(()=>{
       // handleConvertToJPEG()
    },[])
    return (
        <div className={CatelogPDFViewCss.page_wrapper} ref={htmlRef}>
            <button onClick={onExportPDF} className={CatelogPDFViewCss.export_button}>Export PDF</button>
            {productList?.map((item, index) => {
                return <div className={CatelogPDFViewCss.product_card} key={item.id + 'p'}>
                    <div className={CatelogPDFViewCss.name_section}>
                        <div className={CatelogPDFViewCss.sl_holder}>
                            <p>{index + 1}</p>
                        </div>
                        <p>{item.name}</p>
                    </div>
                    <div className={CatelogPDFViewCss.info_section_others}>
                        <div className={CatelogPDFViewCss.image_section}>
                            <img src={`${ApiLink}productimages/${item.imageName}`} alt={item.name} />
                        </div>
                        <div className={CatelogPDFViewCss.text_section}>
                            <div className={CatelogPDFViewCss.others_common}>
                                <p className={`${CatelogPDFViewCss.other_heading} ${CatelogPDFViewCss.head1}`}>MRP</p>
                                <p className={`${CatelogPDFViewCss.number_section} ${CatelogPDFViewCss.num1}`}>{item.price?.toFixed(2)}/-</p>
                            </div>
                            <div className={CatelogPDFViewCss.others_common}>
                                <p className={`${CatelogPDFViewCss.other_heading} ${CatelogPDFViewCss.head2}`}>Special Price</p>
                                <p className={`${CatelogPDFViewCss.number_section} ${CatelogPDFViewCss.num2}`}>{item.dicountPrice?.toFixed(2)}/-</p>
                            </div>
                            <div className={CatelogPDFViewCss.others_common}>
                                <p className={`${CatelogPDFViewCss.other_heading} ${CatelogPDFViewCss.head3}`}>Ready Stock Quantity</p>
                                <p className={`${CatelogPDFViewCss.number_section} ${CatelogPDFViewCss.num3}`}>{item.stock}</p>
                            </div>
                            <div className={CatelogPDFViewCss.others_common}>
                                <p className={`${CatelogPDFViewCss.other_heading} ${CatelogPDFViewCss.head4}`}>Delivery Lead Time</p>
                                <p className={`${CatelogPDFViewCss.number_section} ${CatelogPDFViewCss.num4}`}>{item.lead_date}</p>
                            </div>
                        </div>
                    </div>
                    <div className={CatelogPDFViewCss.variance}>
                        {item.variance?.map((vitem) => {
                            return <div key={vitem.id + 'v'} className={CatelogPDFViewCss.variance_item}>
                                <img src={`${ApiLink}/varianceimages/${vitem.ShadeIamge}`} alt='vr image' />
                                <p>{vitem.Variance_name}</p>
                            </div>
                        })}
                    </div>
                    <div className={CatelogPDFViewCss.fotter_section}>
                        <p>Selim Azam International Ltd</p>
                    </div>
                </div>
            })}

        </div>
        // <div ref={htmlRef}>
        //     <button onClick={onExportPDF} className='export-button'>Export PDF</button>
        //     <p>hello</p>
        // </div>

    )
}

const MyDocument = () => {

    let localList = window.localStorage.getItem('catlistedItem')
    const [productList, setProductList] = useState(localList ? JSON.parse(localList) : [])
    function getExtraHeight(variance) {
        let vlength = variance?.length
        let totalCol = Math.round(vlength/4)
        let value  = 100*totalCol
        return value || 0
    }

    return <Document>
            {productList?.map((item, index) => {
                return <Page size={{ height: 400 + (getExtraHeight(item.variance)), width: 500 }} style={styles.page} key={item.id}>
                    <View style={styles.topsection} key={item.id}>
                        <Text style={styles.number}>{index + 1}</Text>
                        <Text style={styles.heading}>{item.name}</Text>
                    </View>
                    <View style={styles.middlesection}>
                        <View style={styles.imagesection}>
                            <ImageComp name={item.imageName}/>
                        </View>
                        <View>
                            <View style={styles.infotext1}>
                                <Text style={styles.text1}>MRP</Text>
                                <Text style={{padding:5}}>{item.price}</Text>
                            </View>
                            <View style={styles.infotext1}>
                                <Text style={styles.text2}>TP</Text>
                                <Text style={{padding:5}}>{item.tp}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.varianceSection}>
                        {item.variance?.map((vitem) => {
                            return <View  key={vitem.id + 'vr'} style={styles.varianceholder}>
                                {/* <Image  style={styles.varianceImage} src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/New_york_times_square-terabass.jpg/1200px-New_york_times_square-terabass.jpg'/> */}
                                   <VarianceImageComp name={vitem.ShadeIamge}/>
                                <Text style={{fontSize:12}}>{vitem.Variance_name}</Text>
                            </View>
                        })}
                    </View>
                </Page>
            })}
    </Document>
}

const ImageComp = ({ name }) => {
    const [image, setImage] = useState();
   
    async function getLink() {
      if (name) {
        //'1665217854568_083092e1695-2021-03-10.jpg'
        let imageInfo = await GetProductImageConverted({ image_name: name });
        if (imageInfo.imageExist) {
            // console.log(imageInfo);
        setImage(imageInfo.url); 
        } else {
          setImage();
        }
      } else {
        setImage();
      }
    }
  
    useEffect(() => {
      getLink();
    }, [name]);

   
    return <Image  style= {styles.image} src={image} />;
  };
const VarianceImageComp = ({ name }) => {
    const [image, setImage] = useState();
    async function getLink() {
      if (name) {
        let imageInfo = await GetProductVarianceConverted({ image_name: name });
        if (imageInfo.imageExist) {
            // console.log(imageInfo);
        setImage(imageInfo.url); 
        } else {
          setImage();
        }
      } else {
        setImage();
      } 
    }
  
    useEffect(() => {
      getLink();
    }, [name]);

   
    return <Image  style= {styles.varianceImage} src={image} />;
  };

  //<Image  style= {styles.image} src={image} />

const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        padding:10
    },
    wrapper: {
        flex: 1,
        minHeight: 300, // specify the width in points
    },
    topsection: {
        backgroundColor: 'green',
        // flex: 1.5,
        flexDirection: 'row',
        alignItems: 'center',
        padding:10
    },
    number:{
        backgroundColor:'white',
        color:'green',
        padding:10,
        borderRadius:5,
        fontSize:18
    },
    heading:{
        color:'white',
        marginLeft:10,
        marginRight:10
    },
    middlesection: {
        flexDirection: 'row',
       height:300,
        marginTop:20,
    },
    imagesection:{
        width:300,
        height:300,
        borderRadius:15,
        border:'2px solid green'
    },
    image: {
       width:'100%',
       objectFit:'cover',
       overflow:'hidden',
       borderRadius:15,
    },
    infotext1: {
        backgroundColor: '#d8d8d8',
        width: '160px',
        textAlign: 'center',
        borderRadius: 10,
        overflow: 'hidden',
        marginLeft:10,
        marginBottom:10
    },
    text1: {
        backgroundColor: 'violet',
        color: 'white',
        padding:5
    },
    text2: {
        backgroundColor: 'orange',
        color: 'white',
        padding:5
    },
    varianceSection:{
        flexDirection:'row',
        flexWrap:'wrap'
    },
    varianceholder:{
       width:100,
       height:90,
       margin:5,
       alignItems:'center',
       border:'1px solid gray',
       overflow:'hidden',
       borderRadius:5
    },
    varianceImage:{
       width:50,
       height:50,
       objectFit:'cover',
       margin:5
    },
});
