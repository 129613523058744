import React, { useEffect, useState } from 'react'
import { AreaData, DistrictData } from "../../api/MyLocation";
import DummyImage from "../../assets/dummyuser.png";
import { faToggleOn, faToggleOff, faCircleInfo, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllSupplier, selectSupplierList, selectSupplierStatus, setSupplierList } from '../../ReduxFiles/Slices/SupplierSlice';
import { UpdateSupplier } from '../../api/SupplierApi';
import { API_LINK } from '../../api/APILink';
import SupplierListCss from './css/SupplierList.module.css'
const SupplierList = () => {
  const dispatch = useDispatch()
  const supplierData = useSelector(selectSupplierList)
  const supplierStatus = useSelector(selectSupplierStatus)
  const [profileData, setProfileData] = useState()
  const [editSupplierData, setEditSupplierData] = useState()


  function onInfoClick(e, item) {
    setProfileData(item)
  }
  function onEditClick(e, item) {
    setEditSupplierData(item)
  }
  useEffect(() => {
    if (supplierStatus === 'idle') {
      dispatch(fetchAllSupplier())
    }
  }, [supplierStatus])
  function onToggleClick(id) {
    console.log(id);
    let myArr = []
    supplierData.forEach(async element => {
      if (element.id === id) {
        if (element.status) {
          element = { ...element, status: 0 }
          myArr.push(element)
          await UpdateSupplier(id, { status: 0 })
        } else {
          element = { ...element, status: 1 }
          myArr.push(element)
          await UpdateSupplier(id, { status: 1 })
        }

      } else {
        myArr.push(element)
      }
    });
    dispatch(setSupplierList(myArr))
  }

  const TableItem = supplierData?.map((item, index) => {
    return <MyTable key={index} sl={index + 1} companyname={item.companyaName} ownername={item.ownerName} phone={item.phone} address={item.Area + ", " + item.District + ", " + item.Divition}
      status={<ToggleSwitch toggleOnOff={item.status} id={item.id} toggleChange={onToggleClick} />}
      action={
        <div className={SupplierListCss.userIcon}>
          <FontAwesomeIcon className={SupplierListCss.infobutton} icon={faCircleInfo} onClick={(e) => { onInfoClick(e, item) }} /> <FontAwesomeIcon icon={faEdit} onClick={(e) => { onEditClick(e, item) }} />
        </div>
      } />
  })
  const marginTop = {
    marginTop: '10px'
  }

  return (
    <>
      {/* {spinnerShow ?  <Spinner text={'Loading...'}/>:""} */}


      <section style={marginTop} className={`${SupplierListCss.salesMain} ${SupplierListCss.manageuserMain}`}>
        {profileData ? <ProfileView profileData={profileData} setProfileData={setProfileData} /> : ""}
        {editSupplierData ? <EditPop editSupplierData={editSupplierData} setEditSupplierData={setEditSupplierData} /> : ""}
        <div className={SupplierListCss.innerSale}>
          <h2 className={SupplierListCss.saleHeading}>Supplier List</h2>
          <div className={SupplierListCss.tableSection}>
            <table>
              <tbody>
                <tr>
                  <th>SL</th>
                  <th>Company Name</th>
                  <th>Owner Name</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                {TableItem}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  )
}

export default SupplierList

const MyTable = ({ sl, companyname, ownername, phone, address, status, action }) => {

  return <>
    <tr>
      <td>{sl}</td>
      <td>{companyname}</td>
      <td>{ownername}</td>
      <td>{phone}</td>
      <td>{address}</td>
      <td>{status}</td>
      <td>{action}</td>
    </tr>
  </>
}
const Spinner = ({ text }) => {
  const myIndex = {
    zIndex: '15'
  }
  return (
    <>
      <div style={myIndex} className={SupplierListCss.SpinnerWrrapper}>
        <div id="loader"></div>
        <p>{text}</p>
      </div>
    </>
  )
}

const ToggleSwitch = ({ toggleOnOff, id, toggleChange }) => {

  return (
    <>

      {toggleOnOff ? <div className={SupplierListCss.ToggleSwitchon}> <p onClick={() => { toggleChange(id) }}> </p><FontAwesomeIcon icon={faToggleOn} /></div> : <div className={SupplierListCss.ToggleSwitchoff}><p onClick={() => { toggleChange(id) }}> </p><FontAwesomeIcon icon={faToggleOff} /></div>}


    </>
  )
}

const ProfileView = ({ profileData, setProfileData }) => {

  function CloseInfo() {
    setProfileData()
  }
  const zindex = {
    zIndex: 1000
  }

  return (
    <div className={SupplierListCss.EditUserPop_wrapper}>
      <div style={zindex} className={`${SupplierListCss.EditUserPop} ${SupplierListCss.profileView}`}>
        <img name='userImage' src={API_LINK + 'userimages/' + profileData?.imageName} alt='user here' />
        <div className={SupplierListCss.common}>
          <p><strong>Company Name : </strong>{profileData?.companyaName}</p>
          <p><strong>Owner Name : </strong>{profileData?.ownerName}</p>
          <p><strong>Phone Number : </strong>{profileData?.phone}</p>
          <p><strong>Phone Number (alt) : </strong>{profileData?.altphone}</p>
          <p><strong>Address : </strong>{profileData?.address + ", " + profileData?.Area + ", " + profileData?.District + ", " + profileData?.Divition}</p>
          <p><strong>Bazar : </strong>{profileData?.bazar}</p>
          <p><strong>NID : </strong>{profileData?.nid}</p>
          <p><strong>TIN : </strong>{profileData?.tin}</p>
        </div>
        <div className={SupplierListCss.close_btn}>
          <button onClick={CloseInfo}>Close</button>
        </div>
      </div>
    </div>
  )
}

const EditPop = ({ editSupplierData, setEditSupplierData }) => {
  let DivitionArray = ["Dhaka", "Chittagong", "Khulna", "Mymensingh", "Sylhet", "Rangpur", "Rajshahi", "Barisal"]
  const [districtData, setDistrictData] = useState();
  const [areaData, setAreaData] = useState();
  const [supplyData, setSupplyData] = useState(editSupplierData);
  const [spinnerShow, setSpinnerShow] = useState(false)
  // const [errText, setErrText] = useState();
  function onInputChange(e) {
    let name = e.target.name
    let value = e.target.value
    if (e.target.type === 'file') {
      setSupplyData({ ...supplyData, [name]: e.target.files[0], fakeUrl: genarateFakeUrl(e.target.files[0]) })
    } else {
      setSupplyData({ ...supplyData, [name]: value })
    }

  }
  function genarateFakeUrl(image) {
    let myurl = URL.createObjectURL(image)
    return myurl;
  }

  function onDivitionChange(e) {
    const DivitionValue = e.target.value;
    const DivitionName = e.target.name;
    setSupplyData({ ...supplyData, [DivitionName]: DivitionValue });
    let arr = []
    DistrictData?.forEach(element => {
      if (element.division === e.target.value) {
        arr.push(element.district)
      }
    });
    arr.sort()
    setDistrictData(arr)
  }

  function onDisChange(e) {
    const DistrictValue = e.target.value;
    const DistrictName = e.target.name;

    setSupplyData({ ...supplyData, [DistrictName]: DistrictValue })
    let arr = []
    AreaData?.forEach(element => {
      if (element.distrctName === e.target.value) {
        arr.push(element.AreaName)
      }

    })
    arr.sort()
    setAreaData(arr)
  }

  function OnAreaChange(e) {
    const AreaValue = e.target.value;
    const AreaName = e.target.name;
    setSupplyData({ ...supplyData, [AreaName]: AreaValue })
  }
  function automatedData() {
    //console.log(itemID)    
    let myData = editSupplierData
    let disArray = []
    DistrictData.forEach(element => {
      if (element.division === myData.Divition) {
        disArray.push(element.district)
      }
    });
    disArray.sort()
    setDistrictData(disArray)

    let areaArray = []
    AreaData.forEach(element => {
      if (element.distrctName === myData.District) {
        areaArray.push(element.AreaName)
      }
    });
    areaArray.sort()
    setAreaData(areaArray)
  }
  useEffect(() => {
    automatedData()
  }, [editSupplierData])
  async function onUpdateClick() {
    
    let finalData = {
      Area: supplyData?.Area,
      District: supplyData?.District,
      Divition: supplyData?.Divition,
      address: supplyData?.address ? supplyData?.address : '',
      altphone: supplyData?.altphone ? supplyData?.altphone : '',
      bazar: supplyData?.bazar ? supplyData?.bazar : '',
      companyaName: supplyData?.companyaName,
      email: supplyData?.email ? supplyData?.email : '',
      nid: supplyData?.nid ? supplyData?.nid : '',
      ownerName: supplyData?.ownerName,
      tin: supplyData?.tin
    }
    let companyaNameerr = ValidateFunction('companyaName', 'Company Name ')
    let ownerNameerr = ValidateFunction('ownerName', 'Owner Name ')
    let phoneerr = ValidateFunction('phone', 'Phone Number ')
    let Divitionerr = ValidateFunction('Divition', 'Divition ')
    let Districterr = ValidateFunction('District', 'District ')
    let Areaerr = ValidateFunction('Area', 'Area ')
    // setErrText({ companyaNameerr, ownerNameerr, phoneerr, Divitionerr, Districterr, Areaerr })
    if (!companyaNameerr && !ownerNameerr && !phoneerr && !Divitionerr && !Districterr && !Areaerr) {
      setSpinnerShow(true)
      if (supplyData?.supplierImage) {
        let mID = supplyData.id
        delete supplyData.id
        await UpdateSupplier(mID, finalData)
        setSpinnerShow(false)
        setEditSupplierData()
      } else {
        let mID = supplyData.id
        delete supplyData.id
        await UpdateSupplier(mID, finalData)
        setSpinnerShow(false)
        setEditSupplierData()
      }

    }
  }
  function ValidateFunction(field, message) {
    let text = ""
    if (!supplyData[field]) {
      text = message + "is Required"
    }
    return text;
  }

  function onCloseClick() {
    setEditSupplierData()
  }


  return (
    <div className={SupplierListCss.add_supplier_pop_wrapper}>
      {spinnerShow ? <Spinner text={'Updating...'} /> : ""}
      <div className={`${SupplierListCss.addsupplier} ${SupplierListCss.add_supplier_pop}`}>
        <div className={SupplierListCss.supplier_heading}>
          <h3>Edit Supplier Data </h3>
        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>Company Name *</label>
          <input name='companyaName' type='text' placeholder='Company Name' onChange={onInputChange} defaultValue={supplyData.companyaName} />

        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>Owner Name *</label>
          <input name='ownerName' type='text' placeholder='Owner Name' onChange={onInputChange} defaultValue={supplyData.ownerName} />

        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>Phone No *</label>
          <input name='upadatephone' type='text' placeholder='Phone No' onChange={onInputChange} defaultValue={supplyData.phone} disabled />

        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>Alternative Phone</label>
          <input name='altphone' type='text' placeholder='Alternative Phone' onChange={onInputChange} defaultValue={supplyData.altphone} />
        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>Email</label>
          <input name='email' type='text' placeholder='Email' onChange={onInputChange} defaultValue={supplyData.email} />
        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>Division *</label>
          <PopUpOption popName={"Divition"} myArray={DivitionArray} myValue={supplyData?.Divition} onDataChange={onDivitionChange} />

        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>District *</label>
          <PopUpOption popName={"District"} myArray={districtData} myValue={supplyData?.District} onDataChange={onDisChange} />

        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>Sub-District & Thana *</label>
          <PopUpOption popName={"Area"} myArray={areaData} myValue={supplyData?.Area} onDataChange={OnAreaChange} />

        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>Bazar</label>
          <input name='bazar' type='text' placeholder='Bazar' onChange={onInputChange} defaultValue={supplyData.bazar} />
        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>NID</label>
          <input name='nid' type='text' placeholder='NID' onChange={onInputChange} defaultValue={supplyData.nid} />
        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>TIN</label>
          <input name='tin' type='text' placeholder='TIN' onChange={onInputChange} defaultValue={supplyData.tin} />
        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>Address</label>
          <input name='address' type='text' placeholder='Address' onChange={onInputChange} defaultValue={supplyData.address} />
        </div>
        <div className={SupplierListCss.supplierinput}>
          <label>Supplier Image</label>
          <input name='supplierImage' type='file' placeholder='Address' onChange={onInputChange} />
        </div>
        <div className={SupplierListCss.supplierinput}>
          <img src={supplyData?.fakeUrl ? supplyData?.fakeUrl : supplyData?.imageURL ? supplyData.imageURL : DummyImage} alt='Supplier' />
        </div>
        <div className={`${SupplierListCss.supplierinput} ${SupplierListCss.buttonsection}`}>
          <button onClick={onUpdateClick}>Update Data</button>
          <button onClick={onCloseClick}>Close</button>
        </div>

      </div>
    </div>
  )
}

const PopUpOption = ({ myArray, onDataChange, popName, myValue }) => {

  let OptionData = <>
    {myArray ? myArray.map((item, index) => {
      return <option key={index} onChange={onDataChange} defaultValue={item} selected={myValue === item ? true : false}>{item}</option>
    }) : ""}
  </>

  return (
    <>
      <select className={SupplierListCss.popOption} name={popName} onChange={onDataChange} >
        <option value={""}>--Select Item--</option>
        {OptionData}
      </select>

    </>
  )
}

