import Chart from 'chart.js/auto';
import { useEffect, useRef } from "react";

const ChartJsChart = ({amountData}) => {
   const chartRef = useRef(null);
   let labels = [];
   for (let index = 0; index <100; index++) {
    labels.push(index+1)
   }

    const data = {
        // labels: labels,
        datasets: [{
            label: 'Net sales',
            data: amountData,
            backgroundColor: [
                '#5747FF80',
                '#76009D80',
                '#34C30080',
                '#5747FF80',
                '#E6CF0080',
                '#DC044580',
                '#CC00B880'
              ],
              borderColor: [
                '#5747FF',
                '#76009D',
                '#34C300',
                '#5747FF',
                '#E6CF00',
                '#DC0445',
                '#CC00B8'
              ],
            parsing: {
              yAxisKey: 'total' 
            }
          },],
         
          borderWidth: 1
      
      };
      useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        // Check if a chart instance already exists
        if (chartRef.current.chart) {
            chartRef.current.chart.destroy();
        }

        chartRef.current.chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
    }, [data]);
  
    return <canvas id='chart' ref={chartRef} />;
  };
  
  export default ChartJsChart;

  

  /*
   '#5747FF80',
                '#76009D80',
                '#34C30080',
                '#5747FF80',
                '#E6CF0080',
                '#DC044580',
                '#CC00B880'
    datasets: [{
          label: `Sales Chart of ${MonthArr[monthNumber-1].name}, ${year}`,
          data: dataArr,
          backgroundColor: [
            'rgba(255, 0, 200, 0.6)',
            'rgba(255, 159, 64, 0.6)',
            'rgba(255, 205, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(153, 102, 255, 0.6)',
            'rgba(201, 203, 207, 0.6)'
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
          borderWidth: 1
        }]
  */