import { async } from '@firebase/util'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import { DeleteNestedData, GetNestedAllData, GetNestedSingelDoc, GetSingleDoc, SaveNestedExistingData, UpdateData, UpdateNestedDoc } from '../connector/DatabaseCheck'
import DeliveryDetailsViewCss from './css/DeliveryDetailsView.module.css'

const DeliveryDetailsView = () => {
    const myParams = useParams()
    const [tableData, setTableData] = useState()
    const [orderData, setOrderData] = useState()

    function onPrintInvoice() {
        let okdata = {}
        console.log(tableData)
        okdata = {...okdata, tableData, ...orderData}
      
       localStorage.setItem('invoiceData', JSON.stringify(okdata))
    }
    function PrintChallan(params) {
        
    }

    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getAllData() {
        let myData = await GetSingleDoc('sales', myParams.orderid)
        let myNestedData = await GetNestedAllData('sales', myParams.orderid, 'orders', "productId")
        let newAddress = await GetNestedSingelDoc('sales', myParams.orderid, 'newaddress', '1')
        if (newAddress) {
            let myNewData = {
                Address: newAddress.Address,
                AddressPhone: newAddress.AddressPhone,
                Divition: newAddress.Divition,
                District: newAddress.District,
                Area: newAddress.Area,
                deliveryCharge: newAddress.deliveryCharge
            }
            setOrderData({ ...orderData, ...myData.data(), orderid: myParams.orderid, ...myNewData })
        } else {
            setOrderData({ ...orderData, ...myData.data(), orderid: myParams.orderid })
        }
        organizeData(myNestedData)
        getGrandTotal(myNestedData)
    }


    function organizeData(myData) {
        let finalArr = []
        let uniqueElements = myData.slice()
        for (let index = 0; index < myData.length; index++) {
            const element = myData[index];
            let tempArr = []
            for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                const melement = uniqueElements[mindex];
                if (melement.id === element.id) {
                    tempArr.push(melement)
                }
            }

            if (tempArr.length > 0) {
                tempArr.forEach(element => {
                    uniqueElements.forEach((melement, mindex) => {
                        if (melement.id === element.id) {
                            uniqueElements.splice(mindex, 1)
                        }
                    });
                });

                finalArr.push(tempArr)
            }

        }

        setTableData(finalArr)

    }
    const [grandTotal, setGrandTotal] = useState(0)
    function getGrandTotal(data) {
        let total = 0
        data?.forEach(element => {
            total += (element.price * element.mQuantity)
        });
        setGrandTotal(total)
    }

    async function getNestedAddress() {
        let newAddress = await GetNestedSingelDoc('sales', myParams.orderid, 'newaddress', '1')
        if (newAddress) {
            let myNewData = {
                Address: newAddress.Address,
                AddressPhone: newAddress.AddressPhone,
                Divition: newAddress.Divition,
                District: newAddress.District,
                Area: newAddress.Area,
                deliveryCharge: newAddress.deliveryCharge
            }
            setOrderData({ ...orderData, ...myNewData })
        }
        // console.log(myData)

    }

    useEffect(() => {
        getAllData()
    }, [myParams.orderid])

    useEffect(() => {
        getNestedAddress()
    }, [])

    function getQTotal(item) {
        let total = 0
        item?.forEach(element => {
            total += element.mQuantity
        });

        return total;
    }
    function totalAmmount(item) {
        let quantity = getQTotal(item)
        let total = 0
        total += item[0].price
        return total * quantity;
    }

    const TableItem = tableData?.map((item, index) => {
        return <>
            <MyTable key={index} item={item[0].mainName ? item[0]?.mainName : item[0].name}
                price={item[0]?.price} qty={getQTotal(item)} tammount={totalAmmount(item)} />
        </>
    })
    return (
        <>
             <div className={DeliveryDetailsViewCss.menupart}>
                <ul className={DeliveryDetailsViewCss.menuitems}>
                    <NavLink to={"/admin/deliveryagent/agentlist"}>
                        <li >Delivery Agent List</li>
                    </NavLink>
                    <NavLink to={"/admin/deliveryagent/deliveryassignmentlist"}>
                        <li >Delivery Assignment List</li>
                    </NavLink>

                    <NavLink to={"/admin/deliveryagent/addnewagent"}>
                        <li >Add New Delivery Agent</li>
                    </NavLink>
                </ul>
            </div>
            <div>
                <section className={DeliveryDetailsViewCss.salesMain}>
                    <div className={DeliveryDetailsViewCss.innerSale}>
                        <div className={DeliveryDetailsViewCss.gobackSec}>
                            <h2 className={DeliveryDetailsViewCss.saleHeading}>Order Details</h2>
                            <NavLink to={'/admin/sales/complete_orders'}><button>Go Back</button></NavLink>
                        </div>
                        <div className={DeliveryDetailsViewCss.orderdetaislInner}>
                            <h3>Invoice #{orderData?.invoiceno}</h3>
                            <div className={DeliveryDetailsViewCss.customerSection}>
                                <div>
                                    <p className={DeliveryDetailsViewCss.highlighter}>Customer Details</p>
                                    <p><strong>Customer Name</strong> : {orderData?.name}</p>
                                    <p><strong>Phone</strong> : {orderData?.AddressPhone}</p>
                                    <p><strong>Division</strong> :{orderData?.Divition}</p>
                                    <p><strong>District</strong> : {orderData?.District}</p>
                                    <p><strong>area</strong> : {orderData?.Area}</p>
                                </div>
                                <div>
                                    <p className={DeliveryDetailsViewCss.highlighter}>Order Details</p>
                                    <p>Order Date : {convertDate(orderData?.createdDate?.seconds)} </p>
                                    <p>Payment Type : {orderData?.paymentType}</p>
                                </div>
                            </div>
                            <div className={DeliveryDetailsViewCss.customerSection}>
                                <div className={DeliveryDetailsViewCss.customerDetails}>
                                    <p className={DeliveryDetailsViewCss.highlighter}>Confirmation Details</p>
                                    {/* <p><strong>Confirmed By : </strong> : {orderData?.name}</p> */}
                                    <p><strong>Note : </strong> : {orderData?.approval_note}</p>
                                    <p><strong>Delivery Priority : </strong> :{orderData?.priority}</p>
                                    <p><strong>Delivery Date : </strong> : {orderData?.delivery_date}</p>
                                    <p><strong>Special Instruction's : </strong> : {orderData?.special_instruction}</p>
                                </div>
                            </div>
                            <div className={`${DeliveryDetailsViewCss.tableSection} ${DeliveryDetailsViewCss.detailstableSection}`}>
                                <table>
                                    <tr>
                                        <th>Item</th>
                                        <th>Price</th>
                                        {/* <th>Variations</th> */}
                                        <th>Quantity</th>
                                        <th>Total Ammount</th>
                                    </tr>
                                    {TableItem}
                                </table>
                                <div className={DeliveryDetailsViewCss.tableDetails}>
                                    <p>Payment Type : {orderData?.paymentType}</p>
                                    <p>Discount : {orderData?.discount ? orderData?.discount : "0"} tk</p>
                                    <p>Delivery Charge : {orderData?.deliveryCharge} tk</p>
                                    <p>Total Ammount : {grandTotal + orderData?.deliveryCharge} tk</p>
                                    <p>Collected : {orderData?.collectedAmmount ? orderData?.collectedAmmount : "0"} tk</p>
                                    <p>Due : {orderData?.collectedAmmount ? (grandTotal + orderData?.deliveryCharge-orderData?.collectedAmmount) : "0"} tk</p>
                                    <p>Return : {orderData?.returnAmmount ? orderData?.returnAmmount : "0"} tk</p>
                                </div>

                                <div className={DeliveryDetailsViewCss.detailsWrapper}>
                                    <div className={`${DeliveryDetailsViewCss.detailInput} ${DeliveryDetailsViewCss.salesDetail}`}>
                                        <div className={DeliveryDetailsViewCss.ButtonGroup}>
                                            {/* <ButtonInput myValue={"Print Double Invoice"} mclass={'printDouble'}
                                                onClickevent={onPrintInvoice} /> */}
                                            <Link to={"/admin/sales/salesdetails/:orderid/invoice"} target='_blank' rel="noopener noreferrer"><ButtonInput myValue={"Print Invoice"} mclass={'approvedetail'}
                                                onClickevent={onPrintInvoice} /></Link>
                                            <ButtonInput myValue={"Print Challan Copy"} mclass={'printDouble'} onClickevent={PrintChallan} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default DeliveryDetailsView



const ButtonInput = ({ myValue, mclass, onClickevent }) => {
    return (
        <>
            <div>
                <button className={mclass} onClick={onClickevent}>{myValue}</button>
            </div>
        </>
    )
}

const MyTable = ({ item, editVariance, price, qty, tammount }) => {

    return (
        <>
            <tr>
                <td className='foreditVariance'>{item} {editVariance}</td>
                <td >{price}</td>
                <td>{qty}</td>
                <td>{tammount}</td>
            </tr>
        </>
    )
}