import ProductsAttributesCss from './css/ProductsAttributes.module.css'

import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { selectAttributeList } from '../../../ReduxFiles/Slices/MAU_Slice'
import { SaveProducts } from '../../../api/ProductListApi'
import { SaveProductVariance } from '../../../api/ProductVarianceApi'
import Table from '../../Table'

const ProductsAttributes = ({ showState, item, setItem, setstate }) => {
    const allAttributes = useSelector(selectAttributeList)
    let [varianceData, setVarData] = useState([])
    const [varienceOp, setVarianceOp] = useState([])

   
    const [ageIndicationValue, setAgeIndicationValue] = useState(false)
    function onAgeChange(e) {
        let value = e.target.checked
        setAgeIndicationValue(value)
    }

    function addVarianceClick(e) {
        e.preventDefault()
        const form = e.target
        const Variance_name = form.Variance_name.value
        const selectedVarUnit = form.selectedVarUnit.value
        const ShadeIamge = form.ShadeIamge.files[0]
        const MainIamge = form.MainIamge.files[0]
        const ShadeIamgeurl = GenarateFakeUrl(ShadeIamge)
        const MainIamgeurl = GenarateFakeUrl(MainIamge)
        let data = [...varianceData]
        let dataFound = false
        if (varianceData.length>0) {
            for (let index = 0; index < varianceData.length; index++) {
                const element = varianceData[index];
                if (element.Variance_name.toLowerCase() === Variance_name.toLowerCase()) {
                    dataFound =true;
                    break;
                }
            }
            if (!dataFound) {
                data.push({Variance_name, selectedVarUnit, ShadeIamge, MainIamge, ShadeIamgeurl, MainIamgeurl, starus:1})
                setVarData(data)
                form.reset()
            }else{
                toast.warn('Same Variance Name Found')
            }
        }else{
            data.push({Variance_name, selectedVarUnit, ShadeIamge, MainIamge, ShadeIamgeurl, MainIamgeurl, status:1})
             setVarData(data)
             form.reset()
        }
    }

    function GenarateFakeUrl(image) {
        let localUrl = ''
        if (image) {
            localUrl = URL.createObjectURL(image)
        }
        return localUrl
    }

    async function SaveToDatabase() {
        let hasVariance = 0
        if(varianceData?.length>0){
            hasVariance = 1
        }
        let data = {
            en_sort_title: item.en_sort_title,
            en_long_title: item.en_long_title,
            bn_sort_title: item.bn_sort_title,
            bn_long_title: item.bn_long_title,
            item_code: item.item_code,
            selected_catagory_id: item.selected_catagory_id,
            selected_brand_id: item.selected_brand_id,
            ISBN_number: item.ISBN_number,
            selectedUnit: item.selectedUnit,
            en_description: item.en_description,
            bn_description: item.bn_description,
            en_speicification: item.en_speicification,
            bn_speicification: item.bn_speicification,
            Selling_price: item.Selling_price,
            tax: item.tax ? item.tax:0,
            Promo_price: item.Promo_price?item.Promo_price:0,
            Start_date: item.Start_date,
            End_date: item.End_date,
            hasVariance: hasVariance,
            created_date:new Date().getTime(),
            hasAge:ageIndicationValue,
            product_slug:item.en_sort_title.toLowerCase()
        }

        let mitemImage = item.itemImage
        try {
            const toast_id = toast.loading("Please wait...")
            let itemID = await SaveProducts(data, mitemImage[0])
            if (itemID.insertId) {
                // if (item.galleryImage) {
                //     await SaveGalleryData(item.galleryImage, itemID.insertId)
                // }
                SaveVarianceToDatabase(itemID.insertId, toast_id)
            } else if (itemID.warningStatus) {
                toast.warn('Product Name Duplicate Found')
            } else {
                toast.error('No Data Inserted')
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    function SaveVarianceToDatabase(id, toast_id) {
        let myPromises = varianceData?.map(async function (element, index) {
            // console.log(element);
            let field = {
                Variance_name: element.Variance_name,
                attr_value: element.selectedVarUnit,
                serializer: index,
                product_id: id,
                status: 1,
                created_date: new Date().getTime()
            }
           await SaveProductVariance(field, element.ShadeIamge, element.MainIamge)
        })
        Promise.all(myPromises).then(() => {
        //  toast.success('Product Added Successfully')
         toast.update(toast_id, { render: "Product Added Successfully", type: "success", isLoading: false, autoClose:true });
         setstate(1)
         setItem({})
     })
    }



    function deleteClick(e, index) {
        let arr = [...varianceData]
        arr.splice(index, 1)
        setVarData(arr)
    }
    
    async function getSelectedData() {
        let tempArr = []
        allAttributes.forEach(element => {
            if (element.attr_name_id === item.attribute_id) {
                tempArr.push(element)
            }
        });
         setVarianceOp(tempArr)
    }
    useEffect(() => {
        getSelectedData() 
    }, [showState])

    const TableItem = <>
    {varianceData ? varianceData.map((item, index) => {
        return <Table param1={index + 1} param2={item.Variance_name}
        param3={
            varienceOp?.filter((fitem)=>{
                return fitem.id == item.selectedVarUnit ? fitem:''
            })
            .map((vitem)=>{
                return vitem.attr_value_name
            })
        }
        param4={<img src={item.MainIamgeurl} alt={item.MainIamge ? item.MainIamge.name : ""} />} param5={<img src={item.ShadeIamgeurl} alt={item.ShadeIamge ? item.ShadeIamge.name : ""} />}
            param6={<ToggleSwitch toggleOnOff ={item.status} />}
            param7={<>
            <button className='delete' style={{color:'white', padding:'4px 8px', borderRadius:'5px'}} onClick={(e)=>{deleteClick(e, index)}} identifire={item.Variance_name} >Delete</button>
            </>}
        />
    })
    : "Add Data"}
</>

    

    return (
        <>
            <div className={ProductsAttributesCss.att_head}>
                <h4>Product Attributes</h4>
                <div>
                    <input type="checkbox" id='age' name="age" onChange={onAgeChange} />
                    <label htmlFor="age"> Have Age Variations?</label>
                </div>
            </div>
            <section className={ProductsAttributesCss.main}>
              <form onSubmit={addVarianceClick}>
              <div className={ProductsAttributesCss.input_wrapper}>
                    <div className={ProductsAttributesCss.common_att}>
                        <label>Variance Name *</label>
                        <input name='Variance_name' type='text' required />
                    </div>
                    <div className={ProductsAttributesCss.common_att}>
                        <label>Attribute Value </label>
                        <Select
                        options={varienceOp}
                        getOptionLabel={(option)=>`${option.attr_value_name}`}
                        getOptionValue={(option)=>`${option.id}`}
                        isClearable
                        name='selectedVarUnit'

                        />
                    </div>
                    {/* image upload */}
                    <div className={ProductsAttributesCss.common_att}>
                        <label className="additemlabel">Product Image *</label>
                        <input name="MainIamge"  type={"file"} required multiple ={false} accept='image/webp'/>
                    </div>
                    <div className={ProductsAttributesCss.common_att}>
                        <label className="additemlabel">Shade Image *</label>
                        <input name="ShadeIamge"  type={"file"} required multiple= {false} accept='image/webp'/>
                    </div>
                    <div className={ProductsAttributesCss.att_buttons}>
                        <button>Add Attribute</button>
                    </div>
                </div>
                
              </form>

                <table>
                    <tbody>
                    <tr>
                        <th>Sl</th>
                        <th>Variance Name</th>
                        <th>Variations</th>
                        <th>Variation Image</th>
                        <th>Shade Image</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    {TableItem}
                    </tbody>
                </table>
                {/*  onClick={SaveToDatabase} */}
                {/* <input   type={"submit"} value="Save" /> */}
                <button onClick={SaveToDatabase} className={ProductsAttributesCss.submitbutton}>Save Data</button>
                
            </section>
        </>
    )
}

export default ProductsAttributes


const ToggleSwitch = ({ toggleOnOff}) => {
    return (
        <>
            {toggleOnOff ? <div className='ToggleSwitchon'> <p> </p><FontAwesomeIcon icon={faToggleOn} /></div> : <div className='ToggleSwitchoff'><p> </p><FontAwesomeIcon icon={faToggleOff} /></div>}
        </>
    )
}