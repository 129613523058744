import React from 'react'
import { NavLink, Route, Routes } from 'react-router-dom'
import AddArea from './address_book_comp/AddArea'
import ViewDivision from './address_book_comp/ViewDivision'
import ViewDistrict from './address_book_comp/ViewDistrict'
import ViewArea from './address_book_comp/ViewArea'
import AddressBookCss from './css/AddressBook.module.css'
const AddressBook = () => {
  return (
    <div>
       <div className={AddressBookCss.menupart}>
                <ul className={AddressBookCss.menuitems}>
                    <NavLink to={"/admin/address_book/add_address"}>
                        <li >Add Area</li>
                    </NavLink>
                    <NavLink to={"/admin/address_book/view_division"}>
                        <li >View Division</li>
                    </NavLink>
                    <NavLink to={"/admin/address_book/view_district"}>
                        <li >View District</li>
                    </NavLink>
                    <NavLink to={"/admin/address_book/view_area"}>
                        <li >View Area</li>
                    </NavLink>
                   
                </ul>
            </div>
      <div>
        <Routes>
          <Route path='/' element={<AddArea />} />
          <Route path='/add_address' element={<AddArea />} />
          <Route path='/view_division' element={<ViewDivision />} />
          <Route path='/view_district' element={<ViewDistrict />} />
          <Route path='/view_area' element={<ViewArea />} />
        </Routes>
      </div>
    </div>


  )
}

export default AddressBook 