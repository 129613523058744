import MyAxios from 'axios'
import { API_LINK } from './APILink'
const MY_API_LINK = `${API_LINK}product-variance/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}
const configfile = {
    headers:{
        'Content-Type': 'multipart/form-data'
    }
}

export async function SaveProductVariance(data, shadeImage, mainImage) {
    const shadeformData = new FormData();
    shadeformData.append("myfile", shadeImage);
    const mainformData = new FormData();
    mainformData.append("myfile", mainImage);
    try {
        let shadeImageName = await MyAxios.post(`${MY_API_LINK}upload`, shadeformData, configfile)
        let mainImageName = await MyAxios.post(`${MY_API_LINK}upload`, mainformData, configfile)
       let result = await MyAxios.post(`${MY_API_LINK}add`, {...data, ShadeIamge:shadeImageName.data, MainIamge:mainImageName.data}, configdata)
       console.log({...data, ShadeIamge:shadeImageName.data, MainIamge:mainImageName.data});
        return result.data       
    } catch (error) {
        return 0
    }    
}

export async function GetSingleProductVariance(id) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetAllVariance() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetFilterProductVariance(query) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}filter/${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateProductVariance(id, data, mainImage, shadeImage ) {
    try {
        let mIname = data.MainIamge
        let sIname = data.ShadeIamge
        if (shadeImage) {
            const shadeformData = new FormData();
            shadeformData.append("myfile", shadeImage);
            let shadeImageName = await MyAxios.post(`${MY_API_LINK}upload`, shadeformData, configfile)
            sIname = shadeImageName.data
        }
        if (mainImage) {
            const mainformData = new FormData();
            mainformData.append("myfile", mainImage);
            let mainImageName = await MyAxios.post(`${MY_API_LINK}upload`, mainformData, configfile)
            mIname = mainImageName.data 
        }
    //    console.log({...data, MainIamge:mIname, ShadeIamge:sIname});
        let result = await MyAxios.put(`${MY_API_LINK}${id}`, {...data, MainIamge:mIname, ShadeIamge:sIname}, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DeleteVariaceImage(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}delete-image`, data, configdata)
        return result.data
    } catch (error) {
        return 0
    }    
}
export async function DeleteVariance(id) {
    try {
        let result = await MyAxios.delete(`${MY_API_LINK}${id}`,)
        return result.data
    } catch (error) {
        return 0
    }    
}


export async function GetVarianceImage(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}get-image`, data, configdata)
        return result.data
    } catch (error) {
        return 0
    }    
}
