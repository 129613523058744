import { faEye, faTrashRestore } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { GetFilteredDataExpress, UpdateItemExpress } from '../../api/ExpressFunctions'
import { LocalTimeStampToDateOnly } from '../MyFunctions/MyFunctions'
import CustomerTrashListCss from './css/CustomerTrashList.module.css'

const CustomerTrashList = () => {
    const [userData, setUserData] = useState()
    const [addressData, setAddressData] = useState()
async function getAllUser(){
    
  let myuserData = [] // await GetFilteredDataExpress('user', 'deleted', 1)
  let sortArr = myuserData.sort((a, b) => (a.id > b.id) ? -1 : 1)
   setUserData(sortArr)
  }
  useEffect(()=>{
    getAllUser()
  },[])

//onClick={(e)=>{onAddressViewClick(e, item)}}

async function onRestoreClick(e, item) {
   
        let newData = [...userData]
        for (let index = 0; index < newData.length; index++) {
          const element = newData[index];
          if (element.id===item.id) {
              newData.splice(index, 1)
              await UpdateItemExpress('user',{deleted:0}, item.id )
              break;
          }
          
        }
        setUserData(newData)
}

function onAddressViewClick(e, item) {
    //console.log(item)
    setAddressData(item)
  }

  const TableItem = userData?.map((item, index)=>{
    return <MyTable key={index} sl={index+1} joined={item.created_date} name={item.name} email={item.email} address={<span className={CustomerTrashListCss.view_address} onClick={(e)=>{onAddressViewClick(e, item)}}><FontAwesomeIcon icon={faEye}/></span>} phone={item.phone}
    action={<div className={CustomerTrashListCss.userIcon}>
      {/* <FontAwesomeIcon className='infobutton' icon={faCircleInfo} onClick={(e)=>{onInfoClick(e, item)}}/> <FontAwesomeIcon  icon={faEdit} onClick={(e)=>{onEditClick(e, item)}}/> <FontAwesomeIcon icon={faTrashCan} onClick={(e)=>{onDeleteClick(e, item)}}/> */}
      <FontAwesomeIcon icon={faTrashRestore} onClick={(e)=>{onRestoreClick(e, item)}} style={{color:'green'}}/>
      </div>}/>
  })
   
  return (
    <>
     <section className={`${CustomerTrashListCss.salesMain} ${CustomerTrashListCss.manageuserMain}`}>
     {addressData ? <AddressViewPop addressData={addressData} setAddressData={setAddressData}/>:""}
        <div className={CustomerTrashListCss.innerSale}>
            <h2 className={CustomerTrashListCss.saleHeading}>Customer Trash List </h2>
           
            <div className={CustomerTrashListCss.tableSection}>
                        <table>
                            <tbody>
                            <tr>
                                <th>SL</th>
                                <th>Joined</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>Phone Number</th>
                                <th>Action</th>
                            </tr>
                            {TableItem}
                            </tbody>
                        </table>
                    </div>
        </div>
    </section>
    
    </>
  )
}

export default CustomerTrashList

const AddressViewPop = ({addressData, setAddressData})=>{
    const [tableData, setTableData] = useState()
    const [dataFound, setDataFound] = useState(false)
  function CloseInfo(){
    setAddressData()
  }

  async function getAddressData() {
     let data =  await GetFilteredDataExpress('user_address_book', 'userID', addressData.id)
    if (data.length>0) {
      setDataFound(true)
    } else {
      setDataFound(false)
    }
   setTableData(data)
  }
  useEffect(()=>{
    getAddressData()
  },[])
  const zindex = {
    zIndex:100
  }
  const TableItem = tableData?.map((item, index)=>{
    return <AddressTable sl={index+1} address={item.Address} phone={item.Phone} divition={item.Divition} district={item.District} status={item.active ? 'Active':''}/>
  })

  return (
    <div style={zindex} className={`${CustomerTrashListCss.EditUserPop} ${CustomerTrashListCss.address_pop}`}>
       {dataFound ? 

        <div className={CustomerTrashListCss.tableSection}>
        <table>
          <tr>
            <th>SL</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Divistion</th>
            <th>District</th>
            <th>Status</th>
          </tr>
          {TableItem}
        </table>
      </div>
      :<h2>This user has no Address</h2>}
      <button onClick={CloseInfo}>Close</button>
    </div>
  )
}

const AddressTable = ({sl, address, phone, divition, district, status}) => {

    return <>
      <tr>
        <td>{sl}</td>
        <td>{address}</td>
        <td>{phone}</td>
        <td>{divition}</td>
        <td>{district}</td>
        <td>{status}</td>    
      </tr>
    </>
  }

const MyTable = ({sl,joined, name, email, address, phone, action}) => {


    return <>
      <tr>
        <td>{sl}</td>
        <td>{LocalTimeStampToDateOnly(joined)}</td>
        <td>{name}</td>
        <td>{email}</td>
        <td>{address}</td>
        <td>{phone}</td>
        <td>{action}</td>    
      </tr>
    </>
  }