import React, { useEffect, useState } from 'react'
import { GetAllDataExpress, GetDoubleQueryExpress, GetFilteredDataExpress,
     IncreaseValue, SaveDataExpress, UpdateItemExpress } from '../../../api/ExpressFunctions'
     import DepositeCss from './Css/Deposite.module.css'
const Deposite = () => {
    const [bankList, setBankList] = useState()
    const [inputData, setInputData] = useState()
    const [errText, setErrText] = useState()
    const [currentBalance, setCurrentBalance] = useState()
    function onInputChange(e) {
        let name = e.target.name
        let value = e.target.value
        setInputData({...inputData, [name]:value})
    }
    function onbankChange(e) {
        let name = e.target.name
        let value = e.target.value
      
        if (value) {
           
            bankList.forEach(element => {
                if (element.id===parseInt(value)) {
                   // console.log(element)
                    setInputData({...inputData, [name]:value, bankName:element.bankName,acName:element.acName, acNumber:element.acNumber, bankID:element.id})                   
                }
            });
          
        }else{
            setInputData({...inputData, [name]:value, bankName:'',acName:'', acNumber:''})
        }
    }

  async  function onSaveClick() {
        let processorData = window.localStorage.getItem('userData')
        processorData = JSON.parse(processorData)
        let userID = window.localStorage.getItem('user_id')
       if (inputData) {
            let withdraw_dateErr = ValidateFunction('withdraw_date', 'Withdraw Date')
            let selected_bankIDErr = ValidateFunction('selected_bankID', 'Bank Name ')
            // let acNameErr = ValidateFunction('acName', 'Account Name ')
            let receiptNumberErr = ValidateFunction('receiptNumber', 'Check Number ')
            let amountErr = ValidateFunction('amount', 'Amount is ')
            let noteErr = ValidateFunction('note', 'Note is ')
            setErrText({withdraw_dateErr, selected_bankIDErr, receiptNumberErr, amountErr, noteErr})

            if (!withdraw_dateErr && !selected_bankIDErr && !receiptNumberErr && !amountErr && !noteErr) {
                
                if (currentBalance > parseFloat(inputData.amount)) {
                    let debitData = {
                        identity: inputData.receiptNumber,
                        paymentAmmount:0,
                        particulars:'Deposite',
                        processorID:userID,
                        paymentAmmount:parseFloat(inputData.amount),
                        remark:inputData.note,
                        paymentType :'cash',
                        created_date:new Date().getTime()
                    }
               let debitID =   await SaveDataExpress('debit_voucher', {...debitData})
                   
                    let bankData = {
                      debitCredit:1,
                      bankID: inputData.selected_bankID,
                      identity: inputData.receiptNumber,
                      receiptNumber:inputData.receiptNumber,
                      voucherNo: debitID,
                      receiveAmmount:parseFloat(inputData.amount),
                      particulars:'Deposite',
                      head:'Deposite',
                      processorID:userID,
                      paymentAmmount:0,
                      remark:inputData.note,
                      transactionType:'Cash Deposite',
                      voucherType:2,
                      created_date:new Date().getTime()  
                    }
                  // console.log(inputData, inputData.amount)
                   // console.log(bankData)
                await SaveDataExpress('bank_transaction', {...bankData})
                await IncreaseValue('bank_list', 'ammount', inputData.amount, 'id', inputData.bankID)
               //  await UpdateItemExpress('bank_list', {ammount:currentBalance + parseFloat(inputData.amount)}, inputData.bankID)
                 let dayBookData = {
                    created_date:new Date().getTime(),
                    paticulars:"Bank Deposite",
                    identity:inputData.receiptNumber,
                    received:0,
                    payment:parseFloat(inputData.amount),
                    processorID:window.localStorage.getItem('user_id'),
                }
                 await SaveDataExpress('day_book', {...dayBookData})
                    alert('Saved')
                }else{
                    alert('You Dont Have Suffient Value')
                }
            
            }
       }else{
        alert('input data required')
       }
    }

    function ValidateFunction(field, message) {
        let text = ""
            if (!inputData[field]) {
                text = message + " is Required"
            }
        return text;
      }
     async function getDatafromDatabase() {
        let getCreditData = await GetDoubleQueryExpress('credit_voucher', 'particulars', 'transactionType', 'Sales', 'cash')
        let getDebitData = await GetFilteredDataExpress('debit_voucher', 'paymentType', 'cash')
        let CashTotal = 0
        getCreditData.forEach(element => {
            CashTotal += element.receiveAmmount
        });
        getDebitData.forEach(element => {
            CashTotal -= element.paymentAmmount
        });
        setCurrentBalance(CashTotal)

        let myBanklist = await GetAllDataExpress('bank_list')
        // console.log(getCreditData, getDebitData)
        setBankList(myBanklist)
      }
      useEffect(()=>{
        getDatafromDatabase()
      },[])

    return (
        <>
            <section style={{ backgroundColor: '#d7edff', padding: '10px' }}>
                <div className={DepositeCss.withdraw_headign}>
                <h2>Deposite</h2>
                <p>Current Cash Balance : {currentBalance ? currentBalance:'0'} TK</p>
                </div>
                <div className={DepositeCss.addsupplier}>
                    <div className={DepositeCss.supplierinput} >
                        <label>Date *</label>
                        <input type={'date'} name='withdraw_date'  onChange={onInputChange}/>
                        {errText ? <p className={DepositeCss.WarningText}>{errText?.withdraw_dateErr}</p> : ""}
                    </div>
                    <div className={DepositeCss.supplierinput} >
                        <label>Select Bank *</label>
                        <select name='selected_bankID' onChange={onbankChange}>
                           <option value={''}>Select Bank</option>
                           {bankList?.map((item ,index)=>{
                                return <option key={index} value={item.id}>{item.bankName} ({item.acName})</option>
                           })}
                        </select>
                        {errText ? <p className={DepositeCss.WarningText}>{errText?.selected_bankIDErr}</p> : ""}
                    </div>
            
                    <div className={DepositeCss.supplierinput}>
                        <label>Receipt Number *</label>
                        <input name='receiptNumber' type='number' placeholder='Receipt Number' onChange={onInputChange}/>
                        {errText ? <p className={DepositeCss.WarningText}>{errText?.receiptNumberErr}</p> : ""}
                    </div>
                    <div className={DepositeCss.supplierinput}>
                        <label>Total Amount *</label>
                        <input name='amount' type='number' placeholder='Amount' onChange={onInputChange} />
                        {errText ? <p className={DepositeCss.WarningText}>{errText?.amountErr}</p> : ""}
                    </div>
                    <div className={DepositeCss.supplierinput}>
                        <label>Note *</label>
                        <input name='note' type='text' placeholder='type your note' onChange={onInputChange} />
                        {errText ? <p className={DepositeCss.WarningText}>{errText?.noteErr}</p> : ""}
                    </div>
                    <div className={DepositeCss.buttonsection}>
                        <button onClick={onSaveClick}>SAVE</button>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Deposite