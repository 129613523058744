import { faToggleOff, faToggleOn, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { GetAllDataExpress, SaveDataExpress, UpdateItemExpress } from '../../api/ExpressFunctions'
import { LocalTimeStampToDate } from '../MyFunctions/MyFunctions'
import ExpenseHeadCss from './css/ExpenseHead.module.css'

const ExpenseHead = () => {
    const [groupData, setGroupData] = useState()
    const [tableData, setTableData] = useState()
    const [inputData, setInputData] = useState()
    const [errMessage, setErrMessage] = useState()

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setInputData({...inputData, [name]:value})
    }
   async function onAddClick() {
        let headNameErr  = ValidateFunction('headName', 'Group Name ')
        let selectedGroupErr  = ValidateFunction('selectedGroup', 'Description ')
        setErrMessage({headNameErr, selectedGroupErr})
        if (!headNameErr && !selectedGroupErr) {
            let mgroupName = ''
            for (let index = 0; index < groupData.length; index++) {
                const element = groupData[index];
                if (element.id===parseInt(inputData.selectedGroup)) {
                    mgroupName = element.groupName
                    break;
                }
                
            }
         //   console.log({...inputData, status:1, created_date:new Date().getTime()})
            let dataID = await SaveDataExpress('expense_head', {...inputData, status:1, created_date:new Date().getTime()})
            let newArr = []
            tableData?.map((item) => {
                newArr.push(item)
            })
            newArr.push({...inputData, status:true, headID:dataID, created_date:new Date().getTime(), selectedGroupName:mgroupName})
           
            //  console.log(newArr)
            setTableData(newArr)
        }
    }

    function ValidateFunction(field, message) {
        let text = ""
        if (!inputData?.[field]) {
            text = message + " is Required"
        }
        return text;
    }
    async function getDataFromDatabase() {

        let mheadData = await GetAllDataExpress('expense_head')
        let mgroupData = await GetAllDataExpress('expense_group')
        let finalArr = []
        mheadData.map((item)=>{
            for (let index = 0; index < mgroupData.length; index++) {
                const element = mgroupData[index];
                if (element.id===parseInt(item.selectedGroup)) {
                    finalArr.push({...item, selectedGroupName:element.groupName})
                    break;
                }
            }
        })
        

         setTableData(finalArr)
        setGroupData(mgroupData)
    }
    useEffect(()=>{
        getDataFromDatabase()
    },[])

    return (
        <>
            <section className={`${ExpenseHeadCss.salesMain} ${ExpenseHeadCss.expense}`}>
                <div className={ExpenseHeadCss.innerSale}>
                    <h2 className={ExpenseHeadCss.saleHeading}>Expense Head</h2>
                    <div className={ExpenseHeadCss.input_section}>
                        <div className={`${ExpenseHeadCss.input_common} ${ExpenseHeadCss.expenceLanel}`}>
                            <label>Expense Head Name</label>
                            <input name='headName' type={'text'} onChange={onDataChange} />
                            {errMessage ? <p className={ExpenseHeadCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.headNameErr}</p> : ''}
                        </div>
                        <div className={`${ExpenseHeadCss.input_common} ${ExpenseHeadCss.expenceLanel}`}>
                            <label>Expense Group</label>
                            <select name='selectedGroup' onChange={onDataChange}>
                                <option value={''}>Select Group</option>
                                {groupData?.filter((fitem)=>{
                                   
                                   return fitem.status ? fitem:''
                                }).map((gitem, index)=>{
                                    return <option key={index}  value={gitem.id}>{gitem.groupName}</option>
                                })}
                            </select>

                            {errMessage ? <p className={ExpenseHeadCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.selectedGroupErr}</p> : ''}
                        </div>
                        <div className={ExpenseHeadCss.input_common}>
                            <button onClick={onAddClick}>Add New</button>
                        </div>
                    </div>
                    <div className={ExpenseHeadCss.tableSection} >
                        <table style={{marginTop:'0'}}>
                            <tbody>
                                <tr>
                                    <th>Sl</th>
                                    <th>Date</th>
                                    <th>Expense Group Name</th>
                                    <th>Expense Head Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                {tableData?.map((item, index)=>{
                                    return <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{LocalTimeStampToDate(item.created_date/1000)}</td>
                                        <td>{item.selectedGroupName}</td>
                                        <td>{item.headName}</td>
                                        <td>{<ToggleSwitch toggleOnOff={item.status} id={item.headID} tableData={tableData} setTableData={setTableData}/>}</td>
                                        <td><FontAwesomeIcon icon={faTrashCan} className={ExpenseHeadCss.color_red}/></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExpenseHead

const ToggleSwitch = ({ toggleOnOff, id, tableData, setTableData}) => {

    function toggleClick() {
       let myArr = []
        tableData?.map(async (item)=>{
            if (item.headID===id) {
                let okActive = !item.status
                item = {...item, status:okActive}
                myArr.push(item)
                await UpdateItemExpress('expense_head', {status:okActive}, id)
                
            }else{
                myArr.push(item)
            }
        })
        setTableData(myArr)
       // console.log(tableData[findIndex]['active'])
    }

    return (
        <>
            {toggleOnOff ? <div className={ExpenseHeadCss.ToggleSwitchon}> <p onClick={() => { toggleClick(id) }}> </p><FontAwesomeIcon icon={faToggleOn} /></div> : <div className={ExpenseHeadCss.ToggleSwitchoff}><p onClick={() => { toggleClick(id) }}> </p><FontAwesomeIcon icon={faToggleOff} /></div>}
        </>
    )
}