import React from 'react'
import AtributeButtonCss from './css/AtributeButton.module.css'

function AtributeButton(props) {
    const { clickevent, value, identity} = props
    return (
        <>
            <p className={AtributeButtonCss.atributeValue} uid={identity}>{value} <span uid={identity} onClick={clickevent} className={AtributeButtonCss.deletAttr}>X</span></p>
        </>
    )
}

export default AtributeButton