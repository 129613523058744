import { toast } from "react-toastify";

export function CheckRatio(imageFile, callback) {
    let isImageOK = false
    let showImagediv = document.getElementById('image_show')
    const img = document.createElement('img');
    const objectURL = URL.createObjectURL(imageFile);
    img.onload = function handleLoad() { 
        if (img.width === img.height) {
         toast.success('Image Size is ok')
         isImageOK = true
        }else{
            toast.warn('Image Aspect Ration is not ok. Please Select 1:1 Image')
        }
        URL.revokeObjectURL(objectURL);
        callback(isImageOK)
      };
      img.src = objectURL;
      showImagediv.appendChild(img);
}

/**
 * Format a date string to a Date 'dd-mm-yyyy'.
 * @param {string} date - Input any valid date formate or timestamp.
 * @returns {string} The formatted date string in the format 'dd-mm-yyyy'.
 */

export  function DateFormate(date){
    const formattedDate = new Date(date)
  .toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
  .replaceAll('/', '-');
  return formattedDate
}

/**
 * Format a date string to a Date & Time format.
 * @param {string} sdate - The input date string in the format 'YYYY-MM-DDTHH:mm:ss.000Z'.
 * @returns {string} The formatted date string in the format 'dd-mm-yyyy hh:mm:ss AM/PM'.
 */
export  function ServerTimeToFormateTime(sdate, is12){
  const date = new Date(sdate).toLocaleString("en-US", { timeZone: "Asia/Dhaka", hour12: is12});
  let formattedDate = DateFormate(date.split(',')[0])
  let formattedTime = date.split(',')[1]
  const combinedDateTime = `${formattedDate},${formattedTime}`;
  return combinedDateTime
}