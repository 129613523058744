import { faBars, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ServerTimeToFormateTime } from '../../api/GlobalFunctions'
import { GetCompleteSalesData, GetTotalOrderNumber } from '../../api/SalesApi'
import LoadingShow from '../smallThings/LoadingShow'
import SalesSearchComp from './SalesSearchComp'
import ActivityViewModel from './models/ActivityViewModel'
import CustomerInformationModel from './models/CustomerInformationModel'
import CompOrCss from './salesCss/CompleteOrder.module.css'
const CompleteOrder = ({ mtableData }) => {
    const [viewNumber, setViewNumber] = useState(10)
    const [numberofPage, setNumberofPage] = useState()
    const [lastOrderID, setLastOrderID] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [tableData, setTableData] = useState()
    const [showUser, setShowUser] = useState(false)
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [activityView, setActivityView] = useState(false)

    async function customerClick(item) {
        setShowUser(item)
        document.body.style.overflow = 'hidden'
    }
    async function activityViewClick(e, item) {
        setActivityView(item)
        document.body.style.overflow = 'hidden'
    }
    async function tableDataFilter() {
        setSpinnerShow(true)
        let gettotalOrder = await GetTotalOrderNumber({filter:'deliveryComplete = 1'})
        let totalOrder = gettotalOrder[0]?.totalOrder
        setNumberofPage(Math.round(totalOrder/viewNumber))
        let data = await GetCompleteSalesData({limit:viewNumber})
        let tempArr = []
        data?.forEach(element => {
            
            let perdiscount = element.discountPercent*parseInt(element.total_orprice)/100
            let discount = element.total_orprice - (parseInt(element.total_price)-perdiscount)
            let total = parseInt(element.total_orprice)+element.deliveryCharge - discount
            let due = total - (element.receiveAmount + element.rprice)
            tempArr.push({...element, total, due})
            // if (element.id === 2567) {
            //     // console.log({...element, total, due,}, perdiscount, discount, element.discountPercent);
            //     console.log(perdiscount, element.total_orprice, element.total_price, discount);
            // }
        });
        // console.log(tempArr); 
        let lastID = tempArr[tempArr.length - 1]
        setLastOrderID(lastID.id)
        setTableData(tempArr)
        setSpinnerShow(false)
    }

    useEffect(() => {
        tableDataFilter()
    }, [mtableData, viewNumber])

    const TableItem = tableData?.map((item) => {
        return <MyTable key={item.id} id={<Link to={`/admin/sales/complete_orders/${item.id}`}>{'#' + item.id}</Link>} date={ServerTimeToFormateTime(item.created_date)} cname={item.name} cphone={item.aphone || item.phone }
            caddress={item.address || 'In House Delivery'}
            status={item.partialReturn ? <p className={CompOrCss.pertial_css}>Order Partial Return</p> : <p className={CompOrCss.complete_css}>Order Complete</p>}
            tammount={item.total.toFixed()}
            dueAmmount={item.due.toFixed()}
            action={
                <div className={CompOrCss.actionButton}>
                    <Link to={`/admin/sales/complete_orders/${item.id}`}><FontAwesomeIcon icon={faCircleInfo} /></Link>
                    <a onClick={(e) => { activityViewClick(e, item);  document.body.style.overflow = 'hidden' }}><FontAwesomeIcon className={CompOrCss.myBars} icon={faBars} /></a>
                </div>} customerClick={() => { customerClick(item) }} />
    })
    const [customChange, setCustomChange] = useState(false)

   async function onPreCLick() {
        let firstID = tableData[tableData.length - 1]
        let lastID = tableData[0]
        if (firstID.id>= lastOrderID) {
            alert('no previous data found')
        }else{
            setSpinnerShow(true)
            let data = await GetCompleteSalesData({limit:viewNumber, clicked:'pre',lastID:lastID.id})
            let tempArr = []
            data?.forEach(element => {
                let perdiscount = element.discountPercent*parseInt(element.total_orprice)/100
            let discount = element.total_orprice - (parseInt(element.total_price)-perdiscount)
            let total = parseInt(element.total_orprice)+element.deliveryCharge - discount
            let due = total - (element.receiveAmount + element.rprice)
                tempArr.push({...element, total, due})
            });
            let sortArr = tempArr.sort((a, b) => (a.id > b.id) ? -1 : 1)
            setTableData(sortArr)
            setSpinnerShow(false)
            setCurrentPage(currentPage => currentPage-1)
        }
       
              
    }
   async function onNextCLick() {
        let lastID = tableData[tableData.length - 1]
        setSpinnerShow(true)
        let data = await GetCompleteSalesData({limit:viewNumber, clicked:'next',lastID:lastID.id})
        // console.log(data[1]);
        let tempArr = []
        data?.forEach(element => {
            let perdiscount = element.discountPercent*parseInt(element.total_orprice)/100
            let discount = element.total_orprice - (parseInt(element.total_price)-perdiscount)
            let total = parseInt(element.total_orprice)+element.deliveryCharge - discount
            let due = total - (element.receiveAmount + element.rprice)
            tempArr.push({...element, total, due})
        });
        setTableData(tempArr)
        setSpinnerShow(false)
        setCurrentPage(currentPage => currentPage+1)
    }

    function onPageNoChange(e) {
       setCustomChange(e.target.value)
    }
    async function onCustomClick() {
       
    }

    return (
        <>
            {activityView ? <ActivityViewModel popUp={activityView} setPopUp={setActivityView}/> : ""}

            {showUser && <CustomerInformationModel popUp={showUser} setPopUp={setShowUser}/> }
            <section className={CompOrCss.salesMain}>
                <LoadingShow ShowLodign={spinnerShow}/>
                <div className={CompOrCss.innerSale}>
                    <h2 className={CompOrCss.saleHeading}>Complete Orders</h2>
                    {/* {console.log(tableData)} */}
                    <SalesSearchComp passedFrom={'complete'} setFilteredData={setTableData} limitNumber={viewNumber} />
                    <div className={CompOrCss.tableSection}>
                        <select className={CompOrCss.completeOrderSelect} onChange={(e) => { setViewNumber(e.target.value) }}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </select>
                        <table>
                            <tbody>
                                <tr>
                                    <th>id</th>
                                    <th>Date</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Customer Address</th>
                                    <th>Status</th>
                                    <th>Total Ammount</th>
                                    <th>Due Ammount</th>
                                    <th>Action</th>
                                </tr>
                                {TableItem}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={CompOrCss.pagination}>
                    <button onClick={onPreCLick}>Pre</button>
                    <input type={'number'} value={currentPage} onChange={onPageNoChange} />
                    <p>of {numberofPage}</p>
                    <button onClick={onNextCLick}>Next</button>
                    {customChange ? <button onClick={onCustomClick}>Submit</button> : ''}
                </div>
            </section>
        </>
    )
}

export default CompleteOrder


const MyTable = ({ id, date, cname, cphone, caddress, status, tammount, action, customerClick, dueAmmount }) => {

    return (
        <>
            <tr className={CompOrCss.complete_order_tr}>
                <td className={CompOrCss.invoiceClick}>{id}</td>
                <td>{date}</td>
                <td className={CompOrCss.customerClick} onClick={customerClick}>{cname}</td>
                <td>{cphone}</td>
                <td>{caddress}</td>
                <td>{status}</td>
                <td>{tammount}</td>
                <td>{dueAmmount}</td>
                <td>{action}</td>
            </tr>
        </>
    )
}