import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import AddnewCustomer from './AddnewCustomer'
import CustomerList from './CustomerList'
import CustomerTrashList from './CustomerTrashList'
import manageCss from './css/ManageCustomerMain.module.css'

const ManageCustomerMain = () => {
    const myParams = useParams()
    return (
        <>
            <div className={manageCss.menupart}>
                <ul className={manageCss.menuitems}>
                    <NavLink to={"/admin/manage_customer/customerlist"}>
                        <li >Manage Customer</li>
                    </NavLink>
                    <NavLink to={"/admin/manage_customer/addcustomer"}>
                        <li >Add New Customer</li>
                    </NavLink>
                    <NavLink to={"/admin/manage_customer/trashlist"}>
                        <li >Trash List</li>
                    </NavLink>
                </ul>
            </div>
            <div>
            {myParams.muser === undefined ? <CustomerList />: ""}
                {myParams.muser === "customerlist" ? <CustomerList />: ""}

                {myParams.muser === "addcustomer" ? <AddnewCustomer data ={'add new user'}/>: ""}
                {myParams.muser === "trashlist" ? <CustomerTrashList data ={'Trash List'}/>: ""}
            </div>
        </> 
    )
}

export default ManageCustomerMain

// const ComingSoon =({data})=>{
//     return (
//         <h1>Coming Soon {data}</h1>
//     )
// }