import { async } from '@firebase/util'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { GetDatafromDatabase, GetSingleData, GetSingleDoc, GetWhereAndOrderData, MultipleImageUploadwtihURL, SaveData, UpdateData,  } from '../../../AllContent/connector/DatabaseCheck'
import ComplainEditCss from './ComplainEdit.module.css'
const ComplainEdit = () => {
    const myParams = useParams()
    const myNavigate = useNavigate()
    const [showSpinner, setShowSpinner] = useState(false)
    const [complainType, setComplainType] = useState()
    const [complainCat, setComplainCat] = useState()
    const [priorityData, setPriorityData] = useState()
    const [ComplainData, setComplainData] = useState()
    const [userData, setUserData] = useState()
    const [errorMessage, setErrorMessage] = useState()

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setComplainData({ ...ComplainData, [name]: value })
    }

   async function saveToDataBase(params) {

    setComplainData()
    setUserData()
  //  console.log('data saved')
   // console.log(ComplainData?.complainImages.length)
//    let myID = await SaveData('testData', {name:'hellob'})
    }   

   async function onUpdateClick() {
        let subjectError = ValidateFunction('subject', 'Complain Subject ')
        let typeError = ValidateFunction('complainTypeID', 'Complain Type ')
        let catError = ValidateFunction('complainCatID', 'Complain Catagory ')
        let priorityError = ValidateFunction('complainPriorityID', 'Complain Priority ')
        let messageError = ValidateFunction('complainMessage', 'Complain Message ')
        setErrorMessage({subjectError, typeError, catError, priorityError, messageError,})
    
        let finalData = {
            ...ComplainData,
        }
        if (!subjectError && !typeError && !catError && !priorityError && !messageError) {
          // console.log(finalData)
            await UpdateData('userComplain', {...finalData}, myParams.dataid)
        }else{
           
        }
        myNavigate('/admin/manage_complains/complainlist')
        
    }

    function ValidateFunction(field, message) {
        let text = ""
        if (!ComplainData?.[field]) {
          text = message + "is Required"
      }
        return text;
    }
    async function getDatafromDataBase() {
        setShowSpinner(true)
        let mycomaplainData = await GetSingleDoc('userComplain', myParams.dataid)
        setComplainData(mycomaplainData.data())
        let mytypeData = await GetWhereAndOrderData('ComplainSetting', 'identity', 'type', 'createdDate', 'asc')
        setComplainType(mytypeData)

        let myCatData = await GetWhereAndOrderData('ComplainSetting', 'identity', 'category', 'createdDate', 'asc')
        setComplainCat(myCatData)
        let myPriotrityData = await GetWhereAndOrderData('ComplainSetting', 'identity', 'priority', 'createdDate', 'asc')
        setPriorityData(myPriotrityData)
        setShowSpinner(false)
    }

    useEffect(() => {
        getDatafromDataBase()
    }, [])
    const errorStyle = { margin: '0',
    padding: '0', color: 'red',
    width: '100%',
    textAlign: 'right',
}
    return (
        <>
        {showSpinner ? <Spinner text={'loading...'}/>:''}
            <section style={{ marginTop: '10px' }} className={ComplainEditCss.salesMain}>
                <div className={ComplainEditCss.innerSale}>
                    <h2 className={ComplainEditCss.saleHeading}>Complain Setting</h2>
                    <Link to={'/admin/manage_complains/complainlist'}>Back</Link>
                </div>
                <div className={`${ComplainEditCss.common_class} ${ComplainEditCss.complain_category}`} style={{ margin: '0 20px' }}>
                    <div className={ComplainEditCss.input_section}>
                        {/* <div className='input_wrapper dropdown-wrapper'>
                            <input onChange={onSearchChange} name='searchText' type={'text'} placeholder='Search By Costomer Name or Mobile Number' value={ searchText?.searchText} onFocus={()=>{setIsSelected(false)}}/>
                            <div className='dropdown-list'>
                            {CustomerList?.filter((fitem)=>{
                                let msearchText = searchText.searchText?.toLowerCase()
                                let matchText = fitem.name.toLowerCase()
                                let matchTextnumber = fitem.phone.toLowerCase()                             
                                if (msearchText && matchText!==msearchText && !isSelected && matchText.includes(msearchText)) {
                                    return fitem
                                }else if(msearchText && matchText!==msearchText && !isSelected && matchTextnumber.includes(msearchText)){
                                    return fitem
                                }else{
                                    return null
                                }
                            }).slice(0, 10)
                            .map((item, index)=>{
                               return <p key={index} onClick={()=>{setSearchText({searchText:item.name}); setUserData(item); setIsSelected(true)}}>{item.name} @ {item.phone}</p>
                            })}
                            </div>
                            
                        </div> */}
                        <p style={{...errorStyle}}>{errorMessage?.customerError}</p>
                        <div className={ComplainEditCss.input_wrapper}>
                            <p><strong>Customer Name :</strong> {ComplainData?.userName}</p> 
                        </div>
                        <div className={ComplainEditCss.input_wrapper}>
                            <label><strong>Complain Subject *</strong></label>
                            <input name='subject' type={'text'} placeholder='Complain Priority Name' onChange={onDataChange} value={ComplainData?.subject} />
                        </div>
                        <p style={{...errorStyle}}>{errorMessage?.subjectError}</p>
                        <div className={ComplainEditCss.input_wrapper}>
                            <label><strong>Complain Type *</strong></label>
                            <select name='complainTypeID' onChange={onDataChange} defaultValue={ComplainData?.complainTypeID} >
                                <option value={''}>select one</option>
                                {complainType?.map((item, index) => {
                                    return <option key={index} value={item.id} selected={ComplainData?.complainTypeID ===item.id ?true:false }>{item.complainType}</option>
                                })}
                            </select>
                        </div>
                        <p style={{...errorStyle}}>{errorMessage?.typeError}</p>
                        <div className={ComplainEditCss.input_wrapper}>
                            <label><strong>Complain Category *</strong></label>
                            <select name='complainCatID' onChange={onDataChange} defaultValue={''} >
                                <option value={''}>select one</option>
                                {complainCat?.map((item, index) => {
                                    return <option key={index} value={item.id} selected={ComplainData?.complainCatID ===item.id ?true:false }>{item.complainCatName}</option>
                                })}
                            </select>
                        </div>
                        <p style={{...errorStyle}}>{errorMessage?.catError}</p>
                        <div className={ComplainEditCss.input_wrapper}>
                            <label><strong>Complain Priority *</strong></label>
                            <select name='complainPriorityID' onChange={onDataChange} defaultValue={''} >
                                <option value={''}>select one</option>
                                {priorityData?.map((item, index) => {
                                    return <option key={index} value={item.id} selected={ComplainData?.complainPriorityID ===item.id ?true:false }>{item.priority}</option>
                                })}
                            </select>
                        </div>
                        <p style={{...errorStyle}}>{errorMessage?.priorityError}</p>
                        {/* <div className={ComplainEditCss.input_wrapper}>
                            <label><strong>Attachments </strong></label>
                            <input name='complainImages' type={'file'} onChange={onDataChange}  multiple />
                        </div> */}
                        <div className={ComplainEditCss.input_wrapper}>
                            <label><strong>Complain Message</strong></label>
                            <textarea name='complainMessage' rows="6" cols="50" placeholder='Complain Category Notes' onChange={onDataChange} value={ComplainData?.complainMessage}></textarea>
                        </div>
                        <p style={{...errorStyle}}>{errorMessage?.messageError}</p>

                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <button onClick={onUpdateClick} style={{ background: 'purple', marginRight: '10px' }}>Update Complain</button>
                        </div>
                        <div className={ComplainEditCss.image_view}>
                            {ComplainData?.complainImages?.map((image)=>{return <img key={image.name} src={image.url}/>})}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ComplainEdit

const Spinner = ({text}) => {
    const myIndex = {
      zIndex: '15' 
    }
    return (
       <>
         <div style={myIndex} className={ComplainEditCss.SpinnerWrrapper}>
        <div id="loader"></div>
        <p>{text}</p>
    </div>
       </>
    )
  }
