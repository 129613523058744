import React, { useEffect, useState } from 'react'
import { ApiLink } from '../../api/ExpressFunctions'
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import * as htmlToImage from 'html-to-image';
import ListItemPDFViewCss from './Css/ListItemPDFView.module.css'
const ListItemPDFView = () => {

    return (
        <>
            <PDFview/>
            {/* <PDFViewer width="70%" height="1100px">
                <MyDocument />
            </PDFViewer> */}
        </>
    )
}

export default ListItemPDFView

const PDFview = () => {
    let localList = window.localStorage.getItem('listedItem')
    const [productList, setProductList] = useState(localList ? JSON.parse(localList) : [])
    // console.log(productList?.variance);
    function onExportPDF() {
         window.print();
        // handleConvertToJPEG()
    }
    const htmlRef = React.useRef(null);
    const [imageDataUrl, setImageDataUrl] = React.useState('');
  
    const handleConvertToJPEG = () => {
      const htmlElement = htmlRef.current;
        //console.log(htmlElement);
    //   htmlToImage.toJpeg(htmlElement)
    //     .then(function (dataUrl) {
    //       setImageDataUrl(dataUrl);
    //     })
    //     .catch(function (error) {
    //       console.error('Error converting HTML to JPEG:', error);
    //     });
    htmlToImage.toJpeg(htmlElement)
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'image.jpg';
        link.click();
      })
      .catch(function (error) {
        console.error('Error converting HTML to JPEG:', error);
      });
    };
    
    useEffect(()=>{
       // handleConvertToJPEG()
    },[])
    return (
        <div className={ListItemPDFViewCss.page_wrapper} ref={htmlRef}>
            <button onClick={onExportPDF} className={ListItemPDFViewCss.export_button}>Export PDF</button>
            {productList?.map((item, index) => {
                return <div className={ListItemPDFViewCss.product_card} key={item.id + 'p'}>
                    <div className={ListItemPDFViewCss.name_section}>
                        <div className={ListItemPDFViewCss.sl_holder}>
                            <p>{index + 1}</p>
                        </div>
                        <p>{item.name}</p>
                    </div>
                    <div className={ListItemPDFViewCss.info_section_others}>
                        <div className={ListItemPDFViewCss.image_section}>
                            <img src={`${ApiLink}productimages/${item.imageName}`} alt={item.name} />
                        </div>
                        <div className={ListItemPDFViewCss.text_section}>
                            <div className={ListItemPDFViewCss.others_common}>
                                <p className={`${ListItemPDFViewCss.other_heading} ${ListItemPDFViewCss.head1}`}>MRP</p>
                                <p className={`${ListItemPDFViewCss.number_section} ${ListItemPDFViewCss.num1}`}>{item.price?.toFixed(2)}/-</p>
                            </div>
                            <div className={ListItemPDFViewCss.others_common}>
                                <p className={`${ListItemPDFViewCss.other_heading} ${ListItemPDFViewCss.head2}`}>Price for Square</p>
                                <p className={`${ListItemPDFViewCss.number_section} ${ListItemPDFViewCss.num2}`}>{Math.round(item.dicountPrice)?.toFixed(2)}/-</p>
                            </div>
                            {/* <div className='others_common'>
                                <p className='other_heading head3'>Ready Stock Quantity</p>
                                <p className='number_section num3'>{item.stock}</p>
                            </div>
                            <div className='others_common'>
                                <p className='other_heading head4'>Delivery Lead Time</p>
                                <p className='number_section num4'>{item.lead_date}</p>
                            </div> */}
                        </div>
                    </div>
                    <div className={ListItemPDFViewCss.variance}>
                        {item.variance?.map((vitem) => {
                            return <div key={vitem.id + 'v'} className={ListItemPDFViewCss.variance_item}>
                                <img src={`${ApiLink}/varianceimages/${vitem.ShadeIamge}`} alt='vr image' />
                                <p>{vitem.Variance_name}</p>
                            </div>
                        })}
                    </div>
                    <div className={ListItemPDFViewCss.fotter_section}>
                        <p>Selim Azam International Ltd</p>
                    </div>
                </div>
            })}

        </div>
        // <div ref={htmlRef}>
        //     <button onClick={onExportPDF} className='export_button'>Export PDF</button>
        //     <p>hello</p>
        // </div>

    )
}

const MyDocument = () => {

    let localList = window.localStorage.getItem('listedItem')
    const [productList, setProductList] = useState(localList ? JSON.parse(localList) : [])
    
    return <Document>
        
        {productList?.map((item, index) => {
            return <Page size={customPageSize} style={styles.page} key={index}>
            <View style={styles.topsection}>
                <Text>
                   {index+1}. 
                </Text>
                <Text> Product Name</Text>
            </View>
            <View style={styles.middlesection}>
                <View style={styles.imagesection}>
                    <Image src={'https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/New_york_times_square-terabass.jpg/1200px-New_york_times_square-terabass.jpg'} style={styles.image} />
                </View>
                <View>
                    <View style={styles.infotext1}>
                        <Text style={styles.text1}>MRP</Text>
                        <Text>1600/-</Text>
                    </View>
                    <View style={styles.infotext2}>
                        <Text >MRP</Text>
                        <Text>1600/-</Text>
                    </View>
                </View>
            </View>
            <View style={styles.fotterStyle}>
                <Text>Selim Azam International Ltd.</Text>
            </View>
        </Page>
        })}
    </Document>
}

const customPageSize = {
    width: 500, // specify the width in points
    height: 400, // specify the height in points
};
const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        backgroundColor: 'white',
        // padding:10,
    },
    topsection: {
        backgroundColor: 'green',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10
    },
    middlesection: {
        flexDirection: 'row',
        flex: 10,
        alignItems: 'center',
    },
    image: {
        width: '300px',
        height: '300px',
    },
    infotext1: {
        backgroundColor: 'gray',
        width: '180px',
        textAlign: 'center',
        borderRadius: 10,
        overflow: 'hidden'
    },
    text1: {
        backgroundColor: 'red',
        color: 'white'
    },
    fotterStyle: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'red',
        justifyContent: 'center',
        color: 'white'
    },
});

/*
 useEffect(()=>{
        let mAllVariance = JSON.parse(window.localStorage.getItem('allVariance'))
        setAllVariance(mAllVariance)
        let mAllProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        let sortArrid = mAllProducts.sort((a, b) => (a.id > b.id) ? -1 : 1)
        let sortArr = sortArrid.sort((a, b) => (a.selected_brand_id > b.selected_brand_id) ? 1 : -1)
        let tempArr = []
        sortArr.forEach(element => {
            if (element.selected_brand_id===6) {
                tempArr.push(element)
            }
        });
        setProductList(tempArr)
    },[])
*/