import AddnewUserCss from './css/AddnewUser.module.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify"
import { fetchAllUser } from '../../ReduxFiles/Slices/UserSlice'
import { SaveBackUserData } from '../../api/BackendUserApi'
import DummyImage from '../../assets/dummyuser.png'

const AddnewUser = () => {
  const dispatch = useDispatch()
  const myNavigate = useNavigate()
  const [userState, setUserState] = useState({})
  const [errState, seterrState] = useState()
  function onImageChange(e) {
    e.target.files.length ? setUserState({ ...userState, fakeURL: genarateFakeUrl(e.target.files[0]) }) : setUserState({ ...userState, fakeURL: '' })
  }

  function genarateFakeUrl(image) {
    let myurl = URL.createObjectURL(image)
    return myurl;
  }

  function onOptionChange(e) {
    let name = e.target.name
    let value = e.target.value
    let ruleNo = 0;
    if (value === 'Admin') {
      ruleNo = 1
    }
    else if (value === 'Editor') {
      ruleNo = 2
    }
    else if (value === 'Moderator') {
      ruleNo = 3
    }
    setUserState({ ...userState, [name]: ruleNo })
  }
  async function saveData(e) {
    e.preventDefault()
    const userName = e.target.userName.value
    const fullName = e.target.fullName.value
    const email = e.target.email.value
    const phone = e.target.phone.value
    const password = e.target.password.value
    const conpassword = e.target.conpassword.value
    const userbio = e.target.userbio.value
    const userrole = e.target.userrole.value
    const userImage = e.target.userImage.files[0]
    const active = 1
    const created_date = new Date().getTime()
    seterrState()
  
    if (password !== conpassword) {
      toast.warn("Password Not Matched")
    } else {
      // console.log({userName, fullName, email, phone, password, userbio, userrole, active, created_date});
       let data = await SaveBackUserData({userName, fullName, email, phone, password, userbio, userrole, active, created_date}, userImage)
      if (data.userExist) {
        toast.warn(data.message)
      } else {
        toast.success('user added succesfully')
        dispatch(fetchAllUser())
        myNavigate('/admin/manage_user/userlist')
      }

    }

  }

  return (
    <section className={AddnewUserCss.wrapper}>
    {/* {spinnerShow ? <Spinner/>:""} */}
    <div className={AddnewUserCss.holder}>
      <h2>Add New User</h2>
      <form className={AddnewUserCss.my_form} onSubmit={saveData}>
        <div className={AddnewUserCss.devide_wrap}>
        <div className={AddnewUserCss.input_side}>
          <div className={AddnewUserCss.input_inner}>
            <label>User Name*</label>
            <input type='text' name='userName' required />
          </div>
          <div className={AddnewUserCss.input_inner}>
            <label>Full Name*</label>
            <input type='text' name='fullName' required />
          </div>
          <div className={AddnewUserCss.input_inner}>
            <label>Email*</label>
            <input type='text' name='email' required />
          </div>
          <div className={AddnewUserCss.input_inner}>
            <label>Phone*</label>
            <input type='text' name='phone' required />
          </div>
          <div className={AddnewUserCss.input_inner}>
            <label>Password*</label>
            <input type='password' name='password' required />
          </div>
          <div className={AddnewUserCss.input_inner}>
            <label>Confirm Password*</label>
            <input type='password' name='conpassword' required />
          </div>
          <div className={AddnewUserCss.input_inner}>
            <label>Bio*</label>
            <input type='text' name='userbio' required />
          </div>
          <div className={AddnewUserCss.input_inner}>
            <label>Upload Image*</label>
            <input type='file' name='userImage' required onChange={onImageChange}/>
          </div>
          <div className={AddnewUserCss.input_inner}>
            <label>User Role</label>
            <select name='userrole' onChange={onOptionChange}>
              <option>Select Type</option>
              <option value={1}>Admin</option>
              <option value={2}>Editor</option>
              <option value={3}>Moderator</option>
            </select>
            {errState ? <p className={AddnewUserCss.WarningText}>{errState.userroleerr}</p> : ''}
          </div>
        </div>
        <div className={AddnewUserCss.image_section}>
            <img className={AddnewUserCss.userImage} src={userState?.fakeURL ? userState?.fakeURL : DummyImage} alt='1245' />
        </div>
        </div>
        
        <div className={AddnewUserCss.buttonsec}>
          <button >Create user</button>
        </div>

      </form>
    </div>
  </section>
  )
}

export default AddnewUser