import React from 'react'
import { useParams, NavLink} from 'react-router-dom'
import PurchaseConfirm from './PurchaseConfirm'
import PurchaseQuantityEdit from './PurchaseQuantityEdit'
import ViewOnly from './ViewOnly'
import POInvoice from './POInvoice'
import ViewOnlyReturn from './ViewOnlyReturn'
import POInvoiceReturn from './POInvoiceReturn'
import PurchaseEditCss from './css/PurchaseEdit.module.css'

const PurchaseEdit = () => {
    const myParams = useParams()
    // useEffect(()=>{
    //     console.log(myParams.action, (myParams.poid))
    // },[])
  return (
    <>
    <div className={PurchaseEditCss.menupart}>
                <ul className={PurchaseEditCss.menuitems}>
                    <NavLink to={"/admin/purchase/pendinglist"}>
                        <li >Pending Purchase List</li>
                    </NavLink>
                    <NavLink to={"/admin/purchase/purchasehistory"}>
                        <li >Purchase History</li>
                    </NavLink>
                    <NavLink to={"/admin/purchase/newpurchase"}>
                        <li >New Purchase</li>
                    </NavLink>
                    <NavLink to={"/admin/purchase/declinedpurchase"}>
                        <li >Declined Purchase</li>
                    </NavLink>
                    <NavLink to={"/admin/purchase/purchasereturn"}>
                        <li >Purchase Return</li>
                    </NavLink>
                    <NavLink to={"/admin/purchase/purchasereturnhistory"}>
                        <li >Purchase Return History</li>
                    </NavLink>
                </ul>
    </div>
    {myParams.action === 'varianceedit' ? <PurchaseConfirm /> : ""}
    {myParams.action === 'quantityedit' ? <PurchaseQuantityEdit /> : ""}

    {myParams.action === 'view' ? <ViewOnly /> : ""}
    {myParams.action === 'viewreturn' ? <ViewOnlyReturn /> : ""}
    {myParams.action === 'poinvoice' ? <POInvoice /> : ""}
    {myParams.action === 'returnpoinvoice' ? <POInvoiceReturn /> : ""}
    </>
  )
}

export default PurchaseEdit

const ComingSoon =({data})=>{

    return (
        <h1>Coming Soon {data}</h1>
    )
}