import React, { useEffect, useState } from 'react'
import { GetDistrictList } from '../../../api/Address/DistrictApi';
import { GetDivisionList } from '../../../api/Address/DivisionApi';
import { GetAreaList } from '../../../api/Address/AreaApi';
import ViewAreaCss from './css/ViewArea.module.css'
const ViewArea = () => {
    const [divisionArray, setDivitionArray] = useState()
    const [allDistrict, setAllDistrict] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [allArea, setAllArea] = useState([]);
    const [formatedData, setFormatedData] = useState([])

    async function getPrimaryAddress() {
        let division = await GetDivisionList()
        let sortArrDiv = division.sort((a, b) => (a.division_name > b.division_name) ? 1 : -1)
        setDivitionArray(sortArrDiv)
        let district = await GetDistrictList()
        let sortArrDis = district.sort((a, b) => (a.district_name > b.district_name) ? 1 : -1)
        setAllDistrict(sortArrDis)
        setDistrictData(sortArrDis)
        let area = await GetAreaList()
        let sortArrArea = area.sort((a, b) => (a.area_name > b.area_name) ? 1 : -1)
        setAllArea(sortArrArea)
        let tempArr = []
        sortArrDiv.forEach(element => {
            let tempDis = []
            sortArrDis.forEach(delement => {
                let tempArr = []
                if (element.division_id === delement.division_id) {
                    sortArrArea.forEach(aelement => {
                        if (aelement.district_id === delement.district_id) {
                            tempArr.push({ area_name: aelement.area_name, area_id: aelement.area_id })
                        }
                    });
                    tempDis.push({ district_name: delement.district_name, district_id: delement.district_id, areaArr: tempArr })
                }
            });
            let finalDatab = {
                division_id: element.division_id,
                division_name: element.division_name,
                districtArr: tempDis
            }
            tempArr.push(finalDatab)
        });
        //console.log(tempArr);
        setFormatedData(tempArr)
    }
    function onDivisionChange(e) {
        let tempArr = []
        let value = parseInt(e.target.value)

        if (value) {
            divisionArray?.forEach(element => {
                if (element.division_id === value) {
                    let tempDis = []
                    let selectedDis = []
                    allDistrict?.forEach(delement => {
                        let tempArr = []
                        if (element.division_id === delement.division_id) {
                            allArea?.forEach(aelement => {
                                if (aelement.district_id === delement.district_id) {
                                    tempArr.push({ area_name: aelement.area_name, area_id: aelement.area_id })
                                }
                            });
                            tempDis.push({ district_name: delement.district_name, district_id: delement.district_id, areaArr: tempArr })
                            selectedDis.push(delement)

                        }
                    });
                    let finalDatab = {
                        division_id: element.division_id,
                        division_name: element.division_name,
                        districtArr: tempDis
                    }
                    tempArr.push(finalDatab)
                    setDistrictData(selectedDis)
                }

            })

        } else {
            divisionArray.forEach(element => {
                let tempDis = []
                allDistrict.forEach(delement => {
                    if (element.division_id === delement.division_id) {
                        let tempArr = []
                        allArea?.forEach(aelement => {
                            if (aelement.district_id === delement.district_id) {
                                tempArr.push({ area_name: aelement.area_name, area_id: aelement.area_id })
                            }
                        });
                        tempDis.push({ district_name: delement.district_name, district_id: delement.district_id, areaArr: tempArr })
                    }
                });
                let finalDatab = {
                    division_id: element.division_id,
                    division_name: element.division_name,
                    districtArr: tempDis
                }
                tempArr.push(finalDatab)
            });
            setDistrictData(allDistrict)
        }
        //console.log(tempArr);
        setFormatedData(tempArr)
    }
    function onDistrictChange(e) {
        let tempArr = []
        let value = parseInt(e.target.value)
        if (value) {
            divisionArray?.forEach(element => {
                let tempDis = []
                allDistrict?.forEach(delement => {
                    let tempArr = []
                    if (delement.district_id === value) {
                        if (element.division_id === delement.division_id) {
                            allArea?.forEach(aelement => {
                                if (aelement.district_id === delement.district_id) {
                                    tempArr.push({ area_name: aelement.area_name, area_id: aelement.area_id })
                                }
                            });
                            tempDis.push({ district_name: delement.district_name, district_id: delement.district_id, areaArr: tempArr })
                        }
                    }
                });
                let finalDatab = {
                    division_id: element.division_id,
                    division_name: element.division_name,
                    districtArr: tempDis
                }
                tempArr.push(finalDatab)

            });

        } else {
            divisionArray.forEach(element => {
                let tempDis = []
                allDistrict.forEach(delement => {
                    if (element.division_id === delement.division_id) {
                        let tempArr = []
                        allArea?.forEach(aelement => {
                            if (aelement.district_id === delement.district_id) {
                                tempArr.push({ area_name: aelement.area_name, area_id: aelement.area_id })
                            }
                        });
                        tempDis.push({ district_name: delement.district_name, district_id: delement.district_id, areaArr: tempArr })
                    }
                });
                let finalDatab = {
                    division_id: element.division_id,
                    division_name: element.division_name,
                    districtArr: tempDis
                }
                tempArr.push(finalDatab)
            });
        }
        setFormatedData(tempArr)
    }

    useEffect(() => {
        getPrimaryAddress()
    }, [])
    return (
        <div className={ViewAreaCss.view_area}>
            <div className={ViewAreaCss.view_select}>
                <select className={ViewAreaCss.popOption} name={'Divition'} onChange={onDivisionChange} defaultValue={''}>
                    <option value={""}>--Select Division--</option>
                    {divisionArray?.map((item) => {
                        return <option key={item.division_id} value={item.division_id}>{item.division_name}</option>
                    })}
                </select>
                <select className={ViewAreaCss.popOption} name={'District'} onChange={onDistrictChange} defaultValue={''}>
                    <option value={""}>--Select District--</option>
                    {districtData?.map((item) => {
                        return <option key={item.district_id + 'di'} value={item.district_id}>{item.district_name}</option>
                    })}
                </select>
            </div>
            <table className={ViewAreaCss.district_table}>
                <thead>
                    <tr>
                        <th style={{ width: '20px' }}>sl</th>
                        <th style={{ width: '50px' }}>Division Name</th>
                        <th>District Name</th>
                    </tr>
                </thead>
                <tbody>
                    {formatedData?.map((item, index) => {
                        return <tr key={item.division_id + 'd'}>
                            <td>{index + 1}</td>
                            <td>{item.division_name}</td>
                            <td>
                                <table className={ViewAreaCss.nested_table}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '100px' }}>Disctict Name</th>
                                            <th>Area name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item.districtArr?.map((ditem) => {
                                            return <tr key={ditem.district_id + 'dis'}>
                                                <td>{ditem.district_name}</td>
                                                <td style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {ditem.areaArr?.map((ditem) => {
                                                        return <p className={ViewAreaCss.district_name} key={ditem.area_id + 'a'}>{ditem.area_name}</p>
                                                    })}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default ViewArea