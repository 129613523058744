import { faToggleOff, faToggleOn, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { GetAllDataExpress, SaveDataExpress, UpdateItemExpress } from '../../api/ExpressFunctions'
import ExpenseGroupCss from './css/ExpenseGroup.module.css'
const ExpenseGroup = () => {
    const [tableData, setTableData] = useState()
    const [inputData, setInputData] = useState()
    const [errMessage, setErrMessage] = useState()

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setInputData({...inputData, [name]:value})
    }
   async function onAddClick() {
        let groupNameErr  = ValidateFunction('groupName', 'Group Name ')
        let descriptionErr  = ValidateFunction('description', 'Description ')
        setErrMessage({groupNameErr, descriptionErr})
        if (!groupNameErr && !descriptionErr) {
            console.log({...inputData, status:1, created_date:new Date().getTime()})
        //    let dataID = await SaveDataExpress('expense_group', {...inputData, status:1, created_date:new Date().getTime()})
        //  // console.log({...inputData, status:true, created_date:new Date().getTime()})
            let newArr = []
            tableData?.map((item) => {
                newArr.push(item)
            })
            newArr.push({...inputData, status:1, id:'dataID'})
            //  console.log(newArr)
            setTableData(newArr)
        }
    }

    function ValidateFunction(field, message) {
        let text = ""
        if (!inputData?.[field]) {
            text = message + " is Required"
        }
        return text;
    }
    async function getDataFromDatabase() {
        let allData = await GetAllDataExpress('expense_group',)
        setTableData(allData)
    }
    useEffect(()=>{
        getDataFromDatabase()
    },[])

    return (
        <>
            <section className={`${ExpenseGroupCss.salesMain} ${ExpenseGroupCss.expense}`}>
                <div className={ExpenseGroupCss.innerSale}>
                    <h2 className={ExpenseGroupCss.saleHeading}>Expense Group</h2>
                    <div className={ExpenseGroupCss.input_section}>
                        <div className={`${ExpenseGroupCss.input_common} ${ExpenseGroupCss.expenceLanel}`}>
                            <label>Expense Group Name</label>
                            <input name='groupName' type={'text'} onChange={onDataChange} />
                            {errMessage ? <p className={ExpenseGroupCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.groupNameErr}</p> : ''}
                        </div>
                        <div className={`${ExpenseGroupCss.input_common} ${ExpenseGroupCss.expenceLanel}`}>
                            <label>Short Description</label>
                            <input name='description' type={'text'}  onChange={onDataChange}/>
                            {errMessage ? <p className={ExpenseGroupCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.descriptionErr}</p> : ''}
                        </div>
                        <div className={ExpenseGroupCss.input_common}>
                            <button onClick={onAddClick}>Add New</button>
                        </div>
                    </div>
                    <div className={ExpenseGroupCss.tableSection} >
                        <table style={{marginTop:'0'}}>
                            <tbody>
                                <tr>
                                    <th>Sl</th>
                                    <th>Group Name</th>
                                    <th>Short Description</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                {tableData?.map((item, index)=>{
                                    return <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{item.groupName}</td>
                                        <td>{item.description}</td>
                                        <td>{<ToggleSwitch toggleOnOff={item.status} id={item.id} tableData={tableData} setTableData={setTableData}/>}</td>
                                        <td><FontAwesomeIcon icon={faTrashCan} className={ExpenseGroupCss.color_red}/></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExpenseGroup

const ToggleSwitch = ({ toggleOnOff, id, tableData, setTableData}) => {

    function toggleClick() {
       let myArr = []
        tableData?.map(async (item)=>{
            if (item.id===id) {
                let okActive = !item.status
                item = {...item, status:okActive}
                myArr.push(item)
                await UpdateItemExpress('expense_group', {status:okActive}, id)
                
            }else{
                myArr.push(item)
            }
        })
        setTableData(myArr)
       // console.log(tableData[findIndex]['active'])
    }

    return (
        <>
            {toggleOnOff ? <div className={ExpenseGroupCss.ToggleSwitchon}> <p onClick={() => { toggleClick(id) }}> </p><FontAwesomeIcon icon={faToggleOn} /></div> : <div className={ExpenseGroupCss.ToggleSwitchoff}><p onClick={() => { toggleClick(id) }}> </p><FontAwesomeIcon icon={faToggleOff} /></div>}
        </>
    )
}