import { faCircleCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ToggleSwitch from '../smallThings/ToggleSwitch'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProducts, selectProductList, selectProductStatus } from '../../ReduxFiles/Slices/ProductSlice'
import { selectCategoryList } from '../../ReduxFiles/Slices/CategorySlice'
import { selectBrandList } from '../../ReduxFiles/Slices/BrandListSlice'
import { DeleteGalleryImage, DeleteGalleryItem, DeleteProduct, DeleteProductImage, GetAllGalleryImage, UpdateProductList } from '../../api/ProductListApi'
import { DeleteVariaceImage, DeleteVariance, GetFilterProductVariance, UpdateProductVariance } from '../../api/ProductVarianceApi'
import { DeleteAge, GetAllAgeValue, GetFilterProductAge, SaveProductAge } from '../../api/ProductAgeApi'
import SeoPop from './SeoPop'
import ListCss from './css/listitem.module.css'
import Select from 'react-select'

const ListItem = () => {
    const allProducts = useSelector(selectProductList)
    const productStatus = useSelector(selectProductStatus)
    const categoryList = useSelector(selectCategoryList)
    const brandList = useSelector(selectBrandList)
    const myNavigate = useNavigate()
    const [tableData, setTableData] = useState([]);
    const [featureState, setFeatureState] = useState()
    const [stockOutState, setStockOutState] = useState()
    const [stockAmmountState, setStockAmmountState] = useState()

    function addToFeature(e, item) {
      
        setFeatureState({ ...item, message: 'Do You Want To Add To Feature List?' })
    }

    function removeFromFeature(e, item) {
       
        document.body.style.overflow = "hidden";
        setFeatureState({ ...item, message: 'Do You Want To remove From Feature List?' })
    }
    //stockout Click
    function addToStockOut(e, item) {
        // console.log(item)
        setStockOutState({ ...item, message: 'Do You Want To Stock Out This Product?' })

    }
    function removeFromStockOut(e, item) {
        setStockOutState({ ...item, message: 'Do You Want To Remove Form Stock Out This Product?' })
    }

    function AddNewItem() {
        myNavigate("/admin/itemmanagement/additem/")
    }


    function EditClick(id) {
        myNavigate("/admin/itemmanagement/itemlist/" + id)
    }
    function StockEntry(e, item) {
        setStockAmmountState(item)
    }

    async function getAllData() {
        let tempArr = []
        allProducts?.forEach(element => {
            if (element.is_active) {
                tempArr.push(element)
            }
        });
        // console.log(data)
        let sortArr = tempArr.sort((a, b) => (a.item_code > b.item_code) ? 1 : -1)
        setTableData(sortArr)
        getExistedCat(allProducts)
    }
    function getExistedCat(arrayData) {
        let catArr = []
        let myArr = []
        arrayData.forEach(element => {
            if (catArr.includes(element.selected_catagory_id)) {

            } else {
                catArr.push(element.selected_catagory_id)
                for (let index = 0; index < categoryList?.length; index++) {
                    const melement = categoryList[index];
                    if (element.selected_catagory_id === melement.id) {
                        myArr.push(melement)
                    }
                }
            }
        });
        setExistedCatagory(myArr)
    }

    useEffect(() => {
        if (productStatus === 'success') {
            getAllData()
        }
    }, [productStatus])


    const [deleteItem, setDeleteItem] = useState()
    async function DeletClick(item) {
        setDeleteItem(item)
    }
    const [seoData, setSeoData] = useState()
    async function onSeoClick(item) {
        setSeoData(item)
        document.body.style.overflow = 'hidden'
    }
    const [ageItem, setAgeItem] = useState()

    function onAddAgeClick(item) {
        setAgeItem(item)
    }

    async function onDeliveryClick(item) {
        let mfree_delivery = 0
        if (!item.free_delivery) {
            mfree_delivery = 1
        }
        await UpdateProductList(item.id, { free_delivery: mfree_delivery })
        let tempArr = []
        tableData?.forEach(element => {
            if (element.id === item.id) {
                element = {
                    ...element,
                    free_delivery: mfree_delivery
                }
            }
            tempArr.push(element)
        });
        setTableData(tempArr)
    }

    async function onActiveClick(item) {
        let mis_active = 0
        if (!item.is_active) {
            mis_active = 1
        }
        await UpdateProductList(item.id, { is_active: mis_active })
        let tempArr = []
        tableData?.forEach(element => {
            if (element.id === item.id) {
                element = {
                    ...element,
                    is_active: mis_active
                }
            }
            tempArr.push(element)
        });
        setTableData(tempArr)
    }

    const [searchData, setSearchData] = useState()

    const [existedCatagory, setExistedCatagory] = useState()

    function onBrandChange(item) {
        if (item) {
            let value = item.id
            let newArr = []
            if (value) {
                allProducts?.map((item) => {
                    if (item.selected_brand_id === parseInt(value)) {
                        newArr.push(item)
                    }
                })
            } else {
                newArr = allProducts
            }
            setSearchData({ ...searchData, brand: value, catagory: '' })
            setTableData(newArr)
            getExistedCat(newArr)
        } else {
            setTableData(allProducts)
            getExistedCat(allProducts)
        }
    }
    function onCatagoryChange(item) {
        if (item) {
            let value = item.id
            let newArr = []
            if (value) {
                allProducts?.map((item) => {
                    if (item.selected_catagory_id === parseInt(value)) {
                        newArr.push(item)
                    }
                })
            } else {
                newArr = allProducts
            }
            setSearchData({ ...searchData, catagory: value })
            setTableData(newArr)
        } else {
            setTableData(allProducts)
        }
    }
    function onItemClick(item) {
        if (item) {
            let myArr = []
            myArr.push(item)
            setTableData(myArr)
        } else {
            setTableData(allProducts)
        }
    }
    function onCheckChange(e) {
        const isChecked = e.target.checked
        let mcheck = isChecked ? 1 : 0
        let newArr = []
        allProducts?.map((item) => {
            if (item.is_active == mcheck) {
                newArr.push(item)
            }
        })
        setTableData(newArr)
    }

    return (
        <div className={ListCss.list_item}>
            {seoData && <SeoPop popData={seoData} setPopData={setSeoData} />}
            {ageItem ? <AgePop ageItem={ageItem} setAgeItem={setAgeItem} /> : ''}
            {deleteItem ? <ConfirmAlert deleteItem={deleteItem} setDeleteItem={setDeleteItem} tableData={tableData} setTableData={setTableData} /> : ''}
            {featureState ? <MyFeatureAlert featureState={featureState} setFeatureState={setFeatureState} tableData={tableData} /> : ""}
            {stockOutState ? <MyStockOutAlert stockOutState={stockOutState} setStockOutState={setStockOutState} tableData={tableData} /> : ""}
            {stockAmmountState ? <MyAvailableStock stockAmmountState={stockAmmountState} setStockAmmountState={setStockAmmountState} tableData={tableData} /> : ''}

            <div className={ListCss.itemreport}>
                <div className={ListCss.common}>
                    <h2 className={ListCss.itemqty}><strong>{allProducts?.length}</strong> Items</h2>
                </div>
                <div className={ListCss.common}>
                    <h2 className={ListCss.itemqty}><strong>{categoryList?.length}</strong> Catagory</h2>
                </div>
                <div className={ListCss.common}>
                    <h2 className={ListCss.itemqty}><strong>{brandList?.length}</strong> Brands</h2>
                </div>
                <div className={ListCss.common}>
                    <button onClick={AddNewItem}>Add New Item</button>
                </div>
            </div>

            <div className={ListCss.itemsearch}>
                <div className={ListCss.headign}>
                    <h2  >Filter Item List</h2>
                </div>

                <div className={ListCss.filter_sec}>
                    <div className={ListCss.select_option}>
                        <Select options={brandList}
                            getOptionLabel={(option) => `${option.BrandName}`}
                            getOptionValue={(option) => `${option.id}`}
                            placeholder={'select brand'}
                            isClearable
                            onChange={onBrandChange}
                        />
                    </div>
                    <div className={ListCss.select_option}>
                        <Select options={existedCatagory}
                            getOptionLabel={(option) => `${option.catName}`}
                            getOptionValue={(option) => `${option.id}`}
                            placeholder={'select category'}
                            isClearable
                            onChange={onCatagoryChange}
                        />
                    </div>
                    <div className={ListCss.select_option}>
                        <Select options={allProducts}
                            getOptionLabel={(option) => `${option.en_sort_title} - (${option.Selling_price}/-)`}
                            getOptionValue={(option) => `${option.id}`}
                            placeholder={'select product'}
                            isClearable
                            onChange={onItemClick}
                        />
                    </div>
                </div>
            </div>
            <div className={ListCss.tablediv}>
                <table>
                    <thead>
                        <tr>
                            <th>Sl</th>
                            <th>Item Name</th>
                            <th><span>Active <input type='checkbox' defaultChecked={true} onChange={onCheckChange} /></span></th>
                            <th>Category</th>
                            <th>Brand</th>
                            <th>Featured</th>
                            <th>Stock Out</th>
                            <th>Avi Stock</th>
                            <th>Free Delivery</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.en_sort_title}-({item.Selling_price}/-) <br />
                                    {item.hasAge ? <button onClick={() => { onAddAgeClick(item) }} className={ListCss.age_value_button}>add age value</button> : ''}
                                </td>
                                <td><ToggleSwitch toggleOnOff={item.is_active} toggleClick={() => { onActiveClick(item) }} /></td>
                                <td>{item.catName}</td>
                                <td>{item.BrandName}</td>
                                <td><MyFeatured id={item.id} featured={item.featured} addToFeature={(e) => { addToFeature(e, item) }} removeFromFeature={(e) => { removeFromFeature(e, item) }} /></td>
                                <td><MyStockOut id={item.id} stockout={item.stockout} addToFeature={(e) => { addToStockOut(e, item) }} removeFromFeature={(e) => { removeFromStockOut(e, item) }} /></td>
                                <td>{<p onClick={(e) => { StockEntry(e, item); document.body.style.overflow = 'hidden' }} className={item.hasVariance ? `${ListCss.availabeVirtualStock} ${ListCss.color_pink}` : `${ListCss.availabeVirtualStock}`}>{
                                    item?.virtualStock ? item.virtualStock : 0
                                }</p>}</td>
                                <td><ToggleSwitch toggleOnOff={item.free_delivery} toggleClick={() => { onDeliveryClick(item) }} /></td>
                                <td><div className={ListCss.actionBtns}>
                                    <input onClick={() => { EditClick(item.id) }} type="Submit" defaultValue="Edit" sid={item.id} />
                                    <input className={ListCss.seo} onClick={() => { onSeoClick(item) }} type="Submit" defaultValue="SEO" />
                                </div></td>
                            </tr>
                        })}

                        {/* {CatItem} */}


                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ListItem


const AgePop = ({ ageItem, setAgeItem }) => {

    const [attData, setAttData] = useState()
    const [addedValue, setAddedValue] = useState([])

    async function getAgeData() {
        let mattData = await GetAllAgeValue()
        let okageData = await GetFilterProductAge(`product_id = ${ageItem?.id}`)
        setAttData(mattData)
        let mArr = []
        //  console.log(mattData, okageData )
        okageData.forEach(element => {
            for (let index = 0; index < mattData.length; index++) {
                let melement = mattData[index];
                if (melement.id === element.age_id) {
                    melement = {
                        ...melement,
                        item_id: element.id
                    }
                    mArr.push(melement)
                }
            }
        });
        setAddedValue(mArr)
    }

    function onAgeValueChange(e) {
        let myData = [...addedValue]
        let value = e.target.value
        //   let name = e.target.name
        //     console.log(name)
        attData.forEach(element => {
            //   console.log(element)
            let dataFound = false
            if (element.id === parseInt(value)) {
                if (addedValue.length > 0) {
                    for (let index = 0; index < myData.length; index++) {
                        const melement = addedValue[index];
                        if (melement.id === element.id) {
                            dataFound = true
                            break
                        }
                    }
                }
                if (!dataFound) {
                    myData.push(element)
                } else {
                    alert('Age Value Already Exist on this list')
                }
            }

        });
        setAddedValue(myData)
    }

    function onDeleteClick(item, index) {
        let tableData = []
        addedValue.forEach(async (element, mindex) => {
            if (mindex === index) {
                if (element.item_id) {
                    await DeleteAge(element.item_id)
                }
            } else {
                tableData.push(element)
            }
        })
        setAddedValue(tableData)
    }

    async function onSaveClick() {
        let myPromises = addedValue.map(async function (item) {
            let finalItem = {
                age_id: item.id,
                product_id: ageItem.id,
                created_date: new Date().getTime()
            }
            if (!item.item_id) {
                await SaveProductAge(finalItem)
            }
        })
        Promise.all(myPromises).then(() => {
            setAgeItem()
        })
    }

    useEffect(() => {
        getAgeData()
    }, [])

    return (
        <>
            <div className={ListCss.age_pop}>
                <h2>Pealse Add Age Here</h2>
                <div>
                    <label>Age Name : </label>
                    <select onChange={onAgeValueChange}>
                        <option value={''}>Select One</option>
                        {attData?.map((item) => {
                            return <option key={item.id} value={item.id}>{item.attr_value_name}</option>
                        })}
                    </select>
                    <table>
                        <thead>
                            <tr>
                                <th>sl</th>
                                <th>name</th>
                                <th>atcion</th>
                            </tr>
                            {addedValue?.map((item, index) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.attr_value_name}</td>
                                    <td><button onClick={() => { onDeleteClick(item, index) }} className={ListCss.Lisdelete_button}>delete</button></td>
                                </tr>
                            })}
                        </thead>
                    </table>

                </div>
                <div className={ListCss.button_section}>
                    <button onClick={() => { setAgeItem() }} className={ListCss.close_button}>close</button>
                    <button onClick={onSaveClick} className={ListCss.age_save}>Save</button>
                </div>
            </div>
        </>
    )
}


const ConfirmAlert = (props) => {
    const { deleteItem, setDeleteItem, tableData, setTableData } = props
    async function yesClick() {
        document.body.style.overflow = 'auto'
        let arr = []
        if (deleteItem.hasVariance) {
            let varData = await GetFilterProductVariance(`product_id = ${deleteItem.id}`)
            varData.forEach(async element => {
                await DeleteVariance(element.id)
                await DeleteVariaceImage({ shadeimg: element.ShadeIamge, mainimg: element.MainIamge })
                // await DeleteVariaceImage()
            });
        }
        if (deleteItem.hasAge) {
            let ageData = await GetFilterProductAge(`product_id = ${deleteItem.id}`)
            ageData.forEach(async element => {
                await DeleteAge(element.id)

            });
        }
        let galleryImage = await GetAllGalleryImage(`product_id = ${deleteItem.id}`)
        galleryImage.forEach(async element => {
            await DeleteGalleryImage({ image_name: element.image_name })
            await DeleteGalleryItem(element.id)
        });
        await DeleteProduct(deleteItem.id)
        await DeleteProductImage({ image_name: deleteItem.itemImageName })
        tableData.forEach(async element => {
            if (element.id !== deleteItem.id) {
                arr.push(element)
            }
        });
        setTableData(arr)
        setDeleteItem()
    }
    function noClick() {
        setDeleteItem()
        document.body.style.overflow = 'auto'
    }

    return (
        <>
            <div className={ListCss.conAlert}>
                <h2 className={ListCss.caption}>Do you Want To Delete This?</h2>
                <div className={ListCss.buttonsec}>
                    <input onClick={yesClick} className={ListCss.yes} type={"submit"} value={"Yes"} />
                    <input onClick={noClick} className={ListCss.no} type={"submit"} value={"No"} />
                </div>
            </div>
        </>
    )
}



const MyFeatured = ({ featured, removeFromFeature, addToFeature }) => {

    // alert(featured)
    return (
        <>
            {featured ?
                <div className={ListCss.notfeatured}><span className={ListCss.checkCircle}> <FontAwesomeIcon icon={faCircleCheck} /></span> Featured <span onClick={removeFromFeature} className={ListCss.timesCircle}>
                    <p className={ListCss.timesCircleP}></p>
                    <FontAwesomeIcon icon={faTimes} />

                </span></div>
                :
                <div className={ListCss.featured}><span className={ListCss.timesCircle}> <FontAwesomeIcon icon={faTimes} /></span> Featured <span onClick={addToFeature} className={ListCss.checkCircle}>  <p className={ListCss.checkCircleP}></p> <FontAwesomeIcon icon={faCircleCheck} /></span></div>
            }

        </>
    )
}
const MyFeatureAlert = ({ featureState, setFeatureState, tableData }) => {
    const dispatch = useDispatch()
    async function ConfirmClick() {
        let featured = 0
        if (featureState.featured === 0 || featureState.featured === undefined) {
            featured = 1
        } else {
            featured = 0
        }
        await UpdateProductList(featureState.id, { featured: featured })
        setFeatureState()
        dispatch(fetchProducts())
        document.body.style.overflow = 'auto'
    }
    function cancelClick() {
        setFeatureState()
        document.body.style.overflow = "auto";
    }

    return (
        <div className={ListCss.featureAlert_wrapper}>
            <div className={ListCss.featureAlert}>
                <h3>Confirm</h3>
                <p>{featureState?.message}</p>
                <div className={ListCss.buttonClass}>
                    <input onClick={ConfirmClick} type='submit' value="ok" />
                    <input onClick={cancelClick} type='submit' value="Cancel" />
                </div>
            </div>
        </div>

    )
}
const MyStockOut = ({ stockout, removeFromFeature,addToFeature }) => {

    return (
        <>
            {stockout ?
                <div className={ListCss.notfeatured}>
                    <span className={ListCss.checkCircle}>
                        <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                    Applicable

                    <span onClick={removeFromFeature} className={ListCss.timesCircle}>

                        <p className={ListCss.timesCircleP}></p>

                        <FontAwesomeIcon icon={faTimes} />

                    </span>
                </div>
                :
                <div className={ListCss.featured}>
                    <span className={ListCss.timesCircle}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                    Applicable
                    <span onClick={addToFeature} className={ListCss.checkCircle}>
                        <p className={ListCss.checkCircleP}></p>
                        <FontAwesomeIcon icon={faCircleCheck} />
                    </span>
                </div>
            }

        </>
    )
}
const MyStockOutAlert = ({ stockOutState, setStockOutState, tableData }) => {
    const dispatch = useDispatch()
    async function ConfirmClick() {
        let stockout = 0
        if (stockOutState.stockout === 0 || stockOutState.stockout === undefined) {
            stockout = 1
        } else {
            stockout = 0
        }
        await UpdateProductList(stockOutState.id, { stockout: stockout })
        setStockOutState()
        dispatch(fetchProducts())
    }
    function cancelClick() {
        setStockOutState()
        // document.body.style.overflow = 'auto' 
    }

    return (
        <div className={ListCss.featureAlert_wrapper}>
            <div className={ListCss.featureAlert}>
                <h3>Confirm</h3>
                <p>{stockOutState?.message}</p>
                <div className={ListCss.buttonClass}>
                    <input onClick={ConfirmClick} type='submit' value="ok" />
                    <input onClick={cancelClick} type='submit' value="Cancel" />
                </div>
            </div>
        </div>
    )
}
const MyAvailableStock = ({ stockAmmountState, setStockAmmountState, tableData }) => {

    const dispatch = useDispatch()

    const [varianceData, setVarianceData] = useState()
    const [varianceQuantity, setvarianceQuantity] = useState()

    function dataChange(e, index) {
        let name = e.target.name
        let value = e.target.value
        if (stockAmmountState.hasVariance) {
            setvarianceQuantity({ ...varianceQuantity, [name]: value })
            varianceData[index].virtualStock = parseInt(value)
        } else {
            console.log(value);
            setStockAmmountState({ ...stockAmmountState, virtualStock: parseInt(value) })
        }
        // setVarianceData(varianceData)
    }
    async function updateClick() {

        if (stockAmmountState.hasVariance) {
            varianceData.forEach(async (element) => {
                await UpdateProductVariance(element.id, { virtualStock: element.virtualStock })
            });
            let total = 0
            varianceData.forEach(element => {
                total += element.virtualStock
            });
            await UpdateProductList(stockAmmountState.id, { virtualStock: total })
            dispatch(fetchProducts())
        } else {
            await UpdateProductList(stockAmmountState.id, { virtualStock: stockAmmountState.virtualStock })
            dispatch(fetchProducts())
        }

        setStockAmmountState()
    }
    function cancelClick() {
        setStockAmmountState()
        document.body.style.overflow = 'auto'
    }
    async function getVarianceData() {
        if (stockAmmountState.hasVariance) {
            let data = await GetFilterProductVariance(`product_id = ${stockAmmountState.id}`)
            let sortArr = data.sort((a, b) => (parseInt(a.Variance_name) > parseInt(b.Variance_name)) ? 1 : -1)
            let varQuantity = {}
            sortArr.forEach((element, index) => {
                varQuantity = { ...varQuantity, ['virtualStock' + index]: element.virtualStock }
            });
            setvarianceQuantity(varQuantity)
            setVarianceData(data)
        }
    }
    useEffect(() => {
        getVarianceData()
    }, [])
    return (
        <div className={ListCss.virtualStockEnrty_wrapper}>
            <div className={ListCss.virtualStockEnrty}>
                <h3>Confirm</h3>
                <div className={ListCss.virtualStockInput}>
                    {varianceData ? <>{varianceData?.map((item, index) => {
                        return <div key={index} className={ListCss.input_wrapper}>
                            <label>{`(${`${index + 1}`.padStart(2, 0)})`}---{item.Variance_name}</label>
                            <input name={'virtualStock' + index} type={"number"} onChange={(e) => { dataChange(e, index) }} defaultValue={varianceQuantity?.['virtualStock' + index]} />
                        </div>
                    })}</> :
                        <>
                            <input name='virtualStock' type={"number"} onChange={(e) => { dataChange(e) }} defaultValue={stockAmmountState?.virtualStock} /></>
                    }
                </div>
                <div className={ListCss.buttonClass}>
                    <input onClick={updateClick} type='submit' value="Update" />
                    <input onClick={cancelClick} type='submit' value="Cancel" />
                </div>

            </div>
        </div>
    )
}
