import React from 'react'
import { NavLink, Link } from 'react-router-dom' 
import AccountMainCss from './Css/AccountMain.module.css'
const AccountMain = () => {
    return (
        <>
            <div className={AccountMainCss.account} style={{ marginTop: '10px' }}>

                <div className={AccountMainCss.common_part}>
                    <h2>Accounts</h2>
                    <div className={AccountMainCss.account_part}>
                        <div className={AccountMainCss.main_div}>
                            <div className={AccountMainCss.inner_div}>
                                <Link to={'/admin/accounts/debit_voucher'}>Debit Voucher</Link>
                            </div>
                        </div>
                        <div className={AccountMainCss.main_div}>
                            <div className={AccountMainCss.inner_div}>
                                <Link to={'/admin/accounts/debit_voucher_history'}>Debit Voucher History</Link>
                            </div>
                        </div>
                        <div className={AccountMainCss.main_div}>
                            <div className={AccountMainCss.inner_div}>
                                <Link to={'/admin/accounts/credit_voucher'}>Credit Voucher</Link>
                            </div>

                        </div>
                        <div className={AccountMainCss.main_div}>
                            <div className={AccountMainCss.inner_div}>
                                <Link to={'/admin/accounts/credit_voucher_history'}>Credit Voucher History</Link>
                            </div>

                        </div>
                        <div className={AccountMainCss.main_div}>
                            <div className={AccountMainCss.inner_div}>
                                <Link to={'/admin/accounts/day-book'}>Day Book</Link>
                            </div>

                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/admin/accounts/cash'}>Cash</Link>
                            </div>
                            
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/admin/accounts/ssl'}>SSL</Link>
                            </div>
                            
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/admin/accounts/bank_balance_list'}>Bank Balance List</Link>
                            </div>
                            
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/admin/accounts/bank_transiction_history'}>Bank Transaction History</Link>
                            </div>
                            
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/admin/accounts/deposite'}>Deposite</Link>
                            </div>
                           
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/admin/accounts/withdraw'}>Withdraw</Link>
                            </div>
                            
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/admin/accounts/transictionin'}>Transaction in</Link>
                            </div>
                            
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/admin/accounts/transictionout'}>Transaction out</Link>
                            </div>
                            
                        </div>

                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/'}>Cheque in</Link>
                            </div>
                           
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/'}>Cheque in History</Link>
                            </div>
                            
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/'}>Cheque out</Link>
                            </div>
                            
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/'}>Cheque out History</Link>
                            </div>
                            
                        </div>
                        <div className={AccountMainCss.main_div}>
                        <div className={AccountMainCss.inner_div}>
                        <Link to={'/admin/accounts/check_api'}>Check Api</Link>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AccountMain