import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TimyMCE from '../TimyMCE'
import { SavePageData } from '../../api/PagesApi'
import JoditEditor from 'jodit-react'
import AddNewPageCss from './css/AddNewPage.module.css'

const AddNewPage = () => {
    const page_content = useRef()
    const myNavigate = useNavigate()
    
    const [pageData, setPageData] = useState({})
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setPageData({...pageData, [name]:value})
    }
  async function onAddClick() {
        let mpageContent = page_content.current.value
        let finalData ={
            ...pageData,
            page_content:mpageContent,
            created_date:new Date().getTime()
        }
        await SavePageData(finalData)
       // console.log(finalData)
       myNavigate('/admin/pages/page_list')
    }
    const marginTop = {
        marginTop: '10px'
    }
    const config = {
        useSplitMode: false,
        uploader: {
          insertImageAsBase64URI: true
        },
        placeholder: 'Type Your Content Here...',
        showXPathInStatusbar: false
      };
    return (
        <section style={marginTop} className={`${AddNewPageCss.salesMain} ${AddNewPageCss.edit_page}`}>
            <h3>Add New Page</h3>
            <div className={AddNewPageCss.input_all}>
                <div className={AddNewPageCss.common}>
                    <label>Page Name : </label>
                    <input type={'text'} placeholder='enter page name here' name='page_name' onChange={onDataChange} />
                </div>
                <div className={AddNewPageCss.common}>
                    <label>Page Slug : </label>
                    <input type={'text'} placeholder='enter page slug' name='page_slug' onChange={onDataChange} />
                </div>
                <div className={AddNewPageCss.common}>
                    <label>Meta Title : </label>
                    <input type={'text'} placeholder='enter meta title' name='meta_title' onChange={onDataChange} />
                </div>
                <div className={AddNewPageCss.common}>
                    <label>Meta Description : </label>
                    <input type={'text'} placeholder='enter meta description' name='meta_description' onChange={onDataChange} />
                </div>
                <div className={AddNewPageCss.common}>
                    <label>Meta Keyword : </label>
                    <input type={'text'} placeholder='enter meta keyword' name='meta_keyword' onChange={onDataChange} />
                </div>
                <div>
                
                        <p className={AddNewPageCss.page_title}>Page Content</p>
                    <JoditEditor
                          ref={page_content}
                          value={pageData.page_content || ''} 
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                      />
                
                </div>
                <button className={AddNewPageCss.addPageBtn} onClick={onAddClick}>Add Page</button>
            </div>
        </section>
    )
}

export default AddNewPage