import {  faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { GetDatafromDatabase, GetNestedAllDatawithOrder, GetSingleDoc, MultipleImageUploadwtihURLNested, SaveNestedExistingDataWithImage, UpdateData } from '../../connector/DatabaseCheck'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { async } from '@firebase/util'
import ComplainFunctionsCss from './ComplainFunctions.module.css'
const ComplainFunctions = () => {
  let myDate = new Date()
  const myParams = useParams()
  const [replayData, setReplayData] = useState()
  const [mainComplain, setMainComplain] = useState()
  const [settingData, setSettingData] = useState()
  const [replayDataAll, setReplayDataAll] = useState()

  function onDataChange(e) {
    let name = e.target.name
    let value = e.target.value
    if (e.target.files) {
      value = e.target.files
     let urls =[];
      if (value.length>0) {
       for (let index = 0; index < value.length; index++) {
        const element = value[index];
        urls.push(genarateFakeUrl(element))
       }
      }
      setReplayData({...replayData, [name]:value, fakeUrls:urls})
    }else{
      setReplayData({...replayData, [name]:value})
    }
  }
  function genarateFakeUrl(image) {
    let myurl = URL.createObjectURL(image)
    return myurl;
}
function setSolvedState(params) {
  setReplayData({...replayData, solved:!replayData?.solved})
}
async function submitReplay(params) {
  let finalData = {
    ...replayData,
    author:'authority'
  }
  delete finalData.replayImages
  delete finalData.solved
  delete finalData.fakeUrls

 await UpdateData('userComplain', {solved:replayData?.solved ?replayData?.solved:false },  myParams.dataid)
 let nestedID =  await SaveNestedExistingDataWithImage('userComplain', myParams.dataid, 'replies', finalData)

  for (let index = 0; index < replayData?.replayImages?.length; index++) {
    let myTime = myDate.getTime()
    const element = replayData?.replayImages[index];
    await MultipleImageUploadwtihURLNested(element, myTime +'__'+ element.name, 'userimages/comaplain/replies', 'userComplain', myParams.dataid, 'replies', nestedID, 'replayAttachment')
  }
 // console.log('data saved')
}

async function getMainData() {
  let mySettingData = await GetDatafromDatabase('ComplainSetting', 'comID')
  setSettingData(mySettingData)
  let myData =await GetSingleDoc('userComplain', myParams.dataid)
  setMainComplain(myData.data())
  let myReplayData  = await GetNestedAllDatawithOrder('userComplain', myParams.dataid, 'replies', 'replayID', 'createdDate', 'asc')
  //console.log(myReplayData)
  setReplayDataAll(myReplayData)
}
// data from datapobase
useEffect(()=>{
  getMainData()
},[])

function convertDate(time) {
  const dateInMillis = time * 1000
  const date = new Date(dateInMillis)
  let myDate = date.toLocaleDateString()
  let myTime = date.toLocaleTimeString()
  myDate = myDate.replaceAll('/', '-')
  return myDate + " " + myTime
}

function getInfo(data, valueHolder) {
  let myText = ''
  settingData?.map((element)=>{
      if (element.comID===data) {
          myText = element[valueHolder]
      }
  })
  return myText
}

const myStyle = {
  color: 'white',
padding: '4px',
borderRadius: '5px',
  fontSize:'14px',
  width:'max-content'
}
  return (
    <>
    <section style={{marginTop:'20px'}} className ={ComplainFunctionsCss.function}>
      <div className={`${ComplainFunctionsCss.function_common} ${ComplainFunctionsCss.complain_info}`}>
        <h2>Complain Information</h2>
        <p>Subject : {mainComplain?.subject} </p>
        <p>Content : {mainComplain?.complainMessage}</p>
        <p>Created On : {convertDate(mainComplain?.createdDate?.seconds)}</p>
        <p>User : {mainComplain?.userName}</p>
        <p>Type : {getInfo(mainComplain?.complainTypeID, 'complainType')}</p>
        <p>Category : {getInfo(mainComplain?.complainCatID, 'complainCatName')}</p>
        <p>Priority : {getInfo(mainComplain?.complainPriorityID, 'priority')}</p>
        <p>Status :  {mainComplain?.solved ? <span style={{...myStyle, backgroundColor:'green'}}>solved</span>:<span style={{...myStyle, backgroundColor:'#e58e26'}}>Not Solved Yet</span>}</p>
        <p>Attachments : {mainComplain?.complainImages?.map((image, mindex)=>{ return <a key={image.name} href={image.url} target='blank' style={{textDecoration:'none', color:'#1e3799'}}>Attachment {mindex+1}  |  </a>  })}</p>
        </div>  
      <div className={ComplainFunctionsCss.function_common}>
        <h2>Reply</h2>
        {replayDataAll?.map((item, index)=>{
          return <div key={index}>
            <div className={ComplainFunctionsCss.repaly_indivisual}>
          <div className ={item.author ==='authority' ? `${ComplainFunctionsCss.button_section}`:`${ComplainFunctionsCss.button_section} ${ComplainFunctionsCss.mrgin_20}`}>
            <h3>{item.author ==='authority'? 'Authority Replay':'User Replay' }</h3>
           {item.author ==='authority' ? <> <button>Edit</button>
            <button style={{backgroundColor:'red'}}>Delete</button></>:''}
          </div>
          <p><strong>Date : </strong>{convertDate(item.createdDate?.seconds)}</p>
          <p><strong>Title : </strong>	{item.title}</p>
          <p><strong>Message : </strong> {item.replayMessage}</p>
          <p><strong>By: : </strong>	{item.author}</p>
          <p><strong>Attachments : </strong> 
          {item?.replayAttachment?.map((image, mindex)=>{ return <a key={image.name} href={image.url} target='blank' style={{textDecoration:'none', color:'#1e3799'}}>Attachment {mindex+1}  |  </a>  })}
          </p>
        </div>
          </div>
        })}
        
      </div>
      <div className={`${ComplainFunctionsCss.function_common}  ${ComplainFunctionsCss.give_reply}`}>
        <h2>Give Reply</h2>
        <div className={ComplainFunctionsCss.input_section}>
          <label>Title *</label>
        <input name='title' type='text' placeholder='Write Update Title' onChange={onDataChange}/>
        </div>
        <div className={ComplainFunctionsCss.input_section}>
          <label>Replay Message *</label>
          <textarea name='replayMessage' rows="6" cols="50" placeholder='Write your Message here' onChange={onDataChange}></textarea>
        </div>
        <div className={ComplainFunctionsCss.input_section}>
          <label>Complain Solved ? *</label>
           <div className={ComplainFunctionsCss.icon_sec}>
           <span onClick={setSolvedState}> {replayData?.solved ?<FontAwesomeIcon className={ComplainFunctionsCss.class_true} icon={faSquareCheck}/>: <FontAwesomeIcon icon={faSquare}/>} Yes</span>
           <span>{replayData?.solved ?<FontAwesomeIcon onClick={setSolvedState} icon={faSquare}/>: <FontAwesomeIcon className={ComplainFunctionsCss.class_true} icon={faSquareCheck}/>} No</span>
           {/* <i class="fa-solid fa-square-check"></i> */}
           </div>
        </div>
        <div className={ComplainFunctionsCss.input_section}>
          <label>Add Attachments</label>
           <input name='replayImages' type='file' multiple onChange={onDataChange}/>
        </div>
        {replayData?.fakeUrls?.length>0 ? <div className={`${ComplainFunctionsCss.input_section} ${ComplainFunctionsCss.image_view}`}>
              {replayData?.fakeUrls.map((url, index)=>{
                return <img key={index} src={url} alt={index}/>
              })}
        </div>:''}
        
        <div className={`${ComplainFunctionsCss.input_section} ${ComplainFunctionsCss.button_section}`}>
          <button onClick={submitReplay}>Submit Replay</button>
        </div>
      </div>
    </section>
    </>
  )
}

export default ComplainFunctions


// Date:	2022-07-31 11:08:52
// Title:	hello there
// 

// By:	Authority
// Attachments:	