import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GetFilteredDataExpress } from '../../../api/ExpressFunctions'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'

const ViewBankBook = () => {
    const [tableData, setTableData] = useState()
    const [bankData, setBankData] = useState()
 const myParams = useParams()
async function getData(params) {
    let mbankData = await GetFilteredDataExpress('bank_list', 'id', myParams.action)
    setBankData(mbankData[0])
   let bankDatatr = await  GetFilteredDataExpress('bank_transaction', 'bankID', myParams.action)
   setTableData(bankDatatr)
 }
 useEffect(()=>{
    getData()
 },[])
  return (
    <div>
        <h3>Bank Book</h3>
        <p>Bank Name : {bankData?.bankName} ({bankData?.acNumber})</p>
        <p>Current Balance : {bankData?.ammount}</p>
        <p>Account Name: {bankData?.acName}</p>
        <p>Account Holder Name: {bankData?.keyPName}</p>

        <table style={{marginTop:'20px'}}>
                          <tbody>
                              <tr>
                                  <th>Sl</th>
                                  <th>Transaction Time</th>
                                  {/* <th>Transaction no </th> */}
                                  <th>Head</th>
                                  <th>Process By</th>
                                  <th>Deposite Amount</th>
                                  <th>Withdraw Amount</th>
                              </tr>
                              {tableData?.map((item, index)=>{
                                return <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{LocalTimeStampToDate(item.createdDate?.seconds)}</td>
                                    {/* <td>{item.identity}</td> */}
                                    <td>{item.head}</td>
                                    <td>{item.processorName}</td>
                                    <td style={{color:'green', textAlign:'right', fontWeight:'bold'}}>{item.receiveAmmount}</td>
                                    <td style={{color:'red', textAlign:'right', fontWeight:'bold'}}>{item.paymentAmmount}</td>                                   
                                </tr>
                              })}
                          </tbody>
                      </table>
    </div>
  )
}

export default ViewBankBook