import React, { useEffect, useRef, useState } from 'react'
import { GetPageDataFilter, UpdatePageData } from '../../api/PagesApi'
import JoditEditor from 'jodit-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EditPageCss from './css/EditPage.module.css'
const EditPage = () => {
    const page_content = useRef()

    const [searchParams] = useSearchParams()
    const dataid = searchParams.get('id')
    const [editedpageData, setEditedPageData] = useState({})
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setEditedPageData({ ...editedpageData, [name]: value })
    }
    const myNavigate = useNavigate()
    async function onUpdateClick() {
        let mpageContent = page_content.current.value
        let dataID = editedpageData.id
        delete editedpageData.id
        let finalData = {
            ...editedpageData,
            page_content: mpageContent,
            created_date: new Date().getTime()
        }
        await UpdatePageData(dataID, finalData)
        myNavigate('/admin/pages')
    }

    const config = {
        useSplitMode: false,
        uploader: {
            insertImageAsBase64URI: true
        },
        height: '450px',
        placeholder: 'Type Your Content Here...',
        showXPathInStatusbar: false
    };

    async function getPageData() {
        let data = dataid ? await GetPageDataFilter(`id = ${dataid}`) : [{}]
        setEditedPageData(data[0])
    }

    useEffect(() => {
        getPageData()
    }, [dataid])
    return (
        <section className={EditPageCss.edit_page}>
              <h2>{editedpageData?.page_name} Edit</h2>
                <div className={EditPageCss.input_all}>
                    <div className={EditPageCss.common}>
                        <label>Page Name : </label>
                        <input type={'text'} placeholder='enter page name here' name='page_name' onChange={onDataChange} value={editedpageData?.page_name ? editedpageData?.page_name : ''} />
                    </div>
                    <div className={EditPageCss.common}>
                        <label>Page Slug : </label>
                        <input type={'text'} placeholder='enter page slug' name='page_slug' onChange={onDataChange} value={editedpageData?.page_slug ? editedpageData?.page_slug : ''} />
                    </div>
                    <div className={EditPageCss.common}>
                        <label>Meta Title : </label>
                        <input type={'text'} placeholder='enter meta title' name='meta_title' onChange={onDataChange} value={editedpageData?.meta_title ? editedpageData?.meta_title : ''} />
                    </div>
                    <div className={EditPageCss.common}>
                        <label>Meta Description : </label>
                        <input type={'text'} placeholder='enter meta description' name='meta_description' onChange={onDataChange} value={editedpageData?.meta_description ? editedpageData?.meta_description : ''} />
                    </div>
                    <div className={EditPageCss.common}>
                        <label>Meta Keyword : </label>
                        <input type={'text'} placeholder='enter meta keyword' name='meta_keyword' onChange={onDataChange} value={editedpageData?.meta_keyword ? editedpageData?.meta_keyword : ''} />
                    </div>
                    <section className={EditPageCss.editor_wrap}>
                        <h3 className={EditPageCss.page_title}> Page Content</h3>
                        <JoditEditor
                            ref={page_content}
                            value={editedpageData.page_content || ''}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                        />
                    </section>
                </div>
                <button className={EditPageCss.updatePage} onClick={onUpdateClick}>Update Page</button>
                <button className={EditPageCss.close}>Close</button>
        </section>
    )
}

export default EditPage