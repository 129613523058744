import React, { useEffect, useState } from 'react'
import { ApiLink } from '../../api/ExpressFunctions';
import { useSelector } from 'react-redux'
import { selectProductList } from '../../ReduxFiles/Slices/ProductSlice';
import { GetFilterProductVariance } from '../../api/ProductVarianceApi';
import CatelogLiCss from './Css/CatelogListing.module.css'
const CatelogListing = () => {
     const allProducts = useSelector(selectProductList)
    const [selectedItem, setSelectedItem] = useState()
    let localList = window.localStorage.getItem('catlistedItem')
    const [ListedItem, setListeItem] = useState(localList ? JSON.parse(localList):[]) // 
    const [searchText, setSearchText] = useState('')

    function onSearchChange(e) {
        let value = e.target.value
        setSearchText(value)
    }

    function onItemClick(item) {
        let itemFound = false
        // console.log(ListedItem);
        ListedItem.forEach(lelement => {
            if (lelement.id === item.id) {
                itemFound = true
            }
        });

        if (!itemFound) {
            allProducts.forEach(element => {
                if (element.id === item.id) {
                    if (element.hasVariance) {
                        setSelectedItem({ product: element})
                        document.body.style.overflow = 'hidden'
                    }else{
                        let finalData = {
                            id: element.id,
                            name: element.en_sort_title,
                            imageName: element.itemImageName,
                            price:element.Selling_price,
                            tp:element.Selling_price - (element.Selling_price*20/100),
                        }
                        let tempList = [...ListedItem] 
                        tempList.push(finalData)
                        setListeItem(tempList) 
                        window.localStorage.setItem('catlistedItem', JSON.stringify(tempList))
                    } 
                    
                }
            });
        } else {
            alert('Product Already Exist in this list')
        }
    }
    function onItemRemove(item) {
        let tempArr = []
        ListedItem?.forEach(element => {
            if (element.id!==item.id) {
                tempArr.push(element)
            }
        });
        setListeItem(tempArr)
        window.localStorage.setItem('catlistedItem', JSON.stringify(tempArr))
    }
    function createPDFClick() {
        window.open('create-catelog/view')
    }

    return (
        <div className={CatelogLiCss.main_section}>
            {selectedItem ? <ItemPopup product={selectedItem} setSelectedItem={setSelectedItem} setListeItem={setListeItem} ListedItem={ListedItem} /> : ''}
            <div className={CatelogLiCss.product_part}>
                <input type='text' placeholder='search products' className={CatelogLiCss.search_input} onChange={onSearchChange} value={searchText}/>
               <div>
                 <table>
                    <thead>
                        <tr>
                            <th>sl</th>
                            <th>name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allProducts?.filter((fitem)=>{
                            let filterItem = fitem.en_sort_title?.toLowerCase()
                            let msearchText = searchText?.toLowerCase()
                            return filterItem.includes(msearchText) ? fitem:''
                        })                       
                        .map((item, index) => {
                            return <tr key={item.id+'p'} className={CatelogLiCss.item_select} onClick={()=>{(onItemClick(item))}}>
                                <td>{index+1}</td>
                                <td>{item.en_sort_title}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
               </div>
            </div>
            <div className={CatelogLiCss.selected_section}>
                <div className={CatelogLiCss.table_section_others}>
                    <button onClick={createPDFClick}>Create PDF</button>
                    <table>
                        <thead>
                            <tr>
                                <th>sl</th>
                                <th>image</th>
                                <th>name</th>
                                <th>MRP</th>
                                <th>TP</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ListedItem?.map((item, index) => {
                                return <tr key={item.id + 'l'}>
                                    <td>{index + 1}</td>
                                    <td><img src={ApiLink+'productimages/'+ item.imageName} alt={`${item.imageName}`}/></td>
                                    <td>{item.name}</td>
                                    <td>{item.price}</td>
                                    <td>{item.tp}</td>
                                    <td><button onClick={()=>{onItemRemove(item)}}>X</button></td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default CatelogListing


async  function prductVariance(id, setSelectedVariance, setMyVariance) {
    let variance =  await GetFilterProductVariance(`product_id = ${id}`)
    setSelectedVariance(variance)
    setMyVariance(variance)
 }

const ItemPopup = ({ product, setSelectedItem, setListeItem, ListedItem }) => {
    const [myProduct, setMyProduct] = useState(product.product)
    const [myVariance, setMyVariance] = useState([])
    const [SelectedVariance, setSelectedVariance] = useState([])

    function onVariaceSelect(e) {
        let value = e.target.value
        let tempArr = [...SelectedVariance]
        let itemFound = false
        if (value) {
            tempArr.forEach(element => {
                if (element.Variance_name === value) {
                    itemFound = true
                    alert('variance already selected')
                }
            });
            if (!itemFound) {
                let tempItem = {}
                myVariance.forEach(element => {
                    if (element.Variance_name === value) {
                        tempItem = element
                    }
                })

                tempArr.push(tempItem)
                setSelectedVariance(tempArr)
            }
        }
    }
    function onAddtoList() {

        let finalData = {
            id: myProduct.id,
            name: myProduct.en_sort_title,
            imageName: myProduct.itemImageName,
            variance: SelectedVariance,
            price:myProduct.Selling_price,
            tp:myProduct.Selling_price - (myProduct.Selling_price*20/100),
        }
        let tempList = ListedItem
        tempList.push(finalData)
        setListeItem(tempList)
        window.localStorage.setItem('catlistedItem', JSON.stringify(tempList))
        setSelectedItem()
        setMyProduct()
        setMyVariance()


    }

    function onRemoveClick(item) {
        let tempArr = []
        SelectedVariance.forEach(element => {
            if (element !== item) {
                tempArr.push(element)
            }
        });
        setSelectedVariance(tempArr)
    }

    function onCancelClick() {
        setSelectedItem()
        document.body.style.overflow = 'auto'
    }

    useEffect(()=>{
        if (myProduct.id) {
            prductVariance(myProduct.id, setSelectedVariance, setMyVariance)
        }
    },[myProduct])
    return (
       <div className={CatelogLiCss.wrapper}>
         <div className={CatelogLiCss.pop_up}>
            <h2>Select Product Options</h2>
            <h3>{myProduct?.en_sort_title}</h3>
            {myProduct.hasVariance ?
                    <div className={CatelogLiCss.input_holder}>
                        <label>Select Variance</label>
                        <select onChange={onVariaceSelect}>
                            <option value={''}>Select Variance</option>
                            {myVariance?.map((item) => {
                                return <option key={item.id} value={item.Variance_name}>{item.Variance_name}</option>
                            })}
                        </select>
                    </div>
                : ''}
            <div className={CatelogLiCss.varinave_items}>
                {SelectedVariance?.map((item) => {
                    return <div key={item.id} className={CatelogLiCss.varinave_item}>
                       <p >{item.Variance_name} <span onClick={() => { onRemoveClick(item) }}>X</span></p>
                    </div>
                })}
            </div>
            <div className={CatelogLiCss.button_section_others}>
                <button onClick={onCancelClick}>cancel</button>
                <button onClick={onAddtoList}>Add to List</button>
            </div>
        </div>
       </div>
    )
}