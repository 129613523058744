import React, { useEffect, useState } from 'react'
import { GetAllDataExpress } from '../../../api/ExpressFunctions'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import { GetCashHistory } from '../../../api/CreditApi'
import CashCss from './Css/Cash.module.css'
const Cash = () => {
    const [filterData, setFilterData] = useState()
    const [tableData, setTableData] = useState()
    const [backendUser, setBackendUser] = useState()

    async  function getDataFormDatabase() {   
         let myData =  await GetCashHistory()
        // let myDatab = await GetAllDataExpress('debit_voucher')
        // let myUser = await GetAllDataExpress('backend_user')
        // setBackendUser(myUser)
        // myData.push(...myDatab)
        // let sortArr = myData.sort((a, b) => (a.created_date > b.created_date) ? -1 : 1)
        setTableData(myData)
    }

    function onDataChange(params) {
        
    }
    function onSearchClick(params) {
        
    }

    useEffect(() => {
        getDataFormDatabase()
    },[])

    function getTotalAmmount(myArray, fieldName) {
        let total = 0
        myArray?.map((item)=>{
            total +=item[fieldName] ? item[fieldName]:0
        })
        return total
    }
  return (
    <>
    <section className={CashCss.salesMain}>
        <div className={CashCss.innerSale}>
            <h2 className={CashCss.saleHeading}>Cash History</h2>
            <div className={CashCss.search_section}>
                      <div className={CashCss.input_section}>
                          <label>Start Date</label>
                          <input name='startDate' type={'date'} value={filterData?.startDate? filterData?.startDate:''} onChange={onDataChange}/>
                      </div>
                      <div className={CashCss.input_section}>
                          <label>End Date</label>
                          <input name='endDate' type={'date'} value={filterData?.endDate ? filterData?.endDate:''} onChange={onDataChange}/>
                      </div>
                     
                      <div className={CashCss.input_section}>
                          <button onClick={onSearchClick}>search</button>
                          <button>reset</button>
                      </div>
                      
                  </div>
            <div className={CashCss.tableSection}>
                <table>
                    <tbody>
                        <tr>
                            <th>Sl</th>
                            <th>Date</th>
                            <th>Particular</th>
                            <th>Receipt</th>
                            <th>Payment</th>
                            <th>Process By</th>
                            <th>Action</th>
                        </tr>
                        {tableData?.map((item, index)=>{
                          return <tr key={index}>
                              <td>{index+1}</td>
                              <td>{LocalTimeStampToDate(item.created_date)}</td>
                              <td>{item.particulars} (#{item.identity})</td>
                              <td>{item.receiveAmmount}</td>
                              <td>{item.paymentAmmount}</td>
                              <td>{item.userName}</td>
                              <td>action</td>
                          </tr>
                        })}
                        <tr>
                            <td colSpan={3}></td>
                            <td>Total : {getTotalAmmount(tableData, 'receiveAmmount')}</td>
                            <td>Total : {getTotalAmmount(tableData, 'paymentAmmount')}</td>
                            <td>Grand Total : {getTotalAmmount(tableData, 'receiveAmmount')-getTotalAmmount(tableData, 'paymentAmmount')}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</>
  )
}

export default Cash