import React, { useEffect, useState } from 'react';
import { GetChartData } from '../../api/CombineApi';
import { GetMonthname } from '../MyFunctions/MyFunctions';
import ChartJsChart from './ChartJsChart';
import MyChartCss from './MyChart.module.css';
const MyChart = () => {
    const [allTotal, setAllTotal] = useState(0)
    const [monthName, setMonthName] = useState('')
    const [monthNumber, setMonthNumber] = useState(new Date().getMonth() + 1)
    const [fullYear, setFullYear] = useState(new Date().getFullYear())
    const [isLoading, setIsLoading] = useState()
    
      const [myAmountData, setMyAmountData] = useState([])

    async function getChartData(monthNumberPass, fullYearPass) {
        setIsLoading(true)
        let month =  monthNumberPass ? monthNumberPass : monthNumber
        let year = fullYearPass ? fullYearPass : fullYear
        let totalDay = getDaysInMonth(year, month)
        let startDate = `${year}-${`${month}`.padStart(2, '0')}-01`
        let endDate = `${year}-${`${month}`.padStart(2, '0')}-${totalDay}`
        let monthData = await GetChartData({startDate, endDate})
        let mallTotal = 0
        setIsLoading(false)
        let amountData = []
        for (let index = 1; index <= totalDay; index++) {
            const mdate = `${year}-${`${month}`.padStart(2, '0')}-${`${index}`.padStart(2, '0')}`
            const foundData =  monthData.filter(element => element.formatted_date === mdate);
            if (foundData.length>0) {
               
                if (!foundData[0].declined) {
                    
                    amountData.push({...foundData[0], x:`${index}`})
                    mallTotal += foundData[0].total
                }else{
                    amountData.push({total:0, x:`${index}`})
                }
            }else{
                amountData.push({total:0, x:`${index}`})
            }
             
        }
        setAllTotal(Math.round(mallTotal))
        setMyAmountData(amountData)
        setMonthName(GetMonthname(month))
    }

    function getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }
    function onMonthChange(e) {
        let value = e.target.value

        setMonthNumber(value)
    }
    function onYearChange(e) {
        let value = e.target.value
        setFullYear(value)
    }
    function onFilterClick() {
        getChartData()
    }
    function OnResetClick() {
        setFullYear(new Date().getFullYear())
        setMonthNumber(new Date().getMonth() + 1)
        getChartData(new Date().getMonth() + 1, new Date().getFullYear())
    }

    useEffect(() => {
        getChartData()
    }, [])

    let MonthArr = [
        { name: 'January', value: 1 },
        { name: 'February', value: 2 },
        { name: 'March', value: 3 },
        { name: 'April', value: 4 },
        { name: 'May', value: 5 },
        { name: 'June', value: 6 },
        { name: 'July', value: 7 },
        { name: 'August', value: 8 },
        { name: 'September', value: 9 },
        { name: 'October', value: 10 },
        { name: 'November', value: 11 },
        { name: 'December', value: 12 },
    ]
    let YearArr = [
        { name: 2022, value: 2022 },
        { name: 2023, value: 2023 },
        { name: 2024, value: 2024 },
        { name: 2025, value: 2025 },
        { name: 2026, value: 2026 },
        { name: 2027, value: 2027 },
        { name: 2028, value: 2028 },
        { name: 2029, value: 2029 },
        { name: 2030, value: 2030 },
    ]

    return (
        <>
            {isLoading ?
                <h2>Loading Chart...</h2> : 
        
                <div className={MyChartCss.heading_chart}>

                    <div>
                        <h2><span>Month :</span> {monthName}, {fullYear}</h2>
                    </div>
                    <div>
                        <h2>Total : {allTotal} TK</h2>
                    </div>
                    <div>
                        <div className={MyChartCss.FilterSection} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <p>Filter : </p>
                            <select onChange={onYearChange} defaultValue={fullYear}>
                                <option key={'hello'} value={''}>Select Year </option>
                                {YearArr.map((item, index) => {
                                    return <option key={index} value={item.value}>{item.name}</option>
                                })}
                            </select>
                            <select onChange={onMonthChange} defaultValue={monthNumber}>
                                <option key={'hello'} value={''}>Select Month </option>
                                {MonthArr.map((item, index) => {
                                    return <option key={index} value={item.value}>{item.name}</option>
                                })}
                            </select>
                            <div>
                                <button style={{ padding: 8, border: 'none', background: 'green', borderRadius: 5, color: 'white' }} onClick={onFilterClick}>Filter</button>
                                <button style={{ padding: 8, border: 'none', background: 'rgb(5 91 183)', borderRadius: 5, color: 'white', marginLeft: 10 }} onClick={OnResetClick}>Reset</button>
                            </div>

                        </div>
                    </div>

                </div>
            }
           <div className={MyChartCss.chart_holder}>
                <div className={MyChartCss.chart_inner}>
                     <ChartJsChart amountData = {myAmountData}/>
                </div>
           </div>

        </>

    )
}

export default MyChart