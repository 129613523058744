import { faBars, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SalesSearchComp from './SalesSearchComp'
import { GetDeclineSalesData } from '../../api/SalesApi'
import DecOrder from './salesCss/DeclinedOrders.module.css'
import ActivityViewModel from './models/ActivityViewModel'

const DeclinedOrders = ({ mtableData }) => {
    const [tableData, setTableData] = useState(mtableData)
    const [activityView, setActivityView] = useState(false)
    async function activityViewClick(e, item) {
        setActivityView(item)
        document.body.style.overflow = 'hidden'
    }
    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getDataFormDatabase() {
        let declineData = await GetDeclineSalesData()
        setTableData(declineData)
        //  setSpinnerShow(false)
        // let sortArr = myData.sort((a, b) => (a.createdDate?.seconds > b.createdDate?.seconds) ? -1 : 1)
    }
    useEffect(() => {
        getDataFormDatabase()
    }, [mtableData])

    const TableItem = tableData?.map((item) => {
        return <MyTable key={item.id} id={'#' + item.id} date={convertDate(item.created_date)} cname={item.name} cphone={item.phone || item.aphone} caddress={item.address} tammount={item.grandTotal} action={
            <div className={DecOrder.actionButton}>
                <Link to={`/admin/sales/declinedetails/${item.id}`}><FontAwesomeIcon icon={faCircleInfo} /></Link>
                <a><FontAwesomeIcon className={DecOrder.myBars} icon={faBars} onClick={(e) => { activityViewClick(e, item);  document.body.style.overflow = 'hidden' }} /></a>
            </div>} />
    })
    const marginTop = {
        marginTop: '10px'
    }

    return (
        <>
           
            {activityView ? <ActivityViewModel popUp={activityView} setPopUp={setActivityView}/> : ""}

            <section style={marginTop} className={DecOrder.salesMain}>
                <div className={DecOrder.innerSale}>
                    <h2 className={DecOrder.saleHeading}>Declined Orders</h2>
                    <SalesSearchComp passedFrom={'declined'} setFilteredData={setTableData} />
                    <div className={DecOrder.tableSection}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>id</th>
                                    <th>Date</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Customer Address</th>
                                    <th>Total Ammount</th>
                                    <th>Action</th>
                                </tr>
                                {TableItem}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DeclinedOrders

const MyTable = ({ id, date, cname, cphone, caddress, tammount, action }) => {

    return (
        <>
            <tr className={DecOrder.declinedOrder_tr}>
                <td className={DecOrder.invoiceClick}>{id}</td>
                <td>{date}</td>
                <td className={DecOrder.customerClick}>{cname}</td>
                <td>{cphone}</td>
                <td>{caddress}</td>
                <td>{tammount}</td>
                <td>{action}</td>
            </tr>
        </>
    )
}