import React, { useEffect, useState } from 'react'
import PopUpInput from '../PopUpInput'
import ConfirmAlert from '../smallThings/ConfirmAlert'
import Table from '../Table'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUnits, selectMAUStatus, selectUnitList } from '../../ReduxFiles/Slices/MAU_Slice'
import { DeleteUnits, SaveUnits, UpdateUnits } from '../../api/MAU_Api'
import UnitsCss from './css/Units.module.css'
const Units = () => {
    const mauStatus = useSelector(selectMAUStatus)
    const unitList = useSelector(selectUnitList)
    const dispatch = useDispatch()

    const [conAlert, setConAlert] = useState(false)
    const [editId, setEditID] = useState()
    const [buttonValue, setButtonValue] = useState("Add New")
    const [unitName, setUnitName] = useState('');
    const [tableData, setTableData] = useState([]);

    function handleChange(e) {
        setUnitName(e.target.value)
        //  console.log(unitName)
    }


    function cancelClick() {
        setUnitName('')
        setButtonValue('Add New')
    }
    function saveUnit() {
        if (unitName !== '') {
            // console.log(unitName)
            if (buttonValue === 'Add New') {
                saveData();
                setUnitName('');
            } else {
                updateData(editId, unitName)
                setUnitName('');
            }

        } else {
            alert('unite Name Required')
        }
    }

    const updateData = async (id, value) => {
        const newFields = { unit: value };
        await UpdateUnits(id, newFields)
        let tempArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = { ...newFields, id: id }
                tempArr.push(element)
            } else {
                tempArr.push(element)
            }
        });
        setTableData(tempArr)
        setButtonValue('Add New')
        // console.log(newFields, id)
    };

    async function saveData() {
        let itemID = await SaveUnits({ unit: unitName })
        setTableData([...tableData, { unit: unitName, id: itemID.insertId }])

    }

    function EditClick(e) {
        setButtonValue('Update Data')
        let mquery = e.target.getAttribute("sid")
        getEditData(mquery);
    }

    function getEditData(query) {
        for (let index = 0; index < tableData.length; index++) {
            const element = tableData[index];
            if (element.id == query) {
                const getId = element.id
                const getName = element.unit
                setUnitName(getName)
                setEditID(getId)
            }
        }
    }

    function DeletClick(e) {
        const dataid = e.target.getAttribute("sid")
        setConAlert(true)
        setEditID(dataid)
    }

    function Clickyes(e) {
        let delid = e.target.getAttribute("sid")
        DeleteUnits(delid)
        let tempArr = []
        tableData.forEach(element => {
            if (element.id !== parseInt(delid)) {
                tempArr.push(element)
            }
        });
        setTableData(tempArr)
        setConAlert(false)
    }

    function ClickNo() {
        setConAlert(false)
    }


    useEffect(() => {
        if (mauStatus.unitStatus==='idle') {
            dispatch(fetchUnits())
        }
    }, [])


    let TableItem = unitList.map((item, index) => {
        const showData = <Table key={index} param1={index + 1} param2={item.unit} param3={<div>
            <input onClick={EditClick} type="Submit" defaultValue="Edit" sid={item.id} />
            <input className={UnitsCss.delete} onClick={DeletClick} type="Submit" defaultValue="Delete" sid={item.id} />
        </div>} />

        return showData;
    })


    return (
        <>
            {conAlert ? <ConfirmAlert delID={editId} yesClick={Clickyes} noClick={ClickNo} /> : ''}
            <div className={`${UnitsCss.addCatagory} ${UnitsCss.admincomm}`}>
                <h3 className={UnitsCss.itemlisth3}>Units</h3>
                <div className={UnitsCss.atributesec}>
                    <div className={UnitsCss.nameAtrribute}>
                        <PopUpInput popname="addUnit" poplabel="Unit  Name :" popplaceholder="Unit Name" poptype="text" ondatachange={handleChange} value={unitName} />
                        <input className={UnitsCss.addnew} type={"submit"} value={buttonValue} onClick={saveUnit} />
                        <input className={`${UnitsCss.addnew} ${UnitsCss.cancel}`} type={"submit"} value={"Cancel"} onClick={cancelClick} />
                    </div>

                </div>


                <div className={UnitsCss.UnitsTable}>
                    <table>
                        <tbody>
                            <tr>
                                <th>Sl</th>
                                <th>Unit Name</th>
                                <th>Action</th>
                            </tr>
                            {TableItem}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Units