
import React, { useEffect, useState } from 'react'

import AddNewMenu from './addNew/AddNewMenu'
import ItemInformation from './addNew/ItemInformation'
import ItemPrice from './addNew/ItemPrice'
import ProductsAttributes from './addNew/ProductsAttributes'

import AddNewItemCss from './css/AddNewItem.module.css'
const AddNewItem = () => {

    const [showState, setShowState] = useState(1)

    const [item, setItem] = useState(
        {
            en_sort_title: "",
            en_long_title: "",
            bn_sort_title: "",
            bn_long_title: "",
            item_code: "",
            selectedCatagoy: "",
            selectedBrand: "",
            ISBN_number: "",
            selectedUnit: "",
            en_description: "",
            bn_description: "",
            en_speicification: "",
            bn_speicification: "",
            Selling_price: "",
            tax: "",
            Promo_price: "",
            Start_date: "",
            End_date: ""

        }

    )

    useEffect(() => {

    }, [])
    return (
        <>
            <div className={`${AddNewItemCss.addnewiteminfo} ${AddNewItemCss.admincomm}`}>
                <h3>Item Information</h3>
                <AddNewMenu mstate={setShowState} stateValue={showState} item={item} />

                <div className={showState === 1 ? `${AddNewItemCss.disBlock}` : `${AddNewItemCss.disNone}`}>
                    <ItemInformation item={item} setstate={setShowState} setItem={setItem} />
                </div>
                <div className={showState === 2 ? `${AddNewItemCss.disBlock}` : `${AddNewItemCss.disNone}`}>
                    <ItemPrice setstate={setShowState} item={item} setItem={setItem} showState={showState} />
                </div>
                <div className={showState === 3 ? `${AddNewItemCss.disBlock}` : `${AddNewItemCss.disNone}`}>
                    <ProductsAttributes showState={showState} item={item} setItem={setItem} setstate={setShowState} />
                </div>
            </div>


        </>
    )
}

export default AddNewItem