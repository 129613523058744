import React from 'react'
import { NavLink, Route, Routes, useParams } from 'react-router-dom'
import DeclinedList from './DeclinedList'
import NewPurchase from './NewPurchase'
import PendingPurchaseList from './PendingPurchaseList'
import PurchaseReturn from './PurchaseReturn'
import PurchaseReturnHistory from './PurchaseReturnHistory'
import PurhcaseHistory from './PurhcaseHistory'
import PurchaseConfirm from './PurchaseConfirm'
import PurchaseMainCss from './css/PurchaseMain.module.css'

const PurchaseMain = () => {
    const myParams = useParams()

    return (
        <> 
            <div className={PurchaseMainCss.menupart}>
                <ul className={PurchaseMainCss.menuitems}>
                    <NavLink to={"/admin/purchase/pendinglist"}>
                        <li >Pending Purchase List</li>
                    </NavLink>
                    <NavLink to={"/admin/purchase/purchasehistory"}>
                        <li >Purchase History</li>
                    </NavLink>
                    <NavLink to={"/admin/purchase/newpurchase"}>
                        <li >New Purchase</li>
                    </NavLink>
                    <NavLink to={"/admin/purchase/declinedpurchase"}>
                        <li >Declined Purchase</li>
                    </NavLink>
                    <NavLink to={"/admin/purchase/purchasereturn"}>
                        <li >Purchase Return</li>
                    </NavLink>
                    <NavLink to={"/admin/purchase/purchasereturnhistory"}>
                        <li >Purchase Return History</li>
                    </NavLink>
                </ul>
            </div>

            {/* border this class not found  */}
            <div className="border">

                {/* {myParams.mpurchase === undefined ? <PendingPurchaseList data={"Pending Purchase List"}/> : ""}
                {myParams.mpurchase === 'pendinglist' ? <PendingPurchaseList data={"Pending Purchase List"}/> : ""}
                {myParams.mpurchase === 'purchasehistory' ? <PurhcaseHistory/> : ""}
                {myParams.mpurchase === 'newpurchase' ? <NewPurchase data={"New Purchase"}/> : ""}
                {myParams.mpurchase === 'declinedpurchase' ? <DeclinedList /> : ""}
                {myParams.mpurchase === 'purchasereturn' ? <PurchaseReturn /> : ""}
                {myParams.mpurchase === 'purchasereturnhistory' ? <PurchaseReturnHistory/> : ""} */}

                <Routes>
                    <Route path='/' element={<PendingPurchaseList/>}/>
                    <Route path='/pendinglist' element={<PendingPurchaseList/>}/>
                        
                    <Route path='/purchasehistory' element={<PurhcaseHistory/>}/>
                    <Route path='/newpurchase' element={<NewPurchase/>}/>
                    <Route path='/declinedpurchase' element={<DeclinedList/>}/>
                    <Route path='/purchasereturn' element={<PurchaseReturn/>}/>
                    <Route path='/purchasereturnhistory' element={<PurchaseReturnHistory/>}/>
                </Routes>
            </div>
        </>
    )
}

export default PurchaseMain

const ComingSoon =({data})=>{
    return (
        <h1>Coming Soon {data}</h1>
    )
}