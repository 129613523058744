import { faBars, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ServerTimeToFormateTime } from '../../api/GlobalFunctions'
import { GetPendingSalesData } from '../../api/SalesApi'
import CustomerInformationModel from './models/CustomerInformationModel'
import PandingSalesCss from './salesCss/PendingSales.module.css'
const PendingSales = () => {
    const [tableData, setTableData] = useState()
    const [showUser, setShowUser] = useState(false)

    async function customerClick(item) {
        setShowUser(item)
        document.body.style.overflow = 'hidden'
    }
    const marginTop = {
        marginTop: '10px'
    }
    async function getDataFormDatabase() {
       let data =  await GetPendingSalesData()
       setTableData(data)
    }
    useEffect(() => {
        getDataFormDatabase()
    }, [])

    const TableItem = tableData?.map((item) => {
        return <MyTable key={item.id} id={<Link to={`/admin/sales/details/${item.id}`}>{'#' + item.id}</Link>} date={ServerTimeToFormateTime(item.created_date)}
            cname={item.name} cphone={item.phone} caddress={`${item.District}, ${item.Area}, ${item.Address}.`} tammount={item.grandTotal + item.deliveryCharge}
            paid={item.paymentCompleate}
            action={
                <div className={PandingSalesCss.actionButton}>
                    <Link to={`/admin/sales/details/${item.id}`}><FontAwesomeIcon icon={faCircleInfo} /></Link>
                    <FontAwesomeIcon className={PandingSalesCss.myBars} icon={faBars} />
                </div>} customerClick={() => {customerClick(item)}} />
    })

    return (
        <>
            {/* {showUser ? <UserInfo name={userData?.name} phone={userData?.phone} email={userData?.email} address={userData?.address} image={userData.userImageName} oncloseClick={oncloseClick} /> : ""} */}
            {showUser && <CustomerInformationModel popUp={showUser} setPopUp={setShowUser}/>}
            <section style={marginTop} className={PandingSalesCss.salesMain}>
                <div className={PandingSalesCss.innerSale}>
                    <h2 className={PandingSalesCss.saleHeading}>Pending Sales</h2>
                    <div className={PandingSalesCss.tableSection}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>id</th>
                                    <th>Date</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Customer Address</th>
                                    <th>Total Ammount</th>
                                    <th>SSL Paid</th>
                                    <th>Action</th>
                                </tr>
                                {TableItem}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PendingSales

const MyTable = ({ id, date, cname, cphone, caddress, tammount, paid, action, customerClick }) => {

    return (
        <>
            <tr>
                <td className={PandingSalesCss.invoiceClick}>{id}</td>
                <td>{date}</td>
                <td className={PandingSalesCss.customerClick} onClick={customerClick}>{cname}</td>
                <td>{cphone}</td>
                <td>{caddress}</td>
                <td>{tammount}</td>
                <td>{paid ? 'yes' : 'no'}</td>
                <td>{action}</td>
            </tr>
        </>
    )
}
