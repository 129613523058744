import React, { useEffect, useState } from 'react'
import DropDownSearch from '../../smallThings/DropDownSearch'
// import './offerReport.css' 
import offerReportCss from './css/OfferReport.module.css'

const OfferReport = () => {
    const [searchDate, setSearchDate] = useState()
    const [allProducts, setAllProducts] = useState()
    const [alertText, setAlertText] = useState()
    function onInputChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSearchDate({ ...searchDate, [name]: value })
    }

    function searchClick() {
        // let mQuery = `SELECT * FROM sales_offer_items WHERE `
        // let mLink = `/admin/sales_offers/report_route?`
        // if (!searchDate?.start_date && !searchDate?.end_date && !searchDate?.itemID) {
        //     setAlertText('You Must Fill Data To Genarate Report')
        // }
        // else if (searchDate?.itemID && !searchDate?.start_date && !searchDate?.end_date) {
        //     mLink += `product_id=${searchDate?.itemID}`
        // }
        // else if (searchDate?.start_date && !searchDate?.end_date) {
        //     setAlertText('You Must Fill Both Start Date & End Date To Genarate Report')
        // }
        // else if (!searchDate?.start_date && searchDate?.end_date) {
        //     setAlertText('You Must Fill Both Start Date & End Date To Genarate Report')
        // }
        // else if (searchDate?.start_date && searchDate?.end_date && !searchDate?.itemID) {
        //     mLink += `start_date=${searchDate?.start_date}&end_date=${searchDate?.end_date}`
        //     // mQuery += `created_date > ${new Date(searchDate?.start_date).getTime()} AND created_date > ${new Date(searchDate?.end_date).getTime()}`
        // }
        // else {
        //     mLink += `product_id=${searchDate?.itemID}&start_date=${searchDate?.start_date}&end_date=${searchDate?.end_date}`
        //     // mQuery += `created_date > ${new Date(searchDate?.start_date).getTime()} AND created_date > ${new Date(searchDate?.end_date).getTime()} AND product_id = ${searchDate?.itemID}`
        // }
        // //window.open(mLink)

    }
    function resetClick() {
        setSearchDate()
    }
    function searchItemClick(item) {


        setSearchDate({ ...searchDate, itemID: item.id })
    }

    async function getPrimaryData() {
        let myAllProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        // console.log(myAllProducts)
        setAllProducts(myAllProducts)

    }

    useEffect(() => {
        getPrimaryData()
    }, [])


    return (
        <section className={`${offerReportCss.salesMain}  ${offerReportCss.offer_report_page}`}>
            {alertText ? <AlertComp alertText={alertText} setAlertText={setAlertText} /> : ''}
            <div className={offerReportCss.innerSale}>
                <h2 className={offerReportCss.saleHeading}>Offer Reports</h2>

                <div className={offerReportCss.searchSection}>
                    <MyInput labelText="Start" type='date' inputName='start_date' placeholderText='dd/mm/yyyy' onDataChange={onInputChange} myValue={searchDate?.start_date} />
                    <MyInput labelText="End" type='date' inputName='end_date' placeholderText='dd/mm/yyyy' onDataChange={onInputChange} myValue={searchDate?.end_date} />
                    <div className={offerReportCss.search}>
                        <div className={offerReportCss.offer_item_search}>
                            <DropDownSearch mainArray={allProducts} searchField={'en_sort_title'} showDataField={'Selling_price'} onMyClick={searchItemClick} />
                        </div>
                        <div className={offerReportCss.button_section_offer}>
                            <ButtonInput mclass={offerReportCss.searchClass} myValue={'Search'} onClickevent={searchClick} />
                            <ButtonInput mclass={offerReportCss.resetClass} myValue={'Reset'} onClickevent={resetClick} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OfferReport

const MyInput = ({ labelText, type, inputName, placeholderText, myValue, onDataChange }) => {
    const diplayBlock = {
        'display': 'block'
    }
    return (
        <>
            <div className={offerReportCss.inputAll}>
                <label style={diplayBlock}>{labelText}</label>
                <input type={type} name={inputName} placeholder={placeholderText} value={myValue ? myValue : ''} onChange={onDataChange} />
            </div>
        </>
    )
}
const ButtonInput = ({ myValue, mclass, onClickevent }) => {
    return (
        <>
            <div>
                <button className={mclass} onClick={onClickevent}>{myValue}</button>
            </div>
        </>
    )
}

const AlertComp = ({ alertText, setAlertText }) => {
    function onClickevent() {
        setAlertText()
    }
    return (
        <div className={offerReportCss.offer_alert}>
            <h2>ALERT!</h2>
            <p className={offerReportCss.message}>{alertText}</p>
            <div className={offerReportCss.button_section_offer_alert}>
                <button onClick={onClickevent} className={offerReportCss.alert_button}>I Understand</button>
            </div>
        </div>
    )
}
