import React, { useEffect, useRef, useState } from 'react'
import TimyMCE from '../TimyMCE'
import { DeletePageData, GetPageData, UpdatePageData } from '../../api/PagesApi'
import { useNavigate } from 'react-router-dom'
import PageListCss from './css/PageList.module.css'
import ConfirmAlert from '../smallThings/ConfirmAlert'
import { toast } from 'react-toastify'
import { error } from 'jquery'
async function getPageData(setPageList) {
    let mpageList = await GetPageData()
    setPageList(mpageList)
}
const PageList = () => {
    const [pageList, setPageList] = useState()

   
    const myNavigate = useNavigate()
    function onEditClick(item) {
      myNavigate(`/admin/pages/edit_page?id=${item.id}`)
    }

    useEffect(() => {
        getPageData(setPageList)
    }, [])
 
    const [deleteData, setDeleteData] = useState()

    async function onDeleteClick(item) {
       setDeleteData(item)  
       document.body.style.overflow = 'hidden'
    }
    async function onYesClick() {
        try {
          const response =  await DeletePageData(deleteData.id)
          console.log(response);
            toast.success('Page Deleted Successfully.')
            setDeleteData()
            document.body.style.overflow = 'auto'
            getPageData(setPageList)
        } catch (error) {
            toast.error(error.message)
        } 
    }

    function onNoClick() {
        setDeleteData()
        document.body.style.overflow = 'auto'
    }

    return (
        <section className={`${PageListCss.salesMain} ${PageListCss.manageuserMain}`}>
            {deleteData && <ConfirmAlert yesClick ={onYesClick} noClick={onNoClick}/>}
            <h3>Page List</h3>
            <div className={PageListCss.tableSection}>
                <table>
                    <tbody>
                        <tr>
                            <th>SL</th>
                            <th>Page Name</th>
                            <th>Page Slug</th>
                            <th>Page Description</th>
                            <th>Action</th>
                        </tr>
                        {pageList?.map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.page_name}</td>
                                <td>{item.page_slug}</td>
                                <td>{item.meta_description}</td>
                                <td><div>
                                    <button className={PageListCss.edit} onClick={()=>{onEditClick(item)}}>Edit</button>
                                    <button onClick={()=>{onDeleteClick(item)}} className={PageListCss.bg_red} style={{marginLeft:'10px'}}>Delete</button>
                                </div></td>
                            </tr>
                        })}
                        {/* {TableItem} */}
                    </tbody>
                </table>
            </div>
        </section>
    )
}

export default PageList


const EditPageData = ({pageData, setPageData}) => {
    const page_content = useRef()
    
    const [editedpageData, setEditedPageData] = useState(pageData)
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setEditedPageData({...pageData, [name]:value})
    }
  async function onUpdateClick() {
        let mpageContent = page_content.current.getContent()
        let dataID = editedpageData.id
        delete editedpageData.id
        let finalData ={
            ...editedpageData,
            page_content:mpageContent,
            created_date:new Date().getTime()
        }
       
      await UpdatePageData(dataID, finalData)
      setPageData()
      window.location.reload(false)
    }
  
    return (
        <section>
            <div>
                <h2>{editedpageData?.page_name} Edit</h2>
                <div>
                    <label>Page Name : </label>
                    <input type={'text'} placeholder='enter page name here' name='page_name' onChange={onDataChange} value={editedpageData?.page_name ? editedpageData?.page_name:''} />
                </div>
                <div>
                    <label>Page Slug : </label>
                    <input type={'text'} placeholder='enter page slug' name='page_slug' onChange={onDataChange} value={editedpageData?.page_slug ? editedpageData?.page_slug:''}/>
                </div>
                <div>
                    <label>Meta Title : </label>
                    <input type={'text'} placeholder='enter meta title' name='meta_title' onChange={onDataChange} value={editedpageData?.meta_title ? editedpageData?.meta_title:''}/>
                </div>
                <div>
                    <label>Meta Description : </label>
                    <input type={'text'} placeholder='enter meta description' name='meta_description' onChange={onDataChange} value={editedpageData?.meta_description ? editedpageData?.meta_description:''}/>
                </div>
                <div>
                    <label>Meta Keyword : </label>
                    <input type={'text'} placeholder='enter meta keyword' name='meta_keyword' onChange={onDataChange} value={editedpageData?.meta_keyword ? editedpageData?.meta_keyword:''}/>
                </div>
                <div className={PageListCss.tinysec}>
                    <section className={PageListCss.mytextArea}>
                        {/* initValue={"write page description in English here"} */}
                        <TimyMCE refarence={page_content} initValue={editedpageData?.page_content ? editedpageData?.page_content:''} placeholderText={'write page description in here'} labeltext="Write Page Description : " />
                    </section>
                </div>
                <button onClick={onUpdateClick}>Update Page</button>
                <button onClick={()=>{setPageData()}}>Close</button>
            </div>
        </section>
    )
}