import React from 'react'
import { useParams } from 'react-router-dom'
import BankBalanceList from './AllComponent/BankBalanceList'
import BankTransactionHistory from './AllComponent/BankTransactionHistory'
import Cash from './AllComponent/Cash'
import CheckApi from './AllComponent/CheckApi'
import CreditVoucher from './AllComponent/CreditVoucher'
import CreditVoucherHistory from './AllComponent/CreditVoucherHistory'
import DayBook from './AllComponent/DayBook'
import DebitVoucher from './AllComponent/DebitVoucher'
import DebitVoucherHistory from './AllComponent/DebitVoucherHistory'
import Deposite from './AllComponent/Deposite'
import Payment from './AllComponent/Payment'
import PaymentHistory from './AllComponent/PaymentHistory'
import SSL from './AllComponent/SSL'
import TransictionIN from './AllComponent/TransictionIN'
import TransictionOUT from './AllComponent/TransictionOUT'
import Withdraw from './AllComponent/Withdraw'
import ViewBankBook from './PDFFiles/ViewBankBook'
import VoucherCommon from './PDFFiles/VoucherCommon'

const AccountNavigate = () => {
    const myParams = useParams()
  return (
    <div>
    {myParams.function ==='payment' ? <Payment/>:''}
    {myParams.function ==='payment-history' ? <PaymentHistory/>:''}
    {myParams.function ==='payment-history' && myParams.action ==='print-voucher'? <VoucherCommon/>:''}
    {myParams.function ==='debit_voucher' ? <DebitVoucher/>:''}
    {myParams.function ==='debit_voucher_history' ? <DebitVoucherHistory/>:''}
    {myParams.function ==='credit_voucher' ? <CreditVoucher/>:''}
    {myParams.function ==='credit_voucher_history' ? <CreditVoucherHistory/>:''}
 
    {myParams.function ==='cash' ? <Cash/>:''}
    {myParams.function ==='ssl' ? <SSL/>:''}
    {myParams.function ==='day-book' ? <DayBook/>:''}
    {myParams.function ==='bank_balance_list' ? <BankBalanceList/>:''}
    {myParams.function ==='bank_transiction_history' ? <BankTransactionHistory/>:''}
    {myParams.function ==='deposite' ? <Deposite/>:''}
    {myParams.function ==='withdraw' ? <Withdraw/>:''}
    {myParams.function ==='transictionin' ? <TransictionIN/>:''}
    {myParams.function ==='transictionout' ? <TransictionOUT/>:''}
    {myParams.function ==='view_bank_history' ? <ViewBankBook/>:''}
    {myParams.function ==='check_api' ? <CheckApi/>:''}
  </div> 
  )
}

export default AccountNavigate