import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetAllDataExpress, GetDataPassQuery } from '../../../api/ExpressFunctions'
import Headerimage from '../../../assets/Headerimage.jpg'
import OfferReportPDFCss from './css/OfferReportPDF.module.css'

const OfferReportPDF = () => {
    const [invoiceState, setInvoiceState] = useState()
    let [searchParams] = useSearchParams();
    // const [customerData, setCustomerData] = useState()
    async function getData() {
        let query = 'SELECT * FROM sales_offer_items WHERE canceled = 0'
        let myData = await GetDataPassQuery(query)
        let myCustomer = await GetAllDataExpress('user')
        let userAddress = await GetAllDataExpress('user_address_book')
        let AllData = []
        myData.forEach(element => {
            for (let index = 0; index < myCustomer.length; index++) {
                const celement = myCustomer[index];
                    if (element.userID===celement.id) {
                        element = {
                            ...element,
                            itemid:element.id, 
                            ...celement
                        }
                        for (let index = 0; index < userAddress.length; index++) {
                            const aelement = userAddress[index];
                            if (element.user_address_id===aelement.id) {
                                element = {
                                    ...element,
                                    ...aelement
                                }
                                break
                            }
        
                        }
                       break
                    } 
            }
            AllData.push(element)
          //  console.log(element)
        });

        AllData.sort((a, b) => (a.itemid > b.itemid) ? -1 : 1)
        setInvoiceState(AllData)
        
    }
    function PrintPaper() {
        window.print()
    }
   

    useEffect(() => {
        getData()
    }, [])

    function getDiscountPercent(oPrice, pPrice) {
        let mPercent = 0
        let diff = oPrice-pPrice
        mPercent = diff*100/oPrice

        return mPercent +' %'
    }
//    sl, invoiceno, date, cname, cphone, startDate, endDate, discount, promoPrice, quantity, total
    const TableItem = invoiceState?.map((item, index) => {
        return <MyTable key={item.itemid}
        sl={index+1}
        invoiceno={item.sales_id}
        date={new Date(item.created_date).toLocaleDateString()}
        cname={item.name}
        cphone={item.phone}
        startDate={item.Start_date}
        endDate={item.End_date}
        orginalPrice={item.original_price}
        discount={getDiscountPercent(item.original_price,item.promoPrice)}
        promoPrice={item.promoPrice}
        quantity={item.quantity}
        total={item.promoPrice*item.quantity}
      />
    })
   

    return (
        <div className={OfferReportPDFCss.invoicemain}>
        <button className={OfferReportPDFCss.printBtn} onClick={PrintPaper}>Print</button>
    
        <section className={ `${OfferReportPDFCss.invoiceSection} ${OfferReportPDFCss.supplier_invoice}`}>
            <div className={OfferReportPDFCss.container} id="printInvoice" >
                <div className={OfferReportPDFCss.topSection}>
                    <img src={Headerimage} alt="" />
                </div>
                <div className={OfferReportPDFCss.main}>
                    <h3>Offer Sales Report</h3>
                    <div className={OfferReportPDFCss.details}>
                           <div className={OfferReportPDFCss.invoice}>
                            <div className={OfferReportPDFCss.info}>
                                {<span className={OfferReportPDFCss.moreinfo}>Date : {searchParams.get('start_date')}  To {searchParams.get('end_date')}</span>}
                            </div>
                        </div>
                    </div>
                    <div className={`${OfferReportPDFCss.table} ${OfferReportPDFCss.sales_table}  ${OfferReportPDFCss.fixed_table}`}>
                        <table>
                            <thead>
                                <tr >
                                    <th >Sl</th>
                                    <th style={{ width: '20px' }}>Invoice No.</th>
                                    <th>Date & Time</th>
                                    <th >Customer Name</th>
                                    <th >Customer Name</th>
                                    <th >Offer Start Date</th>
                                    <th >Offer End Date</th>
                                    <th >Original Price</th>
                                    <th >Discount (%)</th>
                                    <th >Promo Price</th>
                                    <th >Qunattity</th>           
                                    <th >Total</th>
                                </tr>
                            </thead>
                            <tbody>
                            {TableItem}
                            </tbody>
                        </table>
                    </div>
    
    
    
                </div>
            </div>
        </section>
    </div>
    )
}

export default OfferReportPDF


const MyTable = ({ sl, invoiceno, date, cname, cphone, startDate, endDate, orginalPrice, discount, promoPrice, quantity, total }) => {

return (
        <tr key={invoiceno}>
            <td className=''>{sl}</td>
            <td className=''>{invoiceno}</td>
            <td>{date}</td>
            <td >{cname}</td>
            <td>{cphone}</td>
            <td>{startDate}</td>
            <td>{endDate}</td>
            <td>{orginalPrice}</td>
            <td>{discount}</td>
            <td>{promoPrice}</td>
            <td>{quantity}</td>
            <td>{total}</td>
        </tr>
)
}