import React from 'react'
import { NavLink, Route, Routes, useParams } from 'react-router-dom'
import AddNewPage from './AddNewPage'
import PageList from './PageList'
import EditPage from './EditPage'
import PagesMainCss from './css/PagesMain.module.css'

const PagesMain = () => {
    const myParams = useParams()
 
  return (
    <>
    <div className={PagesMainCss.menupart}>
        <ul className={PagesMainCss.menuitems}>
            <NavLink to={"/admin/pages/page_list"}>
                <li >Page List</li>
            </NavLink>
            <NavLink to={"/admin/pages/add_new_page"}>
                <li >Add New Page</li>
            </NavLink>
        </ul>
    </div>
    <div>
        <Routes>
            <Route path='/' element={<PageList/>}/>
            <Route path='/page_list' element={<PageList/>}/>
            <Route path='/add_new_page' element={<AddNewPage/>}/>
            <Route path='/edit_page' element={<EditPage/>}/>
        </Routes>
        {/* {myParams.mpages === undefined ? <PageList /> : ""}
        {myParams.mpages === "page_list" ? <PageList /> : ""}

        {myParams.mpages === "add_new_page" ? <AddNewPage /> : ""} */}

    </div>


</>
  )
}

export default PagesMain