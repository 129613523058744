import React from 'react'
import { NavLink } from 'react-router-dom'
import ItemMenuCss from './css/Item_menu.module.css'

const Item_menu = () => {


    return (
        <>
            <div className={ItemMenuCss.menupart}>
                <ul className={ItemMenuCss.menuitems}>
                    <NavLink to={"/admin/itemmanagement/itemlist"}> <li>Item List </li></NavLink>
                    <NavLink to={"/admin/itemmanagement/additem"}> <li>Add New Item </li></NavLink>
                    <NavLink to={"/admin/itemmanagement/itemcat"}> <li>Item categories </li> </NavLink>
                    <NavLink to={"/admin/itemmanagement/brands"}> <li>Brand List</li> </NavLink>
                    <NavLink to={"/admin/itemmanagement/managetag"}><li>Manage Tag  </li></NavLink>
                    <NavLink to={"/admin/itemmanagement/atributes"}><li> Attributes </li> </NavLink>
                    <NavLink to={"/admin/itemmanagement/unit"}><li>Units </li></NavLink>
                </ul>

            </div>

        </>
    )
}

export default Item_menu