
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Spinner from '../smallThings/Spinner'
import PopUpLocation from './PopUpLocation'
import { useSelector } from 'react-redux'
import { selectProductList } from '../../ReduxFiles/Slices/ProductSlice'
import { GetDetailsOrderItems, GetSingleOrder, UpdateOrderData } from '../../api/SalesApi'
import { GetSingleCustomer, GetSingleCustomerOrderAddress } from '../../api/CustomerApi'
import { GetFilterProductVariance } from '../../api/ProductVarianceApi'
import { selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import { DeleteSalesItemsData, DeleteSalesOfferItem, GetFilterSalesOfferItem, SaveSalesItemsData, SaveSalesOfferItemsData, SaveSummeryData, UpdateSalesItemsData, UpdateSalesOfferItemsData } from '../../api/OrderInformationApi'
import SalesEditCss from './salesCss/SalesEdit.module.css'

const SalesEdit = () => {
    const myParam = useParams()
    const userInfo = useSelector(selectUserInfo)
    const allProduct = useSelector(selectProductList)
    const [orderData, setOrderData] = useState()
    const [newVarianceData, setNewVarianceData] = useState()
    const [searchtext, setSarchText] = useState()
    const [tableData, setTableData] = useState()
    const [newTempItem, setNewTempItem] = useState()

    const [popAddress, setPopAddress] = useState(false)

    function EditAddressClick() {
        document.body.style.overflow = 'hidden'
        setPopAddress(true)
    }

    function onSearchTextChange(e) {
        setSarchText(e.target.value)
    }
    function onDeliveryDateChange(e) {
        setOrderData({ ...orderData, delivery_date: e.target.value })
    }
    function onDeliveryChargeChange(e) {
        if (e.target.value < 0 || !e.target.value) {
            setOrderData({ ...orderData, deliveryCharge: 0 })
        } else {
            setOrderData({ ...orderData, deliveryCharge: parseInt(e.target.value) })
        }
    }
    function onDiscountChange(e) {
        let value = e.target.value

        if (parseInt(value) < 0 || !value) {
            setOrderData({ ...orderData, discountPercent: 0 })
            // console.log(value)
        } else {
            if (orderData.discountType === 'percent') {
                setOrderData({ ...orderData, discountPercent: parseFloat(value) })
            } else {
                let myPercent = (parseFloat(value) * 100) / originalTotal()
                setOrderData({ ...orderData, discountPercent: myPercent, discountType: 'fixed' })
            }
        }
        // setOrderData({...orderData, deliveryCharge:parseInt(e.target.value)})
    }
    function onDiscountTypeChange(e) {
        setOrderData({ ...orderData, discountType: e.target.value })
    }
    async function onItemClick(e, item) {
        //console.log(item)
        setSarchText(item.en_sort_title)
        let mprice = item.Selling_price
        let promo = 0
        if (item.Promo_price > 0) {
            mprice = item.Promo_price
            promo = 1
        }
        let arrayData = {
            userID: userInfo.id,
            sales_id: myParam.orderid,
            price: mprice,
            promo: promo,
            quantity: 1,
            orPrice:item.Selling_price,
            name: item.en_sort_title,
            userID: orderData.userID,
            product_id: item.id,
            created_date: new Date().getTime(),
            mrp_price: item.Selling_price,
            tp_price: item.Selling_price*.8,
        }
        //  console.log(arrayData)
        if (item.hasVariance) {
            arrayData = {
                ...arrayData,
                hasVriance: 1,
            }
            let myVarince = await GetFilterProductVariance(`product_id = ${item.id}`)
            setNewVarianceData(myVarince)
        } else {
            setNewVarianceData()
        }
        setNewTempItem(arrayData)
        //  console.log(arrayData)
    }

    function onQuantityChange(e, index) {
        let value = e.target.value
        let myData = []
        tableData.map((item, mindex) => {
            if (index === mindex) {
                item = { ...item, quantity: value }
                myData.push(item)
            } else {
                myData.push(item)
            }
        })
        setTableData(myData)
    }
    function onDecreaseClick(e, index) {
        let myData = []
        tableData.map((item, mindex) => {
            if (index === mindex) {
                item = { ...item, quantity: (item.quantity - 1) }
                myData.push(item)
            } else {
                myData.push(item)
            }
        })
        setTableData(myData)
        setOrderData({ ...orderData, discountType: 'fixed', discountPercent: 0 })
    }
    function onIncreaseClick(e, index) {
        let myData = []
        tableData.map((item, mindex) => {
            if (index === mindex) {
                item = { ...item, quantity: (item.quantity + 1) }
                myData.push(item)
            } else {
                myData.push(item)
            }
        })
        setTableData(myData)
        setOrderData({ ...orderData, discountType: 'fixed', discountPercent: 0 })
    }

    function onVarianceChange(e) {
        let value = e.target.value
        // console.log(value)
        newVarianceData.map((item) => {
            if (item.Variance_name === value) {
                let myName = newTempItem.name
                setNewTempItem({ ...newTempItem, name: myName, Variance_name: item.Variance_name, variance_id: item.id, })
            }
        })
    }
    function onAddClick() {
        // console.log(newTempItem)
        let myokData = tableData.slice()
        let dataAdd = true
        if (newTempItem.hasVriance) {
            if (newTempItem.variance_id) {
                for (let index = 0; index < myokData.length; index++) {
                    const element = myokData[index];
                    if (element.variance_id === newTempItem.variance_id) {
                        alert('item already exist')
                        dataAdd = false
                        break
                    } else {
                        dataAdd = true
                    }
                }
            } else {
                alert('select variant to continue')
            }

        } else {
            for (let index = 0; index < myokData.length; index++) {
                const element = myokData[index];
                if (element.product_id === newTempItem.product_id) {
                    alert('item already exist')
                    dataAdd = false
                    break
                } else {
                    dataAdd = true
                }
            }
        }
        // console.log(newTempItem, dataAdd)
        if (dataAdd) {
            myokData.push(newTempItem)
            setTableData(myokData)
            setNewTempItem()
            setNewVarianceData()
            setSarchText('')
            //  setOrderData({...orderData, discountType:'fixed', discountPercent:0})
        }
    }
    const [forDelete, setForDelete] = useState([])

    function onRemoveClick(e, index) {
        let myokData = tableData.slice()
        let deleteData = myokData.splice(index, 1)
        if (deleteData[0].id) {
            let okDelete = forDelete.slice()
            okDelete.push(deleteData[0])
            setForDelete(okDelete)
            setTableData(myokData)
        } else {
            setTableData(myokData)
        }
        setOrderData({ ...orderData, discountType: 'fixed', discountPercent: 0 })
    }
    const myNavigate = useNavigate()

    async function onSubmitClick() {
        if (tableData.length > 0) {
            tableData.map(async (item) => {
                if (item.id) {
                    let mitemID = item.id
                    let mQuery = `sales_id=${myParam.orderid} AND product_id=${item.product_id}`
                    let dataExisted = await GetFilterSalesOfferItem(mQuery)
                    if (dataExisted.length > 0) {
                        await UpdateSalesOfferItemsData(dataExisted[0].id, { quantity: item.quantity })
                    }
                    await UpdateSalesItemsData(mitemID, { quantity: item.quantity })
                } else {
                    delete item.name
                    delete item.Variance_name
                    if (item.promo) {
                        let promoItem = {

                        }
                        allProduct.map((allItem) => {
                            if (allItem.id === item.product_id) {
                                promoItem = {
                                    sales_id: myParam.orderid,
                                    userID: item.userID,
                                    product_id: allItem.id,
                                    promoPrice: allItem.Promo_price,
                                    original_price: allItem.Selling_price,
                                    Start_date: allItem.Start_date,
                                    End_date: allItem.End_date,
                                    canceled: 0,
                                    quantity: item.quantity,
                                    created_date: new Date().getTime(),
                                    mrp_price: item.mrp_price,
                                    tp_price: item.tp_price,
                                }
                            }
                        })
                        await SaveSalesOfferItemsData(promoItem)
                    }
                    await SaveSalesItemsData({ ...item, created_date: new Date().getTime() })
                }
            })
            let newData = {
                grandTotal: totalCount(),
                delivery_date: orderData.delivery_date,
                deliveryCharge: orderData.deliveryCharge,
                discountPercent: orderData.discountPercent
            }
            await UpdateOrderData(myParam.orderid, newData )

            forDelete?.map(async (deletItem) => {
                let mQuery = `sales_id=${myParam.orderid} AND product_id=${deletItem.product_id}`
                let dataExisted = await GetFilterSalesOfferItem(mQuery)
                if (dataExisted.length > 0) {
                    await DeleteSalesOfferItem(dataExisted[0].id)
                }
                 await DeleteSalesItemsData(deletItem.id)
            })

            let nestedData = {
                ProssesedBy: userInfo.id,
                sale_id: myParam.orderid,
                created_date: new Date().getTime(),
                activity: 'Sales Item Edited',
            }
            await SaveSummeryData(nestedData)
            myNavigate('/admin/sales/sale_history/')
        } else {
            console.log('no data')
        }

    }

    function originalTotal() {
        let total = 0
        tableData?.map((item) => {
            total += item.quantity * item.orPrice
        })
        return total
    }
    function productDiscount() {
        let total = 0
        tableData?.map((item) => {
            total +=(item.orPrice- item.price)*item.quantity 
        })
        return total
    }

    function totalCount() {
        let total = 0
        tableData?.map((item) => {
            total += item.quantity * item.price
        })

        return total
    }

    const [mySpinner, setSpinner] = useState(false)

    async function getDataFromDataBase() {
        setSpinner(true)
        let allVariance = []
        let myData = await GetSingleOrder(myParam.orderid)
        let myCustomer = await GetSingleCustomer(myData[0]?.userID)
        let userAddress = await GetSingleCustomerOrderAddress(myData[0]?.user_address_id)
        let finalData = {
            ...myData[0],
            itemid: myData[0].id,
            order_created: myData[0].created_date,
            ...userAddress[0],
            AddressPhone:userAddress[0]?.Phone,
            ...myCustomer[0],
            deliveryCharge: myData[0].deliveryCharge
        }
        setOrderData(finalData)
        let myNestedData = await GetDetailsOrderItems({id:myParam.orderid})
        setTableData(myNestedData)
        setSpinner(false) 
    }
    useEffect(() => {
        getDataFromDataBase()
    }, [])

    return (
        <>
            {mySpinner ? <Spinner /> : ''}
            
            <div className={SalesEditCss.border}>
                <section className={SalesEditCss.salesMain}>
                    <div className={SalesEditCss.innerSale}>
                    {popAddress ?
                            <div className={SalesEditCss.popupLocation_wrapper}>
                            <div className={SalesEditCss.popupLocation}>
                                <PopUpLocation setPopAddress={setPopAddress} orderData={orderData} />
                            </div></div>
                            : ""}
                        <div className={SalesEditCss.gobackSec}>
                            <h2 className={SalesEditCss.saleHeading}>Order Edit</h2>
                            <NavLink to={'/admin/sales/sale_history'}><button>Go Back</button></NavLink>
                        </div>
                        <div className={SalesEditCss.orderdetaislInner}>
                            <h3>Invoice #{orderData?.itemid}</h3>
                            <div className={SalesEditCss.customerSection}>
                                <div className={SalesEditCss.customerDetails}>
                                    <p className={SalesEditCss.highlighter}>Customer Details</p>
                                    <p><strong>Customer Name</strong> : {orderData?.name}</p>
                                    <p><strong>Phone</strong> : {orderData?.AddressPhone}</p>
                                    <p><strong>Division</strong> :{orderData?.Divition}</p>
                                    <p><strong>District</strong> : {orderData?.District}</p>
                                    <p><strong>area</strong> : {orderData?.Area}</p>
                                    <div className={SalesEditCss.addressSec}>
                                        <p><strong>Address</strong> : {orderData?.Address}</p>
                                        <FontAwesomeIcon onClick={EditAddressClick} icon={faEdit} />
                                    </div>
                                </div>
                                <div className={SalesEditCss.orderDetails}>
                                    <p className={SalesEditCss.highlighter}>Order Details</p>
                                    <p>Order Date : { } </p>
                                    <p>Payment Type : {orderData?.paymentType}</p>
                                </div>
                            </div>

                            <div className={SalesEditCss.input_section}>
                                    <div className={SalesEditCss.input_wrapper}>
                                        <label>Delivery Date</label>
                                        <input type={'date'} value={orderData?.delivery_date ? orderData?.delivery_date : ''} onChange={onDeliveryDateChange} />
                                    </div>
                                    <div className={SalesEditCss.input_wrapper}>
                                        <label>Delivery Charge</label>
                                        <input onChange={onDeliveryChargeChange} type={'number'} value={orderData?.deliveryCharge ? orderData?.deliveryCharge : 0} />
                                    </div>
                                    <div className={SalesEditCss.input_wrapper}>
                                        <label>Discount</label>
                                        <div className={SalesEditCss.inner_input}>
                                            <select onChange={onDiscountTypeChange}>
                                                <option value='fixed'>Ammount</option>
                                                <option value='percent'>Percent</option>
                                            </select>
                                            <input type={'number'} onChange={onDiscountChange} defaultValue='0' />

                                        </div>
                                    </div>
                                </div>

                            <div className={SalesEditCss.add_new_product}>
                                <div className={SalesEditCss.product_wrapper}>
                                    <div className={SalesEditCss.input_wrapper_search_wrapper}>
                                        <div className={SalesEditCss.SalesOrderSearchEdit}>
                                            <label>Item Name:</label>
                                            <input type={'text'} onChange={onSearchTextChange} value={searchtext ? searchtext : ''} />
                                            {newVarianceData ? <div className={SalesEditCss.input_wrapper}>
                                                <label>Item variance</label>
                                                <select onChange={onVarianceChange}>
                                                    <option value={''}>select variance</option>
                                                    {newVarianceData?.map((item, index) => {
                                                        return <option key={index} value={item.varianceID}>{item.Variance_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                                : ""}
                                            <div className={SalesEditCss.SalesOrderSearchEditButton}>
                                                <button onClick={onAddClick}>Add</button>
                                            </div>
                                        </div>
                                        <div className={`${SalesEditCss.search_item_section}${SalesEditCss.dropdown_list}`} style={{ margin: '20px 0 0 0' }}>
                                            {allProduct?.filter((fitem) => {
                                                let msearchText = searchtext?.toLowerCase()
                                                let matchText = fitem.en_sort_title?.toLowerCase()

                                                if (msearchText && matchText !== msearchText && matchText.includes(msearchText)) {
                                                    //console.log(fitem)
                                                    return fitem
                                                } else {
                                                    return null
                                                }
                                            }).slice(0, 10)
                                                .map((item, index) => {
                                                    return <p key={index} onClick={(e) => { onItemClick(e, item) }}>{item.en_sort_title}</p>
                                                })}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className={SalesEditCss.salesTable}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Item</th>
                                            <th>Price</th>
                                            {/* <th>Variations</th> */}
                                            <th>Quantity</th>
                                            <th>Total Ammount</th>
                                            <th>Action</th>
                                        </tr>
                                        {tableData?.map((item, index) => {
                                            return <tr key={index}>
                                                <td>{item.name}
                                                {item.Variance_name ? `-(${item.Variance_name})` : ''}
                                                {item.attr_value_name ? `-(${item.attr_value_name})` : ''}
                                                </td>
                                                <td>{item.orPrice}</td>
                                                <td>{
                                                    <div className={SalesEditCss.quantity_edit}>
                                                        <p onClick={(e) => { onDecreaseClick(e, index) }}>-</p>
                                                        <input type='number' value={item.quantity ? item.quantity : 0} onChange={(e) => { onQuantityChange(e, index) }} />
                                                        <p onClick={(e) => { onIncreaseClick(e, index) }} >+</p>
                                                    </div>
                                                }</td>
                                                <td>{item.quantity * item.orPrice}</td>
                                                <td className={SalesEditCss.cross_icon}>{<FontAwesomeIcon icon={faTimes} onClick={(e) => { onRemoveClick(e, index) }} />}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                                <div className={SalesEditCss.tableDetails}>
                                    <p>Previous Ammount : {orderData?.grandTotal} tk</p>
                                    <p>Delivery Charge : {orderData?.deliveryCharge}  tk</p>

                                    <p>Now Total Ammount : {originalTotal() + orderData?.deliveryCharge} tk</p>

                                    <p>Discount : { productDiscount() + (orderData?.discountPercent ? Math.round((originalTotal() * orderData.discountPercent) / 100) : 0)}  tk</p>
                                    <p>Grand Total : {(totalCount() + orderData?.deliveryCharge) - (orderData?.discountPercent ? (originalTotal() * orderData.discountPercent) / 100 :0)} tk</p>

                                </div>
                                <div className={SalesEditCss.button_section} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button style={{ cursor: 'pointer' }} onClick={onSubmitClick}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

            </div >
        </>
    )
}

export default SalesEdit

