import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}sales-tracking/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function GetTrackingTotal(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}count/total`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
