import JoditEditor from 'jodit-react'
import React, { useRef } from 'react'
import popularCss from './popularedit.module.css'
import { UpdatePageContent } from '../../../api/PagesApi';
import { toast } from 'react-toastify';

export default function PopularEdit({popUp, setPopUp}) {
    const my_search = useRef()
    
    const config = {
        useSplitMode: false,
        uploader: {
            insertImageAsBase64URI: true
        },
        placeholder: 'Type Your Content Here...',
        showXPathInStatusbar: false
    };

    async function onUpdateClick() {
        const content_id = popUp.content_id
        try {
            await UpdatePageContent(content_id, { popular_search: my_search.current.value })
            toast.success('Data updated')
            setPopUp()
            document.body.style.overflow = 'auto'
         } catch (error) {
            toast.error(error.message)
         }
    }

    return (
    <div className={popularCss.wrapper}>
       <div className={popularCss.holder}>
        <h2>{popUp.catName}</h2>
       <JoditEditor
              value={popUp.popular_search || ''}
              config={config}
              tabIndex={1}
              ref={my_search}
          />
          <div className={popularCss.btns}>
            <button onClick={()=>{setPopUp(); document.body.style.overflow = 'auto'}}>close</button>
            <button onClick={onUpdateClick}>Update</button>
          </div>
       </div>
    </div>
  )
}
