import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DropDownSearch from '../smallThings/DropDownSearch'
import { useDispatch, useSelector } from 'react-redux'
import { selectProductList } from '../../ReduxFiles/Slices/ProductSlice'
import { fetchAllSupplier, selectSupplierList, selectSupplierStatus } from '../../ReduxFiles/Slices/SupplierSlice'
import { fetchCategory, selectCategoryList, selectCategoryStatus } from '../../ReduxFiles/Slices/CategorySlice'
import { fetchUnits, selectMAUStatus, selectUnitList } from '../../ReduxFiles/Slices/MAU_Slice'
import { GetLastPurchaseNumber, GetLastPurchasePrice, SavePurchaseData, SavePurchaseItemData } from '../../api/PurchaseApi'
import { SaveDayBookData } from '../../api/DayBookApi'
import { SaveDebitData } from '../../api/DebitApi'
import { selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import NewPurchaseCss from './css/NewPurchase.module.css'
import {toast} from 'react-toastify'
const NewPurchase = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector(selectUserInfo)
    const mauStatus = useSelector(selectMAUStatus)
    const supplierStatus = useSelector(selectSupplierStatus)
    const categoryStatus = useSelector(selectCategoryStatus)
    const allProducts = useSelector(selectProductList)
    const mySupplier = useSelector(selectSupplierList)
    const catItem = useSelector(selectCategoryList)
    const unitData = useSelector(selectUnitList)
    const [allItem, setAllItem] = useState([])

    const [selectedProducts, setSelectedProducts] = useState()
    const [myTabelItem, setTableItem] = useState([])
    const [subcatItem, setsubCatitem] = useState([])
    const [subSubcatItem, setsubSubCatitem] = useState()
    const [myFinalData, setMyFinalData] = useState()
    const [alerMessage, setAlertMessage] = useState()
    // const [unitData, setUnitdata] = useState()


    function onCatCahange(e) {
        let value = e.target.value
        //  console.log(value)
        let subArr = []
        catItem.forEach(element => {
            if (element.selectedItem !== 0) {
                if (element.selectedItem == value) {
                    subArr.push(element)
                }
            }
        });
        setSelectedProducts()
        setsubSubCatitem()
        // console.log(subArr)
        setsubCatitem(subArr)
    }

    function onSubcatChange(e) {
        let value = e.target.value
        let subArr = []
        allItem.forEach(element => {
            if (element.selectedItem !== '') {
                if (element.selectedItem == value) {
                    subArr.push(element)
                    //console.log(element)
                }
            }
        });
        let findIndex = catItem.findIndex(obj => obj.id == value)
        let SingleItem = catItem[findIndex]
        setSelectedProducts()
        if (value !== '') {
            findSelectedItem(SingleItem.id)
        }
        if (subArr.length > 0) {
            setsubSubCatitem(subArr)
        } else {
            setsubSubCatitem()
        }
    }
    function onSubSubChange(e) {
        let value = e.target.value
        let findIndex = catItem.findIndex(obj => obj.id == value)
        let SingleItem = catItem[findIndex]
        // console.log(allItem)
        setSelectedProducts()
        if (value !== '') {
            findSelectedItem(SingleItem.id)
        }
    }

    function findSelectedItem(selectedValue) {
        //console.log(selectedValue)
        let myArr = []
        //console.log(selectedValue)
        allProducts.forEach(element => {
            if (element.selected_catagory_id == selectedValue) {
                myArr.push(element)
            }
        });
        //  console.log(myArr)
        if (myArr.length > 0) {
            setSelectedProducts(myArr)
        } else {
            setSelectedProducts()
        }
    }

    function addTotable(e, item) {
        let myArr = [];
        let dataFound = false
        myTabelItem.forEach(element => {
            //element = {...element, quantity:0, price:0}
            myArr.push(element)
            if (element.id === item.id) {
                dataFound = true
            }
        });
        if (!dataFound) {
            item = { ...item, price: item.lastPrice }
            myArr.push(item)

        } else {
            setAlertMessage('item already exist in the table')
        }
        setTableItem([])
        setTableItem(myArr)
    }

    async function loadInitialData() {

        if (mauStatus.unitStatus === 'idle') {
            dispatch(fetchUnits())
        }

        if (supplierStatus === 'idle') {
            dispatch(fetchAllSupplier())
        }

        if (categoryStatus === 'idle') {
            dispatch(fetchCategory())
        }
    }

    useEffect(() => {
        loadInitialData()
    }, [])

    function decreaseClick(e, index) {
        let tableArr = [...myTabelItem]
        tableArr[index].quantity = tableArr[index].quantity ? tableArr[index].quantity - 1 : 0
        setTableItem([...tableArr])
    }

    function increaseClick(e, index) {
        let tableArr = [...myTabelItem]
        tableArr[index].quantity = tableArr[index].quantity ? tableArr[index].quantity + 1 : 1
        setTableItem([...tableArr])
    }
    function onQuantityChange(e, index) {
        let name = e.target.name
        let value = e.target.value
        let tableArr = [...myTabelItem]
        tableArr[index][name] = parseInt(value)
        setTableItem([...tableArr])
    }
    function onPriceChange(e, index) {
        let name = e.target.name
        let value = e.target.value
        let tableArr = [...myTabelItem]
        tableArr[index][name] = value
        setTableItem([...tableArr])
    }
    function onRemove(e, index) {
        let tableArr = [...myTabelItem]
        tableArr.splice(index, 1)
        setTableItem([...tableArr])
    }
    const [discountState, setDiscountState] = useState()
    function discountClick(e) {
        let name = e.target.getAttribute('name');
        let myValue = false
        if (!discountState?.[name]) {
            myValue = true
        }
        setMyFinalData({ ...myFinalData, fixed: 0, percent: 0 })
        setDiscountState({ ...discountState, discountfixed: false, discountpercent: false, [name]: myValue })
    }
    function discountChange(e) {
        let name = e.target.name
        let value = e.target.value
        setMyFinalData({ ...myFinalData, [name]: value })
    }
    function paymentMethodClick(e) {
        // let name = e.target.name
        let value = e.target.value
        setMyFinalData({ ...myFinalData, paymentMethod: value })
    }
    function payAmmountChange(e) {
        let name = e.target.name
        let value = e.target.value
        setMyFinalData({ ...myFinalData, [name]: value })
    }


    const marginTop = {
        marginTop: '10px'
    }

    const tableItem = myTabelItem?.map((item, index) => {
        return <MyTable key={index} sl={index + 1} itemName={item.en_sort_title}
            quantity={
                <div className={NewPurchaseCss.table_quantity}>
                    <p onClick={(e) => { decreaseClick(e, index) }}>-</p>
                    <input name='quantity' type='text' value={item.quantity ? item.quantity : 0} onChange={(e) => { onQuantityChange(e, index) }} />
                    <p onClick={(e) => { increaseClick(e, index) }}>+</p>
             
                </div>
            }
            unit={
                unitData?.filter((fitem) => {
                    return fitem.id === item.selectedUnit ? fitem : ''
                })
                    .map((item) => {
                        return item.unit
                    })
            }
            price={<>
                <input name='price' className={NewPurchaseCss.price} type='text' value={item.price ? item.price : 0} onChange={(e) => { onPriceChange(e, index) }} />
            </>}
            total={getTotal(item.quantity, item.price)}
            action={<button className={NewPurchaseCss.remove_item} onClick={(e) => { onRemove(e, index) }}>x</button>} />
    })

    function getTotal(quantity, price) {
        if (!quantity) {
            quantity = 0
        }
        if (!price) {
            price = 0
        }
        return quantity * price
    }

    function getAllTotal(tableArr) {
        let myTotal = 0

        tableArr?.forEach(element => {
            let quantity = element.quantity ? element.quantity : 0
            let price = element.price ? element.price : 0
            myTotal += quantity * price
        });
        return myTotal
    }
    function getDicountAmmount() {
        let discount = 0
        let total = getAllTotal(myTabelItem)
        let fixed = 0
        let percent = 0
        if (myFinalData?.fixed) {
            fixed = myFinalData?.fixed
        }
        if (myFinalData?.percent) {
            percent = myFinalData?.percent
        }
        if (discountState?.discountfixed) {
            discount = parseInt(fixed)

        } else if (discountState?.discountpercent) {
            discount = (total * parseInt(percent)) / 100
        } else {
            discount = 0
        }
        return discount
    }
    function getDicountPercent() {
        let discountPercent = 0
        if (myFinalData?.fixed) {
            discountPercent = myFinalData?.fixed * 100 / myFinalData.total
        } else {
            discountPercent = myFinalData?.percent
        }
        // console.log(discountPercent)
        return discountPercent ? discountPercent : 0
    }
    function getGrandTotal() {
        let discount = getDicountAmmount()
        let total = getAllTotal(myTabelItem)
        return total - discount
    }
    function dueAmmount() {
        let total = 0
        let payAmmount = 0
        if (myFinalData?.payAmmount) {
            payAmmount = myFinalData?.payAmmount
        }

        if (myFinalData?.payAmmount) {
            total = getGrandTotal() - payAmmount
        } else {
            total = getGrandTotal()
        }
        let quantity = 0
        myTabelItem.forEach(element => {
            quantity += element.quantity ? element.quantity : 0
        })
        //console.log(quantity)
        setMyFinalData({ ...myFinalData, discountAmmount: getDicountAmmount(), grandTotal: getGrandTotal(), dueAmmount: total, totalQuantity: quantity, percent: getDicountPercent() })
    }

    useEffect(() => {
        dueAmmount()
    }, [myTabelItem])

    useEffect(() => {
        dueAmmount()
    }, [myFinalData?.percent, myFinalData?.fixed, myFinalData?.payAmmount])


    function searchClick(params) {
        getDateTime()
    }
    async function getDateTime() {
        let mydate = new Date();
        let day = mydate.getDate()
        let month = mydate.getMonth() + 1
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        let year = mydate.getFullYear()
        let hour = mydate.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = mydate.getMinutes()
        if (minute < 10) {
            minute = '0' + minute
        }
        // console.log(minute)
        let seconds = mydate.getSeconds()
        if (seconds < 10) {
            seconds = '0' + seconds
        }
        let finalTime = 'Date:' + year + '-' + month + '-' + day + ' ' + 'Time:' + hour + ':' + minute + ':' + seconds
        let NumberDoc = await GetLastPurchaseNumber()
        setMyFinalData({ ...myFinalData, poDate: finalTime, PoNo: (NumberDoc.lastNumber + 1), processorID: userInfo.id })
    }
    useEffect(() => {
        getDateTime()
    }, [])

    function dateChange(e) {
        let value = e.target.value
        setMyFinalData({ ...myFinalData, poDate: value })
    }
    function onSupplierChange(e) {
        let value = e.target.value

        if (value) {
            mySupplier.forEach(element => {
                if (value === element.companyaName) {
                    setMyFinalData({ ...myFinalData, supplierID: element.id })
                }
            });
        }
    }
    function getPercent() {
        // console.log(myFinalData)
        let myPercent = 0
        if (myFinalData.fixed) {
            let myTotal = getAllTotal(myTabelItem)
            myPercent = (myFinalData.fixed * 100) / myTotal
        } else {
            myPercent = myFinalData?.percent
        }

        return myPercent
    }
    async function onCompleteClick() {
        //  getPercent()
        //  console.log(getPercent())
        let tableDataok = false;
        if (myTabelItem.length < 1) {
            setAlertMessage('table item needed')
            toast.error('table item needed')
            // console.log()
        } else {
            for (let index = 0; index < myTabelItem.length; index++) {
                const element = myTabelItem[index];
                if (element.quantity > 0 && element.price > 0) {
                    tableDataok = true
                } else {
                    tableDataok = false
                    setAlertMessage('Price & Quantity Required in Item No : #' + (index + 1))
                    break;
                }
            }

            if (tableDataok) {
                if (!myFinalData.supplierID) {
                    setAlertMessage('Please Select Supplier to Continue')
                } else {
                    if (!myFinalData.paymentMethod) {
                        setAlertMessage('Please Select Payment Methotd to Continue')
                    } else {
                        // let finalAmount = myFinalData.payAmmount ? parseInt(myFinalData.payAmmount):0
                        let poData = {
                            ...myFinalData,
                            // payAmmount:myFinalData.payAmmount ? parseInt(myFinalData.payAmmount):0,
                            percent: getPercent(),
                            grandTotal: myFinalData?.grandTotal + myFinalData?.discountAmmount,
                            created_date: new Date().getTime(),
                            pending: 1,
                            status: 'PO Placed',
                            varianceManaged: 0,
                            receivedPO: 0,
                        }
                        delete poData.discountAmmount
                        delete poData.dueAmmount
                        delete poData.fixed
                        delete poData.PoNo
                        delete poData.totalQuantity
                        delete poData.payAmmount
                        let mDock = await  SavePurchaseData(poData)
                        let docID = mDock.insertId
                           let myPromises = myTabelItem.map(async function (element) {
                            let datatoSaved = {
                                purchase_id: docID,
                                product_id: element.id,
                                hasVriance: element.hasVariance,
                                quantity: element.quantity,
                                price: element.price,
                                created_date: new Date().getTime(),
                                varianceManaged: 0,
                            }
                          await SavePurchaseItemData(datatoSaved)
                        });

                        let dayBookData = {
                            created_date: new Date().getTime(),
                            paticulars: "Purchase (PO Placed)",
                            identity: docID,
                            received: 0,
                            payment: 0,
                            processorID: userInfo.id,
                        }
                        await SaveDayBookData(dayBookData)

                        let debitData = {
                            identity: docID,
                            paymentAmmount: myFinalData.payAmmount ? parseInt(myFinalData.payAmmount) : 0,
                            particulars: 'Purchase Payment',
                            processorID: userInfo.id,
                            remark: '',
                            paymentType: myFinalData.paymentMethod,
                            created_date: new Date().getTime()
                        }
                       await SaveDebitData(debitData)

                        Promise.all(myPromises).then(() => {
                           // setAlertMessage('Data Saved')
                            setAlertMessage('Data Saved')
                        })
                    }
                }
            }
        }
    }
    async function onSearchItemClick(item) {
        let myArr = [];
        let dataFound = false
        myTabelItem.forEach(element => {
            myArr.push(element)
            if (element.id === item.id) {
                dataFound = true
            }
        });
        if (!dataFound) {
            item = { ...item, price: item.lastPrice }
            let latPriceItem = await GetLastPurchasePrice({id:item.id})
            myArr.push({ ...item, price: latPriceItem[0]?.price || 0 })

        } else {
            setAlertMessage('item already exist in the table')
        }
        // console.log(myArr)
        setTableItem([])
        setTableItem(myArr)
    }
    return (
        <>
            {alerMessage ? <AlertMessage message={alerMessage} setAlertMessage={setAlertMessage} /> : ""}
            <section style={marginTop}>
                <div>
                    <DropDownSearch mainArray={allProducts} searchField={'en_sort_title'} showDataField={'stockAmmount'} onMyClick={onSearchItemClick} />
                </div>
                <div className={NewPurchaseCss.purchase_wrapper}>
                    <div className={NewPurchaseCss.purchaseinput}>
                        <div className={NewPurchaseCss.purchase_option}>
                            <label>Select Catagory*</label>
                            <select className={NewPurchaseCss.popOption} name={'mainCat'} onChange={onCatCahange} >
                                <option value={""}>--Select Main Item--</option>
                                {catItem.filter((fitem)=>{
                                 return !fitem.selectedItem ? fitem:''
                                })
                                .map((item, index) => {
                                    return <option key={index} value={item.id} >{item.catName}</option>
                                })}
                            </select>

                        </div>
                        <div className={NewPurchaseCss.purchase_option}>
                            <label>Select Catagory*</label>
                            <PopUpOption popName={"subCat"} myArray={subcatItem} myValue={""} onDataChange={onSubcatChange} />
                        </div>
                        {subSubcatItem ?
                            <div className={NewPurchaseCss.purchase_option}>
                                <label>Select Catagory</label>
                                <PopUpOption popName={"subSubCat"} myArray={subSubcatItem} myValue={""} onDataChange={onSubSubChange} />
                            </div> : ""
                        }
                    </div>
                    <div className={NewPurchaseCss.product_show}>
                        <ol>
                            {selectedProducts ? <>{selectedProducts?.map((item, index) => {
        return <li onClick={(e) => { addTotable(e, item) }} key={index}>{index + 1}. {item.en_sort_title}</li>
    })}</> : <h2>Please Select Category to see products</h2>}
                        </ol>
                    </div>
                </div>
                <div className={NewPurchaseCss.purchase_wrapper}>
                    <div className={NewPurchaseCss.table_section}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Sl</th>
                                    <th>Item Name</th>
                                    <th>Quantity</th>
                                    <th>Unit</th>
                                    <th>Price</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                                {tableItem}
                            </tbody>
                        </table>
                    </div>
                    <div className={NewPurchaseCss.info_section}>
                        <div className={NewPurchaseCss.po_section}>
                            <p>PO No : </p>
                            <p># {myFinalData?.PoNo}</p>
                        </div>
                        <div className={NewPurchaseCss.po_section}>
                            <p>PO Date : </p>
                            <input className={NewPurchaseCss.datepicker} name='poDate' defaultValue={myFinalData?.poDate} onChange={dateChange} />
                        </div>
                        <hr />
                        <div className={NewPurchaseCss.supplier_select}>
                            <label>Select Supplier</label>
                            <select name='supplierName' onChange={onSupplierChange}>
                                <option value=''>--select one--</option>
                                {mySupplier?.map((item) => {
                                    return <option key={item.id} value={item.companyaName}>{item.companyaName}</option>
                                })}
                            </select>
                        </div>
                        <div className={NewPurchaseCss.supplier_discount}>
                            <p onClick={discountClick} name='discountfixed'> {discountState?.discountfixed ? <FontAwesomeIcon className={NewPurchaseCss.selected} icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCircle} />} Discount (Fixed)</p>
                            <p onClick={discountClick} name='discountpercent'> {discountState?.discountpercent ? <FontAwesomeIcon className={NewPurchaseCss.selected} icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCircle} />} Discount (%)</p>
                        </div>

                        {discountState?.discountfixed ? <div className={NewPurchaseCss.discount_ammount}>
                            <input name='fixed' type='text' onChange={discountChange} placeholder='discount ammount' />
                        </div> : ""}

                        {discountState?.discountpercent ? <div className={NewPurchaseCss.discount_ammount}>
                            <input name='percent' type='text' onChange={discountChange} placeholder='discount %' />
                        </div> : ""}

                        <div className={NewPurchaseCss.purchase_total} >
                            <div className={`${NewPurchaseCss.total} ${NewPurchaseCss.common}`}>
                                <p className={NewPurchaseCss.constant}>Sub Total : </p>
                                <p> {myFinalData?.grandTotal + myFinalData?.discountAmmount} <span>Taka</span></p>
                            </div>
                            <div className={`${NewPurchaseCss.discount} ${NewPurchaseCss.common}`}>
                                <p className={NewPurchaseCss.constant}>Discount (-)</p>
                                <p>  {myFinalData?.discountAmmount} <span>Taka</span></p>
                            </div>
                            <div className={`${NewPurchaseCss.grand_total} ${NewPurchaseCss.common}`}>
                                <p className={NewPurchaseCss.constant}>Grand Total </p>
                                <p>{myFinalData?.grandTotal} <span>Taka</span></p>
                            </div>
                        </div>
                        <div className={NewPurchaseCss.supplier_payment_method}>
                            <div className={NewPurchaseCss.cash}>
                                <input type="radio" id="cash" name="paymentType" value="cash" onClick={paymentMethodClick} />
                                <label htmlFor="cash">Cash</label>
                            </div>
                            <div className={NewPurchaseCss.check}>
                                <input type="radio" id="check" name="paymentType" value="check" onClick={paymentMethodClick} />
                                <label htmlFor="check">Check</label>
                            </div>
                        </div>
                        <div className={`${NewPurchaseCss.paid_ammount} ${NewPurchaseCss.total_due}`}>
                            <div className={NewPurchaseCss.total}>
                                <p>Net Payable Amount : <span>{myFinalData?.grandTotal}</span></p>
                            </div>
                        </div>
                        <div className={NewPurchaseCss.paid_ammount}>
                            <label>Paid Ammount : </label>
                            <input name='payAmmount' type='text' defaultValue={0} onChange={payAmmountChange} />
                        </div>
                        <div className={ `${NewPurchaseCss.paid_ammount} ${NewPurchaseCss.total_due}`}>
                            <div className={NewPurchaseCss.due}>
                                <p>Due Amount : <span>{myFinalData?.dueAmmount}</span></p>
                            </div>
                        </div>
                        <div className={NewPurchaseCss.paid_ammount}>
                            <label>Upload File : </label>
                            <input type='file' />
                        </div>
                        <div className={`${NewPurchaseCss.paid_ammount} ${NewPurchaseCss.button_section}`}>
                            <button onClick={onCompleteClick}>Complete</button>
                            <button>Save as Draft</button>
                        </div>

                    </div>
                </div>

            </section>
        </>

    )
}

export default NewPurchase
const MyTable = ({ sl, itemName, quantity, unit, price, total, action }) => {

    return <>
        <tr>
            <td>{sl}</td>
            <td>{itemName}</td>
            <td>{quantity}</td>
            <td>{unit}</td>
            <td>{price}</td>
            <td>{total}</td>
            <td>{action}</td>
        </tr>
    </>
}

const PopUpOption = ({ myArray, onDataChange, popName, myValue }) => {

    let OptionData = <>
        {myArray ? myArray.map((item, index) => {
            return <option key={index} onChange={onDataChange} value={item.id} >{item.catName}</option>
        }) : ""}
    </>

    return (
        <>
            <select className={NewPurchaseCss.popOption} name={popName} onChange={onDataChange} >
                <option value={""}>--Select Item--</option>
                {OptionData}
            </select>

        </>
    )
}

const AlertMessage = ({ message, setAlertMessage }) => {
    let myNavigate = useNavigate()
    function alertClick(e) {
        // console.log(e)
        if (message === 'Data Saved') {
            myNavigate('/admin/purchase/pendinglist')
            setAlertMessage()
        } else {
            setAlertMessage()
        }


    }
    const zindex = {
        zIndex: 20
    }
    return (
        <div className={NewPurchaseCss.AlertMessage}>
            <div className={NewPurchaseCss.holder}>
            <h2 >{message}</h2>
            <button onClick={alertClick}>OK</button>
            </div>
        </div>
    )
}