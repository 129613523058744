import MyAxios from 'axios'
import CryptoJS from 'crypto-js'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}sales/`

const encryptionKey = 'Himon824143'

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function InformationMessage(data) {
    let newDate = await GetServerTime()
  data = {
    ...data,
    passedTime:newDate[0].nowtime+10000,
  }
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
    let response = await MyAxios.post(`${API_LINK}sms/send-message`, {data:encryptedData}, configdata)
    return response.data
}

async function GetServerTime(){
    let response = await MyAxios.get(`${API_LINK}time-stamp/1`)
   return response.data
}

export async function GetTotalOrderNumber(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}total-order`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetTodaySales(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}today`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetPendingSalesData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}pending`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetHistorySalesData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}history`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetCompleteSalesData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}complete`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetDataToReturn(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}to-return`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetDeclineSalesData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}decline`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetReturnSalesData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}return`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetSingleOrder(id) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateOrderData(id, data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}




//sales items api link from here

const MY_API_LINK_SALES_ITEMS = `${API_LINK}sales-item/`

export async function GetSingleOrderItems(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK_SALES_ITEMS}get-items`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetDetailsOrderItems(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK_SALES_ITEMS}get-detail-order-item`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetDetailsReturnOrderItems(data) {
    try {
        let result = await MyAxios.post(`${API_LINK}sales-return-items/get-detail-return-order-item`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function SaveSalesReturnData(data) {
    try {
        let result = await MyAxios.post(`${API_LINK}sales-return-items/add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}