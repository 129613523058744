import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetAllProducts } from '../../api/ProductListApi'

const initialState = {
  product_list:[],
  status:'idle',
}
export const ProductSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductList: (state, action) => {
      state.product_list = action.payload
    },
    setProductStatus: (state, action) => {
        state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchProducts.pending, (state, action) => {
      state.status = 'pending'
    })
    .addCase(fetchProducts.fulfilled, (state, action) => {
      state.status = 'success'
      let mData = action.payload
        state.product_list = mData
        window.localStorage.setItem('allProduct', JSON.stringify(mData))
    })
    .addCase(fetchProducts.rejected, (state, action) => {
      state.status = action.payload
    })
},
  
})

export const fetchProducts = createAsyncThunk('products/fetchProducts', async ()=>{
    try {
      const resposne = await GetAllProducts()
      return resposne
    } catch (error) {
      return error.message
    }
})


export const selectProductList = state =>state.products.product_list
export const selectProductStatus = state =>state.products.status
export const { setProductList, setProductStatus} = ProductSlice.actions

export default ProductSlice.reducer
