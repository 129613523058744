import { faArrowsRotate, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchAllUser, selectinActiveUserList } from '../../ReduxFiles/Slices/UserSlice'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateBackUserData } from '../../api/BackendUserApi'
import TrashListUserCss from './css/TrashListUser.module.css'


const TrashListUser = () => {
  const dispatch = useDispatch()
  const userData = useSelector(selectinActiveUserList)
  let myNavigate = useNavigate()

  async function onDeleteClick(e, item) {
    if (
      window.confirm("Do You Want To Delete this user Permanently")) {
      alert('Please Wait, We will See Your Action & Then We will React :)')
    } else {
    }
  }
  async function onRestoreClick(e, item) {
    if (window.confirm("Do You Want To Restore this user")) {
      await UpdateBackUserData(item.id, { active: 1 })
      dispatch(fetchAllUser())
      myNavigate('/admin/manage_user/userlist')
    } else {
    }
  }
  async function getAllUser() {

  }
  function userRuleText(number) {
    let text = ""
    if (number === 1) {
      text = "Admin"
    }
    else if (number === 2) {
      text = "Editor"
    }
    else if (number === 3) {
      text = "Moderator"
    }

    return text

  }

  useEffect(() => {
    getAllUser()
  }, [])
  const TableItem = userData?.map((item, index) => {
    return <MyTable key={index} sl={index + 1} name={item.fullName} userName={item.userName} rule={userRuleText(item.userrole)} action={<div className={TrashListUserCss.userIcon}><FontAwesomeIcon icon={faArrowsRotate} onClick={(e) => { onRestoreClick(e, item) }} /> <FontAwesomeIcon icon={faTrashCan} onClick={(e) => { onDeleteClick(e, item) }} /></div>} />
  })

  return (
    <>
      <section className={`${TrashListUserCss.salesMain} ${TrashListUserCss.manageuserMain}`}>
        <div className={TrashListUserCss.innerSale}>
          <h2 className={TrashListUserCss.saleHeading}>User Trash List</h2>
          <div className={TrashListUserCss.tableSection}>
            <table>
             <tbody>
             <tr>
                <th>SL</th>
                <th>Name</th>
                <th>User Name</th>
                <th>Rule</th>
                <th>Action</th>
              </tr>
              {TableItem}
             </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  )
}

export default TrashListUser

const MyTable = ({ sl, name, userName, rule, action }) => {

  return <tr key={sl+'sl'}>
      <td>{sl}</td>
      <td>{name}</td>
      <td>{userName}</td>
      <td>{rule}</td>
      <td >{action}</td>
    </tr>
 
}