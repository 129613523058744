import React, { useEffect } from 'react'
import { useState } from 'react'
import SettingInput from './SettingInput'
import SettingTextArea from './SettingTextArea'
import { GetSiteInformation, SaveSiteInformation, UpdateSiteInformation } from '../../api/SettingApi'
import WebsiteSettingCss from './css/WebsiteSetting.module.css'

const WebsiteSetting = () => {
    const [siteInfo, setSiteInfo] = useState()
    const [siteContact, setSiteContact] = useState()

    function onSiteInfoChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSiteInfo({ ...siteInfo, [name]: value })
    }

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSiteContact({ ...siteContact, [name]: value })
    }
    function onSiteInfoSave() {
        console.log(siteInfo)
    }
    async function onSitecontactSave() {
        if (siteContact.id) {
            await UpdateSiteInformation(siteContact.id, siteContact)
        } else {
            await SaveSiteInformation(siteContact)
        }
    }
    async function getContactData(params) {
        let contactData = await GetSiteInformation()
        setSiteContact(contactData[0])
    }
    useEffect(() => {
        getContactData()
    }, [])
    return (
        <>
            <section className={WebsiteSettingCss.site_information}>
                <h2>Site Settings</h2>
                <h6>Site Information</h6>
                <div className={WebsiteSettingCss.row}>
                    {/* name, labeltext, Itype, placeholder, mclass, changeme, myref, myValue, setedValue, enableDisable, warning */}
                    <div>
                        <SettingInput name="site_title" labeltext="Site Title" Itype="text" placeholder="Site Title Goes Here" changeme={onSiteInfoChange} />
                        <div className={WebsiteSettingCss.mt_2}>
                            <SettingInput name="site_logo" labeltext="Upload Site Logo" Itype="file" changeme={onSiteInfoChange} />
                        </div>
                        <SettingInput name="free_shipping" labeltext="Free Shipping Text" Itype="text" placeholder="Free Shipping Text Goes Here" changeme={onSiteInfoChange} />


                    </div>
                    <div>
                        <SettingTextArea name="site_meta_tag" labeltext="Site Meta Tag" placeholder="Site Meta Tag Text Goes Here" changeme={onSiteInfoChange} />
                        <SettingTextArea name="site_meta_description" labeltext="Site Meta Description" placeholder="Site Meta Description Text Goes Here" changeme={onSiteInfoChange} />
                    </div>
                    <button className={WebsiteSettingCss.bt_save} type="submit" onClick={onSiteInfoSave}>Save</button>
                </div>
            </section>

            <section className={WebsiteSettingCss.contact_section}>

                <h6>Contact</h6>
                <div className={WebsiteSettingCss.row}>

                    <div>
                        <SettingTextArea name="address" labeltext="Address" placeholder="Address Text Goes Here" changeme={onDataChange} myValue={siteContact?.address ? siteContact?.address : ''} />
                        <SettingInput name="primary_phone" labeltext="Primary Phone" Itype="text" placeholder="eg. +88 012 23 125469" changeme={onDataChange} myValue={siteContact?.primary_phone ? siteContact?.primary_phone : ''} />
                        <SettingInput name="primary_phoneb" labeltext="Primary Phone" Itype="text" placeholder="eg. +88 012 23 125469" changeme={onDataChange} myValue={siteContact?.primary_phoneb ? siteContact?.primary_phoneb : ''} />


                    </div>

                    <div>
                        <form action="">
                            <SettingInput name="email" labeltext="Enail Address" Itype="text" placeholder="example@domainname.com" changeme={onDataChange} myValue={siteContact?.email ? siteContact?.email : ''} />
                            <SettingInput name="alternative_phoneb" labeltext="Alternative Phone" Itype="text" placeholder="eg. +88 012 23 125469" changeme={onDataChange} myValue={siteContact?.alternative_phoneb ? siteContact?.alternative_phoneb : ''} />
                            <SettingInput name="alternative_phonec" labeltext="Alternative Phone" Itype="text" placeholder="eg. +88 012 23 125469" changeme={onDataChange} myValue={siteContact?.alternative_phonec ? siteContact?.alternative_phonec : ''} />
                            <SettingInput name="fax_no" labeltext="Fax No." Itype="text" placeholder="eg. +88 012 23 125469" changeme={onDataChange} myValue={siteContact?.fax_no ? siteContact?.fax_no : ''} />
                        </form>
                    </div>


                </div>
            </section>

            <section className={WebsiteSettingCss.social_link_section}>

                <h6>Social Links</h6>
                <div className={WebsiteSettingCss.row}>

                    <div>

                        <SettingInput name="facebook" labeltext="Facebook" Itype="text" placeholder="https://" changeme={onDataChange} myValue={siteContact?.facebook ? siteContact?.facebook : ''} />
                        <SettingInput name="youtube" labeltext="Youtube" Itype="text" placeholder="https://" changeme={onDataChange} myValue={siteContact?.youtube ? siteContact?.youtube : ''} />
                        <SettingInput name="instagram" labeltext="Instagram" Itype="text" placeholder="example@domainname.com" changeme={onDataChange} myValue={siteContact?.instagram ? siteContact?.instagram : ''} />
                        <SettingInput name="office_timming" labeltext="Office Timing" Itype="text" placeholder="Office Time text" changeme={onDataChange} myValue={siteContact?.office_timming ? siteContact?.office_timming : ''} />
                    </div>
                    <div>
                        <SettingInput name="twitter" labeltext="Twitter" Itype="text" placeholder="https://" changeme={onDataChange} myValue={siteContact?.twitter ? siteContact?.twitter : ''} />

                        <SettingInput name="linkedin" labeltext="LinkedIn" Itype="text" placeholder="example@domainname.com" changeme={onDataChange} myValue={siteContact?.linkedin ? siteContact?.linkedin : ''} />
                        <SettingInput name="pinterest" labeltext="Pinterest" Itype="text" placeholder="https://" changeme={onDataChange} myValue={siteContact?.pinterest ? siteContact?.pinterest : ''} />
                    </div>
                </div>
            </section>
            <div className={WebsiteSettingCss.action_btn}>
                <button className={WebsiteSettingCss.bt_save} type="submit" onClick={onSitecontactSave}>Update</button>
                <button className={WebsiteSettingCss.bt_cancel}>Cancel</button>
            </div>
        </>
    )
}

export default WebsiteSetting