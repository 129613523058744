import { faArrowLeft, faArrowRight, faArrowRightFromBracket, faArrowUp, faBook, faBox, faCircleUser, faHourglass, faInfoCircle, faTruck, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetTodayTransaction } from '../api/BankTransactionApi'
import { GetTodayCredit } from '../api/CreditApi'
import { GetTotalCustomer } from '../api/CustomerApi'
import { GetTodayPurchase } from '../api/PurchaseApi'
import { GetPendingSalesData, GetTodaySales } from '../api/SalesApi'
import { GetTrackingTotal } from '../api/SalesTrackingApi'
import { GetProductStock } from '../api/StockApi'
import { GetTotalSupplier } from '../api/SupplierApi'
import DashContentCss from './Css/DashContent.module.css'
import MyChart from './DashboardContent/MyChart'
import LoadingShow from './smallThings/LoadingShow'

const DashContent = () => {
    const [purchaseTotal, setPurchaseTotal] = useState(0)
    const [salesTotal, setSalseTotal] = useState(0)
    const [transictionTotal, setTransictionTotal] = useState(0)
    const [supplierCount, setSupplierCount] = useState(0)
    const [customerCount, setCustomerCount] = useState(0)
    const [transictionIn, setTransictionIn] = useState(0)
    const [transictionOut, setTransictionOut] = useState(0)
    const [expenseData, setExpenseData] = useState(0)
    const [deliveryCount, setDeliveryCount] = useState(0)
    const [packageCount, setPackingCount] = useState(0)
    const [pendignSales, setPendingSales] = useState()
    const [deliveryPenidng, setDeliveryPending] = useState(0)

    const [showLoading, setShowLoading] = useState(false)
    async function getDataFromDatabase() { 
        setShowLoading(true)
        let myDate = new Date().toLocaleDateString()
        let formatedDate = {date:new Date(mGetStartDate(myDate)).getTime()}    

        let purchaseData = await GetTodayPurchase(formatedDate)
        setPurchaseTotal(getAmountTotal(purchaseData, 'grandTotal'))

        let saleData = await GetTodaySales({date:new Date().toISOString().split('T')[0]})
        let pendingSaleData = await GetPendingSalesData(formatedDate)
        setSalseTotal(saleData[0]?.total || 0)
        setPendingSales(pendingSaleData.length || 0)
       // salesDataDevide(saleData)

        let cashData = await GetTodayCredit(formatedDate)
        setTransictionTotal(parseInt(cashData[0].total || 0))

        let transictionData = await GetTodayTransaction(formatedDate)
        setTransictionIn(transictionData[0]?.received || 0)
        setTransictionOut(transictionData[0]?.payment || 0)

        let supplierData = await GetTotalSupplier()
        setSupplierCount(supplierData?.total || 0)

        let customerData = await GetTotalCustomer()
        setCustomerCount(customerData?.total || 0)
        let trakingData = await GetTrackingTotal(formatedDate)
        trakingData.forEach((element)=>{
            switch (element.note) {
                    case 'Your Order Packaging is Complete.':
                        setPackingCount(element?.count || 0)
                    break;
                    case 'Your Delivery is Completed.':
                        setDeliveryCount(element?.count || 0)
                    break;
                default:
                    break;
            }
        })
        setShowLoading(false)
        
    }


    function getAmountTotal(array, field) {
        let total = 0.00
        array?.forEach(element => {
            total += element[field]
        });
        return total
    }

    function mGetStartDate(start_date) {
        var tempstartDate = new Date(start_date);
        var startDate = new Date(tempstartDate).setHours(tempstartDate.getHours() - 0);
        startDate = new Date(startDate)
        return startDate
    }

    let myNavigate = useNavigate()
  async function onGridClick(location) {
         myNavigate(location)
    }
    const [lowerProducts, setLowerProducts] = useState()
    async function getProducts() {
        const stockData = await GetProductStock()
        let finalArr = []
        stockData.forEach(element => {
            if (element.quantity <= 5) {
                finalArr.push(element)
            }
        });        
        let sortArr = finalArr.sort((a, b) => (a.quantity > b.quantity) ? -1 : 1)
        setLowerProducts(sortArr)
    }
    
    useEffect(() => {
        getDataFromDatabase()
        getProducts()
    }, [])

    return (
        <>
            <div className={DashContentCss.deshboardgrid}>
                <LoadingShow ShowLodign={showLoading}/>
                <div>
                    <div style={{ background: '#06283D' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/purchase/purchasehistory') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {purchaseTotal}</p>
                                <p className={DashContentCss.text}> Item Purchase</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ background: '#87431D' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/sales/sale_history?today=true') }}>
                    <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {salesTotal}</p>
                                <p className={DashContentCss.text}> Sale</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faArrowUp} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ background: '#351F39' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/accounts/cash') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {transictionTotal}</p>
                                <p className={DashContentCss.text}> Transaction</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faBook} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ background: '#4C0033' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/supplier') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {supplierCount}</p>
                                <p className={DashContentCss.text}>Supplier</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faCircleUser} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={DashContentCss.grid} onClick={() => { onGridClick('/admin/manage_customer') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {customerCount}</p>
                                <p className={DashContentCss.text}>Customer</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faUsers} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ background: '#219F94' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/accounts/transictionin') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {transictionIn}</p>
                                <p className={DashContentCss.text}>Transaction In</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ background: '#11698E' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/accounts/transictionout') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {transictionOut}</p>
                                <p className={DashContentCss.text}>Transaction Out</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ background: 'black' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/expenses/expense_history') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {expenseData}</p>
                                <p className={DashContentCss.text}>Expense</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ background: '#420516' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/sales/sale_history') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {deliveryPenidng}</p>
                                <p className={DashContentCss.text}>Delivery Pending</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faHourglass} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ background: '#5F7161' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/sales/sale_history') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {packageCount}</p>
                                <p className={DashContentCss.text}>Packaged Today</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faBox} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ background: '#064420' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/sales/complete_orders') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {deliveryCount}</p>
                                <p className={DashContentCss.text}>Delivery Compleate Today </p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faTruck} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ background: '#4E3D53' }} className={DashContentCss.grid} onClick={() => { onGridClick('/admin/sales/pending_sales') }}>
                        <div className={DashContentCss.inner_grid}>
                            <div className={`${DashContentCss.text_section} ${DashContentCss.common}`}>
                                <p className={DashContentCss.amount}> {pendignSales}</p>
                                <p className={DashContentCss.text}>Pending Orders</p>
                            </div>
                            <div className={`${DashContentCss.icon_section} ${DashContentCss.common}`}>
                                <FontAwesomeIcon icon={faHourglass} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <MyChart />
            </div>
            <div className={DashContentCss.dashboard_table}>
                <h3>Limited Items</h3>
                <table>
                    <tbody>
                        <tr>
                            <th>SL</th>
                            <th>Product Name</th>
                            <th>Stock Quantity</th>
                        </tr>
                        {
                        lowerProducts?.map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.en_sort_title}</td>
                                <td>{item.quantity || 0}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </>

    )
}

export default DashContent

