import { async } from '@firebase/util'
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { DeleteData, GetWhereAndOrderData, SaveData, UpdateData } from '../../connector/DatabaseCheck'
import ComplainPriorityCss from './css/ComplainPriority.module.css'

const ComplainPriority = () => {
    const [priorityData, setPriorityData] = useState()
    const [allData, setAllData] = useState()
    const [editData, setEditData] = useState()
    const [deleteData, setDeleteData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setPriorityData({ ...priorityData, [name]: value })
    }
    async function saveToDataBase() {
        let finalData = {
            ...priorityData,
            identity: 'priority'
        }
        if (finalData.priority) {
            await SaveData('ComplainSetting', finalData)
        } else {
            alert('please Enter Priority Name')
        }

        setPriorityData()
        allData.push(finalData)
    }
   async function onUpdateClick() {
    let finalData = {
        ...priorityData,
    }

    if (finalData.priority) {
        if (editData.id) {
            await UpdateData('ComplainSetting', finalData, editData.id)
            let myArr = []
            allData.forEach(element => {
                if (element.id===editData.id) {
                  element = {...element, ...priorityData}
                  myArr.push(element)
                }else{
                    myArr.push(element)
                }
            });
            setAllData(myArr)
            setEditData();
            setPriorityData()
       }else{
        alert('please Refresh this page to continue')
       }
        
    } else {
        alert('please Enter Priority Name')
    }
    }

    async function getDatafromDataBase() {
        let data = [] // await GetWhereAndOrderData('ComplainSetting', 'identity', 'priority', 'createdDate', 'asc')
        setAllData(data)
    }

    useEffect(() => {
        getDatafromDataBase()
    }, [])

    const tableIconStyle = {
        margin: '0',
        padding: '0',
        margin: '0 5px 0 5px',
        fontSize: '18px',
        cursor: 'pointer'

    }
    const TableItem = allData?.map((item, index) => {
        return (
            <MyTable key={index} sl={index + 1} name={item.priority}
                manage={<>
                    <FontAwesomeIcon style={{ ...tableIconStyle, color: 'green' }} icon={faEdit} onClick={(e) => { setEditData(item); setPriorityData({ priority: item.priority })}} />
                    <FontAwesomeIcon style={{ ...tableIconStyle, color: 'red' }} icon={faTrashCan} onClick={()=>{setDeleteData(item)}}/>
                </>}
            />

        )
    })

    return (
        <>
            {deleteData ? <ConfirmAlert item={deleteData} setDeleteData={setDeleteData} allData={allData}/>:''}
            <div className={ComplainPriorityCss.common_class}>
                <div className={ComplainPriorityCss.input_section}>
                    <div className={ComplainPriorityCss.input_wrapper}>
                        <label><strong>Complain Priority Name *</strong></label>
                        <input name='priority' type={'text'} placeholder='Complain Priority Name' onChange={onDataChange} value={priorityData?.priority ? priorityData?.priority : ''} />
                    </div>
                    {!editData ? <button onClick={saveToDataBase}>Save Priority</button> :
                        <>
                            <button onClick={onUpdateClick} style={{background:'purple', marginRight:'10px'}}>Update Priority</button>
                            <button onClick={()=>{setEditData(); setPriorityData()}} style={{background:'red'}}>Cancel</button>
                        </>}
                </div>
                <div className={ComplainPriorityCss.tableSection} >
                    <table style={{ marginTop: '15px' }}>
                        <tbody>
                            <tr>
                                <th>SL</th>
                                <th>Priority Name</th>
                                <th>Manage</th>
                            </tr>
                            {TableItem}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ComplainPriority

const MyTable = ({ sl, name, manage }) => {

    return <>
        <tr>
            <td>{sl}</td>
            <td>{name}</td>
            <td>{manage}</td>
        </tr>
    </>
}
const ConfirmAlert = ({item, setDeleteData, allData}) => {
    function yesClick() {
        if(item.id){
            DeleteData('ComplainSetting', item.id)
           allData.forEach((element, index) => {
            if (element.id===item.id) {
                allData.splice(index, 1)
            }
           });
           setDeleteData()
        }else{
            alert('please refresh the page and try again')
        }
    }
    function noClick() {
        setDeleteData()
    }

    return (
        <>
            <div className={ComplainPriorityCss.conAlert}>
                <h2 className={ComplainPriorityCss.caption}>Do you Want To Delete This?</h2>
                <div className={ComplainPriorityCss.buttonsec}>
                    <input  onClick={yesClick} className={ComplainPriorityCss.yes} type={"submit"} value={"Yes"} />
                    <input onClick={noClick} className={ComplainPriorityCss.no} type={"submit"} value={"No"} />
                </div>

            </div>
        </>
    )
}