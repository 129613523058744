import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetSingleOrder, GetSingleOrderItems, InformationMessage, UpdateOrderData } from '../../api/SalesApi'
import { GetSingleCustomer, GetSingleCustomerOrderAddress } from '../../api/CustomerApi'
import { SaveSummeryData, SaveTrackingData } from '../../api/OrderInformationApi'
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import OrderDetViewCss from './salesCss/OrderDetailsView.module.css'

const OrderDetailsView = () => {
    const userInfo = useSelector(selectUserInfo)
    const myNavigation = useNavigate()
    const myParams = useParams()
    const [tableData, setTableData] = useState()
    const [orderData, setOrderData] = useState()
    const [approvalInfo, setApprovalInfo] = useState()
    const [conShow, setConShow] = useState(false)

    function myDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setApprovalInfo({ ...approvalInfo, [name]: value })
    }
  
    async function onApproveClick() {
        if (!approvalInfo?.delivery_date) {
            alert('delivery Date needed')
        } else if (!approvalInfo?.priority) {
            alert('Pririty needed')
        } else {
            let summeryData = {
                activity: 'Customer Order Confirmed',
                ProssesedBy: userInfo.id,
                ForwardTo: "",
                deliveryDate: approvalInfo?.delivery_date,
                note: approvalInfo?.approval_note ? approvalInfo?.approval_note : '',
                sale_id:myParams.orderid,
                created_date: new Date().getTime()
            }
            let myInfo = {
                approval_note: approvalInfo?.approval_note ? approvalInfo?.approval_note : '',
                delivery_date: approvalInfo.delivery_date,
                priority: approvalInfo.priority,
                special_instruction: approvalInfo?.special_instruction ? approvalInfo?.special_instruction : '',
            }
            let orderInfo = {
                ...myInfo,
                pending: 0,
                confirmed: 1,
                status: "Order Confirmed",
                CDby:userInfo.id
            }
            await UpdateOrderData(myParams.orderid, orderInfo)
            await SaveSummeryData(summeryData)
            let trackData = {
                sales_id:myParams.orderid,
                created_date:new Date().getTime(),
                note:'Your Order is Confirmed.',
           }
           
            await  SaveTrackingData(trackData)
            
            let myoredermessage = `Dear Sir/Madam, Your order -${myParams.orderid} has been confirmed. Delivery date is ${approvalInfo.delivery_date}.%0aThank You for shopping with us.%0aAnayase%0a01810169101`
            
            try {
                let mPhone = ''
                if (orderData.Phone) {
                    mPhone = orderData.Phone
                }else{
                    mPhone = orderData.phone
                }
                await  InformationMessage({phone:`88${mPhone}`, message:myoredermessage})
            } catch (error) {
                
            }
             myNavigation('/admin/sales/sale_history')
        }
    }

    function onDeclineClick() {
        document.body.style.overflow = 'hidden'
        setConShow(true)
    }

    async function clickYes() {
        await UpdateOrderData(myParams.orderid, {declined:1, pending:0})
        let summeryData = {
          ProssesedBy: userInfo.id,
          note: approvalInfo?.approval_note ? approvalInfo?.approval_note : '',
          sale_id:myParams.orderid,
          created_date: new Date().getTime(),
          activity:'Customer Order Declined'
        }
        await SaveSummeryData(summeryData)
        let myoredermessage =  `Dear Sir, Your order -${myParams.orderid} has been canceled.%0aThank You for shopping with us.%0aAnayase`
        try {
            let mPhone = orderData.phone
            if (orderData.Phone) {
                mPhone = orderData.Phone
            }
            await  InformationMessage({phone:`88${mPhone}`, message:myoredermessage})
        } catch (error) {
            
        }
        setConShow(false)
        setConShow(false)
        myNavigation('/admin/sales/decline_orders')
    }

    function clickNo() {
        document.body.style.overflow = 'auto'
        setConShow(false)
    }

    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getAllData() {
        let myData = await GetSingleOrder(myParams.orderid)
        let myCustomer = await GetSingleCustomer(myData[0].userID)
        let userAddress = await GetSingleCustomerOrderAddress(myData[0].user_address_id)
        let getSSLdata = [] //await GetFilteredDataExpress('ssl_payment', 'identity', myParams.orderid)
        let finalData = {
            ...myData[0],
            order_created: myData[0].created_date,
            ...userAddress[0],
            ...myCustomer[0],
            ...getSSLdata[0]
        }
        setOrderData(finalData)

        let morderItems = await GetSingleOrderItems({id:myParams.orderid} )
        organizeData(morderItems)
        

    }


    function organizeData(myData) {
        let allProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        let finalArr = []
        let uniqueElements = myData.slice()
        for (let index = 0; index < myData.length; index++) {
            const element = myData[index];
            // console.log(element)
            let tempArr = []
            for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                const melement = uniqueElements[mindex];
                if (melement.product_id === element.product_id) {
                    tempArr.push(melement)
                }
            }

            if (tempArr.length > 0) {
                tempArr.forEach(element => {
                    uniqueElements.forEach((melement, mindex) => {
                        if (melement.id === element.id) {
                            uniqueElements.splice(mindex, 1)
                        }
                    });
                });

                finalArr.push(tempArr)
            }

        }
        let okArr = []
        finalArr.forEach(element => {
            for (let index = 0; index < allProducts.length; index++) {
                const pelement = allProducts[index];
                if (pelement.id === element[0].product_id) {
                    element[0].name = pelement.en_sort_title
                    okArr.push(element)
                    break
                }
            }
        });
        // console.log(finalArr)
        setTableData(okArr)

    }

    useEffect(() => {
        getAllData()
    }, [myParams.orderid])

    function getQTotal(item) {
        let total = 0
        item?.forEach(element => {
            total += element.quantity
        });

        return total;
    }
    function totalAmmount(item) {
        let quantity = getQTotal(item)
        let total = 0
        total += item[0].price
        return total * quantity;
    }

    const TableItem = tableData?.map((item, index) => {
        return <MyTable key={index} item={item[0].name} price={item[0]?.price} qty={getQTotal(item)} tammount={totalAmmount(item)} varinace={item} />
    })
    let priorityArr = ['High', 'Medium', 'Normal']
   
    return (
        <>
            {conShow ? <ConfirmAlert alertText='Do you want to decline this order?' yesClick={clickYes} noClick={clickNo} /> : ""}
            <div className={OrderDetViewCss.border}>
                <section className={OrderDetViewCss.salesMain}>
                    <div className={OrderDetViewCss.innerSale}>
                       
                        <div className={OrderDetViewCss.gobackSec}>
                            <h2 className={OrderDetViewCss.saleHeading}>Pending Order Details</h2>
                            <NavLink to={'/admin/sales/pending_sales'}><button>Go Back</button></NavLink>
                        </div>
                        <div className={OrderDetViewCss.orderdetaislInner}>
                            <h3>Invoice #{myParams?.orderid}</h3>
                            <div className={OrderDetViewCss.customerSection}>
                                <div className={OrderDetViewCss.customerDetails}>
                                    <p>Customer Details</p>
                                    <p><strong>Customer Name</strong> : {orderData?.name}</p>
                                    <p><strong>Phone</strong> : {orderData?.Phone}</p>
                                    <p><strong>Division</strong> :{orderData?.Divition}</p>
                                    <p><strong>District</strong> : {orderData?.District}</p>
                                    <p><strong>area</strong> : {orderData?.Area}</p>
                                    <div className={OrderDetViewCss.addressSec}>
                                        {orderData?.pickupPoint ? <p><strong>Pickup Point</strong> : {orderData?.pickupPoint}</p> :
                                            <p><strong>Delivery Address</strong> : {orderData?.Address}</p>}
                                    </div>
                                </div>
                                <div className={OrderDetViewCss.orderDetails}>
                                    <p>Order Details</p>
                                    <p>Order Date : {convertDate(orderData?.order_created / 1000)} </p>
                                    <p>Payment Type : {orderData?.paymentType}</p>
                                </div>
                            </div>

                            <div className={`${OrderDetViewCss.tableSection} ${OrderDetViewCss.detailstableSection}`}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Item</th>
                                            <th>Price</th>
                                            {/* <th>Variations</th> */}
                                            <th>Quantity</th>
                                            <th>Total Ammount</th>
                                        </tr>
                                        {TableItem}
                                    </tbody>
                                </table>
                                <div className={OrderDetViewCss.tableDetails}>
                                    <p>Payment Type : {orderData?.paymentType}</p>
                                    <p>Discount : {orderData?.discount ? orderData?.discount : "0"} tk</p>
                                    <p>Delivery Charge : {orderData?.deliveryCharge} tk</p>
                                    <p>Total Ammount : {orderData?.grandTotal + orderData?.deliveryCharge} tk</p>
                                    <p>Collected : {orderData?.receiveAmmount ? orderData?.receiveAmmount : "0"} tk</p>
                                    <p>Return : {orderData?.returnAmmount ? orderData?.returnAmmount : "0"} tk</p>
                                </div>

                                <div className={OrderDetViewCss.detailsWrapper}>
                                    <div className={OrderDetViewCss.detailInput}>
                                        <MyInput onDataChange={myDataChange} labelText='Approval note' type='text' inputName='approval_note' placeholderText='Confirmation Note' myValue={approvalInfo?.approval_note} />
                                        <OptionComp onDataChange={myDataChange} myArray={priorityArr} popName='priority' myValue={approvalInfo?.priority} />
                                        <MyInput onDataChange={myDataChange} labelText='Delivery Date *' type='date' inputName='delivery_date' myValue={approvalInfo?.delivery_date} placeholderText='Confirmation Note' />
                                        <MyInput onDataChange={myDataChange} labelText='Special Instruction' type='text' inputName='special_instruction' placeholderText='Special Instruction' myValue={approvalInfo?.special_instruction} />
                                        <div className={OrderDetViewCss.ButtonGroup}>
                                            <button onClick={onApproveClick}>Approve</button>
                                            <button onClick={onDeclineClick}>Decline</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default OrderDetailsView

const ConfirmAlert = (props) => {
    const { alertText, yesClick, noClick, delID } = props

    return (
        <div className={OrderDetViewCss.conAlert_wrapper}>
            <div className={OrderDetViewCss.conAlert}>
                <h2 className={OrderDetViewCss.caption}>{alertText}</h2>
                <div className={OrderDetViewCss.buttonsec}>
                    <input sid={delID} onClick={yesClick} className={OrderDetViewCss.yes} type={"submit"} value={"Yes"} />
                    <input onClick={noClick} className={OrderDetViewCss.no} type={"submit"} value={"No"} />
                </div>

            </div>
        </div>
    )
}

const MyInput = ({ labelText, type, inputName, placeholderText, myValue, onDataChange }) => {
    const diplayBlock = {
        'display': 'block'
    }
    return (
        <>
            <div className={OrderDetViewCss.inputAll}>
                <label style={diplayBlock}>{labelText}</label>
                <input onChange={onDataChange} type={type} name={inputName} placeholder={placeholderText} value={myValue ? myValue : ""} />
            </div>
        </>
    )
}
const OptionComp = ({ myArray, onDataChange, popName }) => {
    let OptionData = <>
        {myArray ? myArray.map((item, index) => {
            return <option key={index + 'a'} value={item}>{item}</option>
        }) : ""}
    </>
    const diplayBlock = {
        'display': 'block'
    }
    return (
        <>
            <div className={OrderDetViewCss.optionAll}>
                <label style={diplayBlock}>Delivery Priority *</label>
                <select name={popName} onChange={onDataChange} >
                    <option>Select Priority</option>
                    {OptionData}
                </select>
            </div>

        </>
    )
}

const MyTable = ({ item, varinace, price, qty, tammount }) => {

    return (
        <>
            <tr>
                <td className={OrderDetViewCss.foreditVariance}>{item} {varinace?.map((item)=> `${item.Variance_name ? `(${item.Variance_name} - ${item.quantity}), `:''}`)}</td>
                <td >{price}</td>
                {/* <td>{variations}</td> */}
                <td>{qty}</td>
                <td>{tammount}</td>
            </tr>
        </>
    )
}