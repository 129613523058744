import React, { useEffect, useState } from 'react'
import profileCss from './tabview.module.css'
import { toast } from 'react-toastify'
import { UpdateCustomerPasswordFromBack } from '../../../../api/CustomerApi'

function createCaptcha(setCaptcha) {
    let number = ''
    for (let index = 0; index < 4; index++) {
        number += '' + Math.floor(Math.random() * 10)
    }
    setCaptcha(number)
}
const PasswordTab = ({popUp, setPopUp}) => {
    const [myCaptcha, setMyCaptcha] = useState()
    function ClosePop() {
        setPopUp()
        document.body.style.overflow = 'auto'
    }
   async function onResetClick(e) {
        e.preventDefault(e)
        // createCaptcha(setMyCaptcha)
        const captcha = e.target.captcha.value
        try {
            if (captcha===myCaptcha) {
             const response =   await UpdateCustomerPasswordFromBack({id:popUp.id, password:'123456'})
                toast.success(response.message)
                e.target.reset()
                createCaptcha(setMyCaptcha)
            }else{
                toast.error('Please Enter Valid Captcha')
            }
        } catch (error) {
            toast.error(error.message)
        }
    }
   useEffect(()=>{
    createCaptcha(setMyCaptcha)
   },[])
  return (
    <div className={profileCss.reset_holder}>
        <div className={profileCss.info_section}>
        <p>Before change the Password be sure! After changing the Password user may not logged in his/her account! Be Sure that you inform user that you resset his/her password.</p>
        <p>The New Password is : 123456</p>
        </div>
        <form className={profileCss.captcha_form} onSubmit={onResetClick}>
            <div className={profileCss.holder}>
                <div className={profileCss.captcha_input}>
                    <label>Captcha Code</label>
                    <input name='captcha' type='text' required/>
                </div>
                <p className={profileCss.captcha_code}>{myCaptcha}</p>
            </div>
            <button>Resset User Password</button>
        </form>
        
        <div className={profileCss.close_btn}>
          <button onClick={ClosePop}>Close</button>
        </div>
    </div>
  )
}

export default PasswordTab