import { async } from '@firebase/util';
import { faCircle, faCircleCheck, faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { AutoIncrementNumber, AutoIncrementNumberCustom, GetDataByFieldPath, GetDatafromDatabase, 
    GetSingleDoc, SaveData, SaveNestedExistingData, UpdateData, UpdateDataArray, UpdateNestedDoc }
     from '../../connector/DatabaseCheck'
import { LocalDateToTime, LocalTimeStampToDate } from '../../MyFunctions/MyFunctions';
import PaymentCss from './Css/Payment.module.css'
const Payment = () => {
    const myNavigate = useNavigate()
    const [supplierList, setSupplierList] = useState()
    const [singleSupplier, setSingleSupplier] = useState()
    const [SearchData, setSearchData] = useState()
    const [tableData, setTableData] = useState()
    const [myCheck, setMyCheck] = useState(true)
    const [paymentInfo, setPaymentInfo] = useState({
        paymentDate: '',
        paymentMethod: "cash",
        paymentAmmount: 0,
        paymentRemark: '',
    })
    const [errMessage, setErrMessage] = useState()

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name === 'paymentAmmount') {
            value = e.target.value ? parseInt(e.target.value) : ''
            if (value > getPayableDue()) {
                value = getPayableDue()
                setPaymentInfo({ ...paymentInfo, [name]: value })
            } else if (value < 0) {
                setPaymentInfo({ ...paymentInfo, [name]: 0 })
            } else {
                setPaymentInfo({ ...paymentInfo, [name]: value })
            }
        } else {
            setPaymentInfo({ ...paymentInfo, [name]: value })
        }

    }
function getDateTime() {
        let mydate = new Date();
        let day = mydate.getDate()
        let month = mydate.getMonth() + 1
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        let year = mydate.getFullYear()
        let hour = mydate.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = mydate.getMinutes()
        if (minute < 10) {
            minute = '0' + minute
        }
        // console.log(minute)
        let seconds = mydate.getSeconds()
        if (seconds < 10) {
            seconds = '0' + seconds
        }
        let finalTime = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + seconds
        return finalTime
    }
  
    function checkClick(params) {
        console.log(LocalDateToTime(paymentInfo.paymentDate))
        
    }

  async  function onSaveClick() {
        let payErr = ValidateFunction('paymentAmmount', 'Payment ')
        let remarkErr = ValidateFunction('paymentRemark', 'Remark  ')
        let dateErr = ValidateFunction('paymentDate', 'Date ')

        setErrMessage({ ...errMessage, payErr, remarkErr, dateErr })
        if (!payErr && !remarkErr && !dateErr) {
            let paymentArr = []
            tableData.forEach(element => {
                if (element.nowPay) {
                    paymentArr.push(element)
                }
            });
            let userData = window.localStorage.getItem('userData')
            userData = JSON.parse(userData)
            if (paymentArr.length > 0) {
                let NumberCollection = await GetSingleDoc("NumberCollection", "NumberID")

                if (paymentArr.length > 1) {
                    if (paymentInfo.paymentAmmount === getPayableDue()) {
                        let pono = ''
                        paymentArr.forEach(async(item) => {
                            let dataID = item.id
                            //getDueAmmount(total, payment, discount, payAmmounthere)
                            let purchaseUpdate = {
                                paymentAmmount: getDueAmmount(item.total, item.payAmmount, item.percent, item.Payment),
                                paymentDate: LocalDateToTime(paymentInfo.paymentDate),
                                paymentRemark: paymentInfo.paymentRemark,
                                PVNO:NumberCollection.data().paymentVoucher
                            }
                            //console.log(purchaseUpdate)
                            let summeryData = {
                                supplierName: singleSupplier?.companyaName,
                                status: 'PO Ammount Paid',
                                attemeptBy: userData.name,
                            }
                           await UpdateDataArray('Purchase', dataID, 'Payment', {...purchaseUpdate})
                         await UpdateData('Purchase', { fullPaid: true}, dataID)
                         await SaveNestedExistingData('Purchase', dataID, 'PoSummery', summeryData)
                        });
                        // loop ends

                        paymentArr.map((item)=>{
                            pono += item.PoNo+', '
                        })

                        let paymentData = {
                            PoNo:pono,
                            paidAmmount:paymentInfo.paymentAmmount,
                            paymentMethod:paymentInfo.paymentMethod,
                            paymentRemark:paymentInfo.paymentRemark,
                            supplierInfo:singleSupplier,
                            paymentVoucher:NumberCollection.data().paymentVoucher,
                            processBy:userData.name,
                            processorID:window.localStorage.getItem('user_id')
                        }
                       // console.log(paymentData)
                        await AutoIncrementNumberCustom('NumberCollection', 'NumberID', 'paymentVoucher', 1 )
                        await SaveData('Payment', {...paymentData})
                        
                        alert('data saved')
                      //  console.log(paymentData)
                      
                    } else {
                        console.log('you must pay full ammount')
                    }
                } else {
                    let fullPaid = true
                    let status = ''
                    let dataID = paymentArr[0].id
                    if (paymentInfo.paymentAmmount < getDueAmmount(paymentArr[0].total, paymentArr[0].payAmmount, paymentArr[0].percent)) {
                        fullPaid =false
                        status = 'PO Ammount Partial Paid'
                    }else{
                        fullPaid =true
                        status = 'PO Ammount Paid'
                    }

                    let purchaseUpdate = {
                        paymentAmmount: paymentInfo.paymentAmmount,
                        paymentDate: LocalDateToTime(paymentInfo.paymentDate),
                        paymentRemark: paymentInfo.paymentRemark,
                        PVNO:NumberCollection.data().paymentVoucher
                    }

                    let summeryData = {
                        supplierName: singleSupplier?.companyaName,
                        status: status,
                        attemeptBy: userData.name,
                    }
                    await UpdateDataArray('Purchase', dataID, 'Payment', {...purchaseUpdate})
                   await UpdateData('Purchase', {fullPaid: fullPaid}, dataID)
                   await SaveNestedExistingData('Purchase', dataID, 'PoSummery', summeryData)

                    let paymentData = {
                        PoNo:paymentArr[0].PoNo,
                        paidAmmount:paymentInfo.paymentAmmount,
                        paymentMethod:paymentInfo.paymentMethod,
                        paymentRemark:paymentInfo.paymentRemark,
                        supplierInfo:singleSupplier,
                        processBy:userData.name,
                        processorID:window.localStorage.getItem('user_id'),
                        paymentVoucher:NumberCollection.data().paymentVoucher,
                    }
                   await SaveData('Payment', {...paymentData})
                   await AutoIncrementNumberCustom('NumberCollection', 'NumberID', 'paymentVoucher', 1 )
                   alert('data saved')
                }
            } else {
                console.log('no invoice found')
            }
            
        }
        // console.log(paymentInfo)
    }

    function ValidateFunction(field, message) {
        let text = ""
        if (!paymentInfo[field]) {
            text = message + "is Required"
        }
        return text;
    }

    async function getSearchData() {
        setSingleSupplier()
        supplierList.forEach(element => {
            if (element.supplierID === SearchData) {
                setSingleSupplier(element)
            }
        });
        let mtableData = await GetDataByFieldPath('Purchase', 'supplierInfo', 'supplierID', SearchData)
        let newArr = []
        mtableData.forEach(element => {
            if (element.receivedPO) {
                if (!element.fullPaid) {
                    element = { ...element, nowPay: true }
                    newArr.push(element)
                }
            }
        });
        let sortArr = newArr.sort((a, b) => (a.createdDate?.seconds > b.createdDate?.seconds) ? 1 : -1)
        setTableData(sortArr)
    }

    function CheckAll() {
        let newArr = []
        tableData.forEach(element => {
            element = {
                ...element,
                nowPay: true
            }
            newArr.push(element)
        });
        setTableData(newArr)
    }
    function unCheckAll() {
        let newArr = []
        tableData.forEach(element => {
            element = {
                ...element,
                nowPay: false
            }
            newArr.push(element)
        });
        setTableData(newArr)
    }
    function CheckData(e, id) {
        let newArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = {
                    ...element,
                    nowPay: true
                }
                newArr.push(element)
            } else {
                newArr.push(element)
            }
        });
        setTableData(newArr)
    }
    function unCheckData(e, id) {
        let newArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = {
                    ...element,
                    nowPay: false
                }
                newArr.push(element)
            } else {
                newArr.push(element)
            }
        });
        setTableData(newArr)

    }
    function getPayableDue() {
        // {item.percent ? Math.round(item.total * parseInt(item.percent) / 100
        let dueTotal = 0
        let discount = 0
        tableData?.map((item) => {
            // console.log(item.total-item.payAmmount)
            if (item.nowPay) {
                if (item.percent) {
                    discount = Math.round(item.total * parseInt(item.percent) / 100)
                } else {
                    discount = 0
                }
                if (item.payAmmount) {
                    dueTotal += item.total - item.payAmmount - discount
                } else {
                    dueTotal += item.total - discount
                }

                if (item.Payment) {
                    let total = 0
                    item.Payment?.map((pitem)=>{
                        total +=pitem.paymentAmmount
                    })
                    dueTotal -= total
                }else{
                    dueTotal = dueTotal 
                }
            }
        })
        return dueTotal
    }
    function checkAllData(params) {
        for (let index = 0; index < tableData?.length; index++) {
            const element = tableData[index];
            if (!element.nowPay) {
                setMyCheck(false)
                break;
            } else {
                setMyCheck(true)
            }
        }
    }
    useEffect(() => {
        checkAllData()
        getPayableDue()
        setPaymentInfo({ ...paymentInfo, paymentAmmount: 0, paymentDate:getDateTime()})
    }, [tableData])

    function getLeadgerBalance() {
        // {item.percent ? Math.round(item.total * parseInt(item.percent) / 100
        let dueTotal = 0
        let discount = 0
        tableData?.map((item) => {
            // console.log(item.total-item.payAmmount)
            if (item.percent) {
                discount = Math.round(item.total * parseInt(item.percent) / 100)
            } else {
                discount = 0
            }
            if (item.payAmmount) {
                dueTotal += item.total - item.payAmmount - discount
            } else {
                dueTotal += item.total - discount
            }
        })
        return dueTotal
    }


    async function getSupplier() {
        let supplierData = await GetDatafromDatabase('Supplier', 'supplierID')
        // console.log(supplierData)
        setSupplierList(supplierData)
    }
    useEffect(() => {
        getSupplier()
    }, [])
    function getPaymentTotal(payArr){
        let total = 0
        payArr?.map((pitem)=>{
            total += pitem.paymentAmmount
        })

        return total
    }
    function getDueAmmount(total, payment, discount, payAmmounthere) {
        let payTotal = 0
        payAmmounthere?.map((item)=>{
           payTotal += item.paymentAmmount
        })
        let dueTotal = 0
        if (payment && discount && payAmmounthere) {
            dueTotal = total - (total * Math.round(parseInt(discount)) / 100) - payment-payTotal
        }
         else if (payment && discount) {
            dueTotal = total - (total * Math.round(parseInt(discount)) / 100) - payment
        } else if (payment) {
            dueTotal = total - payment
        } else if (discount) {
            dueTotal = total - Math.round(parseInt(discount))
        } else {
            dueTotal = total
        }
      //  console.log(Math.round(dueTotal))
        return Math.round(dueTotal)
    }
    const errStyle = {
        display: 'block',
        textAlign: 'right',
        color: 'red',
    }
 
    return (
        <>
            <section className={PaymentCss.heading}>
                <div className={PaymentCss.inner_headign}>
                    <h2 className={PaymentCss.headign_h2}>Payment</h2>
                    <div className={PaymentCss.input_section}>
                        <div className={PaymentCss.common}>
                            <select onChange={(e) => { setSearchData(e.target.value) }}>
                                <option>Select Supplier</option>
                                {supplierList?.map((item, index) => {
                                    return <option key={index} value={item.supplierID}>{item.companyaName}</option>
                                })}
                            </select>
                        </div>
                        <div className={PaymentCss.common}>
                            <button onClick={getSearchData}>Search</button>
                        </div>
                        <div className={PaymentCss.common}>
                            <button onClick={checkClick}>Credit Voucher Payment</button>
                        </div>
                    </div>
                    {singleSupplier ? <div className={PaymentCss.supplier_info}>
                        <p>{singleSupplier?.companyaName}</p>
                        <p>{singleSupplier?.ownerName}</p>
                        <p>{singleSupplier?.phone}</p>
                        <p>{singleSupplier?.address + ', ' + singleSupplier?.Area + ', ' + singleSupplier?.District + ', ' + singleSupplier?.Divition}</p>
                    </div> : ''}
                    {singleSupplier ? <div className={PaymentCss.tableSection}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Check
                                        {myCheck ? <FontAwesomeIcon style={{ color: 'white', margin: '0 0 0 8px', padding: '0', fontSize: "18px", cursor: 'pointer' }} icon={faSquareCheck} onClick={(e) => { unCheckAll(e) }} /> : <FontAwesomeIcon style={{ color: 'white', margin: '0 0 0 8px', padding: '0', fontSize: "18px", cursor: 'pointer' }} icon={faSquare} onClick={(e) => { CheckAll(e) }} />}
                                    </th>
                                    <th>Date</th>
                                    <th>Po No.</th>
                                    <th>Total Ammount</th>
                                    <th>Paid Ammount</th>
                                    <th>Discount</th>
                                    <th>Due Ammount</th>
                                </tr>

                                {tableData?.map((item, index) => {
                                    return <tr key={index}>
                                        <td>{item.nowPay ? <FontAwesomeIcon style={{ color: 'green', cursor: 'pointer', margin: '0 0 0 8px', padding: '0', }} icon={faSquareCheck} onClick={(e) => { unCheckData(e, item.id) }} /> : <FontAwesomeIcon style={{ color: '#b5b5b5', cursor: 'pointer', margin: '0 0 0 8px', padding: '0', }} icon={faSquare} onClick={(e) => { CheckData(e, item.id) }} />} </td>
                                        <td>{LocalTimeStampToDate(item.createdDate?.seconds)}</td>
                                        <td>{item.PoNo}</td>
                                        <td>{item.total}</td>
                                        <td>{
                                            (item.payAmmount ? parseInt(item.payAmmount)  : 0) + getPaymentTotal(item.Payment)
                                        }</td>
                                        <td>{
                                            (item.percent ? Math.round(item.total * parseInt(item.percent) / 100) : item.discountAmmount)   
                                        }</td>

                                        <td>{getDueAmmount(item.total, item.payAmmount, item.percent, item.Payment)}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div className={PaymentCss.other_information}>
                            <div className={PaymentCss.text_section}>
                                <p className={PaymentCss.ledger_ballnce}>Supplier Ledger Ballance : {getLeadgerBalance()} Tk</p>
                                <div className={`${PaymentCss.payment_info} ${PaymentCss.limit_due}`}>
                                    <p>Payment Limit : 0</p>
                                    <p>Payable Due : {getPayableDue()} Tk</p>
                                </div>
                            </div>
                            <div className={PaymentCss.payment_info}>
                                <div className={PaymentCss.option_class}>
                                    <label>Payment Method</label>
                                    <select name='paymentMethod' onChange={onDataChange}>
                                        <option value='cash'>Cash</option>
                                        <option value='cash'>Online Deposite</option>
                                        <option value='cash'>Transferred</option>
                                        <option value='cash'>Cheque</option>
                                    </select>
                                </div>
                                <div className={PaymentCss.input_section}>
                                    <label>Paid Ammount</label>
                                    <input onChange={onDataChange} name='paymentAmmount' type='number' placeholder='payment ammount' value={paymentInfo?.paymentAmmount} />
                                </div>
                                <p style={errStyle}>{errMessage?.payErr}</p>
                                <div className={PaymentCss.input_section}>
                                    <label>Payments Remarks</label>
                                    <input onChange={onDataChange} name='paymentRemark' type='text' placeholder='payment remark here' value={paymentInfo.remark} />
                                </div>
                                <p style={errStyle}>{errMessage?.remarkErr}</p>
                                <div className={PaymentCss.input_section}>
                                    <label>Date</label>
                                    <input onChange={onDataChange} name='paymentDate' type='datetime-local' value={paymentInfo?.paymentDate} />
                                </div>
                                <p style={errStyle}>{errMessage?.dateErr}</p>
                                <div className={PaymentCss.input_section}>
                                    <label>Remaining </label>
                                    <input type='text' disabled value={paymentInfo?.paymentAmmount ? getPayableDue() - paymentInfo?.paymentAmmount : getPayableDue()} />
                                </div>
                                <div className={PaymentCss.button_section}>
                                    <button onClick={onSaveClick}>Save</button>
                                </div>
                            </div>
                        </div>

                    </div> : ''}
                </div>
            </section>
        </>
    )
}

export default Payment