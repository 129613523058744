import EditItemCss from './css/edititem.module.css'

import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { UpdateProductList } from '../../../../api/ProductListApi'
import { DeleteVariaceImage, DeleteVariance, GetFilterProductVariance, GetVarianceImage, UpdateProductVariance } from '../../../../api/ProductVarianceApi'
import Table from '../../../Table'
import AddVariance from './editpop/AddVariance'
import EditVariance from './editpop/EditVariance'

const ProductsAttributesEdit = ({ showState, item}) => {
    const myParams = useParams()
    let [varianceData, setVarData] = useState([])
    let [varianceDataOld, setVarDataOld] = useState([])
    const [varienceOp, setVarianceOp] = useState([])
    const [addPopUp, setAddPopUp] = useState()



   
 async function SaveToDatabase() {
       let hasAge = ageIndicationValue ? 1:0
       try {
        await UpdateProductList(item.id, {hasAge:hasAge})
        toast.success('Information Updated')
    } catch (error) {
       toast.error(error.message) 
    }
    }
    
    const [ageIndicationValue, setAgeIndicationValue] = useState(false)

    function onAgeChange(e) {
        let value = e.target.checked
        setAgeIndicationValue(value)
    }

    function deleteClick(e) {
        let identity = e.target.getAttribute("identifire")
        let arr = []
        varianceData.forEach((element, index) => {
            if (identity === element.Variance_name) {
                varianceData.splice(index, 1)
            } else {
                arr.push(element)
            }
        });
        setVarData(arr)
    }
    const [deleteItem, setDeleteItem] = useState()

    async function OlddeleteClick(item) {
        setDeleteItem(item)
    }
    const [editVar, setEditVar] = useState()
    function onEditClick(item) {
        setEditVar(item)     
    }
   
    const TableItem = <>
        {varianceDataOld ? varianceDataOld.map((item, index) => {
            return <Table key={'old'+index} param1={index + 1} param2={item.Variance_name}
            param3={item.attr_value ? <>
                {varienceOp?.filter((fitem)=>{
                    return item.attr_value == fitem.id ? fitem:''
                })
                .map((mitem)=>{
                    return mitem.attr_value_name
                })}
            </>:'-'}
            
            param4={<ProductVarianceImageShow name={item.MainIamge}/>}
            param5={<ProductVarianceImageShow name={item.ShadeIamge}/>}
                param6={<ToggleSwitch toggleOnOff ={item.status} id={item.id} tableData={varianceDataOld} setTableData={setVarDataOld}/>}
                param7={<>
                    {/* <input type="Submit" value="Edit" /> */}
                    <input onClick={()=>{OlddeleteClick(item)}} identifire={item.id} className='delete' type="Submit" value="Delete" />
                    <input onClick={()=>{onEditClick(item)}} identifire={item.id} className='delete' type="Submit" value="Edit" />
                    </>}
            />

        })

            : "Add Data"}
        {varianceData ? varianceData.map((item, index) => {
            return <Table key={'new'+index} param1={varianceDataOld.length + index + 1} param2={item.Variance_name}
            param3={item.selectedVarUnit ? <>
             {varienceOp?.filter((fitem)=>{
                    return item.selectedVarUnit == fitem.id ? fitem:''
                })
                .map((mitem)=>{
                    return mitem.attr_value_name
                })}
            </>:'-'}
            
            param4={<img src={item.MainIamgeurl} alt={item.MainIamge ? item.MainIamge.name : ""} />} param5={<img src={item.ShadeIamgeurl} alt={item.ShadeIamge ? item.ShadeIamge.name : ""} />}
                param6={'status'}
                param7={<>
                    {/* <input type="Submit" value="Edit" /> */}
                    <input onClick={deleteClick} identifire={item.Variance_name} className='delete' type="Submit" defaultValue="Delete" /></>}
            />

        })

            : "Add Data"}

    </>

    async function getSelectedData() {
        let variance = await GetFilterProductVariance(`product_id = ${myParams.data}`)
        let sortArr = variance.sort((a, b) => (parseInt(a.Variance_name) > parseInt(b.Variance_name)) ? 1 : -1)
        setVarDataOld(sortArr)
    }

    useEffect(() => {
        getSelectedData() 
    }, [showState ,addPopUp, deleteItem, editVar])

    return (
        <>
        {addPopUp && <AddVariance popUp={addPopUp} setPopUp={setAddPopUp}/>}
        {deleteItem && <ConfirmAlert deleteItem={deleteItem} setDeleteItem ={setDeleteItem}/>}
        {editVar && <EditVariance popUp={editVar} setPopUp= {setEditVar} mainitem={item}/>}
            <div className={EditItemCss.att_head}>
                <h4>Product Attributes</h4>
                <div>
                    <input type="checkbox" id='age' name="age" onChange={onAgeChange} />
                    <label htmlFor="age">Have Age Variations?</label>
                </div>
            </div>
            <section className={EditItemCss.main}>
                <div className={EditItemCss.att_buttons}>
                        <button onClick={()=>{setAddPopUp(item)}}>Add Variance</button>
                    </div>
                <table>
                    <tbody>
                    <tr>
                        <th>Sl</th>
                        <th>Variance Name</th>
                        <th>Variations</th>
                        <th>Variation Image</th>
                        <th>Shade Image</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    {TableItem}
                    </tbody>
                </table>
                {/*  onClick={SaveToDatabase} */}
                {/* <input onClick={SaveToDatabase} className={EditItemCss.submitbutton} type={"submit"} value="Update data" /> */}
                <button className={EditItemCss.submitbutton} onClick={SaveToDatabase}>Update Data</button>
            </section>
        </>
    )
}

export default ProductsAttributesEdit

const ToggleSwitch = ({ toggleOnOff, id, tableData, setTableData}) => {

    function toggleClick() {
       let myArr = []
        tableData?.map(async (item)=>{
            if (item.id===id) {
                let okActive = !item.status
                item = {...item, status:okActive}
                myArr.push(item)
               await UpdateProductVariance(id, {status:okActive})                
            }else{
                myArr.push(item)
            }
        })
        setTableData(myArr)
    }

    return (
        <>
            {toggleOnOff ? <div className={EditItemCss.switch_on}> <FontAwesomeIcon icon={faToggleOn} onClick={() => { toggleClick(id) }} /></div> : <div className={EditItemCss.switch_off}><FontAwesomeIcon icon={faToggleOff} onClick={() => { toggleClick(id) }}/></div>}
        </>
    )
}


const ConfirmAlert = (props) => {
    const {deleteItem, setDeleteItem} = props

  async  function yesClick() {
      try {
          await DeleteVariance(deleteItem.id)
          await DeleteVariaceImage({ image_name: deleteItem.ShadeIamge })
          await DeleteVariaceImage({ image_name: deleteItem.MainIamge })
          setDeleteItem()
      } catch (error) {
          console.log(error)
      }
    }
    function noClick() {
        setDeleteItem()
    }

    return (
        <>
            <div className='conAlert'>
                <h2 className='caption'>Do you Want To Delete This?</h2>
                <div className='buttonsec'>
                    <input onClick={yesClick} className='yes' type={"submit"} value={"Yes"} />
                    <input onClick={noClick} className='no' type={"submit"} value={"No"} />
                </div>
            </div>
        </>
    )
}

const ProductVarianceImageShow = ({name}) => {
    const imgStyle = {
        width: "100px",
        height: "auto"
    }

    const [image, setImage] = useState()
    
    async function getLink() {
       // console.log(name);
        if (name) {
            let imageInfo =  await GetVarianceImage({image_name:name})
           // console.log(imageInfo);
            if (imageInfo.imageExist) {
                setImage(imageInfo.url)
            }else{
                setImage('')
            }
        }else{
            setImage('')
        }
    }
    useEffect(() => {
        getLink()
    }, [name])
    return (
        <> {image ? <img src={image} alt={name} />:''}</>
    )
}