import React, { useEffect, useState } from 'react'
import { GetDataByFieldPath, GetDatafromDatabase, GetSingleData, GetSingleDoc } from '../../connector/DatabaseCheck'
import {useParams} from 'react-router-dom'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import { async } from '@firebase/util'
import PaymentVoucherCss from './css/PaymentVoucher.module.css'

const PaymentVoucher = ({heading, date}) => {
    const myParams = useParams()
    const [invoiceState, setInvoiceState] = useState()
    const [supplierInfo, setSupplierInfo] = useState()


 async function getData() {

    let vaocherData = JSON.parse(window.localStorage.getItem('vaocherData'))
    let text = vaocherData.PoNo.toString();
    setSupplierInfo(vaocherData)
   let splitPo = text.split(',')
    //console.log(splitPo)
    let dataArr = []
    let promises = splitPo.map(async function(element){
        let finalPO = parseInt(element)
        if (finalPO) {
            let purchaseData = await GetSingleData('Purchase', 'PoNo', finalPO)
            dataArr.push(purchaseData[0])
          //  console.log(purchaseData)
        }
    })
    Promise.all(promises).then(()=>{
        setInvoiceState(dataArr)
    })

  //  dataOrganize(invoiceData)
    }
    function getPaymentAmmount() {
        
    }

    function checkClick(params) {
        console.log(invoiceState)
    }

    useEffect(() => {
        getData()
    }, [])

    function getTotalPaid() {
        let total = 0
        invoiceState?.map((myitem) => {
            myitem.Payment
                .filter((fitem) => {
                    if (supplierInfo?.paymentVoucher === fitem.PVNO) {
                        return fitem
                    }
                })
                .map((item) => {
                    total += item.paymentAmmount
                })
        })
        return total
    }

  return (
    <>
            <div className={PaymentVoucherCss.main}>
            <h3>{heading}</h3>
            <div className={PaymentVoucherCss.details}>
                <div className={PaymentVoucherCss.client}>
                    <div className={PaymentVoucherCss.info}>
                        <strong><p className={PaymentVoucherCss.moreinfo} onClick={checkClick}>Voucher No. : {supplierInfo?.paymentVoucher} </p></strong>
                        <strong><p className={PaymentVoucherCss.moreinfo} onClick={checkClick}>Supplier : {supplierInfo?.supplierInfo?.companyaName} </p> </strong>
                    </div>
                </div>
                <div className={PaymentVoucherCss.invoice}>
                        <div className={PaymentVoucherCss.info}>
                            <span className={PaymentVoucherCss.moreinfo}>Date : </span>{LocalTimeStampToDate(supplierInfo?.createdDate?.seconds)}
                        </div>
                        <div className={PaymentVoucherCss.info}>
                            <span className={PaymentVoucherCss.moreinfo}>Purchased By : </span>{supplierInfo?.paymentMethod}
                        </div>
                </div>
            </div>
            <div className={PaymentVoucherCss.table}>
                <table>
                    <thead>
                        <tr>
                            <th className={PaymentVoucherCss.no}>Sl</th>
                            <th style={{ paddingLeft: '10px'}}>Po No.</th>
                            <th className={PaymentVoucherCss.unit}>Purchase Date</th>
                            <th className={PaymentVoucherCss.unit_price}>Purchase Total</th>
                            <th className={PaymentVoucherCss.total}>Payment Ammount</th> 
                        </tr>
                    </thead>
                    <tbody>
                    {invoiceState?.map((item, index)=>{
                        return <>
                            <tr key={index}>
                            <td>{index+1}</td>
                            <td style={{ textAlign: 'left'}} >{item.PoNo}</td>
                            <td>{LocalTimeStampToDate(item.createdDate?.seconds)}</td>
                            <td>{Math.round(item.grandTotal)}</td>
                            <td>{
                                item.Payment
                                .filter((fitem)=>{
                                    if (supplierInfo?.paymentVoucher ===fitem.PVNO) {
                                        return fitem
                                    }
                                })
                                .map((item)=>{
                                    return item.paymentAmmount
                                })
                            }</td>
                            </tr>
                        </>
                    })}
                    </tbody>
                </table>
                <div className={PaymentVoucherCss.last_raw}>
                <p>Total Paid : {getTotalPaid()} </p>
                </div>
            </div>
            </div>
        </>
  )
}

export default PaymentVoucher