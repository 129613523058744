import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {GetEndDate, GetStartDate, LocalTimeStampToDate} from '../../MyFunctions/MyFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import { SalesReturnReportData } from '../../../api/ReportApi/SalesReportApi'
import SalesViewReturnCss from './css/SalesViewReturn.module.css'

const SalesViewReturn = ({ heading, date }) => {
    const [invoiceState, setInvoiceState] = useState()
    let [searchParams] = useSearchParams();
    let [allProduct, setAllProcducts] = useState(JSON.parse(window.localStorage.getItem('allProduct')));
    let [allUsers, setAllUsers] = useState();

    async function getData() {
        let startDate = searchParams.get('start_date')
        let endDate = searchParams.get('end_date')
        let myStartDate = new Date(GetStartDate(startDate)).getTime()
        let myEndDate = new Date(GetEndDate(endDate)).getTime()
        //  console.log(myStartDate, myEndDate ) 
       if (startDate && endDate) {
       // findSalesReturnData(startDate, endDate)
       let data =  await SalesReturnReportData({starttime:myStartDate, endtime:myEndDate})
       setInvoiceState(data)
       }
       
    }
   
   
    useEffect(() => {
        getData()
    }, [])

    function getQTotalAll(marray) {
        let total = 0
        marray?.forEach(element => {
            total +=parseInt(element.rquantity)
        });
        return total
    }
    function getTotalAll(marray) {
        let total = 0
        marray?.forEach(element => {
            total += element.rprice*element.rquantity
        });
        return total
    }
    function getChargeTotal(marray) {
        let total = 0
        marray?.forEach(element => {
            total += element.return_charge
        });
        return total
    }
   

    return (

        <HeaderFooterWrapper content={
            <>
                <h3>{heading}</h3>
            <div className={SalesViewReturnCss.details}>
                <div className={SalesViewReturnCss.client}>
                    <div className={SalesViewReturnCss.info}>
                    {searchParams.get('start_date')? <span className={SalesViewReturnCss.moreinfo}>Date : {searchParams.get('start_date')}  To {searchParams.get('end_date')}</span>:''}
                    </div>
                </div>
                <div className={SalesViewReturnCss.invoice}>
                    <div className={SalesViewReturnCss.info}>
                      
                    </div>
                </div>
            </div>
            <div className={SalesViewReturnCss.table}>
                <table>
                    <thead>
                        <tr>
                            <th>Sl</th>
                            <th>Date</th>
                            <th>Invoice No</th>
                            <th>Product Name</th>
                            <th>Customer Name</th>
                            <th>Return Charge</th>
                            <th>Price</th>
                            <th>Qty</th>
                            <th>Sub Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceState?.map((item, index) => {
                            return  <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{LocalTimeStampToDate(item.mdate)}</td>
                                        <td >{item.id}</td>
                                        <td >{item.pname} </td>
                                        <td>{item.name }</td>
                                        <td>{item.return_charge }</td>
                                        <td>{item.rprice}</td>
                                        <td>{item.rquantity}</td>
                                        <td>{item.rprice *item.rquantity}</td>
                                    </tr>
                        })}
                        <tr>
                            <td colSpan={5}>Total : </td>
                            <td>{getChargeTotal(invoiceState)}</td>
                            <td></td>
                            <td>{getQTotalAll(invoiceState)}</td>
                            <td>{getTotalAll(invoiceState)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            </>
        } />
            
    )
}

export default SalesViewReturn