import { async } from '@firebase/util'
import { faEdit, faToggleOff, faToggleOn, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
// import { GetWhereAndOrderData, SaveData, UpdateData, DeleteData } from '../../connector/DatabaseCheck'
import ComplainCategoriesCss from './css/ComplainCategories.module.css'

const ComplainCategories = () => {
  const [allData, setAllData] = useState()
  const [complainType, setComplainType] = useState()
  const [catData, setCatData] = useState({
    complainCatName: "",
    complainTypeID: "",
    comlainCatNotes: "",
  })
  const [editData, setEditData] = useState()
  const [deleteData, setDeleteData] = useState()

  function onDataChange(e) {
    let name = e.target.name
    let value = e.target.value
    setCatData({ ...catData, [name]: value })
  }

  async function saveToDataBase() {
    let finalData = {
      ...catData,
      identity: 'category',
      active: true
    }
    console.log(finalData)
    if (!finalData.complainCatName) {
      alert('please Enter Category Name')

    } else if (!finalData.complainTypeID) {
      alert('please Select complain Type')
    } else if (!finalData.comlainCatNotes) {
      alert('please Enter Category Notes')
    }
    else {
      // alert('ok')
      //await SaveData('ComplainSetting', finalData)
    }
    setCatData({
      complainCatName: "",
      complainTypeID: "",
      comlainCatNotes: "",
    })
    allData.push(finalData)
  }

  async function activeFalse(e, item, index) {
    if (item.id) {
     // await UpdateData('ComplainSetting', { active: false }, item.id)
      let myArr = []
      for (let index = 0; index < allData.length; index++) {
        let element = allData[index];
        if (element.id == item.id) {
          element = {
            ...element,
            active: false
          }
          myArr.push(element)
        } else {
          myArr.push(element)
        }
      }
      setAllData(myArr)
    } else {
      let data = [] // await GetWhereAndOrderData('ComplainSetting', 'identity', 'category', 'createdDate', 'asc')
      setAllData(data)
    }
  }
  async function activeTrue(e, item, index) {
    if (item.id) {
     // await UpdateData('ComplainSetting', { active: true }, item.id)
      let myArr = []
      for (let index = 0; index < allData.length; index++) {
        let element = allData[index];
        if (element.id == item.id) {
          element = {
            ...element,
            active: true
          }
          myArr.push(element)
        } else {
          myArr.push(element)
        }
      }
      setAllData(myArr)
    } else {
      let data = [] // await GetWhereAndOrderData('ComplainSetting', 'identity', 'category', 'createdDate', 'asc')
      setAllData(data)
    }
  }

 async function onUpdateClick(params) {
    let finalData = {
      complainCatName: catData.complainCatName,
      complainTypeID: catData.complainTypeID,
      comlainCatNotes: catData.comlainCatNotes
    }
   // console.log(editData)
    if (!finalData.complainCatName) {
      alert('please Enter Category Name')

    } else if (!finalData.complainTypeID) {
      alert('please Select complain Type')
    } else if (!finalData.comlainCatNotes) {
      alert('please Enter Category Notes')
    }
    else {
      if (editData.id) {
       // await UpdateData('ComplainSetting', finalData, editData.id)
        let myArr = []
        allData.forEach(element => {
          if (element.id === editData.id) {
            element = { ...element, ...finalData }
            myArr.push(element)
          } else {
            myArr.push(element)
          }
        });
        setAllData(myArr)
        setEditData();
        setCatData({
          complainCatName: "",
          complainTypeID: "",
          comlainCatNotes: "",
        })

      } else {
        alert('please Refresh this page to continue')
      }
    }
  }

  async function getDatafromDataBase() {
    let mytypeData = [] // await GetWhereAndOrderData('ComplainSetting', 'identity', 'type', 'createdDate', 'asc')
    setComplainType(mytypeData)

    let data = [] // await GetWhereAndOrderData('ComplainSetting', 'identity', 'category', 'createdDate', 'asc')
    setAllData(data)
  }

  useEffect(() => {
    getDatafromDataBase()
  }, [])

  const tableIconStyle = {
    margin: '0',
    padding: '0',
    margin: '0 5px 0 5px',
    fontSize: '18px',
    cursor: 'pointer'

  }
  const TableItem = allData?.map((item, index) => {
    return (
      <MyTable key={index} sl={index + 1} name={item.complainCatName}
        notes={item.comlainCatNotes}
        status={item.active ? <FontAwesomeIcon style={{ ...tableIconStyle, color: 'green' }} icon={faToggleOn} onClick={(e) => { activeFalse(e, item, index) }} />
          : <FontAwesomeIcon style={{ ...tableIconStyle, color: 'gray' }} icon={faToggleOff} onClick={(e) => { activeTrue(e, item, index) }} />}
        manage={<>
          <FontAwesomeIcon style={{ ...tableIconStyle, color: 'green' }} icon={faEdit} onClick={(e) => { setEditData(item); setCatData({ ...item }) }} />
          <FontAwesomeIcon style={{ ...tableIconStyle, color: 'red' }} icon={faTrashCan} onClick={() => { setDeleteData(item) }} />
        </>}
      />

    )
  })
  return (
    <>
      {deleteData ? <ConfirmAlert item={deleteData} setDeleteData={setDeleteData} allData={allData} /> : ''}
      <div className={`${ComplainCategoriesCss.common_class} ${ComplainCategoriesCss.complain_category}`}>
        <div className={ComplainCategoriesCss.input_section}>
          <div className={ComplainCategoriesCss.input_wrapper}>
            <label><strong>Complain Category Name *</strong></label>
            <input name='complainCatName' type={'text'} placeholder='Complain Priority Name' onChange={onDataChange} value={catData?.complainCatName} />
          </div>
          <div className={ComplainCategoriesCss.input_wrapper}>
            <label><strong>Complain Type *</strong></label>
            {editData ? <select name='complainTypeID' onChange={onDataChange} defaultValue={editData?.complainTypeID}>
              <option value={''}>select one</option>
              {complainType?.map((item, index) => {
                return <option key={index} value={item.id}>{item.complainType}</option>
              })}
            </select>:
            <>
              <select name='complainTypeID' onChange={onDataChange} defaultValue={''} >
              <option value={''}>select one</option>
              {complainType?.map((item, index) => {
                return <option key={index} value={item.id}>{item.complainType}</option>
              })}
            </select>
            </>}

          </div>
          <div className={ComplainCategoriesCss.input_wrapper}>
            <label><strong>Complain Category Notes *</strong></label>
            <textarea name='comlainCatNotes' rows="6" cols="50" placeholder='Complain Category Notes' onChange={onDataChange} value={catData?.comlainCatNotes}></textarea>
          </div>
          {!editData ? <button onClick={saveToDataBase}>Save Priority</button> :
            <>
              <button onClick={() => {
                setEditData(); setCatData({
                  complainCatName: "",
                  complainTypeID: "",
                  comlainCatNotes: "",
                })
              }}
                style={{ background: 'red' }}>Cancel</button>

              <button onClick={onUpdateClick} style={{ background: 'purple', marginRight: '10px' }}>Update Priority</button>
            </>}
        </div>
        <div className={ComplainCategoriesCss.tableSection} >
          <table style={{ marginTop: '15px' }}>
            <tbody>
              <tr>
                <th>SL</th>
                <th>Category Name</th>
                <th>Category Notes</th>
                <th>Category Status</th>
                <th>Manage</th>
              </tr>
              {TableItem}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default ComplainCategories

const MyTable = ({ sl, name, notes, status, manage }) => {

  return <>
    <tr>
      <td>{sl}</td>
      <td>{name}</td>
      <td>{notes}</td>
      <td>{status}</td>
      <td>{manage}</td>
    </tr>
  </>
}
const ConfirmAlert = ({ item, setDeleteData, allData }) => {
  function yesClick() {
    if (item.id) {

     // DeleteData('ComplainSetting', item.id)
      allData.forEach((element, index) => {
        if (element.id === item.id) {
          allData.splice(index, 1)
        }
      });
      setDeleteData()
    } else {
      alert('please refresh the page and try again')
    }
  }
  function noClick() {
    setDeleteData()
  }

  return (
    <>
      <div className={ComplainCategoriesCss.conAlert}>
        <h2 className={ComplainCategoriesCss.caption}>Do you Want To Delete This?</h2>
         <div className={ComplainCategoriesCss.buttonsec}>
          <input onClick={yesClick} className={ComplainCategoriesCss.yes} type={"submit"} value={"Yes"} />
          <input onClick={noClick} className={ComplainCategoriesCss.no} type={"submit"} value={"No"} />
        </div>
      </div>
    </>
  )
}