import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import PopUpLocation from './PopUpLocation'
import { GetDetailsOrderItems, GetSingleOrder, InformationMessage, UpdateOrderData} from '../../api/SalesApi'
import { GetSingleCustomer, GetSingleCustomerOrderAddress } from '../../api/CustomerApi'
import { SaveSummeryData } from '../../api/OrderInformationApi'
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import selesDeViewCss from './salesCss/SalesDetailView.module.css'
const SalesDetailView = () => {
    const userInfo = useSelector(selectUserInfo)
    const myParams = useParams()
    const [tableData, setTableData] = useState()
    const [orderData, setOrderData] = useState()
    const [popAddress, setPopAddress] = useState(false)
    const [conShow, setConShow] = useState(false)
   
    function onPrintInvoice() {
        let okdata = {}
        okdata = { ...okdata, tableData, ...orderData }
        localStorage.setItem('invoiceData', JSON.stringify(okdata))
    }
    function PrintChallan(params) {

    }

    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getAllData() {
        let myData = await GetSingleOrder(myParams.orderid)
        
        let myCustomer = await GetSingleCustomer(myData[0]?.userID)
        let userAddress = await GetSingleCustomerOrderAddress(myData[0]?.user_address_id)
        let getSSLdata = []
        let finalData = {
            ...myData[0],
            order_created: myData[0].created_date,
            ...userAddress[0],
            ...myCustomer[0],
            ...getSSLdata[0],
            deliveryCharge:myData[0].deliveryCharge,
            id:myData[0].id
        }
        // console.log(finalData)

        setOrderData(finalData)
        let morderItems = await GetDetailsOrderItems({id:myParams.orderid} )
        organizeData(morderItems)
    }


    function organizeData(myData) {
        let finalArr = []
        let uniqueElements = myData?.slice()
        for (let index = 0; index < myData.length; index++) {
            const element = myData[index];
            // console.log(element)
            let tempArr = []
            for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                const melement = uniqueElements[mindex];
                if (melement.product_id === element.product_id) {
                    tempArr.push(melement)
                }
            }

            if (tempArr.length > 0) {
                tempArr.forEach(element => {
                    uniqueElements.forEach((melement, mindex) => {
                        if (melement.id === element.id) {
                            uniqueElements.splice(mindex, 1)
                        }
                    });
                });

                finalArr.push(tempArr)
            }

        }
        setTableData(finalArr)
    }

    useEffect(() => {
        getAllData()
    }, [myParams.orderid])

    function getQTotal(item) {
        let total = 0
        item?.forEach(element => {
            total += element.quantity-parseInt(element.rquantity)
        });

        return total;
    }
    const [extainfo, setExtrainfo] = useState({})
    function getAllTotal() {
        let total = 0
        let saleTotal = 0
        tableData?.forEach(item => {
            item.forEach(element => {
                total += (element.quantity * element.orPrice) - element.rtotal
                saleTotal += (element.quantity * element.price) - element.rtotal
            });
        });
       let discount = (total - saleTotal) + (total*orderData?.discountPercent/100)
        setExtrainfo({total, saleTotal, discount})
    }
    useEffect(()=>{
        getAllTotal()
    }, [tableData])

    function totalAmmount(item) {  
        let quantity = getQTotal(item) 
        let total = 0
        total += item[0].orPrice
        return total * quantity;
    }
    function onDeclineClick() {
          
      setConShow(true)
      document.body.style.overflow = 'hidden'
      }
      const myNavigate = useNavigate()
      async function clickYes() {
        await UpdateOrderData(myParams.orderid, {declined:1, confirmed:0, packaging:0})
          //await UpdateItemExpress('sales', {declined:1}, myParams.orderid)
          let summeryData = {
            ProssesedBy: userInfo.id,
            sale_id: myParams.orderid,
            created_date: new Date().getTime(),
            activity:'Order Declined'
          }
          await SaveSummeryData(summeryData)
        // await SaveDataExpress('sales_summery', { ...summeryData })
          let myoredermessage =  `Dear Sir, Your order -${myParams.orderid} has been canceled.%0aThank You for shopping with us.%0aAnayase`
          try {
              let mPhone = orderData.phone
              if (orderData.Phone) {
                  mPhone = orderData.Phone
              }
            await  InformationMessage({phone:`88${mPhone}`, message:myoredermessage})
          } catch (error) {
              
          }
          setConShow(false)
        myNavigate('/admin/sales/decline_orders')
        document.body.style.overflow = 'auto'
      }
  
      function clickNo() {
         setConShow(false)
         document.body.style.overflow = 'auto'
      }
      function getVarianceList(item) {
        let variations = ''
            item.forEach(element => {
                variations += element.Variance_name ?  element.Variance_name+', ' : ''
            });
            return variations
      }
      function getAgeList(item) {
        let age = ''
            item.forEach(element => {
                age += element.attr_value_name ?  element.attr_value_name+', ': ''
            });
            return age
      }

    const TableItem = tableData?.map((item, index) => {
        return <MyTable key={index} item={item[0].mainName ? item[0]?.mainName : item[0].name}
                variance={getVarianceList(item)}
                age={getAgeList(item)}
            price={item[0]?.orPrice} qty={getQTotal(item)} returned={getQTotal(item) <= 0 ? 'returned' : ''} tammount={totalAmmount(item)} />
    })
    return (
        <>
            {conShow ? <ConfirmAlert alertText='Do you want to decline this order?' yesClick={clickYes} noClick={clickNo} /> : ""}
            
            <div className={selesDeViewCss.border}>
                <section className={selesDeViewCss.salesMain}>
                    <div className={selesDeViewCss.innerSale}>
                        
                        {popAddress ?
                            <div className={selesDeViewCss.popupLocation}>
                                <PopUpLocation setPopAddress={setPopAddress} orderData={orderData} />
                            </div>
                            : ""}
                        <div className={selesDeViewCss.gobackSec}>
                            <h2 className={selesDeViewCss.saleHeading}>Order History Details</h2>
                            <NavLink to={'/admin/sales/sale_history'}><button>Go Back</button></NavLink>
                        </div>
                        <div className={selesDeViewCss.orderdetaislInner}>
                            <h3>Invoice #{orderData?.id}</h3>
                            <div className={selesDeViewCss.customerSection}>
                           
                                <div className={selesDeViewCss.customerDetails}>
                                    <p className={selesDeViewCss.highlighter}>Customer Details</p>
                                    <p><strong>Customer Name</strong> : {orderData?.name}</p>
                                    <p><strong>Phone</strong> : {orderData?.Phone}</p>
                                    <p><strong>Division</strong> :{orderData?.Divition}</p>
                                    <p><strong>District</strong> : {orderData?.District}</p>
                                    <p><strong>Area</strong> : {orderData?.Area}</p>
                                    <div className={selesDeViewCss.addressSec}>
                                        <p><strong>Address</strong> : {orderData?.Address}</p>
                                        
                                    </div>
                                </div>
                                <div className={selesDeViewCss.orderDetails}>
                                    <p className={selesDeViewCss.highlighter}>Order Details</p>
                                    <p>Order Date : {convertDate(orderData?.order_created)} </p>
                                    <p>Payment Type : {orderData?.paymentType}</p>
                                </div>
                            </div>
                            <div className={selesDeViewCss.customerSection}>
                                <div className={selesDeViewCss.customerDetails}>
                                    <p className={selesDeViewCss.highlighter}>Confirmation Details</p>
                                    <p><strong>Note : </strong> : {orderData?.approval_note}</p>
                                    <p><strong>Delivery Priority : </strong> :{orderData?.priority}</p>
                                    <p><strong>Delivery Date : </strong> : {orderData?.delivery_date}</p>
                                    <p><strong>Special Instruction's : </strong> : {orderData?.special_instruction}</p>
                                   
                                </div>
                            </div>
                            <div className={`${selesDeViewCss.tableSection} ${selesDeViewCss.detailstableSection}`}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Item</th>
                                            <th>Variance</th>
                                            <th>age</th>
                                            <th>Price</th>
                                            {/* <th>Variations</th> */}
                                            <th>Quantity</th>
                                            <th>Total Ammount</th>
                                        </tr>
                                        {TableItem}
                                    </tbody>
                                </table>
                                <div className={selesDeViewCss.tableDetails}>
                                    <p>Total Ammount : {extainfo.total?.toFixed(2)} tk</p>
                                    <p>Discount :  {extainfo.discount?.toFixed(2)}tk</p> 
                                    <p>Delivery Charge : {orderData?.deliveryCharge.toFixed(2)} tk</p>
                                    <p>Grand Total : {(extainfo.total-extainfo.discount+orderData?.deliveryCharge).toFixed(2)} tk</p>
                                    <p>Collected : {orderData?.totalCollection || 0}</p>
                                </div>
                                <div className={selesDeViewCss.detailsWrapper}>
                                    <div className={`${selesDeViewCss.detailInput} ${selesDeViewCss.salesDetail}`}>
                                        <div className={selesDeViewCss.ButtonGroup}>
                                            {/* <ButtonInput myValue={"Print Invoice"} mclass={'approvedetail'} onClickevent={onPrintInvoice} /> */}
                                            <Link to={`/admin/sales/salesdetails/${orderData?.id}/invoice`} target='_blank'  rel="noopener noreferrer" onClick={onPrintInvoice}>Print Invoice</Link>
                                            {/* <ButtonInput myValue={"Print Challan Copy"} mclass={'printDouble'} onClickevent={PrintChallan} /> */}
                                            <button>Print Chalan Copy</button>
                                            {/* {orderData?.packaging ? '' : <ButtonInput myValue={"Decline"} mclass={'declinedetail'} onClickevent={onDeclineClick} />} */}
                                            {orderData?.packaging ? '' : <button onClick={onDeclineClick}>Decline</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </section>
            </div>
        </>
    )
}

export default SalesDetailView


const ConfirmAlert = (props) => {
    const { alertText, yesClick, noClick, delID } = props

    return (
        <>
            <div className={selesDeViewCss.alert_wrapper}>
                <div className={selesDeViewCss.conAlert}>
                <h2 className={selesDeViewCss.caption}>{alertText}</h2>
                <div className={selesDeViewCss.buttonsec}>
                    <input sid={delID} onClick={yesClick} className={selesDeViewCss.yes} type={"submit"} value={"Yes"} />
                    <input onClick={noClick} className={selesDeViewCss.no} type={"submit"} value={"No"} />
                </div>
                </div>

            </div>
        </>
    )
}



const MyTable = ({ item, variance, age,  price, qty, tammount, returned }) => {

    return (
        <>
            <tr>
                <td className={selesDeViewCss.foreditVariance}>{item}</td>
                <td >{variance}</td>
                <td >{age}</td>
                <td >{price}</td>
                <td>{qty}  {returned}</td>
                <td>{tammount}</td>
            </tr>
        </>
    )
}