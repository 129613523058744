import { faEdit, faEye, faPrint} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { GetDatafromDatabase, GetDatafromDatabasewithOrder } from '../../connector/DatabaseCheck'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import { Link } from "react-router-dom";
import PaymentHistoryCss from './Css/PaymentHistory.module.css'

const PaymentHistory = () => {
    const [supplierList, setSupplierList] = useState()
    const [myAllData, setMyAllData] = useState()
    const [tableData, setTableData] = useState()
    const [searchData, setSearchData] = useState()

    function onSupplierChange(e) {
       // console.log(e.target.value)
        autoSetData(e.target.value)
        setSearchData({...searchData, supplierID:e.target.value})
    }
    function getSearchData(params) {
        let myNewArr = []
        myAllData.map((item)=>{
            if (item.supplierInfo?.supplierID===searchData.supplierID) {
                myNewArr.push(item)
            }
        })

        setTableData(myNewArr)
        
    }
    function autoSetData(supplierid) {
        let myNewArr = []
       // console.log(supplierid)
       if (!supplierid) {
        myAllData.map((item)=>{
            myNewArr.push(item)
        })
       }else{
        myAllData.map((item)=>{
            if (item.supplierInfo?.supplierID===supplierid) {
                myNewArr.push(item)
            }
        })
       }

        setTableData(myNewArr)
    }
    function checkClick(params) {
        
    }
    function getTotalPayment() {
        let total = 0
        tableData?.map((item)=>{
            total += item.paidAmmount
        })
        return total
    }
    async function getSupplier() {
        let supplierData = await GetDatafromDatabase('Supplier', 'supplierID')
        // console.log(supplierData)
        setSupplierList(supplierData)
        let allData = await GetDatafromDatabasewithOrder('Payment', 'createdDate', 'desc')
        setTableData(allData)
        setMyAllData(allData)
    }
    useEffect(() => {
        getSupplier()
    }, [])

    const tableIconStyle = {
        margin: '0',
        padding: '0',
        margin: '0 3px 0 3px',
        fontSize: '18px',
        cursor: 'pointer'
      }
  return (
    <>
     <section className={PaymentHistoryCss.heading}>
                <div className={PaymentHistoryCss.inner_headign}>
                    <h2 className={PaymentHistoryCss.headign_h2}>Payment History</h2>
                    <div className={PaymentHistoryCss.input_section}>
                        <div className={PaymentHistoryCss.common}>
                            <label>Start Date</label>
                            <input type='datetime_local' />
                        </div>
                        <div className={PaymentHistoryCss.common}>
                            <label>End Date</label>
                            <input type='datetime_local'/>
                        </div>
                        <div className={PaymentHistoryCss.common}>
                            <select onChange={onSupplierChange}>
                                <option value=''>Select Supplier</option>
                                {supplierList?.map((item, index) => {
                                    return <option key={index} value={item.supplierID}>{item.companyaName}</option>
                                })}
                            </select>
                        </div>
                        <div className={PaymentHistoryCss.common}>
                            <button onClick={getSearchData}>Search</button>
                        </div>
                        <div className={PaymentHistoryCss.common}>
                            <button className={PaymentHistoryCss.bg_red} onClick={checkClick}>Reset</button>
                        </div>
                    </div>
                   <div className={PaymentHistoryCss.tableSection}>
                    <h3>Total Payment : <span className={PaymentHistoryCss.color_red}>{getTotalPayment()} TK</span></h3>
                        <table>
                            <tbody>
                                <tr>
                                    <th>PV No.</th>
                                    <th>PO No.</th>
                                    <th>Date & Time</th>
                                    <th>Supplier Name</th>
                                    <th>Payment Ammount</th>
                                    <th>Payment Method</th>
                                    <th>Process By</th>
                                    <th>Action</th>
                                </tr>
                                {tableData?.map((item, index)=>{
                                   return <tr key={index}>
                                            <td>{item.paymentVoucher}</td>
                                            <td>{item.PoNo}</td>
                                            <td>{LocalTimeStampToDate(item.createdDate?.seconds)}</td>
                                            <td>{item.supplierInfo?.companyaName}</td>
                                            <td>{item.paidAmmount}</td>
                                            <td>{item.paymentMethod}</td>
                                            <td>{item.processBy}</td>
                                            <td >
                                                <Link to={'/admin/accounts/payment-history/edit-voucher'} ><FontAwesomeIcon style={{...tableIconStyle, color:'green'}} icon={faEdit}/></Link>
                                                <Link to={'/admin/accounts/payment-history/print-voucher'} onClick={()=>{window.localStorage.setItem('vaocherData', JSON.stringify(item))}}><FontAwesomeIcon  style={{...tableIconStyle, color:'#A91079'}} icon={faPrint}/></Link>
                                            </td>
                                         </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
    </>
  )
}

export default PaymentHistory