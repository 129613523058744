import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import AddNewSupplier from './AddNewSupplier'
import SupplierList from './SupplierList'
import SupplierMainCss from './css/SupplierMain.module.css'

const SupplierMain = () => {
    const myParams = useParams()
   
    return (
        <> 
            <div className={SupplierMainCss.menupart}>
                <ul className={SupplierMainCss.menuitems}>
                    <NavLink to={"/admin/supplier/supplierlist"}>
                        <li >Supplier List</li>
                    </NavLink>
                    <NavLink to={"/admin/supplier/addsupplier"}>
                        <li >Add new Supplier</li>
                    </NavLink>
                    <NavLink to={"/admin/supplier/suppliertrash"}>
                        <li >Trash List</li>
                    </NavLink>
                </ul>
            </div>
            <div>
                {myParams.msupplier === undefined ? <SupplierList data={"Supplier List"}/> : ""}
                {myParams.msupplier === 'supplierlist' ? <SupplierList data={"Supplier List"}/> : ""}
                {myParams.msupplier === 'addsupplier' ? <AddNewSupplier data={"Add new Supplier"}/> : ""}
                {myParams.msupplier === 'suppliertrash' ? <ComingSoon data={"Supplier Trash List"}/> : ""}
            </div>
        </>
    )
}

export default SupplierMain


const ComingSoon =({data})=>{
    return (
        <h1>Coming Soon {data}</h1>
    )
}