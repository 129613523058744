import React, { useEffect, useState } from 'react'
import Headerimage from '../../assets/Headerimage.jpg'
import QRcode from '../../assets/anayase_qrcode.png'
import { GenarateText } from '../connector/InWord'
import {RearrangeDateThree} from '../MyFunctions/MyFunctions'
import InvoiceCss from './salesCss/Invoice.module.css'

const Invoice = () => {
    const [invoiceState, setInvoiceState] = useState()
    function PrintPaper() {
         window.print()
    }
    function convertDateTime(time) {
        const dateInMillis = time 
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myTime
    }
    const [extraInfo, setExtrainfo] = useState({})

  async function getData() {
        let data =  JSON.parse(localStorage.getItem('invoiceData'))
        setInvoiceState(data)
        // console.log(data?.tableData);
        getGrandTotal(data?.tableData)
        
    }
    useEffect(()=>{
        getGrandTotal(invoiceState?.tableData)
    },[invoiceState?.tableData])

    function getGrandTotal(data) {
        // console.log(data)
         let total = 0
         let saleTotal = 0
         let myData = []
         data?.forEach(element => {
             element?.forEach(melement => {
                 myData.push(melement)
             });
         });
 
         myData.forEach(element => {
             total += (element.orPrice * element.quantity)-(element.rtotal  || 0)
             saleTotal += (element.price * element.quantity)- (element.rtotal  || 0)
         });
         let percentDis = invoiceState?.discountPercent ? (total*invoiceState?.discountPercent/100):0
        //  console.log(percentDis);
         let discount = total-saleTotal + percentDis
         setExtrainfo({total, saleTotal, discount})
     }


    useEffect(()=>{
        getData()
    },[])

    function getQTotal(item) {
        let total = 0
        item?.forEach(element => {
            total += element.quantity-element.rquantity
        });

        return total;
    }
    function totalAmmount(item) {
        let quantity = getQTotal(item)
        let total = 0
        total += item[0].orPrice
        return total * quantity;
    }   
    


    const tableItem = invoiceState?.tableData?.filter((fitem)=>{
        return getQTotal(fitem)>0 ? fitem :''
    })
        .map((item, index) => {
            return <InvoiceTable sl={index + 1} description={item[0].name}
                varName={item[0].hasVriance ? <>
                    {item.map((mainItem) => {
                        return <>
                            {mainItem.Variance_name} : {mainItem.quantity} pcs
                            {mainItem.attr_value_name ? ` (${mainItem.attr_value_name})`:''}
                            <br />
                        </>
                    })}
                </> : ''}

                quantity={getQTotal(item)} uniteprice={item[0].orPrice} total={totalAmmount(item)} />
        })
    
    
  return (
    <>
        <div className={InvoiceCss.invoicemain}>
        <button className={InvoiceCss.printBtn} onClick={PrintPaper}>Print</button>

        <section className={InvoiceCss.invoiceSection}>
        <div className={InvoiceCss.container} id="printInvoice">
            <div className={InvoiceCss.topSection}>
                <img src={Headerimage} alt=""/>
            </div>

            <div className={InvoiceCss.main}>
                <h3>INVOICE / BILL</h3>
                <div className={InvoiceCss.details}>

                    <div className={InvoiceCss.client}>
                        <div className={InvoiceCss.info}>
                            <span className={InvoiceCss.moreinfo}>Invoice Number : </span>#{invoiceState?.id}
                        </div>
                        <div className={InvoiceCss.info}>
                            <span className={InvoiceCss.moreinfo}>Customar Name : </span>{invoiceState?.name}
                        </div>
                        <div className={InvoiceCss.info}>
                            <span className={InvoiceCss.moreinfo}>Phone No : </span>{invoiceState?.Phone}
                        </div>
                        <div className={InvoiceCss.info}>
                            <span className={InvoiceCss.moreinfo}>Address : </span> { invoiceState?.Address ?invoiceState?.Address+", "+invoiceState?.Area+", "+invoiceState?.District+", "+invoiceState?.Divition+"," : ''}
                        </div>
                    </div>
                    <div className={InvoiceCss.invoice}>
                        <div className={InvoiceCss.info}>
                            <span className={`${InvoiceCss.moreinfo} ${InvoiceCss.invoiceMoreinfo}`}>Date : </span>{RearrangeDateThree(new Date(invoiceState?.order_created).toLocaleDateString())}
                        </div>
                        <div className={InvoiceCss.info}>
                            <span className={`${InvoiceCss.moreinfo} ${InvoiceCss.invoiceMoreinfo}`}>Time : </span> {convertDateTime(invoiceState?.order_created)}
                        </div>
                        <div className={InvoiceCss.info}>
                            <span className={ `${InvoiceCss.moreinfo} ${InvoiceCss.invoiceMoreinfo}`}>Sold By : </span>Anayase.com
                        </div>
                    </div>
                </div>
                <div className={InvoiceCss.table}>
                    <table>
                        <thead>
                            <tr>
                                <th className={InvoiceCss.no}>SI No.</th>
                                <th style={{paddingLeft:'10px'}}>Item Name</th>
                                <th className={InvoiceCss.unit}>Quantity</th>
                                <th className={InvoiceCss.unit_price}>Unit Price</th>
                                <th className={InvoiceCss.total}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                           {tableItem}
                        </tbody>
                        <tbody className={InvoiceCss.tfoot}>
                            <tr>
                                <td colSpan="3" className={InvoiceCss.inWord}></td>
                                <td>Sub Total</td>
                                <td>{extraInfo.total} ৳</td>
                            </tr>
                            <tr>
                                <td colSpan="3"></td>
                                <td style={{width: '110px'}}>Discount(-)</td>
                                <td> {extraInfo.discount?.toFixed()}৳</td>
                            </tr>
                            <tr>
                                <td colSpan="3"></td>
                                <td>Delivery Charge</td>
                                <td>{invoiceState?.deliveryCharge} ৳</td>
                            </tr>
                            <tr>
                                <td colSpan="5">
                                    <span></span>
                                </td>
                            </tr>
                            <tr className={InvoiceCss.gran_total}>
                                <td colSpan="3"></td>
                                <td className={InvoiceCss.gran_total_text}>Grand Total</td>
                                <td>{(extraInfo.total-extraInfo.discount+invoiceState?.deliveryCharge).toFixed(2)} ৳</td>
                            </tr>
                            <tr className={InvoiceCss.pre_Paid}>
                                <td colSpan=" 3"></td>
                                <td className={InvoiceCss.pre_Paid_text}>Pre Paid(-)</td>
                                <td>{(invoiceState?.totalCollection) || 0} ৳</td>
                            </tr>
                            <tr className={InvoiceCss.amount}>
                                <td colSpan="3"></td>
                                <td className={InvoiceCss.amount_text}>Amount to Pay</td>
                                <td>{(extraInfo.total-extraInfo.discount+invoiceState?.deliveryCharge).toFixed(2)} ৳</td>
                            </tr>
                            <tr>
                                <td colSpan="3"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={InvoiceCss.notice1}>
                    <div className={InvoiceCss.notice1_1}>
                        <p>
                            <span className={InvoiceCss.inword}>In Word : </span>
                            {GenarateText(Math.round(extraInfo.total-extraInfo.discount+invoiceState?.deliveryCharge))}
                        </p>
                    </div>
                </div> 
                <div className={InvoiceCss.notices}>
                    <div>
                        <b className={InvoiceCss.notetext}>NOTE : </b>
                    </div>
                    <div className={InvoiceCss.notice}>{invoiceState?.special_instruction}</div>

                </div>
                <img src={QRcode} alt=""/>
                <div className={InvoiceCss.seal_signiture}>
                    <div className={`${InvoiceCss.singniture} ${InvoiceCss.singniture1}`}>
                        <span>Customer Signature</span>
                    </div>
                    <div className={`${InvoiceCss.singniture} ${InvoiceCss.singniture2}`}>
                        <span>Authority Signature</span>
                    </div>
                </div>
                <div className={`${InvoiceCss.seal_signiture} ${InvoiceCss.seal_signiture1}`}>
                    <span className={InvoiceCss.thankyou_msg}>Thank you for being with <strong style={{fontSize:'18px'}}>anayase.com</strong></span>
                </div>
            </div>
        </div>

    </section>

        </div>
    
    </>
  )
}

export default Invoice

const InvoiceTable = ({sl, description, varName, ageName, quantity, uniteprice, total}) => {

    return (
        <>
            <tr>
                <td className={InvoiceCss.no}>{sl}</td>
                <td className={InvoiceCss.desc}><strong>{description }</strong> <span style={{color:'#424242', display:'flex'}}>{varName} {ageName}</span>
                </td>
                <td className={InvoiceCss.unit}>{quantity}</td>
                <td className={InvoiceCss.unit_price}>{uniteprice}</td>
                <td className={InvoiceCss.total}>{total}</td>
            </tr>
        </>
    )
}