import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { ApiLink, DeleteImageExpress, GetDataPassQuery, UpdateItemExpress, UploadImageAndUpdateDataExpress } from '../../api/ExpressFunctions'
import { GetDeliveryAgentList } from '../../api/DeliveryManagementApi'
import DeliveryAgentListCss from './css/DeliveryAgentList.module.css'

const DeliveryAgentList = () => {
  const [agentList, setAgentList] = useState()
  const [editUserData, setEditUserData] = useState()
  const [editPopShow, setEditpopShow] = useState(false)
  const [profileData, setProfileData] = useState()

  function onEditClick(e, item) {
    setEditUserData(item)
    setEditpopShow(true)
  }
  function onViewClick(e, item) {
    setProfileData(item)
  }

  async function getAllUser() {
    let myuserData = await GetDeliveryAgentList()
    setAgentList(myuserData)
  }

  useEffect(() => {
    getAllUser()
  }, [])
  const TableItem = agentList?.map((item, index) => {
    return <MyTable key={index} sl={index + 1} name={item.fullName} userName={item.userName} action={<div className={DeliveryAgentListCss.userIcon}><FontAwesomeIcon icon={faEdit} onClick={(e) => { onEditClick(e, item) }} /> <FontAwesomeIcon icon={faEye} onClick={(e) => { onViewClick(e, item) }} /></div>} />
  })

  return (
    <>
      <section className={`${DeliveryAgentListCss.salesMain} ${DeliveryAgentListCss.manageuserMain}`}>
        {/* {spinnerShow ? <Spinner/>:""} */}
        {profileData ? <ProfileView profileData={profileData} setProfileData={setProfileData} /> : ""}
        {editPopShow ? <EditPop setShowEditPop={setEditpopShow} editUserData={editUserData} /> : ""}
        <div className={DeliveryAgentListCss.innerSale}>
          <h2 className={DeliveryAgentListCss.saleHeading}>Delivery Agent List</h2>
          <div className={DeliveryAgentListCss.tableSection}>
            <table>
              <tbody>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>User Name</th>
                  <th>Action</th>
                </tr>
                {TableItem}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  )
}

export default DeliveryAgentList

const MyTable = ({ sl, name, userName, rule, action }) => {

  return <>
    <tr>
      <td>{sl}</td>
      <td>{name}</td>
      <td>{userName}</td>
      {/* <td>{rule}</td> */}
      <td>{action}</td>
    </tr>
  </>
}

const EditPop = ({ editUserData, setShowEditPop }) => {
  const [meditUserData, msetEditUserData] = useState(editUserData)
  function onDataChange(e) {
    let name = e.target.name
    let value = e.target.value
    /// console.log(name)
    if (e.target.type === 'file') {
      msetEditUserData({ ...meditUserData, [name]: e.target.files[0], fakeUrl: genarateFakeUrl(e.target.files[0]) })
    } else {
      msetEditUserData({ ...meditUserData, [name]: value })
    }
  }

  let myDate = new Date()
  async function onUpdateClick() {
    let myTime = myDate.getTime()
    let userImageName = ""
    if (meditUserData?.userImageUpdated) {
      userImageName = myTime + "__" + meditUserData?.userImageUpdated?.name
    } else {
      userImageName = meditUserData.userImageName
    }

    let finalData = {
      userName: meditUserData?.userName.toLowerCase(),
      fullName: meditUserData?.fullName,
      email: meditUserData?.email,
      phone: meditUserData?.phone,
      userImageName: userImageName,
    }

    if (meditUserData?.userImageUpdated) {
      await UpdateItemExpress('delivery_management', { ...finalData }, meditUserData?.id)
      await UploadImageAndUpdateDataExpress(meditUserData.userImageUpdated, 'delivery_management', 'userImageName', meditUserData?.id, 'userimages')
      try {
        DeleteImageExpress('userimages', meditUserData?.userImageName)
      } catch (error) {

      }
      setShowEditPop(false)
    } else {
      await UpdateItemExpress('delivery_management', { ...finalData }, meditUserData?.id)
      setShowEditPop(false)
    }
    // console.log(meditUserData)
  }
  function genarateFakeUrl(image) {
    let myurl = URL.createObjectURL(image)
    return myurl;
  }
  function ClosePop() {
    setShowEditPop(false)
  }
  return (
    <div className={DeliveryAgentListCss.EditUserPop_wrapper}>
      <div className={DeliveryAgentListCss.EditUserPop}>
        <img name='userImage' src={!meditUserData?.fakeUrl ? ApiLink + 'userimages/' + meditUserData.userImageName : meditUserData?.fakeUrl} alt='user image here' />
        <div className={DeliveryAgentListCss.common}>
          <label>UserName</label>
          <input name='notchangeable' type='text' placeholder="user name" defaultValue={meditUserData?.userName} disabled={true} />
        </div>
        <div className={DeliveryAgentListCss.common}>
          <label>Full Name</label>
          <input name='fullName' type='text' placeholder="Full Name" defaultValue={meditUserData?.fullName} onChange={onDataChange} />
        </div>
        <div className={DeliveryAgentListCss.common}>
          <label>Email</label>
          <input name='email' type='text' placeholder="Email" defaultValue={meditUserData?.email} onChange={onDataChange} />
        </div>
        <div className={DeliveryAgentListCss.common}>
          <label>Phone</label>
          <input name='phone' type='text' placeholder="Phone" defaultValue={meditUserData?.phone} onChange={onDataChange} />
        </div>
        <div className={DeliveryAgentListCss.common}>
          <label>About</label>
          <input name='about' type='text' placeholder="about" defaultValue={meditUserData?.about} onChange={onDataChange} />
        </div>
        <div className={DeliveryAgentListCss.common}>
          <label>Upload Image</label>
          <input name='userImageUpdated' type='file' onChange={onDataChange} />
        </div>
        <div className={`${DeliveryAgentListCss.common} ${DeliveryAgentListCss.buttosection}`}>
          <button onClick={onUpdateClick}>Update Info</button>
          <button onClick={ClosePop}>Close</button>
        </div>

      </div>
    </div>
  )
}

const ProfileView = ({ profileData, setProfileData }) => {

  function CloseInfo() {
    setProfileData()
  }
  const zindex = {
    zIndex: 1000
  }

  return (
    <div className={DeliveryAgentListCss.EditUserPop_wrapper}>
      <div style={zindex} className={`${DeliveryAgentListCss.EditUserPop} ${DeliveryAgentListCss.profileView}`}>
        <img name='userImage' src={ApiLink + 'userimages/' + profileData?.userImageName} alt='user image here' />
        <div className={DeliveryAgentListCss.common}>
          <p><strong>Name : </strong>{profileData?.fullName}</p>
          <p><strong>Phone : </strong>{profileData?.phone}</p>
          <p><strong>Email : </strong>{profileData?.email}</p>
          <p><strong>About : </strong>{profileData?.about}</p>
          <p><strong>Delivery Ammount : </strong>need to implement</p>
        </div>

        <div className={DeliveryAgentListCss.close_btn}>

          <button onClick={CloseInfo}>Close</button>
        </div>
      </div>
    </div>
  )
}