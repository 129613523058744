import { faCircleInfo, faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllUser, selectActiveUserList, selectBackUserStatus, selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import { GetUserImage, UpdateBackUserData } from '../../api/BackendUserApi'
import DummyImage from '../../assets/dummyuser.png'
import ManageUserCss from './css/ManageUser.module.css'
const ManageUser = () => {
  const dispatch = useDispatch()
  const userData = useSelector(selectActiveUserList)
  const userStatus = useSelector(selectBackUserStatus)
  const [editUserData, setEditUserData] = useState()
  const [showEditPop, setShowEditPop] = useState(false)
  const [alertMessage, setAlertMessage] = useState()
  const [profileData, setProfileData] = useState()
  const currentUser = useSelector(selectUserInfo)

  async function onDeleteClick(e, item) {
    userData.forEach(async (element) => {
      if (element.id === item.id) {
        if (item.id === currentUser.id) {
          setAlertMessage("You Cannot Delete Your Own ID")
        } else {
          if (item.userrole < currentUser.rule) {
            setAlertMessage("You Cannot Delete This user")
          } else {
            if (window.confirm("Do You Want To Delete this user")) {
              await UpdateBackUserData(item.id, { active: 0 })
              dispatch(fetchAllUser())

            }
          }
        }
      }
    });
  }

  function onEditClick(e, item) {

    if (currentUser.rule === 1) {
      setEditUserData(item)
      setShowEditPop(true)
    } else if (currentUser.rule > 1 && currentUser.id !== item.id) {
      setAlertMessage("You are not Permited to Edit")
    } else {
      setEditUserData({ ...item, self: true })
      setShowEditPop(true)
    }
    // setEditUserData(item)
    // setShowEditPop(true)
  }
  function onInfoClick(e, item) {
    // console.log(item)
    setProfileData(item)
  }
  const EditPop = () => {
    const [meditUserData, msetEditUserData] = useState(editUserData)
    function onDataChange(e) {
      let name = e.target.name
      let value = e.target.value
      /// console.log(name)
      if (e.target.type === 'file') {
        msetEditUserData({ ...meditUserData, [name]: e.target.files[0], fakeUrl: genarateFakeUrl(e.target.files[0]) })
      } else {
        msetEditUserData({ ...meditUserData, [name]: value })
      }
    }
    function onOptionChange(e) {
      let name = e.target.name
      let value = e.target.value
      let ruleNo = 0;

      if (value === 'Admin') {
        ruleNo = 1
      }
      else if (value === 'Editor') {
        ruleNo = 2
      }
      else if (value === 'Moderator') {
        ruleNo = 3
      }
      msetEditUserData({ ...meditUserData, [name]: ruleNo })
    }
    async function onUpdateClick() {
      let finalData = {
        fullName: meditUserData?.fullName,
        email: meditUserData?.email,
        phone: meditUserData?.phone,
        userbio: meditUserData?.userbio,
        userImageName: meditUserData?.userImageName,
        userrole: meditUserData?.userrole,
      }
      await UpdateBackUserData(meditUserData.id, finalData, meditUserData?.userImageUpdated)
      setShowEditPop(false)
      dispatch(fetchAllUser())
    }

    function genarateFakeUrl(image) {
      let myurl = URL.createObjectURL(image)
      return myurl;
    }

    function ClosePop() {
      setShowEditPop(false)
    }
    return (
      <div className={ManageUserCss.EditUserPop_wrapper}>
        <div className={ManageUserCss.EditUserPop}>
          <div style={{ display: 'flex', gap: '30px' }}>
            <AvatarComp name={meditUserData?.userImageName} />
            {meditUserData?.fakeUrl && <img name='userImage' src={meditUserData?.fakeUrl} alt='user image here' />}
          </div>
          <div className={ManageUserCss.common}>
            <label>UserName</label>
            <input name='notchangeable' type='text' placeholder="user name" defaultValue={meditUserData?.userName} disabled={true} />
          </div>
          <div className={ManageUserCss.common}>
            <label>Full Name</label>
            <input name='fullName' type='text' placeholder="Full Name" defaultValue={meditUserData?.fullName} onChange={onDataChange} />
          </div>
          <div className={ManageUserCss.common}>
            <label>Email</label>
            <input name='email' type='text' placeholder="Email" defaultValue={meditUserData?.email} onChange={onDataChange} />
          </div>
          <div className={ManageUserCss.common}>
            <label>Phone</label>
            <input name='phone' type='text' placeholder="Phone" defaultValue={meditUserData?.phone} onChange={onDataChange} />
          </div>
          <div className={ManageUserCss.common}>
            <label>Bio</label>
            <input name='userbio' type='text' placeholder="Bio" defaultValue={meditUserData?.userbio} onChange={onDataChange} />
          </div>
          <div className={ManageUserCss.common}>
            <label>Upload Image</label>
            <input name='userImageUpdated' type='file' onChange={onDataChange} />
          </div>

          {!meditUserData?.self ? <div className={ManageUserCss.common}>
            <label>Select Rule</label>
            <select name='userrole' onChange={onOptionChange} defaultValue={meditUserData.userrole}>
              <option>Select Type</option>
              <option value={1} >Admin</option>
              <option value={2}>Editor</option>
              <option value={3}>Moderator</option>
            </select>
          </div> : ""}
          <div className={`${ManageUserCss.common} ${ManageUserCss.buttosection}`}>
            <button onClick={onUpdateClick}>Update Info</button>
            <button onClick={ClosePop}>Close</button>
          </div>

        </div>
      </div>
    )
  }

  function userRuleText(number) {
    let text = ""
    if (number === 1) {
      text = "Admin"
    }
    else if (number === 2) {
      text = "Editor"
    }
    else if (number === 3) {
      text = "Moderator"
    }

    return text

  }
  const marginTop = {
    marginTop: '10px'
  }
  useEffect(() => {
    if (userStatus === 'idle') {
      dispatch(fetchAllUser())
    }
  }, [])

  const TableItem = userData?.map((item, index) => {
    return <MyTable key={index} sl={index + 1} name={item.fullName} userName={item.userName} rule={userRuleText(item.userrole)}
      action={<div className={ManageUserCss.userIcon}>
        <FontAwesomeIcon className={ManageUserCss.infobutton} icon={faCircleInfo} onClick={(e) => { onInfoClick(e, item) }} /> <FontAwesomeIcon icon={faEdit} onClick={(e) => { onEditClick(e, item) }} /> <FontAwesomeIcon icon={faTrashCan} onClick={(e) => { onDeleteClick(e, item) }} />
      </div>} />
  })
  return (
    <>
      {alertMessage ? <AlertMessage message={alertMessage} setAlertMessage={setAlertMessage} /> : ""}

      <section style={marginTop} className={`${ManageUserCss.salesMain} ${ManageUserCss.manageuserMain}`}>
        {showEditPop ? <EditPop /> : ""}
        {profileData ? <ProfileView profileData={profileData} setProfileData={setProfileData} /> : ""}
        <div className={ManageUserCss.innerSale}>
          <h2 className={ManageUserCss.saleHeading}>User List</h2>
          <div className={ManageUserCss.tableSection}>
            <table>
              <tbody>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>User Name</th>
                  <th>Rule</th>
                  <th>Action</th>
                </tr>
                {TableItem}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  )
}

export default ManageUser

const MyTable = ({ sl, name, userName, rule, action }) => {

  return <>
    <tr>
      <td>{sl}</td>
      <td>{name}</td>
      <td>{userName}</td>
      <td>{rule}</td>
      <td>{action}</td>
    </tr>
  </>
}

const AlertMessage = ({ message, setAlertMessage }) => {

  function alertClick() {
    setAlertMessage()
  }
  const zindex = {
    zIndex: 1000
  }
  return (
    <div style={zindex} className={ManageUserCss.AlertMessage}>
      <h2>{message}</h2>
      <button onClick={alertClick}>OK</button>
    </div>
  )
}
const ProfileView = ({ profileData, setProfileData }) => {

  function CloseInfo() {
    setProfileData()
  }
  const zindex = {
    zIndex: 1000
  }
  function userRuleText(number) {
    let text = ""
    if (number === 1) {
      text = "Admin"
    }
    else if (number === 2) {
      text = "Editor"
    }
    else if (number === 3) {
      text = "Moderator"
    }

    return text

  }

  return (
    <div className={ManageUserCss.EditUserPop_wrapper}>
      <div style={zindex} className={`${ManageUserCss.EditUserPop} ${ManageUserCss.profileView}`}>
        <AvatarComp name={profileData?.userImageName} />
        <div className={ManageUserCss.common}>
          <p><strong>Name : </strong>{profileData?.fullName}</p>
          <p><strong>Phone : </strong>{profileData?.phone}</p>
          <p><strong>Email : </strong>{profileData?.email}</p>
          <p><strong>Bio : </strong>{profileData?.userbio}</p>
          <p><strong>Rule : </strong>{userRuleText(profileData?.userrole)}</p>
        </div>
        <div className={ManageUserCss.close_btn}>
          <button onClick={CloseInfo}>Close</button>
        </div>
      </div>
    </div>
  )
}

const AvatarComp = ({ name }) => {
  const [image, setImage] = useState()
  async function getLink() {
    if (name) {
      let imageInfo = await GetUserImage({ image_name: name })
      if (imageInfo.imageExist) {
        setImage(imageInfo.url)
      } else {
        setImage(DummyImage)
      }
    } else {
      setImage(DummyImage)
    }
  }
  useEffect(() => {
    getLink()
  }, [])
  return (
    <>
      <img className={ManageUserCss.userImage} src={image} alt='avatar' />
    </>
  )
}