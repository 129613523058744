import React from 'react'
import { NavLink, Route, Routes } from 'react-router-dom'
import ExpenseHead from './ExpenseHead'
import ExpenseGroup from './ExpenseGroup'
import AddNewExpense from './AddNewExpense'
import PendingExpense from './PendingExpense'
import PendingView from './PendingView'
import PendingEditView from './PendingEditView'
import ExpenseHistory from './ExpenseHistory'
import HistoryView from './HistoryView'
import ExpenseDecline from './ExpenseDecline'
import DeclineView from './DeclineView'
import ExpenseMainCss from './css/ExpenseMain.module.css'

const ExpenseMain = () => {
   
    return (
        <> 
            <div className={ExpenseMainCss.menupart}>
                <ul className={ExpenseMainCss.menuitems}>
                    <NavLink to={"/admin/expenses/add_new_expense"}>
                        <li >Expense</li>
                    </NavLink>
                    <NavLink to={"/admin/expenses/pending_expense"}>
                        <li >Pending Expense</li>
                    </NavLink>
                    <NavLink to={"/admin/expenses/expense_history"}>
                        <li >Expense History</li>
                    </NavLink>
                    <NavLink to={"/admin/expenses/declined_expense"}>
                        <li >Declined Expense</li>
                    </NavLink>
                    <NavLink to={"/admin/expenses/expense_head"}>
                        <li >Expense Head</li>
                    </NavLink>
                    <NavLink to={"/admin/expenses/expense_group"}>
                        <li >Expense Group</li>
                    </NavLink>
                </ul>
            </div>
            <div>
                    <Routes>
                        <Route path='/' element={<AddNewExpense data={"Supplier Trash List"}/>}/>
                        <Route path='/add_new_expense' element={<AddNewExpense />}/>
                        <Route path='/pending_expense' element={<PendingExpense />}/>
                        <Route path='/pending_expense/expense_view' element={<PendingView />}/>
                        <Route path='/pending_expense/expense_edit' element={<PendingEditView />}/>
                        <Route path='/expense_history' element={<ExpenseHistory />}/>
                        <Route path='/expense_history/expense_view_history' element={<HistoryView />}/>
                        <Route path='/declined_expense' element={<ExpenseDecline />}/>
                        <Route path='/declined_expense/expense_view_decline' element={<DeclineView />}/>
                        <Route path='/expense_head' element={<ExpenseHead />}/>
                        <Route path='/expense_group' element={<ExpenseGroup />}/>
                    </Routes>
                {/* {myParams.myexpences === undefined ? <AddNewExpense data={"Supplier Trash List"}/> : ""}
                {myParams.myexpences === 'add_new_expense' ? <AddNewExpense data={"Supplier Trash List"}/> : ""}
                {myParams.myexpences === 'pending_expense' && myParams.expenseviewedit===undefined? <PendingExpense /> : ""}
                {myParams.myexpences === 'pending_expense' && myParams.expenseviewedit==='expense_view' ? <PendingView /> : ""}
                {myParams.myexpences === 'pending_expense' && myParams.expenseviewedit==='expense_edit' ? <PendingEditView /> : ""}
                {myParams.myexpences ===  'expense_history' && myParams.expenseviewedit===undefined? <ExpenseHistory /> : ""}
                {myParams.myexpences === 'expense_history' && myParams.expenseviewedit==='expense_view_history' ? <HistoryView /> : ""}
                {myParams.myexpences ===  'declined_expense' && myParams.expenseviewedit===undefined? <ExpenseDecline /> : ""}
                {myParams.myexpences ===  'declined_expense' && myParams.expenseviewedit==='expense_view_decline'? <DeclineView /> : ""}
                {myParams.myexpences === 'expense_head' ? <ExpenseHead /> : ""}
                {myParams.myexpences === 'expense_group' ? <ExpenseGroup /> : ""} */}
            </div>
        </>
    )
}

export default ExpenseMain
