import React from 'react'
import admin_footerCss from './Css/admin_footer.module.css'

const admin_footer = () => {


    function onHomeClick(params) {
       window.open('https://anayase.com/', '_blank')
    }
    return (
        <div className={admin_footerCss.adminfooter}>
            <p>all rights reserved <span className={admin_footerCss.mylink} onClick={onHomeClick}>@ayanase.com</span></p>
        </div>
    )
}

export default admin_footer;