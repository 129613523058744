import React, { useEffect, useRef } from 'react'
import { toast } from 'react-toastify';
import AddNewMenuCss from './css/AddNewMenu.module.css'
const AddNewMenu = ({ mstate, stateValue, item }) => {


    const tabItem = useRef()

    function CheckPreviousData(index) {
        const requiredFields = ['en_sort_title', 'en_long_title', 'bn_sort_title', 'bn_long_title', 
                                'selected_catagory_id', 'selected_brand_id', 'selectedUnit', 'itemImage'];
      
        for (const field of requiredFields) {
          if (!item[field]) {
            toast.warn('Please Fill Required Field')
            mstate(1);
            return; // Exit the function if any required field is missing
          }else{
            mstate(index)
          }
        }
      
        if (stateValue===2 && !item.Selling_price && index===3) {
          mstate(stateValue);
          toast.warn('Please Fill Required Field')
        }else{
            mstate(index);
        }
      }

    function changeUi(e) {
        let data = parseInt(e.target.getAttribute("tab"))
        CheckPreviousData(data)
    }
    function changeView(data) {
        mstate(data)
        let marr = tabItem.current.children
        for (let index = 0; index < marr.length; index++) {
            const element = marr[index];
            element.classList.remove("tabActive")
            let mdata = parseInt(element.getAttribute("tab"));
            if (data === mdata || stateValue === mdata) {
                element.classList.add("tabActive")
            }
        }
    }
    useEffect(() => {
        changeView(stateValue)
    }, [stateValue])

    return (
        <div ref={tabItem} className={AddNewMenuCss.addnewtab}>
            <h4 onClick={changeUi} tab="1" className={AddNewMenuCss.tabActive}>Item Information</h4>
            <h4 onClick={changeUi} tab="2">Item Price</h4>
            <h4 onClick={changeUi} tab="3" >Product Attributes</h4>
        </div>
    )
}

export default AddNewMenu