import React from 'react'
import SettingInputCss from './css/SettingInput.module.css'

const SettingInput = (props) => {
    const { name, labeltext, Itype, placeholder, mclass, changeme, myref, myValue, setedValue, enableDisable, warning } = props;
    return (
        <div className={SettingInputCss.mt_2}>
            <div className={SettingInputCss.mySetting}>
                <div className={SettingInputCss.innercom}>
                    <label className={SettingInputCss.additemlabel}>{labeltext}</label>
                    <input ref={myref} onChange={changeme} className={SettingInputCss.additeminput} type={Itype} name={name} placeholder={placeholder} value={myValue} disabled={enableDisable} />
                    <p className={SettingInputCss.WarningText}> {warning}</p>
                    <p className={SettingInputCss.selectedValue}>{setedValue}</p>
                </div>
            </div>
        </div>


    )
}

export default SettingInput