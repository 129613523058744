import CommonPartsCss from './MainCss/CommonParts.module.css'
import { faAddressCard, faBell, faBuildingColumns, faCalculator, faCircleInfo, faCirclePlus, faClipboardList, faEnvelopesBulk, faGears, faGift, faIdBadge, faIdCardClip, faMountainSun, faPowerOff, faRightFromBracket, faShoppingCart, faTriangleExclamation, faUser, faBars, faAddressBook, faHeartCircleBolt } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from './assets/anayase_logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo, setIsLogedIn, setUserInfo } from './ReduxFiles/Slices/UserSlice';
import { GetUserImage } from './api/BackendUserApi';
import Avatar from  './assets/dummyuser.png'
const CommonParts = () => {
    const userInfo = useSelector(selectUserInfo)
    const myNavigate = useNavigate()
    const myDispatch = useDispatch()
    
    function LogoutClick() {
        window.localStorage.removeItem('userData')
        myNavigate('/')
        myDispatch(setIsLogedIn(false))
        myDispatch(setUserInfo({}))
    }

    return (
        <>
            <div className={`${CommonPartsCss.menuSection}`}>
                <div className={CommonPartsCss.toppart}>
                    <div className={CommonPartsCss.bar}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                    <div className={CommonPartsCss.toppartLogo}>
                        <img src={Logo} alt='logo' />
                    </div>
                    <div className={CommonPartsCss.userPart}>
                        <div className={`${CommonPartsCss.common} ${CommonPartsCss.userinfo}`}>
                            {/* {userInfo?.userImageName ? <img src={API_LINK + 'userimages/' + userInfo?.userImageName} /> : ''} */}
                            <AvatarComp name={userInfo?.userImageName}/>
                            <p >{userInfo?.userName}</p>
                        </div>

                        <div className={`${CommonPartsCss.common} ${CommonPartsCss.logoutpart}`} onClick={LogoutClick}>
                            <FontAwesomeIcon icon={faPowerOff} />
                            <p>Logout</p>
                        </div>
                        <div className={`${CommonPartsCss.common} ${CommonPartsCss.notification}`}>
                            <FontAwesomeIcon icon={faBell} />
                            <p>12</p>
                        </div>
                    </div>
                </div>
                <div className={CommonPartsCss.sidepart} >
                    <div className={CommonPartsCss.sidenav}>

                        <ul >
                            <li ><NavLink to={'/'} ><FontAwesomeIcon icon={faMountainSun} />Dashboard</NavLink></li>
                            <li><NavLink to={'/admin/itemmanagement'} > <FontAwesomeIcon icon={faShoppingCart} />Item Management</NavLink></li>
                            <li><NavLink to={'/admin/sales'} ><FontAwesomeIcon icon={faRightFromBracket} />Sales</NavLink></li>
                            <li><NavLink to={'/admin/sales_offers'} ><FontAwesomeIcon icon={faGift} />Offers</NavLink></li>
                            <li><NavLink to={'/admin/bogo_offers'} ><FontAwesomeIcon icon={faGift} />BOGO</NavLink></li>
                            <li><NavLink to={'/admin/purchase'} ><FontAwesomeIcon icon={faCirclePlus} />Purchase</NavLink></li>
                            <li><NavLink to={'/admin/stockinformation'} ><FontAwesomeIcon icon={faCircleInfo} />Stock Information</NavLink></li>
                            <li><NavLink to={'/admin/report'} ><FontAwesomeIcon icon={faClipboardList} />Report</NavLink></li>
                            <li><NavLink to={'/admin/accounts'} ><FontAwesomeIcon icon={faCalculator} />Accounts</NavLink></li>
                            <li><NavLink to={'/admin/banks'} ><FontAwesomeIcon icon={faBuildingColumns} />Bank Management</NavLink></li>
                            <li><NavLink to={'/admin/expenses'} ><FontAwesomeIcon icon={faGears} />Expense Management</NavLink></li>

                            <li><NavLink to={'/admin/deliveryagent'} ><FontAwesomeIcon icon={faAddressCard} />Delivery Management</NavLink></li>
                            <li><NavLink to={'/admin/manage_user'} ><FontAwesomeIcon icon={faUser} />Manage User</NavLink></li>
                            <li><NavLink to={'/admin/address_book'} ><FontAwesomeIcon icon={faAddressBook} />AddressBook</NavLink></li>
                            <li><NavLink to={'/admin/manage_customer'} ><FontAwesomeIcon icon={faIdCardClip} />Manage Customer</NavLink></li>
                            <li><NavLink to={'/admin/crm'} ><FontAwesomeIcon icon={faEnvelopesBulk} />CRM</NavLink></li>
                            <li><NavLink to={'/admin/customer-wishlist'} ><FontAwesomeIcon icon={faHeartCircleBolt} />Customer Wishlist</NavLink></li>

                            <li><NavLink to={'/admin/manage_complains'} ><FontAwesomeIcon icon={faTriangleExclamation} />Manage Complains</NavLink></li>
                            <li><NavLink to={'/admin/supplier'} ><FontAwesomeIcon icon={faIdBadge} />Supplier</NavLink></li>
                            <li><NavLink to={'/admin/setting'} ><FontAwesomeIcon icon={faGears} />Setting</NavLink></li>
                            <li><NavLink to={'/admin/pages'} ><FontAwesomeIcon icon={faGears} />Pages</NavLink></li>
                            <li><NavLink to={'/admin/page-content'} ><FontAwesomeIcon icon={faGears} />Page Content</NavLink></li>
                            <li><NavLink to={'/admin/otherlinks'} ><FontAwesomeIcon icon={faGears} />Other Links</NavLink></li>

                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CommonParts

async function getLink(name, setLink) {
    if (name) {
        let imageInfo =  await GetUserImage({image_name:name})
        if (imageInfo.imageExist) {
            setLink(imageInfo.url)
        }else{
            setLink(Avatar)
        }
    }else{
        setLink(Avatar)
    }
}
const AvatarComp = ({ name }) => {
    const [image, setImage] = useState()
   
    useEffect(() => {
        getLink(name, setImage)
    }, [name])
    return (
        <>
            <img src={image} alt='avata' />
        </>
    )
}