import Select from 'react-select'
import addnewCss from './addnew.module.css'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { fetchCategory, selectCategoryList } from '../../../ReduxFiles/Slices/CategorySlice'
import { selectAttributeNameList } from '../../../ReduxFiles/Slices/MAU_Slice'
import { GetCategoryImageLink, UpdateCategory } from '../../../api/CategoryApi'

const UpdateCategoryPop = ({popUp, setPopUp}) => {
    const allCategory = useSelector(selectCategoryList)
    const attributeArray = useSelector(selectAttributeNameList)
    const dispatch = useDispatch()
    function CapitalizeLatter(myString) {
        let firstChar = myString.substring(0, 1);
        firstChar = firstChar.toUpperCase();
        let tail = myString.substring(1).toLowerCase(); 
        myString = firstChar + tail; 
        return myString
    }

    async function onDataSubmit(e) {
        e.preventDefault();
        const form = e.target
        const catImage = form.catImage.files
        const catBanner = form.catBanner.files
        const catIcon = form.catIcon.files
        const catName = form.catName.value.trim()
        const catDescriptioon = form.catDescriptioon.value.trim()
        const catSlug = form.catSlug.value.trim()
        const code = form.code.value
        const selectedItem = form.selectedItem.value
        const SelectedAttribute = form.SelectedAttribute.value
        const finalData = {
            catName:CapitalizeLatter(catName),
            catDescriptioon,
            catSlug:catSlug.replaceAll(" ", "_"),
            code,
            selectedItem,
            SelectedAttribute,
        }
        try {
            await UpdateCategory(popUp.id, finalData, catImage[0], catIcon[0], catBanner[0])
            toast.success('Category Updated!')
            dispatch(fetchCategory())
            setPopUp()
            document.body.style.overflow='auto'
        } catch (error) {
            toast.error(error.message)
        }
        
    }
  return (
    <div className={addnewCss.wrapper}>
       <div className={addnewCss.holder}>
             <h3>Update Category</h3>
             <form className={addnewCss.my_form} onSubmit={onDataSubmit}>
                <div className={addnewCss.input_holder}>
                    <label>Category Name*</label>
                    <input name='catName' type='text' required defaultValue={popUp.catName}/>
                </div>
                <div className={addnewCss.input_holder}>
                    <label>Category Description*</label>
                    <input name='catDescriptioon' type='text' required defaultValue={popUp.catDescriptioon}/>
                </div>
                <div className={addnewCss.input_holder}>
                    <label>Category Slug*</label>
                    <input name='catSlug' type='text' required defaultValue={popUp.catSlug}/>
                </div>
                <div className={addnewCss.input_holder}>
                    <label>Category Code*</label>
                    <input name='code' type='text' required defaultValue={popUp.code}/>
                </div>
                <div className={addnewCss.input_holder}>
                    <label>Select Item Category*</label>
                    <Select
                    name='selectedItem'
                    options={allCategory}
                    getOptionLabel={(option)=>`${option.catName}`}
                    getOptionValue={(option)=>`${option.id}`}
                    className={addnewCss.my_select}
                    defaultValue={{id:popUp.selectedItem, catName:popUp.selected_cat}}
                    />
                </div>
                <div className={addnewCss.input_holder}>
                    <label>Select Attribute*</label>
                    <Select
                    name='SelectedAttribute'
                    options={attributeArray}
                    getOptionLabel={(option)=>`${option.att_name}`}
                    getOptionValue={(option)=>`${option.id}`}
                    className={addnewCss.my_select}
                    defaultValue={{id:popUp.SelectedAttribute, att_name:popUp.att_name}}
                    required
                    />
                </div>
                <div className={addnewCss.input_holder}>
                    <label>Category Image*</label>
                    
                    <div className={addnewCss.image_wrapper}>
                        <input name='catImage' type='file'/>
                        <ImageComp my_class={addnewCss.show_image_sqr} name={popUp.catImage} />
                    </div>
                </div>
                <div className={addnewCss.input_holder}>
                    <label>Catagory Icon*</label> 
                    <div className={addnewCss.image_wrapper}>
                        <input name='catIcon' type='file'/>
                        <ImageComp my_class={addnewCss.show_image_sqr} name={popUp.catIcon} />
                    </div>
                </div>
                <div className={addnewCss.input_holder}>
                    <label>Category Banner*</label>
                    <div className={addnewCss.image_wrapper}>
                        <input name='catBanner' type='file'/>
                        <ImageComp my_class={addnewCss.show_image} name={popUp.catBanner} />
                    </div>
                </div>
                <div className={addnewCss.btn_group}>
                <button type='button' onClick={()=>{setPopUp(false); document.body.style.overflow='auto'}}>Close</button>
                    <button type='submit'>Submit</button>
                </div>
             </form>
       </div>
    </div>
  )
}

export default UpdateCategoryPop


const ImageComp = ({ name, my_class }) => {
    const [image, setImage] = useState()
    async function getLink() {
        if (name) {
            let imageInfo = await GetCategoryImageLink({ image_name: name })
            if (imageInfo.imageExist) {
                setImage(imageInfo.url)
            } else {
                setImage('')
            }
        } else {
            setImage('')
        }
    }
    useEffect(() => {
        getLink()
    }, [name])
    return (
        <div className={my_class}>
            {image ? <img  src={image} alt={name} /> : ''}
        </div>
    )
}
