import React, { useEffect, useState } from 'react'
import { GetWishListAllData, UpdateWishListData } from '../../api/WishListApi'
import CustomerWishlistCss from './CustomerWishlist.module.css'

const CustomerWishlist = () => {
    const [wishlist, setWishList] = useState([])
    async function getData() {
        let data = await GetWishListAllData()
        setWishList(data)
    }
    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        myDate = myDate.replaceAll('/', '-')
        return myDate
    }
    const [noteData, setNotedata] = useState()
    const [updateData, setUpdatedata] = useState()

    function onViewNote(item) {
        setNotedata(item)
    }
    function onUpdateNote(item) {
        setUpdatedata(item)
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div className={CustomerWishlistCss.tableSection}>
            {updateData ? <UpdateNote item={updateData} setitem={setUpdatedata} /> : ''}
            {noteData ? <ViewNote item={noteData} setitem={setNotedata} /> : ''}
            <h2>Customer Wishlist Data</h2>
            <table>
                <thead>
                    <tr>
                        <th>SL</th>
                        <th>product name</th>
                        <th>customer Name</th>
                        <th>customer Phone</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {wishlist?.map((item, index) => {
                        return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.en_sort_title}</td>
                            <td>{item.name}</td>
                            <td>{item.phone}</td>
                            <td>{convertDate(item.created_date / 1000)}</td>
                            <td style={{ display: 'flex', gap: '10px' }}>
                                <button onClick={() => { onUpdateNote(item); document.body.style.overflow = 'hidden' }}> Update Note</button>
                                <button style={{ backgroundColor: 'green' }} onClick={() => { onViewNote(item); document.body.style.overflow = 'hidden' }}>View Note</button>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>

    )
}

export default CustomerWishlist




const UpdateNote = ({ item, setitem }) => {
    const [noteData, setNoteData] = useState(item?.note || '')
    function onNoteChange(e) {
        let value = e.target.value
        setNoteData(value)
    }
    async function onUpdateClick() {
        document.body.style.overflow = 'auto'
        await UpdateWishListData(item.id, { note: noteData })
        setitem()
        window.location.reload(false)
    }
    return <div className={CustomerWishlistCss.pop_up_wrapper}>
        <div className={CustomerWishlistCss.pop_up}>
            <textarea name='note' placeholder='your note here' style={{ width: '96%' }} rows="10" cols="50" onChange={onNoteChange} value={noteData}></textarea>
            <div className={CustomerWishlistCss.action_btns}>
                <button style={{ backgroundColor: 'green', marginRight: '20px' }} onClick={onUpdateClick}>Update</button>
                <button onClick={() => { setitem(); document.body.style.overflow = 'auto' }}>Close</button>
            </div>
        </div>
    </div>

}

const ViewNote = ({ item, setitem }) => {

    return <div className={CustomerWishlistCss.pop_up_wrapper}>
        <div className={CustomerWishlistCss.pop_up}>
            <h3>{item?.note || 'no note'}</h3>
            <div className={CustomerWishlistCss.action_btns}>
            <button onClick={() => { setitem(); document.body.style.overflow = 'auto' }}>Close</button>

            </div>
        </div>
    </div>

}

