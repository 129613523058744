import React from 'react'
import { Link, NavLink, Route, Routes } from 'react-router-dom'
import ProductListing from './ProductListing'
import ListItemPDFView from './ListItemPDFView'
import GetImageName from '../../OthesPageNoNeed/GetImageName'
import CSVReader from './CSVReader'
import CatelogListing from './CatelogListing'
import CatelogPDFView from './CatelogPDFView'
import OtherLinksCss from './Css/OtherLinks.module.css'

const OtherLinks = () => {
  return (
    <div>
        <div className={OtherLinksCss.other_menu}>
            <NavLink to={'product-listing'}>product listing</NavLink>
            <NavLink to={'imagenameget'}>Get Image Name</NavLink>
            <NavLink to={'csv-reader'}>Read CSV File</NavLink>
            <NavLink to={'create-catelog'}>Create Catelog</NavLink>
        </div>
      <Routes>
        <Route path='product-listing' element={<ProductListing />} />
        <Route path='product-listing/view' element={<ListItemPDFView />} />
        <Route path='imagenameget' element={<GetImageName />} />
        <Route path='csv-reader' element={<CSVReader />} />
        <Route path='create-catelog' element={<CatelogListing />} />
        <Route path='create-catelog/view' element={<CatelogPDFView />} />
      </Routes>

    </div>
  )
}

export default OtherLinks