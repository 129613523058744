import { faCircleCheck, faCircle} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import DebitVoucherCustomer from './DebitVoucherCustomer'

import DebitVoucherSupplier from './DebitVoucherSupplier'
import DebitVoucherCss from'./Css/DebitVoucher.module.css'
import { toast } from 'react-toastify';

const DebitVoucher = () => {
    const [selectedType, setSelectedType] = useState('customer')
    function onTypeClick(text) {
        if(text == 'supplier'){
          return  toast.warn('This is working 👀')
        }
           setSelectedType(text)
    }

    return (
        <>
            <section className={DebitVoucherCss.heading}>
                <div className={`${DebitVoucherCss.inner_headign}`}>
                    <h2 className={DebitVoucherCss.headign_h2}>Debit Voucher</h2>
                    <div className={`${DebitVoucherCss.input_section} ${DebitVoucherCss.selection_section}`}>
                        <label>Select Type : </label>
                        <div className={DebitVoucherCss.icon_sec}>
                            {selectedType === 'customer' ? <span><FontAwesomeIcon className={DebitVoucherCss.color_green} icon={faCircleCheck} /> <p>Customer</p></span> : <span onClick={() => { onTypeClick('customer') }}><FontAwesomeIcon icon={faCircle} /> <p>Customer</p></span>}
                        </div>
                        <div className={DebitVoucherCss.icon_sec}>
                            {selectedType === 'supplier' ? <span><FontAwesomeIcon className={DebitVoucherCss.color_green} icon={faCircleCheck} /> <p>Supplier</p></span> : <span onClick={() => { onTypeClick('supplier') }}><FontAwesomeIcon icon={faCircle} /> <p>Supplier</p></span>}
                        </div>
                    </div>
                </div>
            </section>
            {selectedType ==='supplier' ? <DebitVoucherSupplier />:''}
            {selectedType ==='customer' ? <DebitVoucherCustomer/>:''}
        </>
    )
}

export default DebitVoucher
/*

<h2 className='headign_h2'>Payment</h2>
            <div className='input-section selection_section'>
                    <label>Select Type : </label>
                    <div className='icon_sec'>
                        {selectedType ==='invoice' ? <span><FontAwesomeIcon className='color_green' icon={faCircleCheck}/> <p>Invoice No</p></span>:<span onClick={()=>{onTypeClick('invoice')}}><FontAwesomeIcon icon={faCircle}/> <p>Invoice No</p></span>}
                    </div>
                    <div className='icon_sec'>
                    {selectedType ==='pono' ? <span><FontAwesomeIcon className='color_green' icon={faCircleCheck}/> <p>PO No</p></span>:<span onClick={()=>{onTypeClick('pono')}}><FontAwesomeIcon icon={faCircle}/> <p>PO No</p></span>}
                    </div>
                </div>
*/