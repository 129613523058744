import React, { useEffect, useState } from 'react'
import { GetPageContent, UpdatePageContent } from '../../api/PagesApi'
import { Link, Route, Routes } from 'react-router-dom';
import PageContentEdit from './PageContentEdit';
import ToggleSwitch from '../smallThings/ToggleSwitch';
import { toast } from 'react-toastify';
import pageContentMainCss from './css/PageContentMain.module.css'
import PopularEdit from './models/PopularEdit';

async function getData(setData) {
    const data = await GetPageContent()
    setData(data)
}

const PageContentMain = () => {

    return (
        <div>
            <Routes>
                <Route path='/' element={<MainView />} />
                <Route path='/edit' element={<PageContentEdit />} />
            </Routes>
        </div>
    )
}

export default PageContentMain

const MainView = () => {
    const [pageData, setPageData] = useState([])
    async function onToggleClick(item) {

        try {
            await UpdatePageContent(item.content_id, { is_visible: !item.is_visible })
            toast.success('Data updated')
            getData(setPageData)
        } catch (error) {
            toast.error(error.message)
        }
    }
    const [editData, setEditData] = useState()
    function onEditClick(item) {
        setEditData(item)
        document.body.style.overflow = 'hidden'
    }

    useEffect(() => {
        getData(setPageData)
    }, [editData])
    return <div className={pageContentMainCss.tableSection}>
       { editData && <PopularEdit popUp={editData} setPopUp={setEditData}/>}
        <table>
            <thead>
                <tr>
                    <th>SL</th>
                    <th>Page Id</th>
                    <th>Page Name</th>
                    <th>Content</th>
                    <th>Popular Search</th>
                    <th>Visible</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {pageData.map((item, index) => {
                    return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.page_id}</td>
                        <td>{item.catName ? item.catName : 'Home'}</td>
                        <td>{item.my_content}</td>
                        <td><div className={pageContentMainCss.popular_edit}>
                            {item.popular_search?.slice(0, 100)}
                            <button onClick={()=>onEditClick(item)}>Edit</button>
                        </div></td>
                        <td><ToggleSwitch toggleOnOff={item.is_visible} toggleClick={onToggleClick} item={item} /></td>
                        <td><Link className={pageContentMainCss.edite_btn} to={`edit?content_id=${item.content_id}`}>Edit</Link></td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}