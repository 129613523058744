import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import CommonParts from './CommonParts'
import ItemManagement from './AllContent/item_management/ItemManagement'
import DashContent from './AllContent/DashContent'
import Admin_footer from './AllContent/admin_footer'
import SettingMain from './AllContent/Setting/SettingMain'
import SalesMain from './AllContent/sales/SalesMain'
import ManageUserMain from './AllContent/ManageUser/ManageUserMain'
import DeliveryAgentMain from './AllContent/deliveryManagment/DeliveryAgentMain'
import DeliveryDetailsView from './AllContent/deliveryManagment/DeliveryDetailsView'
import SupplierMain from './AllContent/supplier/SupplierMain'
import PurchaseMain from './AllContent/purchase/PurchaseMain'
import PurchaseEdit from './AllContent/purchase/PurchaseEdit'
import ManageCustomerMain from './AllContent/manageCustomer/ManageCustomerMain'
import StockInfoMain from './AllContent/StockInfo/StockInfoMain'
import ManageComplainMain from './AllContent/ManageComplains/ManageComplainMain'
import ReportMain from './AllContent/Report/ReportMain'
import AccountMain from './AllContent/Accounts/AccountMain'
import AccountNavigate from './AllContent/Accounts/AccountNavigate'
import BankMain from './AllContent/Banks/BankMain'
import ExpenseMain from './AllContent/Expense/ExpenseMain'
import PagesMain from './AllContent/pages/PagesMain'
import AllCombineCss from './MainCss/AllCombine.module.css'
//
import OfferMain from './AllContent/sales/salesOffer/OfferMain'
import CrmMain from './AllContent/CRM/CrmMain'
import OtherLinks from './AllContent/othesLinks/OtherLinks'
import AddressBook from './AllContent/ManageUser/AddressBook'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllUser, selectIsLogedIn, setIsLogedIn, setUserInfo } from './ReduxFiles/Slices/UserSlice'
import { DecryptLocalData } from './api/BackendUserApi'
import { fetchProducts, setProductList, setProductStatus } from './ReduxFiles/Slices/ProductSlice'
import { fetchCategory, selectCategoryStatus } from './ReduxFiles/Slices/CategorySlice'
import { fetchBrands } from './ReduxFiles/Slices/BrandListSlice'
import CustomerWishlist from './AllContent/CustomerWishlist/CustomerWishlist'
import PageContentMain from './AllContent/pagecontent/PageContentMain'
import BogoMain from './AllContent/sales/salesOffer/bogo/BogoMain'
const AllCombine = () => {
    const isLogedIn = useSelector(selectIsLogedIn)
    const categoryStatus = useSelector(selectCategoryStatus)
    const myDispatch = useDispatch()
    const myNavigation = useNavigate()
   async function getAllProductList() {
        if (!isLogedIn) {
            const localUserData =  window.localStorage.getItem('userData')
         if (localUserData) {
            const decrytedData = DecryptLocalData(localUserData)
            let finalData = decrytedData && JSON.parse(decrytedData)
            myDispatch(setIsLogedIn(true))
            myDispatch(setUserInfo(finalData))
            if (finalData.userName) {
               let localProducts =  window.localStorage.getItem('allProduct')
               if (localProducts && !localProducts ==='undefined') {
                    myDispatch(setProductList(JSON.parse(localProducts)))
                    myDispatch(setProductStatus('success'))
               }else{
                   myDispatch(fetchProducts())
               }
               myDispatch(fetchAllUser())
            }else{
                myNavigation('/login')
            } 
         }else{
            myNavigation('/login')
        }
        }

    }  
  
    function getPrimaryData() {
       
        if (categoryStatus==='idle') {
           myDispatch(fetchCategory())
           myDispatch(fetchBrands())
        }
        
    }

    useEffect(()=>{
        getPrimaryData()
    },[])

    useEffect(()=>{
        getAllProductList()
    },[isLogedIn])

    return (
            <div className={AllCombineCss.container}>
                {isLogedIn ? <CommonParts />:''}
                <div className={AllCombineCss.Spacer}>
                    <Routes>
                        {/* DashBoard Here */}
                        <Route path='/' element={<DashContent />} />

                        {/* item Management here */}
                        <Route path='/admin/itemmanagement/*' element={<ItemManagement />} />

                        {/* sales here */}
                        <Route path='/admin/sales/*' element={<SalesMain />} />
                        

                        {/* Sales Offer Here */}
                        <Route path='/admin/sales_offers/*' element={<OfferMain />} />
                        <Route path='/admin/bogo_offers/*' element={<BogoMain />} />
                        
                        {/* Purchase here */}
                        <Route path='/admin/purchase/*' element={<PurchaseMain />} />
                        <Route path='/admin/purchase/:mpurchase/:poid' element={<PurchaseEdit />} />
                        <Route path='/admin/purchase/:mpurchase/:poid/:action' element={<PurchaseEdit />} />

                        {/* Stock Information here */}
                        <Route path='/admin/stockinformation' element={<StockInfoMain text={'Stock Information'}/>} />
                        <Route path='/admin/stockinformation/:mstock' element={<StockInfoMain text={'Stock Information'}/>} />

                        {/* Report here */}
                        <Route path='/admin/report/*' element={<ReportMain/>} />
                        {/* <Route path='/admin/report/:reportData' element={<ReportNavigate/>} />
                        <Route path='/admin/report/:reportData/:reportShow' element={<ReportShow/>} />
                        <Route path='/admin/report/:reportData/:reportShow/:otherData' element={<ReportShow/>} /> */}

                        {/* Accounts here */}
                        <Route path='/admin/accounts' element={<AccountMain/>} />
                        <Route path='/admin/accounts/:function' element={<AccountNavigate />} />
                        <Route path='/admin/accounts/:function/:action' element={<AccountNavigate />} />

                        {/* Banks here */}
                        <Route path='/admin/banks' element={<BankMain/>} />
                        <Route path='/admin/banks/:mybanks' element={<BankMain/>} />
                        {/* Expence here */}
                        <Route path='/admin/expenses/*' element={<ExpenseMain/>} />
           
                        {/* Delivery Agent */}
                        <Route path='/admin/deliveryagent' element={<DeliveryAgentMain />} />
                        <Route path='/admin/deliveryagent/:agentdata' element={<DeliveryAgentMain />} />
                        <Route path='/admin/deliveryagent/:agentdata/:orderid' element={<DeliveryDetailsView />} />

                        {/* manage User  */}
                        <Route path='/admin/manage_user' element={<ManageUserMain />} />
                        <Route path='/admin/manage_user/:muser' element={<ManageUserMain />} />
                        <Route path='/admin/address_book/*' element={<AddressBook/>}/>
                        
                        {/* manage Customer  */}
                        <Route path='/admin/manage_customer' element={<ManageCustomerMain />} />
                        <Route path='/admin/manage_customer/:muser' element={<ManageCustomerMain />} />

                        {/*CRM Here  */}
                        <Route path='/admin/crm' element={<CrmMain/>}/>
                        <Route path='/admin/customer-wishlist' element={<CustomerWishlist/>}/>

                        {/* Manage Complains  */}
                        <Route path='/admin/manage_complains' element={<ManageComplainMain />} />
                        <Route path='/admin/manage_complains/:mcomplain' element={<ManageComplainMain />} />
                        <Route path='/admin/manage_complains/:mcomplain/:actions/:dataid' element={<ManageComplainMain />} />

                        {/* Supplier Here */}
                        <Route path='/admin/supplier' element={<SupplierMain />} />
                        <Route path='/admin/supplier/:msupplier' element={<SupplierMain />} />
                        
                        {/* setting here */}
                        <Route path='/admin/setting' element={<SettingMain />} />
                        <Route path='/admin/setting/:msetting' element={<SettingMain />} />
                        {/* pages here */}
                        <Route path='/admin/pages/*' element={<PagesMain />} />
                        <Route path='/admin/page-content/*' element={<PageContentMain />} />
                        

                        {/* Extra Page here */}
                        <Route path='/admin/otherlinks/*' element={<OtherLinks />} />
                       

                        <Route path='/*' element={<NoRoute />} />
                    </Routes>
                    
                </div>
                <Admin_footer /> 
            </div>
    )
}

export default AllCombine

const ComingSoon = ({text})=>{
    return <h2>{text} Coming Soon</h2>
}
const NoRoute = ()=>{
    const myNavigate = useNavigate()
    useEffect(()=>{
        myNavigate('/404.html')
        window.location.reload();
    },[])
    return <h2>No Route Found</h2>
}