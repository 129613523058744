import React from 'react'
import ConfirmAlertCss from './css/ConfirmAlert.module.css'

const ConfirmAlert = (props) => {
    const { yesClick, noClick, delID } = props

    return (
        <div className={ConfirmAlertCss.conAlert_wrapper}>
            <div className={ConfirmAlertCss.conAlert}>
                <h2 className={ConfirmAlertCss.caption}>Do you Want To Delete This?</h2>
                <div className={ConfirmAlertCss.buttonsec}>
                    <input sid={delID} onClick={yesClick} className={ConfirmAlertCss.yes} type={"submit"} value={"Yes"} />
                    <input onClick={noClick} className={ConfirmAlertCss.no} type={"submit"} value={"No"} />
                </div>

            </div>
        </div>
    )
}

export default ConfirmAlert